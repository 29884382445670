import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { quoteToScheduled } from './api'
import { useParams } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../../context/context'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

interface IQuoteToScheduledProps {
  handleClose: Function
}
export default function QuoteToSchedule(props: IQuoteToScheduledProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData } = useContext(WoDetailContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const handleQuoteToScheduled = async () => {
    const orderId = id ?? ''
    try {
      setIsLoading(true)
      await fetchData<IOrder>(quoteToScheduled(orderId, 'scheduled'))
      await queryOrderData(orderId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order status changed to scheduled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error changing order status!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>
          Yes I want to change this work order to scheduled (this is mainly to
          fix an order stuck in quote)
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Schedule'
          disabled={!isChecked || isLoading}
          onClick={handleQuoteToScheduled}
        />
      </div>
    </div>
  )
}
