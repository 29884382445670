import { IAlertStatusProps } from '../../../../../global/components/toast-alert/ToastNotification'
import { fetchData } from '../../../../../global/utils/fetch'
import { deleteEntity } from '../../../api'
import getEndpointPath from './get-endpoint-path'

async function deleteRow(
  entityName: string,
  rowIndex: number,
  tableState: any,
  toastStatus: IAlertStatusProps,
  setToastStatus: Function,
  retrieveTableData: Function,
) {
  const rowToDelete = tableState.data[rowIndex]

  const endpointPath = getEndpointPath(entityName)
  const deleteApiCall = deleteEntity(endpointPath, rowToDelete.objectId)

  try {
    const deleteResponse = await fetchData(deleteApiCall)

    setToastStatus({
      ...toastStatus,
      isOpen: true,
      message: [
        'Row deleted successfully! You will be emailed when the change',
        'is visible in Tableau.',
      ].join(' '),
      severity: 'info',
    })
    retrieveTableData()
  } catch (error) {
    console.error(error)
    setToastStatus({
      ...toastStatus,
      isOpen: true,
      message: [
        'An error occurred while deleting the entity. Please try again.',
        'If the problem persists, contact support.',
      ].join(' '),

      severity: 'error',
    })
  }
}

export default deleteRow
