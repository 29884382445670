import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import CircularProgress from '@mui/material/CircularProgress'
import { adjustInvoices } from '../../../../../../api'
import { useParams } from 'react-router-dom'
import { ToastNotificationContext } from '../../../../../../../../global/context/toast-context/ToastNotificationContext'
import { WoDetailContext } from '../../../../../../context/context'
import { fetchData } from '../../../../../../../../global/utils/fetch'
import { IInvoice } from '../../../../../../../../../../app/entities/Invoice'

interface IAdjustInvoiceProps {
  isOpen: boolean
  handleClosed: Function
  refundAmount: number | string
  allocations: Record<string, number>
  adjustmentInvoice: Partial<IInvoice>
}

export default function AdjustInvoiceConfirmationModal({
  isOpen,
  handleClosed,
  refundAmount,
  allocations,
  adjustmentInvoice,
}: IAdjustInvoiceProps) {
  const { id: orderObjectId } = useParams() as any
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { queryOrderData } = useContext(WoDetailContext)
  const [isLoading, setIsLoading] = useState(false)

  // console.log('refundAmount', refundAmount)
  // console.log('allocations', allocations)
  // console.log('adjustmentInvoice', adjustmentInvoice)

  // const balance =

  function handleClosing() {
    handleClosed()
  }

  async function handleSubmit() {
    setIsLoading(true)
    let response: any = undefined

    try {
      response = await fetchData(
        adjustInvoices(orderObjectId, adjustmentInvoice),
      )

      if (!response?.success) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: response.message,
          severity: 'error',
        })
      } else if (adjustmentInvoice.tip) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: 'Tip successfully adjusted!',
          severity: 'success',
        })
      } else {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: 'Service amount successfully adjusted!',
          severity: 'success',
        })
      }

      if (orderObjectId) {
        queryOrderData(orderObjectId)
      }
    } catch (error) {
      console.log('error', error)
      alert(error)
    }

    setIsLoading(false)
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed()}
      >
        <Box className={styles.scheduleModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            Are you sure?
          </div>
          <div className={styles.text}>
            This adjustment will result in a balance of -${refundAmount}. A
            refund will be issued for this amount. Are you sure you want to
            proceed?
          </div>
          {isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={handleClosing}
            />
            <PrimaryButton
              buttonName="Yes I'm sure."
              disabled={isLoading}
              onClick={() => handleSubmit()}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
