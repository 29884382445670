import { useState } from 'react'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import { TextField } from '@mui/material'
import styles from './styles.module.css'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../global/utils/fetch'
import { getDriveTimeDetails } from '../api'

interface IDriveTimeDetails {
  numberOfStops: number
  numberOfRoutes: number
  totalDriveTimeInSeconds: number
}

export default function DriveTimesCalculator() {
  const [form, setForm] = useState({
    fromDate: new Date().toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0],
    locationObjectId: '',
  })

  const [driveTimeDetails, setDriveTimeDetails] =
    useState<IDriveTimeDetails | null>(null)

  const [loading, setLoading] = useState(false)

  const dateSelectors = ['fromDate', 'toDate'].map((dateSelector) => (
    <TextField
      type='date'
      label={dateSelector === 'fromDate' ? 'From Date' : 'To Date'}
      name='date'
      value={(form as any)[dateSelector]}
      onChange={(e) => setForm({ ...form, [dateSelector]: e.target.value })}
      InputLabelProps={{ shrink: true }}
      fullWidth
      key={dateSelector}
    />
  ))

  async function calculateDriveTimes() {
    const { fromDate, toDate, locationObjectId } = form

    if (!locationObjectId || !fromDate || !toDate) {
      return alert('Missing required parameters')
    }

    setLoading(true)
    try {
      const response = (await fetchData(
        getDriveTimeDetails(locationObjectId, fromDate, toDate),
      )) as IDriveTimeDetails
      setDriveTimeDetails(response)
    } catch (error) {
      console.error(error)
      alert('Error calculating drive times')
    }

    setLoading(false)
  }

  let driveTimeDisplay = null

  if (loading) {
    driveTimeDisplay = <p>Loading...</p>
  } else if (driveTimeDetails) {
    const { totalDriveTimeInSeconds, numberOfRoutes, numberOfStops } =
      driveTimeDetails
    driveTimeDisplay = (
      <div className={styles.driveTimeDetailsContainer}>
        <div>
          <h3>Average Drive Time</h3>
          <p>
            {(
              totalDriveTimeInSeconds /
              (numberOfStops - numberOfRoutes) /
              60
            ).toFixed(2)}{' '}
            minutes
          </p>
        </div>
        <div>
          <h3>Number of Routes</h3>
          <p>{numberOfRoutes}</p>
        </div>
        <div>
          <h3>Number of Stops</h3>
          <p>{numberOfStops}</p>
        </div>
        <div>
          <h3>Cost</h3>
          <p>${((numberOfStops - numberOfRoutes) / 100).toFixed(2)}</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h2>Drive Times Calculator</h2>
      <div className={styles.inputsContainer}>
        <LocationDropDown
          onLocationChange={(locationObjectId: any) =>
            setForm({ ...form, locationObjectId })
          }
        />
        {dateSelectors}
        <PrimaryButton
          buttonName='Calculate Drive Times'
          onClick={calculateDriveTimes}
        />
      </div>
      <div className={styles.driveTimeDetailsContainer}>{driveTimeDisplay}</div>
    </div>
  )
}
