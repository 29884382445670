import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import ServiceDropDown from '../../../../global/components/service-drop-down/ServiceDropDown'
import { useState } from 'react'
import { getLocaleTimeString } from '../../../../global/utils/date/dates'
import { ILocationServiceOrRule } from '../../../../../../app/types/location-service-or-rule'
import isEntityALocationService from '../../../table/utils/is-entity-a-location-service'
interface IPriceChangeFormProps {
  formData: any
  setFormData: Function
  chosenLocationServiceOrRule: ILocationServiceOrRule | null
}
export default function PriceChangeForm({
  formData,
  setFormData,
  chosenLocationServiceOrRule,
}: IPriceChangeFormProps) {
  const isAService = isEntityALocationService(chosenLocationServiceOrRule)

  const [dayDate, setDayDate] = useState<string | null>(
    new Date().toISOString().slice(0, 10),
  )
  const [dayTime, setDayTime] = useState<string | null>(getLocaleTimeString())

  function setTimeComponent(event: any) {
    const { name, value } = event.target

    if (!value) return

    const isDate = name === 'date'
    const localSetter = isDate ? setDayDate : setDayTime
    localSetter(event.target.value)

    const dateVal = isDate ? value : dayDate
    const timeVal = isDate ? dayTime : value

    const created = new Date(`${dateVal} ${timeVal}`)
    setFormData({ ...formData, created })
  }

  return (
    <div className={styles.formContainer}>
      <LocationDropDown
        fullWidth
        defaultValue={chosenLocationServiceOrRule?.location?.objectId}
        disabled={true}
      />
      {isAService && (
        <ServiceDropDown
          fullWidth
          defaultValue={chosenLocationServiceOrRule?.service?.objectId}
          disabled={true}
        />
      )}
      <TextField
        type='number'
        label='New Price'
        onChange={(e: any) =>
          setFormData({ ...formData, price: Number(e.target.value) })
        }
      />
      <b>When should this price change take effect?</b>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '1rem',
        }}
      >
        <TextField
          onChange={setTimeComponent}
          value={dayDate}
          InputLabelProps={{ shrink: true }}
          type='Date'
          label='Date'
          name='date'
          sx={{
            width: 140,
          }}
        />
        <TextField
          onChange={setTimeComponent}
          value={dayTime}
          InputLabelProps={{ shrink: true }}
          type='time'
          label='Time'
          name='time'
          sx={{
            width: 128,
          }}
        />
      </div>
    </div>
  )
}
