import { useContext, useState } from 'react'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import Table from '../../../../../global/components/table/Table'
import CreateZoneDefRow from './CreateZoneDefRow'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import { IZoneDefProps } from '../container-modal/LocationModal'
import { Column } from 'react-table'
import { IZoneDefinition } from '../../../../../../../app/entities/ZoneDefinition'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../global/constants/permissions'

interface IZoneDefTableProps {
  zoneDefProps: IZoneDefProps
  isEditOrCreate: boolean
}

export default function ZoneDefinitionTable(props: IZoneDefTableProps) {
  const { user } = useContext(UserContext)
  const {
    zoneDefProps: { zoneDefinitionsData, setZoneDefinitionsData },
  } = props
  const [row, setRow] = useState<JSX.Element | null>(null)
  const [editingRowIndex, setEditingRowIndex] = useState(-1)
  const [isEditing, setIsEditing] = useState(false)

  const isZoneDefinitionsDisabled =
    props.isEditOrCreate ||
    !hasPermission(user).edit(
      PERMISSIONS.LOCATIONS.CATEGORY,
      PERMISSIONS.LOCATIONS.ENTRIES.ZONE_DEFINITIONS.NAME,
    )
  const isZoneDefinitionsViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.ZONE_DEFINITIONS.NAME,
  )

  function cancelZoneDefRow() {
    return setRow(null)
  }

  function addZoneDefRow() {
    return setRow(
      <CreateZoneDefRow
        cancel={cancelZoneDefRow}
        zoneDefProps={{ zoneDefinitionsData, setZoneDefinitionsData }}
      />,
    )
  }
  function handleEditRow(rowIndex: number) {
    setEditingRowIndex(rowIndex)
    setIsEditing(true)
  }
  function handleCancelEdit() {
    setEditingRowIndex(-1)
    setIsEditing(false)
  }
  function handleChange(
    newValue: string | boolean,
    valueName: string,
    rowIndex: number,
  ) {
    setZoneDefinitionsData((prevState: any) => {
      const newZoneDef = prevState.zoneDefinitions.map(
        (zoneDefObj: any, index: number) => {
          if (index === rowIndex) {
            if (valueName === 'type') {
              return { ...zoneDefObj, [valueName]: newValue }
            } else {
              return { ...zoneDefObj, [valueName]: Number(newValue) }
            }
          }
          return zoneDefObj
        },
      )
      return { ...prevState, zoneDefinitions: newZoneDef }
    })
  }
  function handleRemoveZoneDef(index: number) {
    setZoneDefinitionsData((prevState: any) => {
      const newZoneDefs = [...prevState.zoneDefinitions]
      const deletedItem = newZoneDefs.splice(index, 1)
      const newDeletedSettings = [...prevState.deleted, deletedItem]
      return {
        ...prevState,
        zoneDefinitions: newZoneDefs,
        deleted: newDeletedSettings,
      }
    })
    setEditingRowIndex(-1)
  }
  function handleSaveEditRow(rowIndex: number) {
    let updatedZoneDef = zoneDefinitionsData.zoneDefinitions.filter(
      (zoneDefObj: any, index: number) => index === rowIndex,
    )
    console.log(updatedZoneDef)
    setZoneDefinitionsData((prevState: any) => {
      const updatedCopy = prevState.updated.map((zoneDef: any) => {
        if (zoneDef.objectId === updatedZoneDef[0].objectId) {
          return updatedZoneDef[0]
        } else {
          return zoneDef
        }
      })
      console.log(updatedCopy)
      if (!updatedCopy.includes(updatedZoneDef[0])) {
        updatedCopy.push(updatedZoneDef[0])
      }
      return { ...prevState, updated: updatedCopy }
    })
    setIsEditing(false)
    setEditingRowIndex(-1)
  }
  const columns: Column<IZoneDefinition>[] = [
    {
      Header: 'Type',
      accessor: (data: any, rowIndex: number) => {
        const { type } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='type'
            value={type}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'From Distance',
      accessor: (data: any, rowIndex: number) => {
        const { fromDistance } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='fromDistance'
            value={fromDistance}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'To Distance',
      accessor: (data: any, rowIndex: number) => {
        const { toDistance } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='toDistance'
            value={toDistance}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'Width',
      accessor: (data: any, rowIndex: number) => {
        const { width } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='width'
            value={width}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'Max Routes',
      accessor: (data: any, rowIndex: number) => {
        const { maxRoutes } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='maxRoutes'
            value={maxRoutes || ''}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: ' ',
      accessor: (data: any, rowIndex: number) => {
        return (
          <div className={styles.buttonCell}>
            {isEditing && rowIndex === editingRowIndex ? (
              <button
                className={styles.button}
                onClick={() => handleCancelEdit()}
                disabled={isZoneDefinitionsDisabled}
              >
                Cancel
              </button>
            ) : (
              <button
                className={styles.button}
                onClick={() => handleRemoveZoneDef(rowIndex)}
                disabled={isZoneDefinitionsDisabled}
              >
                Remove
              </button>
            )}
            <div className={styles.line}></div>
            {isEditing && rowIndex === editingRowIndex ? (
              <button
                className={styles.button}
                onClick={() => handleSaveEditRow(rowIndex)}
                disabled={isZoneDefinitionsDisabled}
              >
                Save
              </button>
            ) : (
              <button
                className={styles.button}
                onClick={() => handleEditRow(rowIndex)}
                disabled={isZoneDefinitionsDisabled}
              >
                Edit
              </button>
            )}
          </div>
        )
      },
    },
  ]
  return isZoneDefinitionsViewable ? (
    <div style={{ width: '800px' }}>
      <TableTopper>
        <div className={styles.titleButtonContainer}>
          <TableTitle>Zone Definitions</TableTitle>
          <div className={styles.buttonCell}>
            <button
              style={{ color: props.isEditOrCreate ? 'grey' : '' }}
              disabled={isZoneDefinitionsDisabled}
              onClick={addZoneDefRow}
              className={styles.button}
            >
              Add Zone Definition
            </button>
          </div>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={zoneDefinitionsData.zoneDefinitions as any}
        isDisplayMode={true}
        isLoading={false}
        customRow={row}
      />
    </div>
  ) : (
    <></>
  )
}
