import { InstallationImage } from './ViewInstallationImages'

export const sortImagesByInstallationImageType = (images: InstallationImage[]) => {
  const prefixOrder = ['pre', 'install', 'post']
  const suffixOrder = ['vin', 'front', 'rear', 'torque', 'additional', 'left', 'right']

  return images.sort((a, b) => {
    const [aPrefix, aSuffix] = a.type.split('_')
    const [bPrefix, bSuffix] = b.type.split('_')

    const aPrefixOrder = prefixOrder.indexOf(aPrefix)
    const bPrefixOrder = prefixOrder.indexOf(bPrefix)

    if (aPrefixOrder !== bPrefixOrder) {
      return aPrefixOrder - bPrefixOrder
    }

    const aSuffixOrder = suffixOrder.indexOf(aSuffix)
    const bSuffixOrder = suffixOrder.indexOf(bSuffix)
    return aSuffixOrder - bSuffixOrder
  })
}
