import { useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../../../global/components/table/Table'
import { fetchData } from '../../../../../global/utils/fetch'
import { getInvoices } from '../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { convertMsToYMD } from '../../../../../global/utils/date/dates'
import styles from '../styles.module.css'
import { IInvoice } from '../../../../../../../app/entities/Invoice'
export default function InvoiceDetails(props: any) {
  const { workOrderId } = props
  const { user } = useAuthenticator((context) => [context.user])
  const [invoices, setInvoices] = useState<IInvoice[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchInvoice = async () => {
    try {
      setIsLoading(true)
      const res = await fetchData<IInvoice[]>(getInvoices(workOrderId))
      setInvoices(res)
      console.log(res)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (workOrderId) {
      fetchInvoice()
    }
  }, [workOrderId])

  const columns: Column<IInvoice>[] = [
    {
      Header: 'Balance as of OEM',
      accessor: 'balanceAsOfEom',
    },
    {
      Header: 'Invoiced',
      accessor: 'invoiced',
    },
    {
      Header: 'Invoice ID',
      accessor: 'invoiceId',
    },
    {
      Header: 'Tip',
      accessor: 'tip',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Payment Method Token',
      accessor: 'paymentMethodToken',
    },
    {
      Header: 'Service Amount',
      accessor: 'serviceAmount',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Total Amount Charged',
      accessor: 'totalAmountCharged',
    },
    {
      Header: 'Total Amount Collected',
      accessor: 'totalAmountCollected',
    },
    {
      Header: 'Total Discount',
      accessor: 'totalDiscount',
    },
    {
      Header: 'Total Non Taxable',
      accessor: 'totalNonTaxable',
    },
    {
      Header: 'Total Promo Code',
      accessor: 'totalPromoCode',
    },
    {
      Header: 'Total Tax',
      accessor: 'totalTax',
    },
    {
      Header: 'Total Taxable',
      accessor: 'totalTaxable',
    },
    {
      Header: 'Total Tip Collected',
      accessor: 'totalTipCollected',
    },
    {
      Header: 'Transaction Error Message',
      accessor: 'transactionErrorMessage',
    },
    {
      Header: 'Transaction ID',
      accessor: 'transactionId',
    },
    {
      Header: 'Created',
      accessor: (d: any) => {
        const date = d.created
        const cleanedDate = convertMsToYMD(date)
        return cleanedDate
      },
    },
    {
      Header: 'Updated',
      accessor: (d: any) => {
        const date = d.updated
        const cleanedDate = convertMsToYMD(date)
        return cleanedDate
      },
    },
  ]
  return (
    <div className={styles.invoiceTable}>
      <Table
        columns={columns}
        data={invoices as any}
        isLoading={isLoading}
        onRowClick={console.log}
        isDisplayMode={true}
      />
    </div>
  )
}
