import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryCheckbox from '../../../../../../../global/components/checkbox/Checkbox'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import { cloneOrder } from './api'
interface IProps {
  handleClose: Function
}

const CloneOrder = (props: IProps) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const handleCloneOrder = async () => {
    try {
      if (id) {
        setIsLoading(true)
        const newlyClonedOrderId = await fetchData<string>(cloneOrder(id))
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message:
            'The order has successfully been cloned; you have been redirected to the new order.',
          severity: 'success',
        })

        if (newlyClonedOrderId) {
          //navigates users to new order, and passes state value finish = true as a flag to open the finish flow modal
          navigate(`/work-orders/${newlyClonedOrderId}`, {
            state: { finish: 'true' },
          })
          await queryOrderData(newlyClonedOrderId)
        } else {
          console.error('Newly cloned order ID is undefined or null')
        }

        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'An error occurred during the cloning of the order.',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      {isLoading && (
        <div
          style={{
            width: '100&',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className={styles.checkBoxContainer}>
        <PrimaryCheckbox
          onChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
          label={'Yes I want to clone this order'}
        />
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Clone Order'
          disabled={!isChecked || isLoading}
          onClick={handleCloneOrder}
        />
      </div>
    </div>
  )
}

export default CloneOrder
