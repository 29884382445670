import moment from 'moment'

import getCurrentTimestamp from './get-current-timestamp'

function getCurrentDay({ hour = 0, minute = 0, offset = 0 } = {}) {
  const utcDate = moment.utc(getCurrentTimestamp())

  utcDate.set({
    hour       : offset + hour,
    minute     : minute,
    second     : 0,
    millisecond: 0,
  })

  return utcDate.valueOf()
}

export default getCurrentDay