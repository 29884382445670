enum VAN_IMAGE_TYPES {
  qr_code = 'qr_code',
}

enum SMALL_PART_SUPPLIES_IMAGE_TYPES {
  qr_code = 'qr_code',
}

export {
  VAN_IMAGE_TYPES,
  SMALL_PART_SUPPLIES_IMAGE_TYPES,
}

