import { MouseEventHandler } from "react"
import styles from "./styles.module.css"

interface CustomSizeButtonProps {
	id: number
	onClick: Function
	selected: number
	setSelectedItem: Function
}

export default function CustomSizeButton(props: CustomSizeButtonProps) {
	let borderStyles = {}

	if (props.id === props.selected) {
		borderStyles = styles.tireSizeButtonRadioBorder1
	} else {
		borderStyles = styles.tireSizeButtonRadioBorder2
	}

	const click = () => {
		props.onClick(props.id)
		props.setSelectedItem({
			frontTire: {
				aspectRatio: "",
				width: "",
				diameter: "",
			},
			rearTire: {
				aspectRatio: "",
				width: "",
				diameter: "",
			},
		})
	}

	return (
		<div
			onClick={click}
			className={styles.tireSizeButton}>
			<div className={`${styles.tireSizeButtonRadio} ${borderStyles}`}></div>
			<div className={styles.customTireSizeButtonTitle}>Enter a custom tire size</div>
		</div>
	)
}
