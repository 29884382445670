import React, { useRef } from "react"
import styles from "../styles.module.css"
import TextField from "@mui/material/TextField"
import { ISelectedTipFormProps } from "./TipContainer"
interface ITipAmountOptionsProps {
	formProps: ISelectedTipFormProps
	amount: number
}
export default function TipAmountOptions(props: ITipAmountOptionsProps) {
	const { selectedTip, setSelectedTip } = props.formProps
	const { amount } = props
	const customTipRef = useRef<HTMLInputElement>(null)

	const handleTipOptionClick = (percent: string) => {
		const tipAmount = (amount * Number(percent)) / 100
		setSelectedTip({ percentage: percent, amount: tipAmount.toFixed(2) })
		if (customTipRef.current && selectedTip.percentage === "custom") {
			customTipRef.current.value = ""
		}
	}

	const handleCustomTipClick = () => {
		setSelectedTip({ percentage: "custom", amount: "" })
		if (customTipRef.current) {
			customTipRef.current.focus()
		}
	}

	return (
		<div className={styles.tipAmountContainer}>
			<div className={styles.defaultOptions}>
				{[15, 20, 25].map((percent) => (
					<TipOptionBox
						key={percent}
						percent={percent.toString()}
						totalAmount={amount}
						isSelected={percent.toString() === selectedTip.percentage}
						onClick={() => handleTipOptionClick(percent.toString())}
					/>
				))}
			</div>
			<div
				className={`${styles.customTipContainer} ${selectedTip.percentage === "custom" ? styles.selected : ""}`}
				onClick={handleCustomTipClick}>
				<div className={styles.header}>Custom Tip Amount</div>
				<TextField
					size="small"
					label="$ Enter amount"
					className={styles.textField}
					inputRef={customTipRef}
					onChange={(e) => setSelectedTip({ percentage: "custom", amount: e.target.value })}
					value={selectedTip.percentage === "custom" ? selectedTip.amount : ""}
				/>
			</div>
		</div>
	)
}

interface ITipOptionBoxProps {
	percent: string
	totalAmount: number
	isSelected: boolean
	onClick: () => void
}

function TipOptionBox(props: ITipOptionBoxProps) {
	const { percent, totalAmount, onClick, isSelected } = props
	const tipAmount = (totalAmount * Number(percent)) / 100

	return (
		<div
			className={`${styles.option} ${isSelected ? styles.selected : ""}`}
			onClick={onClick}>
			<div className={styles.percentageAmount}>{percent}%</div>
			<div className={styles.tipAmount}>${tipAmount.toFixed(2)}</div>
		</div>
	)
}
