import { BI_DATA_WAREHOUSE_TABLE_NAMES } from '../../../../../global/constants/bi-reports'

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  ATA_ACCOUNT_DEFINITION,
  ATA_GL_TRANSACTION,
  DTC_INVOICE,
  DTC_STORE,
  DTMI_WORK_ORDER,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
  TRMI_WORK_ORDER,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

function getEndpointPath(entityName: string): string {
  if (entityName === ACCOUNTING_TRANSACTION_ADJUSTMENT) {
    return 'bi-accounting-transaction-adjustment'
  } else if (entityName === ATA_ACCOUNT_DEFINITION) {
    return 'bi-ata-account-definition'
  } else if (entityName === ATA_GL_TRANSACTION) {
    return 'bi-ata-gl-transaction'
  } else if (entityName === DTC_INVOICE) {
    return 'bi-dtc-invoice'
  } else if (entityName === DTC_STORE) {
    return 'bi-dtc-store'
  } else if (entityName === DTMI_WORK_ORDER) {
    return 'bi-dtmi-work-order'
  } else if (entityName === HUB_SPOKE_STORE_MAPPING) {
    return 'bi-hub-spoke-store-mapping'
  } else if (entityName === LOCATION) {
    return 'bi-location'
  } else if (entityName === TRMI_WORK_ORDER) {
    return 'bi-trmi-work-order'
  }

  return ''
}

export default getEndpointPath
