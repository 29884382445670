import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IOrderVehicle } from '../../../../../../../../../app/entities/OrderVehicle'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import { cancelRequestedRefund } from './api'

interface ICancelRequestedRefundModalProps {
  isOpen: boolean
  handleClosed: Function
  invoice?: any
}

export default function CancelRequestedRefundModal(
  props: ICancelRequestedRefundModalProps,
) {
  const { isOpen, invoice } = props
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [disableCancelButton, setDisableCancelButton] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const { id } = useParams()

  async function handleCancelRequestedRefund() {
    if (!invoice) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There is no requested refund selected!`,
        severity: 'error',
      })
      return
    }

    try {
      setDisableCancelButton(true)
      await fetchData<IOrderVehicle>(cancelRequestedRefund(invoice.objectId))
      if (id) {
        queryOrderData(id)
      }
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Requested Refund successfully cancelled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There has been an error deleting requested refund(id:${invoice.objectId}).`,
        severity: 'error',
      })
    } finally {
      setDisableCancelButton(false)
    }
  }

  return (
    <Modal open={isOpen}>
      <Box className={styles.scheduleModal}>
        <div className={`${styles.scheduleModalPageTitle} font--bold`}>
          Cancel Requested Refund
        </div>
        <div className={styles.inputsContainer}>
          <div style={{ display: 'flex', gap: 24 }}>
            <div className={styles.topInputsContainerLineItem}>
              <div className={styles.checkBoxContainer}>
                <Checkbox onChange={() => setIsChecked(!isChecked)} />
                <div>Yes I want to cancel this requested refund</div>
              </div>
            </div>
          </div>
          <div
            className={styles.buttonContainer}
            style={{ marginTop: 0 }}
          >
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => props.handleClosed()}
            />
            <PrimaryButton
              buttonName='Confirm'
              onClick={handleCancelRequestedRefund}
              disabled={!isChecked || disableCancelButton}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}
