export const pendingToScheduled = (body: any) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/pending-to-scheduled`,
		body,
	}
}
export const getRouteAreas = (locationObjectId: string) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/route_areas/future?locationObjectId=${locationObjectId}`,
	}
}
