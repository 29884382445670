import { useState } from 'react'
import { refreshToken } from '../utils/refreshToken'

interface IApiProps {
  method: string
  url: string
  body?: any
}

interface IApiState<R> {
  data: R | null
  isLoading: boolean
  error: any | null
}

export function useFetchNoEffect<R>({ method, url, body }: IApiProps) {
  const [response, setResponse] = useState<IApiState<R>>({
    data: null,
    isLoading: false,
    error: null,
  })

  const fetchData = async () => {
    const idToken = await refreshToken()

    setResponse({ ...response, isLoading: true })
    try {
      const resp = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: idToken ? `Bearer ${idToken}` : '',
        },
        body: JSON.stringify(body),
      })

      if (resp.status === 204) {
        setResponse((prevState) => ({
          ...prevState,
          data: [] as unknown as R,
          isLoading: false,
        }))
        return
      }
      const data = (await resp.json()) as R
      if (!resp.ok) {
        setResponse({ ...response, error: true, isLoading: false })
        throw new Error(`Request failed with status ${resp.status}`)
      }

      setResponse({ ...response, data, isLoading: false })
    } catch (error: any) {
      setResponse({ ...response, error, isLoading: false })
    }
  }

  const handleFetchData = async () => {
    await fetchData()
  }

  return { handleFetchData, response }
}
