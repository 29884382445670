import { STATUSES } from '../global/constants/order-status'
import { CANCELLATION_REASONS } from '../global/constants/orders'

export function getHasPreviousOrderBeenStarted(orderObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/has-previous-order-been-started/${orderObjectId}`,
  }
}
export function getPicturesByIds(technicianIds: string[]) {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/technicians/pictures?techObjectIds=${JSON.stringify(technicianIds)}`
	}
}
export function getOrderData(orderObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}`,
  }
}
export function setTireRackOrder(orderObjectId: string, tireRackOrderNumber: string) {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/tire-rack/${tireRackOrderNumber}`,
	}
}
export function updateOrderStatusToCanceled(orderObjectId: string) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/status/${STATUSES.cancelled}?reason=${CANCELLATION_REASONS.customer_cancelled}`,
	}
}
export function putToggleStandbyQueue(orderObjectId: string, optedIntoStandbyQueue: boolean) {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/toggle-standby-queue/${optedIntoStandbyQueue}`,
	}
}