export function getCasesByOrderId(orderOjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/cases/byOrderId/workOrderDetail?orderObjectId=${orderOjectId}&offSet=0&pageSize=100`,
  }
}
export function getNotesByCaseId(caseObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/cases/notes/byId?offSet=0&pageSize=100&sortBy=created%20DESC&caseObjectId=${caseObjectId}`,
  }
}
export function getUsers() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/users`,
  }
}
export function createCase(formInfo: any, id: string | undefined) {
  const body = {
    caseProps: {
      ...formInfo,
      damageCost: Number(formInfo.damageCost),
    },
    orderObjectId: id,
  }
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/cases`,
    body,
  }
}
export function updateCase(formInfo: any, caseObjectId: string) {
  const body = {
    objectId: caseObjectId,
    status: formInfo.status,
    summary: formInfo.summary,
    type: formInfo.type,
    damageCost: Number(formInfo.damageCost),
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/cases`,
    body,
  }
}

export function createCaseNote(note: string, caseObjectId: string) {
  const body = {
    note,
    case: {
      objectId: caseObjectId,
    },
  }
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/note`,
    body,
  }
}
