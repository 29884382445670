import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { AppointmentSlots } from '../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { ISchedulerStateProps } from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { MODES } from '../../../global/constants/scheduler'
import styles from './style.module.css'
import moment from 'moment'

interface INextAvailableAppointmentsProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Dispatch<SetStateAction<ISchedulerStateProps>>
  isCalendarVisible: boolean
}

export default function NextAvailableAppointments(
  props: INextAvailableAppointmentsProps,
) {
  const { schedulerState, setSchedulerState, isCalendarVisible } = props
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [fromDate, setFromDate] = useState(
    moment().add(1, 'day').format('MMMM D'),
  )

  const ref = useRef(undefined)

  const fromDateOverride = moment()
    .utc()
    .add(15, 'days')
    .startOf('day')
    .valueOf()

  const fromDateOverrideHandler = () => {
    setSchedulerState((prevState) => ({
      ...prevState,
      availableCalenderFromDateOverride: fromDateOverride,
    }))
    setIsNextDisabled(true)
    setFromDate(moment(fromDateOverride).add(1, 'day').format('MMMM D'))
  }

  const resetFromDateOverride = () => {
    setSchedulerState((prevState) => ({
      ...prevState,
      availableCalenderFromDateOverride: undefined,
    }))
    setIsNextDisabled(false)
    setFromDate(moment().add(1, 'day').format('MMMM D'))
  }

  return (
    <div className={styles.calendarContainer}>
      {isCalendarVisible && (
        <div>
          <div className={styles.dateText}>
            Available appointments from <strong>{fromDate}</strong> to{' '}
            <strong>{moment(fromDate).add(13, 'days').format('MMMM D')}</strong>
            :
            <div className={styles.prevNextContainer}>
              <div
                className={`${styles.blueLinkButton} ${!isNextDisabled ? styles.disabled : ''}`}
                onClick={isNextDisabled ? resetFromDateOverride : undefined}
              >
                {'< Prev'}
              </div>
              <div
                className={`${styles.blueLinkButton} ${isNextDisabled ? styles.disabled : ''}`}
                onClick={!isNextDisabled ? fromDateOverrideHandler : undefined}
              >
                {'Next >'}
              </div>
            </div>
          </div>
          <AppointmentSlots
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={() => {}}
            mode={MODES.SCHEDULER}
            toggleToastError={() => {}}
            viewOnly={true}
          />
          <div className={styles.readOnly}>CALENDAR IS READ ONLY</div>
        </div>
      )}
    </div>
  )
}
