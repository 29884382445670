import {
  useTable,
  useSortBy,
  Column,
  useFilters,
  usePagination,
} from 'react-table'
import styles from './styles.module.css'
import PaginationComponent from './helpers/pagination/Pagination'
import { IOrder } from '../../../../../app/entities/Order'

interface TableDataState {
  data: Array<any>
  isLoading?: boolean
  noDataMessage?: string
}
function TableIndicator({ data, isLoading, noDataMessage }: TableDataState) {
  let expression = ''

  if (isLoading) {
    expression = 'Loading...'
  } else if (!data.length) {
    expression = noDataMessage ?? 'No results found.'
  }

  return (
    <tr>
      <td
        colSpan={1000}
        className={`${styles.tableLoading}`}
      >
        {expression}
      </td>
    </tr>
  )
}

interface TableProps extends TableDataState {
  columns: Column<IOrder | any>[]
  isOpen?: boolean
  onRowClick?: Function
  rowClickDisabledList?: number[]
  isDisplayMode?: boolean
  customRow?: null | JSX.Element
  isPaginated?: boolean
  pagination?: any
  isTableTopperPresent?: boolean // As part of portal usability improvements, some tableTopper components have been removed to save whitespace or to move the filters, making the border-top squared rather than rounded.
  disableAllRowClicks?: boolean
  highlightLastRow?: boolean
  disableSortBy?: boolean
  height?: string
  maxHeight?: string
}

function Table({
  columns,
  data,
  isLoading,
  isOpen,
  onRowClick,
  rowClickDisabledList = [],
  isDisplayMode = false,
  customRow,
  isPaginated = false,
  pagination,
  isTableTopperPresent = true,
  noDataMessage,
  disableAllRowClicks: disableRowClick = false,
  highlightLastRow = false,
  disableSortBy = false,
  height = 'auto',
  maxHeight = '',
}: TableProps) {
  const hooks: any[] = []

  if (!isDisplayMode) {
    hooks.push(useFilters)
  }

  const handlePrevPage = () => {
    if (
      isPaginated &&
      pagination?.tableState.offSet - pagination?.tableState.pageSize >= 0
    ) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        offSet: prevState.offSet - prevState.pageSize,
      }))
    }
  }

  const handleNextPage = () => {
    if (
      isPaginated &&
      pagination?.tableState.offSet + pagination?.tableState.pageSize <
        pagination?.tableState.count
    ) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        offSet: prevState.offSet + prevState.pageSize,
      }))
    }
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    if (isPaginated) {
      pagination?.setTableState((prevState: any) => ({
        ...prevState,
        pageSize: newPageSize,
        offSet: 0,
      }))
    }
  }

  // must be after `useFilters` or it becomes very frustrated.
  hooks.push(useSortBy)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, ...hooks, usePagination)

  const seeTable = typeof isOpen === 'undefined' ? true : isOpen

  const shouldShowTableIndicator = isLoading || (!data.length && !customRow)

  const onRowClickFunction = onRowClick || (() => {})

  const tableRows = rows
    //.slice(0, 100)
    .map((row, key) => {
      prepareRow(row)

      const isRowClickDisabled =
        disableRowClick || rowClickDisabledList.includes(key)
      const shouldBoldRow = rows.length - 1 === key && highlightLastRow
      return (
        <tr
          className={
            isRowClickDisabled ? styles.bodyRowNoHover : styles.bodyRow
          }
          {...row.getRowProps()}
          key={key}
          onClick={
            isRowClickDisabled ? () => {} : () => onRowClickFunction(row)
          }
        >
          {row.cells.map((cell: any) => {
            return (
              <td
                // style={{ width: cell.column.Header.includes("ID") ? "40px !important" : "auto" }}
                className={`${styles.bodyRowCell} ${shouldBoldRow ? `${styles.highlightedRow}` : ''}`}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </td>
            )
          })}
        </tr>
      )
    })
  if (customRow) {
    tableRows.unshift(customRow)
  }

  return (
    <div
      className={styles.tableWrapper}
      style={{
        height: height,
        maxHeight: maxHeight, // workaround to override the max-height of the table
        borderTop: seeTable ? 'thin solid #c2c2c2' : 'none',
        borderBottom: seeTable ? 'thin solid #c2c2c2' : 'none',
        borderTopLeftRadius: isTableTopperPresent ? '0' : '10px',
        borderTopRightRadius: isTableTopperPresent ? '0' : '10px',
      }}
    >
      <table
        hidden={!seeTable}
        className={`${styles.table}`}
        {...getTableProps()}
      >
        <thead className='ReactTable rt-thead'>
          {headerGroups.map((headerGroup, idx) => (
            <tr
              className={`${styles.columnHeaderRow}`}
              {...headerGroup.getHeaderGroupProps()}
              key={idx}
            >
              {headerGroup.headers.map((column: any, idx) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  className={`${styles.tableHeader} ${isDisplayMode ? styles.displayMode : ''}`}
                  key={idx}
                >
                  <div className={styles.thFlexWrapper}>
                    <div
                      className={disableSortBy ? '' : styles.columnHeader}
                      {...(disableSortBy
                        ? []
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                    >
                      <div className={`${styles.columnHeaderTitle} font--bold`}>
                        {column.render('Header')}
                      </div>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </div>
                    <div className={styles.filterContainer}>
                      {column && column.canFilter
                        ? column.render('Filter')
                        : null}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={styles.tbody}
        >
          {shouldShowTableIndicator ? (
            <TableIndicator
              data={data}
              isLoading={isLoading}
              noDataMessage={noDataMessage}
            />
          ) : (
            tableRows
          )}
        </tbody>
        {isPaginated && (
          <PaginationComponent
            totalRows={pagination?.tableState.count}
            rowsPerPage={pagination?.tableState.pageSize}
            currentPage={
              Math.ceil(
                pagination?.tableState.offSet / pagination?.tableState.pageSize,
              ) + 1
            }
            onPrevPage={handlePrevPage}
            onNextPage={handleNextPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </table>
    </div>
  )
}

export default Table
