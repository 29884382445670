import React from 'react'
import styles from './styles.module.css'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import RoleModalMain from '../../role-modal/RoleModalMain'

interface INewUserProps {
  refetchData: Function
}

function NewRole(props: INewUserProps) {
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
  }

  return (
    <div className={styles.makeAppContainer}>
      <div className={styles.inputsContainer}>
        <PrimaryButton
          buttonName='Create Role'
          onClick={handleOpen}
        />
      </div>
      {open && (
        <RoleModalMain
          isOpen={open}
          refetchData={props.refetchData}
          handleClosed={handleClosed}
        />
      )}
    </div>
  )
}

export default NewRole
