import { Box, Modal, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { AddressComponent } from '../../../../../../../app/types/scheduling'
import { MODES } from '../../../../../global/constants/scheduler'
import { fetchData } from '../../../../../global/utils/fetch'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import { ISchedulerStateProps } from '../container-modal/initialData'
import ContactForm from '../customer-information/contact/ContactForm'
import InstallationAddressForm from '../customer-information/installation-address/InstallationAddressForm'
import VehicleInformationForm from '../customer-information/vehicle-information/VehicleInformationForm'
import AddressConfirm from './address-confirm/AddressConfirm'
import { getAddressValidation } from './api'
import styles from './styles.module.css'
import {
  checkFormFields,
  determineDifferingAddressFields,
  handleWithCustomerVehicleId,
  handleWithoutCustomerVehicleId,
} from './utils'

export interface IFormDataProps {
  [key: string]: any
  firstName: string
  lastName: string
  phone: string
  email: string
  address: string
  aptOrSuite: string
  city: string
  zipCode: string
  additionalInfo: string
  state: string
  make: string
  model: string
  year: string
  variant: string
  brandId: string
  mileage: null | number
  installationPointId?: string
}

export interface IContactProps {
  formData: IFormDataProps
  setFormData: Function
  error?: boolean
  mode: (typeof MODES)[keyof typeof MODES]
}

function retrieveAddressValidation(formData: IFormDataProps) {
  const { address, city, state, zipCode } = formData

  return fetchData(
    getAddressValidation({
      administrativeArea: state,
      locality: city,
      postalCode: zipCode,
      addressLines: [address],
    }),
  )
}

function getSuggestedComponentText(
  name: string,
  suggestedAddressComponents: AddressComponent[],
) {
  return (
    suggestedAddressComponents.find(
      (component: any) => component.componentType === name,
    )?.componentName.text || ''
  )
}

export const CustomerInformation = forwardRef(
  (props: IScheduleModalComponentProps, ref) => {
    const { toggleToastError, schedulerState, setDisabled, setSchedulerState } =
      props
    const mode = props.mode as (typeof MODES)[keyof typeof MODES]
    const {
      customerId,
      customerVehicleId,
      orderId,
      orderVehicleId,
      customerInformation,
    } = schedulerState

    const [isLoading, setIsLoading] = useState(false)
    const [formError, setFormError] = useState(false)
    const [formData, setFormData] =
      useState<IFormDataProps>(customerInformation)
    const [seeAddressConfirmModal, setSeeAddressConfirmModal] = useState(false)
    const [suggestedAddressComponents, setSuggestedAddressComponents] =
      useState<AddressComponent[]>([])

    const overwriteAddressWithSuggestedComponents = (
      suggestedAddressComponents: AddressComponent[],
    ) => {
      const newCustomerFormData = {
        ...formData,
        state:
          getSuggestedComponentText(
            'administrative_area_level_1',
            suggestedAddressComponents,
          ) || formData.state,
        zipCode:
          getSuggestedComponentText(
            'postal_code',
            suggestedAddressComponents,
          ) || formData.zipCode,
        city:
          getSuggestedComponentText('locality', suggestedAddressComponents) ||
          getSuggestedComponentText(
            'neighborhood',
            suggestedAddressComponents,
          ) ||
          formData.city,
        address:
          `${getSuggestedComponentText('street_number', suggestedAddressComponents)} ${getSuggestedComponentText('route', suggestedAddressComponents)}` ||
          formData.address,
      }

      setFormData(newCustomerFormData)

      return newCustomerFormData
    }

    function onAddressConfirmChange() {
      setSeeAddressConfirmModal(false)
      overwriteAddressWithSuggestedComponents(suggestedAddressComponents)
    }

    async function validateAddress(goToNextPage: Function) {
      setIsLoading(true)
      setDisabled(true)

      const { verdict, address } = (await retrieveAddressValidation(
        formData,
      )) as any

      setSuggestedAddressComponents(address.addressComponents)

      const addressIsInvalid = verdict.hasUnconfirmedComponents

      if (addressIsInvalid) {
        setFormError(true)
        setIsLoading(false)
        return
      }

      const addressIsValidWithReplacedValues = verdict.hasReplacedComponents

      if (addressIsValidWithReplacedValues) {
        // we do one more check here to ensure that the address did indeed have a differing component
        // since sometimes google will say the zipCode has changed when it hasn't
        const differingComponents = determineDifferingAddressFields(
          address.addressComponents,
          formData,
        )

        if (differingComponents.length) {
          setIsLoading(false)

          setSeeAddressConfirmModal(true)
          return
        }
      }

      const overwrittenFormData = overwriteAddressWithSuggestedComponents(
        address.addressComponents,
      )

      await handleNextClickLogic(goToNextPage, overwrittenFormData)
    }

    const handleNextClickLogic = async (
      goToNextPage: Function,
      incomingFormDate: IFormDataProps,
    ) => {
      if (mode === MODES.FINISH || mode === MODES.CUSTOMER) {
        await handleSaveClickLogic()
      }

      try {
        setSchedulerState({
          ...props.schedulerState,
          customerInformation: incomingFormDate,
        })
        goToNextPage(true)
      } catch (error) {
        console.error('An error occurred:', error)
        toggleToastError && toggleToastError(true)
        goToNextPage(false)
        setFormError(true)
      } finally {
        setDisabled(false)
        setIsLoading(false)
      }
    }
    const handleSaveClickLogic = async () => {
      setIsLoading(true)
      setDisabled(true)
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        customerInformation: formData,
      }))
      if (!customerVehicleId) {
        await handleWithoutCustomerVehicleId(
          setSchedulerState,
          formData,
          customerId,
          orderId,
        )
      } else {
        await handleWithCustomerVehicleId(
          formData,
          customerId,
          customerVehicleId,
          orderVehicleId,
          orderId,
        )
      }
    }

    const isJmulletLocal = process.env.REACT_APP_JMULLET_LOCAL === 'true'

    useImperativeHandle(
      ref,
      () => ({
        async handleNextClick(goToNextPage: Function) {
          await validateAddress(goToNextPage)
        },
        async handleSaveClick() {
          await handleSaveClickLogic()
        },
      }),
      [formData, mode],
    )
    useEffect(() => {
      if (formError) {
        setFormError(false)
      }
      const formFieldCheck = checkFormFields(formData)
      if (formFieldCheck) {
        props.setDisabled(false)
      } else {
        props.setDisabled(true)
      }
    }, [formData])
    return (
      <div
        className={
          mode === MODES.CUSTOMER
            ? styles.responsiveFormContainer
            : styles.formContainer
        }
      >
        {isLoading && (
          <div className={styles.overlay}>
            <div className={styles.progressContainer}>
              <CircularProgress size={80} />
            </div>
          </div>
        )}
        {isJmulletLocal && (
          <TextField
            onChange={(e) => {
              //console.log(e.target.value)
              setSchedulerState({
                ...schedulerState,
                availableCalenderFromDateOverride: Number(e.target.value),
              })
              //console.log(schedulerState.availableCalenderFromDateOverride)
            }}
            label='Available Calendar From Date Override'
            value={schedulerState.availableCalenderFromDateOverride}
            style={{ width: '300px', position: 'absolute', top: 16, right: 16 }}
            type='number'
          />
        )}
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          mode={mode}
        />
        <InstallationAddressForm
          mode={mode}
          formData={formData}
          setFormData={setFormData}
          error={formError}
        />
        <VehicleInformationForm
          formData={formData}
          setFormData={setFormData}
          mode={mode}
        />
        <div>
          <Modal open={seeAddressConfirmModal}>
            <Box className={styles.addressConfirmBox}>
              <AddressConfirm
                inputtedAddress={formData}
                suggestedAddressComponents={suggestedAddressComponents}
                setSeeAddressConfirmModal={setSeeAddressConfirmModal}
                onConfirm={onAddressConfirmChange}
              />
            </Box>
          </Modal>
        </div>
      </div>
    )
  },
)
