export const assignAdditionalTrNumber = (orderObjectId: string | undefined, additionalTireRackNumber: string) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/additional-tire-rack-number/${additionalTireRackNumber}`,
	}
}
export const deleteAdditionalTrNumber = (orderObjectId: string | undefined) => {
	return {
		method: "DELETE",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/additional-tire-rack-number`,
	}
}
