import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { WoDetailContext } from '../../../../../context/context'
interface IProps {
	handleClose: Function
}
export default function ResendReceipt(props: IProps) {
	const [isChecked, setIsChecked] = useState(false)
	const { data } = useContext(WoDetailContext)
	const [email, setEmail] = useState(data.customer?.email)

	return (
		<div className={styles.inputsContainer}>
			<div className={styles.topInputsContainer}>
				<TextField
					type="email"
					label="email"
					fullWidth
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className={styles.checkBoxContainer}>
				<Checkbox onChange={() => setIsChecked(!isChecked)} />
				<div>Yes I want to resend the receipt for this work order</div>
			</div>

			<div className={styles.buttonContainer}>
				<SecondaryButton
					buttonName="Cancel"
					onClick={() => props.handleClose()}
				/>
				<PrimaryButton
					buttonName="Send"
					disabled={!isChecked}
					onClick={() => console.log('hello')}
				/>
			</div>
		</div>
	)
}
