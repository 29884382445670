import { useState, useEffect } from 'react'
import TechTableRow from './rows/TechTableRow'
import styles from './styles.module.css'
import TechTableHeader from './table-headers/TechTableHeader'
import useFetch from '../../../../global/hooks/useFetchHook'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { getTechScheduleForDay } from '../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { WoDetailContextProvider } from '../../../../work-orders/components/context/context'
import { getTechPictures } from '../../api'
import { fetchData } from '../../../../global/utils/fetch'
import { ITechnician } from '../../../../../../app/entities/Technician'
import { ILocation } from '../../../../../../app/entities/Location'
import { IOrder } from '../../../../../../app/entities/Order'
import { IScheduleFilter } from '../../main/ScheduleMain'
import { TechnicianPicturesUrls } from '../../../../../../app/eworkflows/technician/get-pictures'

export interface IScheduleFilterProps {
  date: string
  locationId: string
}
//The endpoint for scheduling/schedule returns a bastardized version of IRouteArea, created these two to match the return
export interface IExtendedOrder extends IOrder {
  startTime: string
  endTime: string
  status: string
}
export interface IGetTechScheduleRouteArea {
  objectId: string
  technician: ITechnician
  location: ILocation
  schedule: {
    morning: IExtendedOrder[]
    afternoon: IExtendedOrder[]
    evening: IExtendedOrder[]
  }
  scheduled: boolean
  unassigned: boolean
  blank: boolean
}
interface ITechTableContainerProps {
  filters: IScheduleFilter
}

export default function TechTableContainer(props: ITechTableContainerProps) {
  const { user } = useAuthenticator((context) => [context.user])

  const { scheduleFilter } = props.filters

  const [routes, setRoutes] = useState<IGetTechScheduleRouteArea[]>([])

  const { data, isLoading, refetch, error } = useFetch<
    IGetTechScheduleRouteArea[]
  >(getTechScheduleForDay(scheduleFilter.date, scheduleFilter.locationId))
  useEffect(() => {
    const fetchTechAvatars = async (technicianIds: string[]) => {
      const input = getTechPictures(technicianIds)
      const resp = fetchData<TechnicianPicturesUrls[]>(input)

      return resp
    }

    if (!isLoading && data && !error) {
      const sortedRoutes = data
        // Filter out routes that don't have a location because the technician is not assigned to a location
        .filter((route: IGetTechScheduleRouteArea) => route.location)
        // Add a filter to include only routes where location.active is true
        .filter(
          (route: IGetTechScheduleRouteArea) => route.location.active === true,
        )
        .sort((a: IGetTechScheduleRouteArea, b: IGetTechScheduleRouteArea) => {
          const { stateAbbr: stateA, name: displayNameA } = a.location
          const { stateAbbr: stateB, name: displayNameB } = b.location

          const displayNameCompare = displayNameA.localeCompare(displayNameB)
          const stateAbbrCompare = stateA.localeCompare(stateB)

          return displayNameCompare || stateAbbrCompare
        })
      setRoutes(sortedRoutes)

      const technicianIds = sortedRoutes.map(
        (route: any) => route.technician.objectId,
      )
      fetchTechAvatars(technicianIds).then(
        (technicianPicturesUrls: TechnicianPicturesUrls[]) => {
          setRoutes(
            sortedRoutes.map((route: any) => {
              const { technician } = route
              const { profilePicture } = technician
              route.technician.profilePicture = profilePicture
                ? technicianPicturesUrls.find(
                    (technicianPicturesUrlsObj: TechnicianPicturesUrls) =>
                      technicianPicturesUrlsObj.technicianObjectId ===
                      technician.objectId,
                  )?.profilePictureURL
                : null

              return route
            }),
          )
        },
      )
    }
  }, [isLoading, data, scheduleFilter, user, setRoutes])

  const handleRerender = () => {
    // This will trigger a re-render of TechTableContainer
    // called from modal when user updates tech.
    refetch()
  }

  const renderTechRoutes = routes.map((route: any, index: number) => {
    return (
      <TechTableRow
        key={index}
        techRoute={route}
        techAvatar={route.technician.profilePicture}
        date={scheduleFilter.date}
        triggerRerender={handleRerender}
      />
    )
  })

  return (
    <WoDetailContextProvider>
      <div className={styles.tableContainer}>
        {isLoading ? (
          <div className={styles.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {renderTechRoutes.length ? (
              <>
                <TechTableHeader />
                {renderTechRoutes}
              </>
            ) : (
              <>
                <div className={styles.progressContainer}>NO RECORDS FOUND</div>
              </>
            )}
          </>
        )}
      </div>
    </WoDetailContextProvider>
  )
}
