import { MouseEventHandler } from 'react'
import styles from './styles.module.css'

interface PrimaryButtonProps {
	buttonName: string
	onClick: MouseEventHandler
	disabled?: boolean
	style?: React.CSSProperties
	children?: React.ReactNode
}

export default function PrimaryButton({
	buttonName,
	disabled,
	onClick,
	style,
	children,
}: PrimaryButtonProps) {

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={styles.primaryButton}
			style={style}
		>
			<span className={styles.primaryButtonText}>
				{children || buttonName}
			</span>
		</button>
	)
}
