import { ILocationService } from '../../../../../app/entities/LocationService'

function isEntityALocationService(entity: any): entity is ILocationService {
  if (!entity) return false

  return entity.hasOwnProperty('price') && entity.hasOwnProperty('state')
}

export default isEntityALocationService

