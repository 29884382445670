import React, { useContext, useState } from 'react'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import PromoCodeModal from '../promo-modal/container/PromoCodeModal'
import PromoCodesTable from '../table/PromoCodesTable'
import NewPromoCode from './new-promo/NewPromoCode'
import hasPermission from '../../../global/utils/user/has-permission'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
export interface IRefreshProps {
  refreshCounter: number
  setRefreshCounter: Function
}
function PromoCodesMain() {
  const { user } = useContext(UserContext)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [locationFilter, setLocationFilter] = useState('')
  const [open, setOpen] = React.useState({
    isOpen: false,
    promoId: '',
  })

  const handleClosed = () => {
    setOpen({ isOpen: false, promoId: '' })
  }

  return (
    <MainPageWrapper
      titleFields={
        <NewPromoCode
          refresh={{ refreshCounter, setRefreshCounter }}
          locationFilters={{ locationFilter, setLocationFilter }}
          disabled={
            !hasPermission(user).allow(
              PERMISSIONS.PROMO_CODES.CATEGORY,
              PERMISSIONS.PROMO_CODES.ENTRIES.CREATE_BUTTON.NAME,
            )
          }
        />
      }
    >
      <PromoCodeModal
        isOpen={open.isOpen}
        handleClosed={handleClosed}
        promoId={open.promoId}
        refresh={setRefreshCounter}
      />
      <PromoCodesTable
        setOpen={setOpen}
        refresh={refreshCounter}
        locationFilters={{ locationFilter, setLocationFilter }}
      />
    </MainPageWrapper>
  )
}

export default PromoCodesMain
