import styles from './styles.module.css'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
interface IPaginationComponentProps {
  totalRows: number
  rowsPerPage: number
  currentPage: number
  onPrevPage: () => void
  onNextPage: () => void
  onRowsPerPageChange: Function
}
const PaginationComponent = ({
  totalRows, // Total number of rows in the data
  rowsPerPage, // Number of rows to display per page
  currentPage, // The current page number
  onPrevPage, // Handler when "Previous" button is clicked
  onNextPage, // Handler when "Next" button is clicked
  onRowsPerPageChange, // Handler when rows per page dropdown value changes
}: IPaginationComponentProps) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage)
  return (
    <tfoot className={styles.tfooter}>
      <tr>
        <td colSpan={100}>
          <div className={styles.paginationControls}>
            <div className={styles.buttonsContainer}>
              <TextField
                select
                value={rowsPerPage}
                label="Rows"
                size="small"
                onChange={(e) => onRowsPerPageChange(Number(e.target.value))}>
                {[10, 25, 50, 100].map((row, idx) => {
                  return (
                    <MenuItem
                      value={row}
                      key={idx}>{`${row} Rows`}</MenuItem>
                  )
                })}
              </TextField>
              <Button
                disabled={currentPage === 1}
                onClick={onPrevPage}
                size="large"
                style={{ color: currentPage === 1 ? '#c2c2c2' : 'black', fontSize: '1.5rem' }}>
                {'<'}
              </Button>
              <span>
                Page {currentPage} of {totalPages} ({totalRows} Records)
              </span>
              <Button
                disabled={currentPage === totalPages}
                onClick={onNextPage}
                size="large"
                style={{ color: currentPage === totalPages ? '#c2c2c2' : 'black', fontSize: '1.5rem' }}>
                {'>'}
              </Button>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  )
}

export default PaginationComponent
