export function getLocationServicesForLocationByService(locationObjectId: string, serviceObjectId: string) {

  const query = new URLSearchParams({
    locationObjectId,
    serviceObjectId,
  })

  const endpoint = `${process.env.REACT_APP_ORIGIN}/location_service?${query}&`

  return {
    method: 'GET',
    url: endpoint,
  }
}

export function getLocationRulesForLocationByRule(
  locationObjectId: string,
  ruleName: string
) {
  const query = new URLSearchParams({
    locationObjectId,
    ruleName,
  })

  const endpoint = `${process.env.REACT_APP_ORIGIN}/location_rule?${query}&`

  return {
    method: 'GET',
    url: endpoint,
  }
}