import { useEffect, useState } from 'react'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import { ISchedulerStateProps } from '../../container-modal/initialData'
import GenericTiresModal from './GenericTiresModal'
import GenericTiresTable from './GenericTiresTable'

interface IGenericTiresProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
}

function GenericTires({
  schedulerState,
  setSchedulerState
}: IGenericTiresProps) {

  const [seeGenericTireModal, setSeeGenericTireModal] = useState(false) 
  const [genericItemIdxToEdit, setGenericItemIdxToEdit] = useState<number | null>(null)

  useEffect(() => {
  }, [schedulerState.genericItems])

  return (
    <div className=''>
      <PrimaryButton
        onClick={() => {
          setGenericItemIdxToEdit(null)
          setSeeGenericTireModal(true)
        }}
        buttonName="+ Add Tires"
        style={{
          marginBottom: '2rem',
        }}
      />
      <GenericTiresModal
        seeGenericTireModal={seeGenericTireModal}
        setSeeGenericTireModal={setSeeGenericTireModal}
        genericItemIdxToEdit={genericItemIdxToEdit}
        schedulerState={schedulerState}
        setSchedulerState={setSchedulerState}
      />
      <GenericTiresTable
        schedulerState={schedulerState}
        setSeeGenericTireModal={setSeeGenericTireModal}
        setGenericItemIdxToEdit={setGenericItemIdxToEdit}
      />
    </div>
  )
}

export default GenericTires