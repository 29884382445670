const TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_HEIGHT = 2048
const TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_WIDTH = 2048
const TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_BYTES = 3 // 3MB
const TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_MB = 3 * 1024 * 1024 // 3MB

const TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png'
}

const TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES_ARRAY = Object.values(TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES)

export {
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_HEIGHT,
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_WIDTH,
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_BYTES,
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_MB,
  TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES,
  TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES_ARRAY
}