import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { RESCHEDULE_REASONS } from '../../constants/orders'

interface IReasonDropDownProps {
  handleDropDown: Function
  reasons: { [key: string]: string }
  reason: string
}
export default function ReasonDropDown(props: IReasonDropDownProps) {
  const { reasons } = props
  const reasonsMap = Object.values(reasons).map((reason: string, index: number) => {
      return (
      <MenuItem
        key={index}
        value={reason}
        disabled={reason === RESCHEDULE_REASONS.bumped_by_system}>
        {reason}
      </MenuItem>
    )
  })

  return (
    <TextField
      value={props.reason}
      fullWidth
      onChange={(e) => props.handleDropDown(e)}
      label="Reason"
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 200,
            },
          },
        },
      }}>
      {reasonsMap}
    </TextField>
  )
}