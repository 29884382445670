import { useAuthenticator } from '@aws-amplify/ui-react'
import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import { MouseEventHandler, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import convertUtcToEst from '../../../../../../../global/utils/date/convert-utc-to-est'
import { fetchData } from '../../../../../../../global/utils/fetch'
import getUsername from '../../../../../../../global/utils/getUsername'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import { removeDiscountFromOrder } from './api'
interface IDeleteDiscountModalProps {
  handleClose: MouseEventHandler
  triggerRerender: (() => void) | undefined
  discountObjectId?: string
}

const DATE_TIME_FORMAT = 'M/D/YYYY h:mm:ss A'
const NULL_ORDER_ID_ERROR = 'Discount object id is null!'

export default function DeleteDiscountModal(props: IDeleteDiscountModalProps) {
  const { handleClose, discountObjectId } = props
  const { user } = useAuthenticator((context) => [context.user])
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false)

  function generateNote() {
    const timestamp = convertUtcToEst(moment.utc())?.format(DATE_TIME_FORMAT)
    return `\n\nDiscount removed by ${getUsername(user)} on ${timestamp}`
  }

  async function handleDeleteDiscount() {
    setIsLoading(true)
    if (!id) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: NULL_ORDER_ID_ERROR,
        severity: 'error',
      })
      return
    }
    if (!discountObjectId) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There is no discount selected!`,
        severity: 'error',
      })
      return
    }

    try {
      setIsDeleteButtonDisabled(true)

      await fetchData(
        removeDiscountFromOrder(id, discountObjectId, getUsername(user)),
      )

      if (id) {
        queryOrderData(id)
      }
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Discount successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There has been an error deleting discount ${discountObjectId}.`,
        severity: 'error',
      })
    } finally {
      setIsDeleteButtonDisabled(false)
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.editDiscountsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to delete this discount</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={handleClose}
        />
        <PrimaryButton
          buttonName='Delete Discount'
          onClick={handleDeleteDiscount}
          disabled={!isChecked || isDeleteButtonDisabled || isLoading}
        />
      </div>
    </div>
  )
}
