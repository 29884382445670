export const payOrderTips = (orderId: string | undefined, amount: number) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/pay-tips?amount=${amount}`,
  }
}
export const getInvoice = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/invoice/order/${orderId}`,
  }
}

export function getOrderById(orderId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}`,
  }
}

export const getTechnician = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/byOrderId?orderObjectId=${orderId}`,
  }
}
