import React, { ChangeEvent, useState, useEffect } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { getLocationList } from './api'
import useFetch from '../../hooks/useFetchHook'
import { WAREHOUSE_TYPES, WAREHOUSE_TYPES_ARRAY } from '../../constants/warehouse-types'
interface IWarehouseReportType {
  warehouseType: string
}
interface IWarehouseReportTypeDropdownProps {
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
  showInactive?: boolean
  onWarehouseReportTypeChange: Function
}

export default function WarehouseReportTypeDropDown(props: IWarehouseReportTypeDropdownProps) {
  const { disabled, defaultValue, fullWidth, onWarehouseReportTypeChange } = props
  const [value, setValue] = useState(defaultValue || '')

  const onWarehouseReportTypeChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onWarehouseReportTypeChange(newValue)
  }

  const menuItems = WAREHOUSE_TYPES_ARRAY.map((warehouseType: string) => {
    return (
      <MenuItem
        key={warehouseType}
        value={warehouseType}
        className={styles.dropDownOption}>
        {warehouseType}
      </MenuItem>
    )
  })

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label="Warehouse Type"
        select
        fullWidth={fullWidth}
        name="warehouseType"
        disabled={disabled}
        onChange={onWarehouseReportTypeChangeWrapper}
        value={value}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}>
        {menuItems}
      </TextField>
    </div>
  )
}
