import { ChangeEvent, useState } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
interface NumberDropDownProps {
  numberOfOptions: number
  onNumberChange?: Function
  disabled?: boolean
  defaultValue?: number | null
  showInactive?: boolean
  fieldLabel?: string
}


export default function NumberDropDown({
  numberOfOptions,
  onNumberChange: onLocationChange,
  disabled,
  defaultValue,
  showInactive,
  fieldLabel,
}: NumberDropDownProps) {
  const [value, setValue] = useState(defaultValue ? defaultValue : 4)
  const options: number[] = Array.from({ length: numberOfOptions }, (_, i) => i + 1)

  const onNumberChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: newValue },
    } = event
    setValue(parseInt(newValue))
    onLocationChange && onLocationChange(newValue)
  }

  const menuItems = options.map((option: number) => {
    return (
      <MenuItem
        key={option}
        value={option}
        className={styles.dropDownOption}>
        {option}
      </MenuItem>
    )
  })

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label={fieldLabel ? fieldLabel : "Number"}
        select
        name="numberInput"
        disabled={disabled}
        onChange={onNumberChangeWrapper}
        value={value}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}
        >
        {menuItems}
      </TextField>
    </div>
  )
}
