import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { listOrdersByTrNumber } from './api'
import { IOrder } from '../../../../../app/entities/Order'
import ErrorDisplay from '../../../global/components/error/ErrorDisplay'
import CircularProgress from '@mui/material/CircularProgress'
import TrNumberSearchBar from '../components/tr-number-search/TrNumberSearchBar'
import TrOrdersTable from '../components/tr-order-table/TrOrdersTable'

export default function CheckTrOrderStatus() {
  const [trNumber, setTrNumber] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false)
  const [trOrderData, setTrOrderData] = useState<IOrder[]>({} as IOrder[])

  useEffect(() => {
    if (trNumber === '') {
      setError(undefined)
      setSearchPerformed(false)
    }
  }, [trNumber])

  const fetchOrderData = async () => {
    setSearchPerformed(true)
    try {
      setIsLoading(true)
      const orderData = await fetchData<IOrder[]>(
        listOrdersByTrNumber(trNumber),
      )
      const orderNotFound = Array.isArray(orderData) && orderData.length === 0

      if (orderNotFound) {
        setError('Tire Rack Order Not Found')
        return
      }
      setTrOrderData(orderData)
      setError(undefined)
    } catch (err) {
      console.error(err)
      setError('An error occurred while fetching the order')
    } finally {
      setIsLoading(false)
    }
  }

  const displayContentIfSearchPerformed =
    searchPerformed && !error && !isLoading

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      )
    } else if (error) {
      return <ErrorDisplay error={error} />
    } else if (displayContentIfSearchPerformed) {
      return (
        <>
          <div>
            <TrOrdersTable
              isLoading={isLoading}
              orderData={trOrderData}
            />
          </div>
        </>
      )
    }
    return null
  }

  return (
    <div className={styles.appointmentBookingContainer}>
      <div>
        {`Enter a Tire Rack Order number below to check status of associated Mobile Installation order(s)`}
      </div>
      <div className={styles.infoSearchBarContainer}>
        <TrNumberSearchBar
          trNumber={trNumber}
          setTrNumber={setTrNumber}
          fetchOrderData={fetchOrderData}
        />
      </div>
      {renderContent()}
    </div>
  )
}
