import { useState, useEffect, useContext } from 'react'
import { Column } from 'react-table'
import Table from '../../../../global/components/table/Table'
import TableTopper from '../../../../global/components/table/helpers/table-topper/TableTopper'
import Box from '@mui/material/Box'
import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import { isValidEmail } from '../../../../global/utils/formValidation'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import retrieveData from '../../../../global/components/table/helpers/retrieveData'
import NoFilter from '../../../../global/components/table/helpers/NoFilter'
import BasicFilter from '../../../../global/components/table/helpers/BasicFilter'
import { handleColumnFilterUpdate } from '../../../../global/components/table/helpers/multiColumnFilters'
import { fetchData } from '../../../../global/utils/fetch'
import { exportServicedZips } from './api'
import { ToastNotificationContext } from '../../../../global/context/toast-context/ToastNotificationContext'
import { ILocation } from '../../../../../../app/entities/Location'

interface IServicedZipsModalProps {
  isOpen: boolean
  handleClosed: Function
}
interface IFormDataProps {
  email: string
}

export default function ServicedZipsModal(props: IServicedZipsModalProps) {
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
  })
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [validEmail, setValidEmail] = useState<any>(null)
  const [formData, setFormData] = useState<IFormDataProps>({
    email: '',
  })
  const [columnFilters, setColumnFilters] = useState({})

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
    value ? setValidEmail(isValidEmail(value)) : setValidEmail(null)
  }

  const endpoint = `${process.env.REACT_APP_ORIGIN}/zips?`

  useEffect(() => {
    if (props.isOpen) {
      retrieveData(setTableState, {
        endpoint,
        pageSize: 100,
        offSet: 0,
        columnFilters,
      })
    }
  }, [columnFilters, props.isOpen])

  const handleClose = () => {
    setFormData({ email: '' })
    setColumnFilters({})
    props.handleClosed()
  }

  const onRecordClickHandler = (row: any) => {
    const { original } = row
    console.log(original)
  }
  const handleReportExport = async () => {
    const params = { email: formData.email }
    try {
      const res = await fetchData<{ success: string }>(
        exportServicedZips(params),
      )
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: res.success,
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error exporting csv.',
        severity: 'error',
      })
    }
  }
  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }
  const columns: Column<ILocation>[] = [
    {
      Header: 'name',
      accessor: 'name',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ name: val }),
      }),
    },
    {
      Header: 'addressStreet',
      accessor: 'addressStreet',
      Filter: NoFilter(),
    },
    {
      Header: 'addressCity',
      accessor: 'addressCity',
      Filter: NoFilter(),
    },
    {
      Header: 'state',
      accessor: 'stateAbbr',
      Filter: NoFilter(),
    },
    {
      Header: 'zipCode',
      accessor: 'zipCode',
      Filter: NoFilter(),
    },
    {
      Header: 'workDayFrom',
      accessor: 'workDayFrom',
      Filter: NoFilter(),
    },
    {
      Header: 'workDayTo',
      accessor: 'workDayTo',
      Filter: NoFilter(),
    },
    {
      Header: 'lunchFrom',
      accessor: 'lunchFrom',
      Filter: NoFilter(),
    },
    {
      Header: 'lunchTo',
      accessor: 'lunchTo',
      Filter: NoFilter(),
    },
    {
      Header: 'ServicedZips',
      accessor: (locationData: any) => locationData.ServicedZips,
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ ServicedZips: val }),
      }),
    },
    {
      Header: 'status',
      accessor: (locationData: any) => locationData.status,
      Filter: NoFilter(),
    },
  ]

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => handleClose()}
      >
        <Box className={styles.servicedZipsModal}>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <TableTopper>
              <div style={{ width: '100%' }}>
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.servicedZipsHeader} font--bold font--x-large`}
                  >
                    Serviced Zips
                  </div>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <TextField
                      label='Email'
                      name='email'
                      type='email'
                      sx={{ width: '300px' }}
                      value={formData.email}
                      error={validEmail === false}
                      helperText={
                        validEmail === false
                          ? 'Please enter a valid email address'
                          : ''
                      }
                      onChange={handleChange}
                    />
                    <PrimaryButton
                      buttonName='Export'
                      disabled={!validEmail}
                      onClick={() => handleReportExport()}
                    />
                  </div>
                </div>
              </div>
            </TableTopper>
            <Table
              columns={columns}
              data={tableState.data ?? []}
              isLoading={tableState.isLoading}
              onRowClick={onRecordClickHandler}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
