const TIRE_SIZE_LIMITS = {
  tire_width_warning_min: 295,
  tire_width_disable_min: 325,
  tire_diameter_min: 22,
  tire_aspect_ratio_lower_min: 7.5,
  tire_aspect_ratio_lower_max: 12.5,
  tire_aspect_ratio_upper_min: 30,
  tire_aspect_ratio_upper_max: 95,
}

const TIRE_SIZE_KEYS = {
  width: 'width',
  diameter: 'diameter',
  aspect_ratio: 'aspectRatio',
}

export { TIRE_SIZE_LIMITS, TIRE_SIZE_KEYS }
