import { IAlertStatusProps } from '../../../../../global/components/toast-alert/ToastNotification'
import { fetchData } from '../../../../../global/utils/fetch'
import { updateEntity } from '../../../api'
import getEndpointPath from './get-endpoint-path'

async function saveEditedRow(
  entityName: string,
  rowIndex: number,
  tableState: any,
  toastStatus: IAlertStatusProps,
  setToastStatus: Function,
  setSelectedRowIndex: Function,
  retrieveTableData: Function,
) {
  const rowToSave = tableState.data[rowIndex]

  const endpointPath = getEndpointPath(entityName)
  const saveApiCall = updateEntity(endpointPath, rowToSave)

  try {
    await fetchData(saveApiCall)

    setToastStatus({
      ...toastStatus,
      isOpen: true,
      message: [
        'Row edited successfully! You will be emailed when the change',
        'is visible in Tableau.',
      ].join(' '),
      severity: 'info',
    })
    retrieveTableData()
    setSelectedRowIndex(-1)
  } catch (error: any) {
    const cleanedErrorMessage = error.message.replace(
      'Error making API call: Error: ',
      '',
    )

    const errors = cleanedErrorMessage
      ? JSON.parse(cleanedErrorMessage).errors
      : cleanedErrorMessage

    const isValidationErrors = Array.isArray(errors)
    if (isValidationErrors) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: [
          'The following errors occurred while trying to save the row:\n',
          errors.map((error) => error.message).join('\n'),
        ].join(' '),
        severity: 'error',
      })
    } else {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: [
          'An error occurred while saving the entity. Please try again. If',
          `the problem persists, contact support. ${error?.message}`,
        ].join(' '),
        severity: 'error',
      })
    }
  }
}

export default saveEditedRow
