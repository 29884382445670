import { ILocationService } from '../../../../../app/entities/LocationService'
import { ILocationRule } from '../../../../../app/entities/LocationRule'
import isEntityALocationService from './is-entity-a-location-service'
import { SERVICE_NAMES } from '../../../global/constants/service'

interface IServiceOrRule extends ILocationRule, ILocationService {}

export default function getMostRecentLocationServicesOrRules(
  data: IServiceOrRule[],
) {
  const onlyActiveLocations = data.filter(
    (serviceOrRule: IServiceOrRule) => serviceOrRule.location?.active,
  )

  return (onlyActiveLocations || []).reduce(
    (acc: IServiceOrRule[], incomingLocationServiceOrRule: IServiceOrRule) => {
      /* If the incoming service is a Minimum Service Charge (MSC), we don't
       * want to include it in the list as the MSC is stored in the location
       * rules table, but each MSC order service uses a MSC location service
       * as a template.
       */
      const isIncomingAService = Boolean(
        isEntityALocationService(incomingLocationServiceOrRule),
      )
      const isMinimumServiceChargeLocationService =
        isIncomingAService &&
        incomingLocationServiceOrRule.service?.name ===
          SERVICE_NAMES.MINIMUM_SERVICE_CHARGE
      if (isMinimumServiceChargeLocationService) {
        return acc
      }

      const existingLocationServiceOrRule = acc.find(
        (serviceOrRule: IServiceOrRule) => {
          const isSameLocation =
            serviceOrRule.location?.objectId ===
            incomingLocationServiceOrRule.location?.objectId

          if (!isSameLocation) return false

          const isAService = isEntityALocationService(serviceOrRule)
          if (isAService) {
            const isSameService =
              serviceOrRule.service?.objectId ===
              incomingLocationServiceOrRule.service?.objectId

            return isSameService
          } else {
            const rule = serviceOrRule as ILocationRule
            const isSameRule = rule.name === incomingLocationServiceOrRule.name

            return isSameRule
          }
        },
      )

      if (!existingLocationServiceOrRule) {
        acc.push(incomingLocationServiceOrRule)
      } else if (
        existingLocationServiceOrRule.created <
        incomingLocationServiceOrRule.created
      ) {
        acc[acc.indexOf(existingLocationServiceOrRule)] =
          incomingLocationServiceOrRule
      }

      return acc
    },
    [] as IServiceOrRule[],
  ) //as unknown as Row[]
}
