import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import React, { useState, ChangeEvent, useEffect } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { useFetchNoEffect } from '../../../../../../../global/hooks/useFetchNoUseEffectHook'
import {
  getAvailableTechsForAssignment,
  resetTechToRouteArea,
  resetTechToRoute,
} from '../../../../../../../schedule/components/api'
import CircularProgress from '@mui/material/CircularProgress'
import { ITechnician } from '../../../../../../../../../app/entities/Technician'
import { IGetTechScheduleRouteArea } from '../../../../../../../schedule/components/technician-table/table-container/TechTableContainer'
import hasDayBeenOptimized from '../../../../../../../global/utils/has-day-been-optimized'
interface ITechAssignmentProps {
  techInfo: IGetTechScheduleRouteArea
  handleClose: Function
  date: string
  triggerRerender: () => void
}

export default function TechAssignment({
  techInfo,
  handleClose,
  date,
  triggerRerender,
}: ITechAssignmentProps) {
  const [selectedTech, setSelectedTech] = useState('')
  const [hasRouteOpHappened, setHasRouteOptimizationHappened] = useState(
    hasDayBeenOptimized(new Date(date).valueOf()),
  )
  const {
    location,
    objectId: routeObjectId,
    technician: { objectId: technicianObjectId },
  } = techInfo
  const { data, isLoading } = useFetch<ITechnician[]>(
    getAvailableTechsForAssignment(hasRouteOpHappened, date, location.objectId),
  )
  const {
    handleFetchData: resetToRouteArea,
    response: { isLoading: isLoadingTwo },
  } = useFetchNoEffect(
    resetTechToRouteArea(routeObjectId, selectedTech, technicianObjectId),
  )
  const {
    handleFetchData: resetToRoute,
    response: { isLoading: isLoadingThree },
  } = useFetchNoEffect(resetTechToRoute(routeObjectId, selectedTech))

  const handleSave = async () => {
    if (hasRouteOpHappened) {
      await resetToRoute()
    } else {
      await resetToRouteArea()
    }
    triggerRerender()
  }

  useEffect(() => {
    setHasRouteOptimizationHappened(
      hasDayBeenOptimized(new Date(date).valueOf()),
    )
  }, [date])

  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>Location: {location.name}</div>
      <div className={styles.headerTwo}>Primary Technician</div>
      <div className={styles.topInputsContainer}>
        {!isLoading && data?.length ? (
          <TechDropDown
            onTechChange={(val: string) => {
              setSelectedTech(val)
            }}
            response={data}
          />
        ) : (
          <div className={styles.noTechs}>
            No Technicians available to reassign
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => handleClose()}
        />
        <PrimaryButton
          buttonName='Save'
          disabled={isLoadingTwo || isLoadingThree || selectedTech === ''}
          onClick={handleSave}
        />
      </div>
    </div>
  )
}
interface ITechDropDown {
  response: any
  onTechChange: Function
}
const TechDropDown = (props: ITechDropDown) => {
  const { response, onTechChange } = props
  const [value, setValue] = useState('')

  const onTechChangeWrapper = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onTechChange && onTechChange(newValue)
  }
  const menuItems = response.map((tech: any, index: number) => {
    const { name, objectId } = tech
    return (
      <MenuItem
        key={index}
        value={objectId}
      >
        {name}
      </MenuItem>
    )
  })
  return (
    <TextField
      fullWidth
      label='Technician Name'
      select
      value={value}
      onChange={onTechChangeWrapper}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 200,
            },
          },
        },
      }}
    >
      {menuItems}
    </TextField>
  )
}
