import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import Table from '../../../global/components/table/Table'
import { IPromoCode } from '../../../../../app/entities/PromoCode'
import { ILocationFilters } from '../../../work-orders/components/main/WorkOrdersMain'

interface IPromoCodeTableProps {
  setOpen: Function
  refresh: any
  locationFilters: ILocationFilters
}
function PromoCodesTable(prop: IPromoCodeTableProps) {
  const { locationFilter } = prop.locationFilters
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })

  const endpoint = `${process.env.REACT_APP_ORIGIN}/promocodes/promoCodesForCSTable?`

  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      locationId: locationFilter,
    })
  }, [locationFilter, prop.refresh, tableState.pageSize, tableState.offSet])

  const columns: Column<IPromoCode>[] = [
    {
      Header: 'Code',
      accessor: (promoCode: any) => promoCode.code,
    },
    {
      Header: 'Value',
      accessor: (promoCode: any) => promoCode.discountPercent,
    },
    {
      Header: 'Measure',
      accessor: (promoCode: any) => promoCode.measure,
    },
    {
      Header: 'Location',
      accessor: (promoCode: any) => promoCode.locationName,
    },
    {
      Header: 'Start Date',
      accessor: (data: any) => {
        const date = data.startDate.split('T')[0]
        return date
      },
    },
    {
      Header: 'End Date',
      accessor: (data: any) => {
        const date = data.endDate.split('T')[0]
        return date
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={(row: Row) => {
          const original: any = row.original
          prop.setOpen({ isOpen: true, promoId: original.objectId })
        }}
        isDisplayMode
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default PromoCodesTable
