import { Modal, TextField } from '@mui/material'
import { OrderService } from '../../../../../../../../app/entities/OrderService'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import { useEffect, useState } from 'react'
import {
  SERVICE_NAMES,
  CATEGORIES,
} from '../../../../../../global/constants/service'
import TaxRuleDropDown from '../../../../../../global/components/tax-rule-drop-down/TaxRuleDropDown'
import { getServiceList } from '../../../../../../global/components/service-drop-down/api'
import { fetchData } from '../../../../../../global/utils/fetch'
import { Service } from '../../../../../../../../app/entities/Service'
import { ISchedulerStateProps } from '../../container-modal/initialData'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ITaxRule } from '../../../../../../../../app/entities/TaxRule'
import useFetch from '../../../../../../global/hooks/useFetchHook'
import { getTaxRuleList } from '../../../../../../global/components/tax-rule-drop-down/api'

const initialGenericTire: OrderService = {
  description: '',
  quantity: 0,
  price: 0,
  cost: null,
  label: SERVICE_NAMES.GENERIC_TIRE,
  category: CATEGORIES.TIRES,
  taxRule: {
    objectId: '',
  },
  service: {
    objectId: '',
  },
  tireRackOrder: {
    orderNumber: '',
    shipping: {
      shipped_on: '',
    },
  },
} as unknown as OrderService

interface IGenericTiresModalProps {
  seeGenericTireModal: boolean
  setSeeGenericTireModal: Function
  genericItemIdxToEdit?: number | null
  schedulerState: Partial<ISchedulerStateProps>
  setSchedulerState: Function
  onSave?: Function
  inputsToDisable?: string[]
  hideRemoveButton?: boolean
  customerName?: string
}

function GenericTiresModal({
  seeGenericTireModal,
  setSeeGenericTireModal,
  genericItemIdxToEdit,
  schedulerState,
  setSchedulerState,
  onSave,
  inputsToDisable = [],
  hideRemoveButton = false,
  customerName,
}: IGenericTiresModalProps) {
  const [services, setServices] = useState<Service[]>([])

  const [genericTire, setGenericTire] =
    useState<OrderService>(initialGenericTire)

  const [isNew, setIsNew] = useState<boolean>(true)

  const [saveButtonIsDisabled, setSaveButtonIsDisabled] =
    useState<boolean>(true)

  const { data: taxRules } = useFetch<ITaxRule[]>(getTaxRuleList())

  useEffect(() => {
    if (taxRules) {
      const alwaysTaxRule = taxRules.find(
        (taxRule: ITaxRule) => taxRule.code === 0,
      )

      setGenericTire(
        (prevValue) =>
          ({
            ...prevValue,
            taxRule: {
              objectId: alwaysTaxRule?.objectId ?? '',
            },
          }) as OrderService,
      )
    }
  }, [taxRules, genericTire.taxRule?.objectId])

  useEffect(() => {
    const { description, quantity, price, service, tireRackOrder, taxRule } =
      genericTire

    const saveButtonShouldBeDisabled =
      !description ||
      !quantity ||
      price === undefined ||
      price === null ||
      !service?.objectId ||
      !tireRackOrder?.orderNumber ||
      !taxRule?.objectId

    // console.log('saveButtonShouldBeDisabled', saveButtonShouldBeDisabled, genericTire)

    setSaveButtonIsDisabled(saveButtonShouldBeDisabled)
  }, [genericTire])

  useEffect(() => {
    const genericTireToEdit =
      genericItemIdxToEdit || genericItemIdxToEdit === 0
        ? schedulerState.genericItems?.[genericItemIdxToEdit]
        : null

    if (genericTireToEdit) {
      setGenericTire(genericTireToEdit)
      setIsNew(false)
    } else {
      setGenericTire(initialGenericTire)
      setIsNew(true)
    }
  }, [genericItemIdxToEdit, schedulerState.genericItems])

  useEffect(() => {
    if (!services.length) {
      fetchData<Service[]>(getServiceList()).then((data) => {
        setServices(data)
      })
    }
  }, [services])

  useEffect(() => {
    if (!genericTire.service?.objectId && services.length) {
      const genericTireService = services.find(
        (service) => service.name === SERVICE_NAMES.GENERIC_TIRE,
      )

      setGenericTire(
        (prevValue) =>
          ({
            ...prevValue,
            service: {
              name: SERVICE_NAMES.GENERIC_TIRE,
              objectId: genericTireService?.objectId ?? '',
            },
          }) as OrderService,
      )
    }
  }, [services, genericTire.service?.objectId])

  async function handleOnSave() {
    const genericItems = schedulerState.genericItems || []

    if (isNew) {
      setSchedulerState((prevValue: ISchedulerStateProps) => ({
        ...prevValue,
        genericItems: [...genericItems, genericTire],
      }))
    } else {
      genericItems[genericItemIdxToEdit as number] = genericTire

      setSchedulerState((prevValue: ISchedulerStateProps) => ({
        ...prevValue,
        genericItems: genericItems,
      }))
    }

    await (onSave && onSave(genericTire))

    setSeeGenericTireModal(false)
  }

  return (
    <Modal
      open={seeGenericTireModal}
      onClose={() => setSeeGenericTireModal(false)}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h2>{isNew ? 'Add' : 'Edit'} Tires</h2>
          {customerName && (
            <div className={styles.customerName}>Customer: {customerName}</div>
          )}
        </div>
        <div className={styles.modalBody}>
          <div className={styles.modalBodyContent}>
            <TextField
              label='Description'
              name='description'
              className={styles.genericTireInput}
              onChange={(event) =>
                setGenericTire(
                  (prevValue) =>
                    ({
                      ...prevValue,
                      description: event.target.value,
                    }) as OrderService,
                )
              }
              placeholder='245/45R18 Michelin Pilot Sport 4S'
              fullWidth
              disabled={inputsToDisable.includes('description')}
              value={genericTire.description}
            />
            <TextField
              label='Quantity'
              name='quantity'
              className={styles.genericTireInput}
              type='number'
              onChange={(event) =>
                setGenericTire(
                  (prevValue) =>
                    ({
                      ...prevValue,
                      quantity: parseInt(event.target.value),
                    }) as OrderService,
                )
              }
              fullWidth
              disabled={inputsToDisable.includes('quantity')}
              value={genericTire.quantity ?? ''}
            />
            <TextField
              label='Price'
              name='price'
              className={styles.genericTireInput}
              type='number'
              onChange={(event) =>
                setGenericTire(
                  (prevValue) =>
                    ({
                      ...prevValue,
                      price: parseFloat(event.target.value),
                    }) as OrderService,
                )
              }
              fullWidth
              disabled={inputsToDisable.includes('price')}
              value={genericTire.price ?? ''}
            />
            <TaxRuleDropDown
              defaultValue={genericTire.taxRule?.objectId || ''}
              fullWidth
              className={styles.genericTireInput}
              label='Tax Category'
              disabled={true}
            />
            <TextField
              label='Whsl TR Order ID'
              name='tireRackOrderNumber'
              className={styles.genericTireInput}
              onChange={(event) =>
                setGenericTire(
                  (prevValue) =>
                    ({
                      ...prevValue,
                      tireRackOrder: {
                        ...prevValue.tireRackOrder,
                        orderNumber: event.target.value,
                      },
                    }) as OrderService,
                )
              }
              fullWidth
              disabled={inputsToDisable.includes('tireRackOrderNumber')}
              value={genericTire.tireRackOrder?.orderNumber}
            />
            {!isNew && (
              <>
                <TextField
                  label='Shipped On'
                  type='date'
                  value={genericTire.tireRackOrder?.shipping?.shipped_on}
                  InputLabelProps={{ shrink: true }}
                  fullWidth={true}
                  className={styles.genericTireInput}
                  onChange={(event) => {
                    setGenericTire(
                      (prevValue) =>
                        ({
                          ...prevValue,
                          tireRackOrder: {
                            ...prevValue.tireRackOrder,
                            shipping: {
                              ...prevValue.tireRackOrder?.shipping,
                              shipped_on: event.target.value,
                            },
                          },
                        }) as OrderService,
                    )
                  }}
                  disabled={inputsToDisable.includes('shippedOn')}
                />
                <TextField
                  label='Cost'
                  name='cost'
                  type='number'
                  className={styles.genericTireInput}
                  onChange={(event) => {
                    let cost: number | null = parseFloat(event.target.value)

                    if (Number.isNaN(cost)) {
                      cost = null
                    }

                    setGenericTire(
                      (prevValue) =>
                        ({
                          ...prevValue,
                          cost,
                        }) as OrderService,
                    )
                  }}
                  fullWidth
                  disabled={inputsToDisable.includes('cost')}
                  value={genericTire.cost ?? ''}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.modalFooter}>
          <PrimaryButton
            onClick={handleOnSave}
            buttonName='Save'
            disabled={saveButtonIsDisabled}
          />
          <SecondaryButton
            onClick={() => {
              setSeeGenericTireModal(false)
            }}
            buttonName='Cancel'
          />
          {!isNew && !hideRemoveButton && (
            <PrimaryButton
              onClick={() => {
                const genericItems = schedulerState.genericItems || []
                genericItems.splice(genericItemIdxToEdit as number, 1)

                setSchedulerState((prevValue: ISchedulerStateProps) => ({
                  ...prevValue,
                  genericItems: genericItems,
                }))

                setSeeGenericTireModal(false)
              }}
              buttonName='Remove'
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default GenericTiresModal
