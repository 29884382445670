import { IDtmiWorkOrder } from '../../../../../../../../../bi/entities/DtmiWorkOrder'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateDtmiWorkOrderColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Work Order Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ workOrderId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'workOrderId',
          data.workOrderId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Hub Location',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ hubLocation: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'hubLocation',
          data.hubLocation,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Demand Store',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ demandStore: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'demandStore',
          data.demandStore,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Tire Location',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tireLocation: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'tireLocation',
          data.tireLocation,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Status',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ status: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'status',
          data.status,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Appointment Time',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ appointmentTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'appointmentTime',
          data.appointmentTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'First Name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ firstName: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'firstName',
          data.firstName,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Last Name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ lastName: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'lastName',
          data.lastName,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Email',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ email: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'email',
          data.email,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Phone',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ phone: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'phone',
          data.phone,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Address',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ address: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'address',
          data.address,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Year Make Model',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ yearMakeModel: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'yearMakeModel',
          data.yearMakeModel,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Install Quantity',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installQuantity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'installQuantity',
          data.installQuantity,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Disposal Quantity',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ disposalQuantity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'disposalQuantity',
          data.disposalQuantity,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Tire Source',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tireSource: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'tireSource',
          data.tireSource,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Order Source',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ orderSource: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'orderSource',
          data.orderSource,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Sales Representative',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ salesRepresentative: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'salesRepresentative',
          data.salesRepresentative,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Rework',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ rework: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'rework',
          data.rework,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Scheduled Time',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ scheduledTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'scheduledTime',
          data.scheduledTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'En Route Time',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ enRouteTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'enRouteTime',
          data.enRouteTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Started Time',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ startedTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'startedTime',
          data.startedTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Completed Time',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ completedTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'completedTime',
          data.completedTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cancelled Time',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ cancelledTime: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'cancelledTime',
          data.cancelledTime,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cancel Reason I',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ cancelReasonI: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'cancelReasonI',
          data.cancelReasonI,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cancel Reason II',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ cancelReasonII: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'cancelReasonII',
          data.cancelReasonII,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cancel Reason III',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ cancelReasonIII: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'cancelReasonIII',
          data.cancelReasonIII,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Secondary Technician',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ secondaryTechnician: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'secondaryTechnician',
          data.secondaryTechnician,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Technician Username',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ technicianUsername: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'technicianUsername',
          data.technicianUsername,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Created By',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ createdBy: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiWorkOrder, rowIndex: number) => {
        return generateTextField(
          'createdBy',
          data.createdBy,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateDtmiWorkOrderColumns
