import React from 'react'
import styles from './styles.module.css'
import LocationDropDown from '../../../../../global/components/location-drop-down/LocationDropDown'
import TextField from '@mui/material/TextField'
import { IScheduleFilter } from '../../../main/ScheduleMain'

interface IScheduleFilterContainerProps {
  filterInfo: IScheduleFilter
}
export default function ScheduleFilterContainer(props: IScheduleFilterContainerProps) {
  const { filterInfo } = props
  const { scheduleFilter, setScheduleFilter } = filterInfo
  const handleLocationChange = (str: string) => {
    localStorage.setItem('selectedLocationObjectId', str)
    setScheduleFilter({ ...scheduleFilter, locationId: str })
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    localStorage.setItem('selectedDate', value)
    setScheduleFilter({ ...scheduleFilter, date: value })
  }

  return (
    <div className={styles.navContainer}>
      <TextField
        label="Select a Date"
        type="date"
        value={scheduleFilter.date}
        InputLabelProps={{ shrink: true }}
        sx={{ width: 130 }}
        onChange={handleChange}
      />
      <LocationDropDown
        onLocationChange={(val: string) => {
          handleLocationChange(val)
        }}
        defaultValue={scheduleFilter.locationId}
      />
    </div>
  )
}
