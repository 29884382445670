import styles from './styles.module.css'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download-icon.svg'

export interface DownloadButtonProps {
  data: string
  isUrl?: boolean
  reportName?: string
}

function DownloadButton({
  isUrl,
  data,
  reportName,
}: DownloadButtonProps) {
  let downloadUrl
  if (isUrl) {
    downloadUrl = data
  } else {
    const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    downloadUrl = window.URL.createObjectURL(csvData)
  }
  const now = new Date().toISOString()
  return (
    <a
      href={downloadUrl}
      download={reportName ? `${reportName}-${now}.csv` : `report-${now}.csv`}
      className={styles.primaryButton}
      role="button">
      <div className={styles.primaryButtonText}>
        Download
        <DownloadIcon style={{ height: '12px', fill: '#FFFFFF' }} />
      </div>
    </a>
  )
}

export default DownloadButton