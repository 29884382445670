import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import GoogleAddressSearchBar from '../components/auto-complete-search-bar/GoogleAddressSearchBar'
import { Select } from '../../../global/components/select/Select'
import { tireCountData } from '../../../schedule/components/schedule-modal/components/choose-service/select-quantity/SelectQuantity'
import styles from './styles.module.css'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import {
  ISchedulerStateProps,
  ITiresProps,
} from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { Address } from '../../../../../app/types/scheduling'
import { IFormDataProps } from '../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'

interface IAddressSearchWithTireQuantityProps {
  isZipCodeServiced: any
  setIsZipCodeServiced: Function
  displayAvailabilityCalendar: Function
  schedulerState: ISchedulerStateProps
  setSchedulerState: Dispatch<SetStateAction<ISchedulerStateProps>>
}
export default function AddressSearchWithTireQuant(
  props: IAddressSearchWithTireQuantityProps,
) {
  const {
    isZipCodeServiced,
    setIsZipCodeServiced,
    displayAvailabilityCalendar,
    schedulerState,
    setSchedulerState,
  } = props

  const [addressInput, setAddressInput] = useState<string>('')
  const [formattedAddress, setFormattedAddress] = useState<Address>(
    {} as Address,
  )

  const onChange = (e: { target: { value: number } }) => {
    const tires: ITiresProps = {
      ...schedulerState.tires,
      frontCount: e.target.value,
      rearCount: 0,
    }
    setSchedulerState((prevState) => ({
      ...prevState,
      tires: tires,
    }))
    displayAvailabilityCalendar()
  }

  useEffect(() => {
    const customerInformation: IFormDataProps = {
      ...schedulerState.customerInformation,
      address: formattedAddress.address,
      city: formattedAddress.city,
      state: formattedAddress.state,
      zipCode: formattedAddress.zipCode,
    }
    setSchedulerState((prevState) => ({
      ...prevState,
      customerInformation: customerInformation,
    }))
  }, [formattedAddress])

  const buttonName =
    isZipCodeServiced === false ? 'Address Not Serviced' : 'View Appointments'

  return (
    <div className={styles.mainContainer}>
      <div>{`Enter an address below to see available TRMI appointments`}</div>
      <GoogleAddressSearchBar
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        setIsZipCodeServiced={setIsZipCodeServiced}
        setFormattedAddress={setFormattedAddress}
      />
      <div className={styles.buttonContainer}>
        <Select
          name='frontCount'
          onChange={onChange}
          defaultValue={
            schedulerState.tires.frontCount + schedulerState.tires.rearCount
          }
          label='Quantity'
          width='156px'
          data={tireCountData}
        />
        <PrimaryButton
          buttonName={buttonName}
          onClick={() => displayAvailabilityCalendar()}
          disabled={!isZipCodeServiced}
        />
      </div>
    </div>
  )
}
