import { Select, SelectListObject } from '../../../../../../global/components/select/Select'
import { IScheduleModalComponentProps } from '../../container-modal/ScheduleModal'
import { ISchedulerStateProps } from '../../container-modal/initialData'
import styles from './styles.module.css'

export const tireCountData: Array<SelectListObject> = [
  {
    name: '0',
    objectId: 0,
  },
  {
    name: '1',
    objectId: 1,
  },
  {
    name: '2',
    objectId: 2,
  },
  {
    name: '3',
    objectId: 3,
  },
  {
    name: '4',
    objectId: 4,
  },
  {
    name: '5',
    objectId: 5,
  },
  {
    name: '6',
    objectId: 6,
  },
  {
    name: '7',
    objectId: 7,
  },
  {
    name: '8',
    objectId: 8,
  },
]

export default function SizeQuantity(props: IScheduleModalComponentProps) {
  const { schedulerState, setSchedulerState } = props

  const onChange = (e: any) => {
    if (e.target.name === 'frontCount') {
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        tires: {
          ...prevState.tires,
          frontCount: e.target.value,
        },
      }))
    } else if (e.target.name === 'rearCount') {
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        tires: {
          ...prevState.tires,
          rearCount: e.target.value,
        },
      }))
    }
  }

  return (
    <div className={styles.selectQuantityContainer}>
      <div className={`${styles.selectTireQuantityTitle} font--bold`}>Select Tire Quantity</div>
      <div className={styles.frontRearSelectContainer}>
        <div className={styles.quantSelection}>
          <div className={styles.quantSelectionTitle}>Front</div>
          <Select
            name="frontCount"
            onChange={onChange}
            defaultValue={schedulerState.tires.frontCount ?? 2}
            label="Quantity"
            width="156px"
            data={tireCountData.slice(0, 5)}
          />
        </div>
        <div className={styles.quantSelection}>
          <div className={styles.quantSelectionTitle}>Rear</div>
          <Select
            name="rearCount"
            onChange={onChange}
            defaultValue={schedulerState.tires.rearCount ?? 2}
            label="Quantity"
            width="156px"
            data={tireCountData.slice(0, 5)}
          />
        </div>
      </div>
    </div>
  )
}
