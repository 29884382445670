import { useState, useEffect } from 'react'
import { ISchedulerStateProps } from '../container-modal/initialData'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { getServices } from '../../../../../global/api'
import { IService } from '../../../../../../../app/entities/Service'
import { ILocationService } from '../../../../../../../app/entities/LocationService'
import { fetchData } from '../../../../../global/utils/fetch'
import { getLocationServiceByServiceForOrder } from './api'

interface IChangeServiceCustomHookProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
}
export interface ICreateLineItemBody {
  label: string
  orderVehicle: {
    objectId: string
  }
  price: number
  priceOverride?: number
  quantity: string
  service: {
    defaultPrice: number
    objectId: string
  }
}

const getLocationService = async (
  schedulerState: ISchedulerStateProps,
  setNewOrderService: Function,
) => {
  const mostRecentLocationServiceForLocation =
    await fetchData<ILocationService>(
      getLocationServiceByServiceForOrder(schedulerState),
    )
  setNewOrderService((prevState: ICreateLineItemBody) => ({
    ...prevState,
    price: mostRecentLocationServiceForLocation.price,
  }))
  return
}

function useDetectChangeOrderService(props: IChangeServiceCustomHookProps) {
  //This custom hook is used to detect if user changes selected service / or disposal qty and provides the body for a new orderService
  const { data: servicesData } = useFetch<IService[]>(getServices())
  const { schedulerState } = props

  const [originalServicePriceOverride, setOriginalServicePriceOverride] =
    useState(schedulerState.servicePriceOverride)
  const [originalService, setOriginalService] = useState(schedulerState.service)
  const [serviceHasChanged, setServiceHasChanged] = useState(false)
  const [originalDisposalQty, setOriginalDisposalQty] = useState(
    schedulerState.disposalQty,
  )

  const [originalTireQuantity, setOriginalTireQuantity] = useState(
    schedulerState.tires.frontCount + schedulerState.tires.rearCount,
  )

  const [disposalQtyHasChanged, setDisposalQtyHasChanged] = useState(false)
  const [tireQuantityHasChanged, setTireQuantityHasChanged] = useState(false)
  const [newOrderService, setNewOrderService] = useState<ICreateLineItemBody>({
    label: '',
    orderVehicle: {
      objectId: '',
    },
    price: 0,
    quantity: '',
    service: {
      defaultPrice: 0,
      objectId: '',
    },
  })

  useEffect(() => {
    // Initialize originalService only once
    if (originalService === null || originalService === undefined) {
      setOriginalService(schedulerState.service)
    }
    // Initialize originalDisposalQty only once
    if (originalDisposalQty === null || originalDisposalQty === undefined) {
      setOriginalDisposalQty(schedulerState.disposalQty)
    }
    //If original disposal qty changes, compare it to the original value
    const disposalQtyHasChanged =
      schedulerState.disposalQty !== originalDisposalQty
    setDisposalQtyHasChanged(disposalQtyHasChanged)
    // If the service value changes, compare it to the original value

    const tireQuantityHasChanged =
      originalTireQuantity !==
      schedulerState.tires.frontCount + schedulerState.tires.rearCount

    if (tireQuantityHasChanged) {
      setOriginalTireQuantity(
        schedulerState.tires.frontCount + schedulerState.tires.rearCount,
      )

      setTireQuantityHasChanged(tireQuantityHasChanged)
    }

    const serviceHasChanged =
      schedulerState.service !== originalService ||
      (schedulerState.servicePriceOverride ?? 0) !==
        (originalServicePriceOverride ?? 0)

    if (serviceHasChanged) {
      setServiceHasChanged(true)
      const newSelectedService = servicesData?.find(
        (service: IService) => service.objectId === schedulerState.service,
      )

      //spread operator in next two due to asnyc call in location services
      //GetLocationService adds the price to the newOrderService and setNewOrderService adds additional information
      getLocationService(schedulerState, setNewOrderService)

      setNewOrderService((prevState) => ({
        ...prevState,
        label: newSelectedService?.name ?? '',
        orderVehicle: {
          objectId: schedulerState.orderVehicleId,
        },
        priceOverride: schedulerState.servicePriceOverride,
        quantity: String(
          schedulerState.tires.frontCount + schedulerState.tires.rearCount,
        ),
        service: {
          defaultPrice: newSelectedService?.defaultPrice ?? 0,
          objectId: schedulerState.service,
        },
      }))
    } else {
      setServiceHasChanged(false)
    }
  }, [
    schedulerState.service,
    originalService,
    schedulerState.disposalQty,
    originalDisposalQty,
    schedulerState.servicePriceOverride,
    originalServicePriceOverride,
    servicesData,
    schedulerState.tires,
  ])

  return {
    serviceHasChanged,
    newOrderService,
    disposalQtyHasChanged,
    tireQuantityHasChanged,
  }
}

export default useDetectChangeOrderService
