import {
  BI_DATA_WAREHOUSE_TABLE_NAMES,
  BI_REPORT_MODAL_DISPLAY_MODES,
  BiReportModalDisplayModes,
} from '../../../../../../global/constants/bi-reports'
import TextField from '@mui/material/TextField'
import styles from '../../styles.module.css'
import { Column } from 'react-table'
import { ENTITIES_AVAILABLE_FOR_INDIVIDUAL_OPERATIONS } from '../../BiReportModal'
import generateAccountingTransactionAdjustmentColumns from './generate/accounting-tranasction-adjustment'
import generateAtaAccountDefinitionColumns from './generate/ata-account-definition'
import generateAtaGlTransactionColumns from './generate/ata-gl-transaction'
import generateDtcInvoiceColumns from './generate/dtc-invoice'
import generateDtcStoreColumns from './generate/dtc-store'
import generateDtmiVanColumns from './generate/dtmi-van-history'
import generateDtmiWorkOrderColumns from './generate/dtmi-work-order'
import generateHubSpokeStoreMappingColumns from './generate/hub-spoke-store-mapping'
import generateLocationColumns from './generate/location'
import generateTrmiVanColumns from './generate/trmi-van-history'
import generateTrmiWorkOrderColumns from './generate/trmi-work-order'
import generateTrmiVanOperatingStatusColumns from './generate/trmi-van-operating-status-history'
import generateDtmiVanOperatingStatusColumns from './generate/dtmi-van-operating-status-history'

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  ATA_ACCOUNT_DEFINITION,
  ATA_GL_TRANSACTION,
  DTC_INVOICE,
  DTC_STORE,
  DTMI_VAN_OPERATING_STATUS,
  DTMI_VAN,
  DTMI_WORK_ORDER,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
  TRMI_VAN_OPERATING_STATUS,
  TRMI_VAN,
  TRMI_WORK_ORDER,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

const { EDIT, DELETE } = BI_REPORT_MODAL_DISPLAY_MODES

function generateTextField(
  name: string,
  value: string,
  mode: BiReportModalDisplayModes,
  rowIndex: number,
  editingRowIndex: number,
  handleChange: Function,
  width?: string,
) {
  const disabled =
    mode === DELETE || (mode === EDIT && rowIndex !== editingRowIndex)
  return (
    <TextField
      size='small'
      name={name}
      value={value ?? ''}
      disabled={disabled}
      sx={{ minWidth: 100, width }}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => handleChange(e.target.value, e.target.name, rowIndex)}
    />
  )
}

function generateColumns(
  entityName: string,
  onColumnFilterUpdate: any,
  selectedRowIdx: number,
  mode: BiReportModalDisplayModes,
  handleCancel: Function,
  handleDelete: Function,
  handleDeleteConfirmed: Function,
  handleSaveEditRow: Function,
  handleEditRow: Function,
  handleChange: Function,
  retrieveTableData: Function,
) {
  let columns: Column<any>[] = []

  if (entityName === ACCOUNTING_TRANSACTION_ADJUSTMENT) {
    columns = generateAccountingTransactionAdjustmentColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === ATA_ACCOUNT_DEFINITION) {
    columns = generateAtaAccountDefinitionColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === ATA_GL_TRANSACTION) {
    columns = generateAtaGlTransactionColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === DTC_INVOICE) {
    columns = generateDtcInvoiceColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === DTC_STORE) {
    columns = generateDtcStoreColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === DTMI_VAN_OPERATING_STATUS) {
    columns = generateDtmiVanOperatingStatusColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === DTMI_VAN) {
    columns = generateDtmiVanColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === DTMI_WORK_ORDER) {
    columns = generateDtmiWorkOrderColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === HUB_SPOKE_STORE_MAPPING) {
    columns = generateHubSpokeStoreMappingColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === LOCATION) {
    columns = generateLocationColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === TRMI_VAN_OPERATING_STATUS) {
    columns = generateTrmiVanOperatingStatusColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === TRMI_VAN) {
    columns = generateTrmiVanColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  if (entityName === TRMI_WORK_ORDER) {
    columns = generateTrmiWorkOrderColumns(
      onColumnFilterUpdate,
      retrieveTableData,
      generateTextField,
      mode,
      selectedRowIdx,
      handleChange,
    )
  }

  const shouldDisableEditAndDelete =
    !ENTITIES_AVAILABLE_FOR_INDIVIDUAL_OPERATIONS.includes(entityName)
  if (!shouldDisableEditAndDelete) {
    // This column is appended to the end of the table to allow for editing and removing rows
    columns.push({
      Header: ' ',
      Cell: (data: any) => {
        const idx = Number.parseInt(data.row.id)
        const saveButton = (
          <a
            className={styles.buttonText}
            onClick={() => handleSaveEditRow(idx)}
          >
            Save
          </a>
        )
        const deleteButton = (
          <a
            className={styles.buttonText}
            onClick={() => handleDelete(idx)}
          >
            Delete
          </a>
        )
        const editButton = (
          <a
            className={styles.buttonText}
            onClick={() => handleEditRow(idx)}
          >
            Edit
          </a>
        )
        const cancelButton = (
          <a
            className={styles.buttonText}
            onClick={() => handleCancel()}
          >
            Cancel
          </a>
        )
        const confirmButton = (
          <a
            className={styles.buttonText}
            onClick={() => handleDeleteConfirmed(idx)}
          >
            Confirm
          </a>
        )

        let button1
        let button2

        const shouldButton1BeDelete = idx !== selectedRowIdx
        const shouldButton2BeEdit = idx !== selectedRowIdx

        const shouldButton1BeSave = idx === selectedRowIdx && mode === EDIT
        const shouldButton2BeCancel = idx === selectedRowIdx && mode === EDIT

        const shouldButton1BeCancel = idx === selectedRowIdx && mode === DELETE
        const shouldButton2BeConfirm = idx === selectedRowIdx && mode === DELETE

        if (shouldButton1BeDelete) {
          button1 = deleteButton
        } else if (shouldButton1BeSave) {
          button1 = saveButton
        } else if (shouldButton1BeCancel) {
          button1 = cancelButton
        }

        if (shouldButton2BeEdit) {
          button2 = editButton
        } else if (shouldButton2BeCancel) {
          button2 = cancelButton
        } else if (shouldButton2BeConfirm) {
          button2 = confirmButton
        }

        return (
          <div className={styles.buttonCell}>
            <div className={styles.buttonTextBox}>{button1}</div>
            <div className={styles.line}></div>
            <div className={styles.buttonTextBox}>{button2}</div>
          </div>
        )
      },
    })
  }

  return columns
}

export default generateColumns
