import {
  IInvoice,
  Invoice,
} from '../../../../../../../../../../app/entities/Invoice'
import {
  STATUSES,
  TYPES,
} from '../../../../../../../../global/constants/invoice'
import isTransactionDeclined from '../../../../../../../../global/utils/invoice/status/is-transaction-declined'
import isTransactionProcessing from '../../../../../../../../global/utils/invoice/status/is-transaction-processing'
import isInvoiceRefund from '../../../../../../../../global/utils/invoice/type/is-invoice-refund'

function determineIfInvoiceIsFullyRefunded(invoice: any, invoices: any[]) {
  const { tip, transactionId, totalAmountCharged, totalTipCharged } = invoice
  let maxRefundAmount = tip
    ? Number.parseFloat(totalTipCharged as any)
    : Number.parseFloat(totalAmountCharged as any)

  const refundsToCalculate = invoices.filter(
    (invoice: IInvoice) =>
      invoice.transactionIdToRefund === transactionId &&
      isTransactionProcessing(invoice as Invoice),
  )
  refundsToCalculate.forEach((invoice: any) => {
    maxRefundAmount += Number.parseFloat(
      (tip ? invoice.totalTipCharged : invoice.totalAmountCharged) as any,
    )
  })

  return maxRefundAmount <= 0
}

export const formatRefundAmount = (value: string, serviceAmount: number) => {
  let replacedValue = value.replace(
    /^(\\d*)(\\.)?([0-9])?([0-9])?(.*)/g,
    '$1$2$3$4',
  )
  if (replacedValue.startsWith('0') && replacedValue.length > 1) {
    if (replacedValue.charAt(1) === '0') {
      replacedValue = '0'
    } else if (replacedValue.charAt(1) !== '.') {
      replacedValue = replacedValue.slice(1, replacedValue.length)
    }
  }
  if (Number(replacedValue) > serviceAmount) {
    //refund value cant exceed serviceAmount
    replacedValue = String(serviceAmount.toFixed(2))
  }
  let [integerPart, decimalPart] = replacedValue.split('.')
  if (decimalPart && decimalPart.length > 2) {
    //max decimals length 2
    replacedValue = `${integerPart}.${decimalPart.slice(0, 2)}`
  }
  return replacedValue
}

export function generateRowClickDisabledList(
  tableState: any,
  setDisableRowClickList: Function,
) {
  // -1 allows the current row to be clickable, any other number means that row is disabled
  const disabledList: number[] = tableState.data.map(
    (invoice: any, idx: number, invoices: any[]) => {
      const isTotalRow = idx === invoices.length - 1
      if (isTotalRow) {
        return idx
      }

      const isTransactionStatusDeclined = isTransactionDeclined(invoice)
      const isRequestedRefund = invoice.status === STATUSES.REFUND_REQUESTED
      const isRefund = isInvoiceRefund(invoice)
      const isQuote = invoice.type === TYPES.QUOTE

      if (!isRefund) {
        const isInvoiceFullyRefunded = determineIfInvoiceIsFullyRefunded(
          invoice,
          invoices,
        )
        if (isInvoiceFullyRefunded) {
          return idx
        }
      }

      if (isTransactionStatusDeclined) {
        return idx
      }

      const isNonRequestedRefund = isRefund && !isRequestedRefund
      if (isNonRequestedRefund) {
        return idx
      }

      if (isQuote) {
        return idx
      }

      return -1
    },
  )

  setDisableRowClickList(disabledList)
}

function adjustTotalRowColumnsToNotTransform(
  totalRowColumnsToNotTransform: any,
  invoice: any,
) {
  return {
    totalNonTaxable: invoice.tip
      ? 0
      : totalRowColumnsToNotTransform.totalNonTaxable ||
        Number(invoice.totalNonTaxable) !== 0,
    totalTaxable:
      totalRowColumnsToNotTransform.totalTaxable ||
      Number(invoice.totalTaxable) !== 0,
    totalTax:
      totalRowColumnsToNotTransform.totalTax || Number(invoice.totalTax) !== 0,
    totalAmountCharged:
      totalRowColumnsToNotTransform.totalAmountCharged ||
      Number(invoice.totalAmountCharged) !== 0,
    totalAmountCollected:
      totalRowColumnsToNotTransform.totalAmountCollected ||
      Number(invoice.totalAmountCollected) !== 0,
    totalTipCharged:
      totalRowColumnsToNotTransform.totalTipCharged ||
      Number(invoice.totalTipCharged) !== 0,
    totalTipCollected:
      totalRowColumnsToNotTransform.totalTipCollected ||
      Number(invoice.totalTipCollected) !== 0,
  }
}

function transformZeroToDash(
  value: string | number | null | undefined,
  doNotTransform: boolean,
) {
  const valueAsNumber = Number(value)
  const shouldTransformToDash = valueAsNumber === 0 && !doNotTransform
  if (shouldTransformToDash) {
    return '-'
  }

  return value
}

export function transformInvoices(invoices: any[]) {
  // This object should be adjusted if columns are added/removed from the invoice table
  // It is used to skip transforming a 0 value in the total row if the rows above it are not 0
  let totalRowColumnsToNotTransform = {
    totalNonTaxable: false,
    totalTaxable: false,
    totalTax: false,
    totalAmountCharged: false,
    totalAmountCollected: false,
    totalTipCharged: false,
    totalTipCollected: false,
  }

  return invoices.map((invoice: any, idx: number, array: any[]) => {
    totalRowColumnsToNotTransform = adjustTotalRowColumnsToNotTransform(
      totalRowColumnsToNotTransform,
      invoice,
    )

    const {
      tip,
      totalNonTaxable,
      totalTaxable,
      totalTax,
      totalAmountCharged,
      totalAmountCollected,
      totalTipCharged,
      totalTipCollected,
    } = invoice
    const isTotalRow = idx === array.length - 1

    const invoiceWithTipTransformationApplied = {
      ...invoice,
      totalNonTaxable: tip
        ? '-'
        : transformZeroToDash(
            totalNonTaxable,
            isTotalRow ? totalRowColumnsToNotTransform.totalNonTaxable : false,
          ),
      totalTaxable: tip
        ? '-'
        : transformZeroToDash(
            totalTaxable,
            isTotalRow ? totalRowColumnsToNotTransform.totalTaxable : false,
          ),
      totalTax: tip
        ? '-'
        : transformZeroToDash(
            totalTax,
            isTotalRow ? totalRowColumnsToNotTransform.totalTax : false,
          ),
      totalAmountCharged: tip
        ? '-'
        : transformZeroToDash(
            totalAmountCharged,
            isTotalRow
              ? totalRowColumnsToNotTransform.totalAmountCharged
              : false,
          ),
      totalAmountCollected: tip
        ? '-'
        : transformZeroToDash(
            totalAmountCollected,
            isTotalRow
              ? totalRowColumnsToNotTransform.totalAmountCollected
              : false,
          ),
      totalTipCharged:
        !tip && !isTotalRow
          ? '-'
          : transformZeroToDash(
              totalTipCharged,
              isTotalRow
                ? totalRowColumnsToNotTransform.totalTipCharged
                : false,
            ),
      totalTipCollected:
        !tip && !isTotalRow
          ? '-'
          : transformZeroToDash(
              totalTipCollected,
              isTotalRow
                ? totalRowColumnsToNotTransform.totalTipCollected
                : false,
            ),
      transactionId: invoice.transactionId ? invoice.transactionId : '-',
      transactionIdToRefund: invoice.transactionIdToRefund
        ? invoice.transactionIdToRefund
        : '-',
    }

    return invoiceWithTipTransformationApplied
  })
}
