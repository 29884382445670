/**
 * This is a list of carriers that we have TireRackInfo.shipping info for,
 * but the information in the shipping object may not be valid for the
 * following reasons:
 *  - 'Customer Pick-Up': the dates in the object may be invalid as
 *    warehouses might not mark the order as ready for pick up or picked
 *    up until after the order has been picked up
 */
const CARRIERS_WITH_POSSIBLY_INVALID_INFO = {
  CUSTOMER_PICK_UP: 'Customer Pick-Up'
}
const CARRIERS_WITH_POSSIBLY_INVALID_INFO_ARRAY = Object.values(CARRIERS_WITH_POSSIBLY_INVALID_INFO)

const CARRIERS_WITH_TRACKING = {
  FEDEX: 'fedex',
  ONTRAC: 'ontrac',
  UPS: 'ups',
}
const CARRIERS_WITH_TRACKING_ARRAY = Object.values(CARRIERS_WITH_TRACKING)

// A non-complete list of shipping methods
const SHIPPING_METHODS = {
  CUSTOMER_PICK_UP: 'Customer Pick-Up',
    FEDEX: 'fedex',
    ONTRAC: 'ontrac',
    UPS: 'ups'
  }

const TRACKING_LINK_BASE_URLS = {
  FEDEX: 'https://www.fedex.com/fedextrack/summary?trknbr=',
  ONTRAC: 'https://www.ontrac.com/tracking/?number=',
  UPS: 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum='
}

const UNIVERSAL_TR_ORDER_NUMBERS = {
  CHT: 'CHT',
  CHT000: 'CHT000',
  RESHIP: 'RESHIP',
  REWORK: 'REWORK'
}

const UNIVERSAL_TR_ORDER_NUMBERS_ARRAY = [
  'CHT',
  'CHT000',
  'RESHIP',
  'REWORK'
]

export {
  CARRIERS_WITH_POSSIBLY_INVALID_INFO,
  CARRIERS_WITH_POSSIBLY_INVALID_INFO_ARRAY,
  CARRIERS_WITH_TRACKING,
  CARRIERS_WITH_TRACKING_ARRAY,
  SHIPPING_METHODS,
  TRACKING_LINK_BASE_URLS,
  UNIVERSAL_TR_ORDER_NUMBERS,
  UNIVERSAL_TR_ORDER_NUMBERS_ARRAY
}