import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { IDaySetting } from '../../../../../../../../app/entities/DaySetting'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles.module.css'
import { Column } from 'react-table'
import ActivityLogEntry from '../../../../../../../../app/types/activity_log_entry'
import Table from '../../../../../../global/components/table/Table'

interface IDaySettingActivityLogProps {
  daySetting: IDaySetting | null,
  isOpen: boolean,
  handleClosed: () => void,
}

export default function DaySettingActivityLog({
  daySetting,
  isOpen,
  handleClosed,
}: IDaySettingActivityLogProps) {
  if (!daySetting) return null

  const { activityLog } = daySetting

  const sortedActivityLog = activityLog?.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)

    return dateA.getTime() - dateB.getTime()
  })

  const columns: Column<ActivityLogEntry>[] = [
    {
      Header: 'Action',
      accessor: 'type',
      Cell: ({ value: action }) => {
        const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1)

        return (
          <div>{capitalizedAction}</div>
        )
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => {
        const stringDate = new Date(value).toLocaleString()

        return (
          <div>{stringDate}</div>
        )
      },
    },
    {
      Header: 'User',
      accessor: 'userEmail',
    },
  ]

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed()}>
        <Box className={styles.daySettingActivityLogModal}>
          <div>
            <h1>Day Setting Activity Log</h1>
            <div className={styles.tableContainer}>
              <Table
                columns={columns}
                data={sortedActivityLog ?? []}
                isDisplayMode
                isTableTopperPresent={false}
              />
            </div>
            <div className={styles.deleteButtonContainer}>
              <SecondaryButton
                buttonName="Close"
                onClick={() => handleClosed()}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
