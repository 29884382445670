import styles from './styles.module.css'
import OrderSummary from '../order-summary/OrderSummary'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import { timestampToDateTime, timestampToTime, formatPhoneNumber } from '../confirm-appointment/utils'
import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import { useContext, useEffect } from 'react'
import { WoDetailContext } from '../../../../../work-orders/components/context/context'

export default function ConfirmationScreen(props: IScheduleModalComponentProps) {
	const { schedulerState } = props
	const { customerInformation, selectedAvailabilityCalendarSlot } = schedulerState

	const { data: order } = useContext(WoDetailContext)

	const timeSlot = selectedAvailabilityCalendarSlot?.timeSlot || order?.timeSlot


	return (
		<div>
			<div className={styles.flexConfirmationContainer}>
				<div className={styles.message}>Your Appointment has been scheduled 🥳</div>
				<div className={styles.orderInfo}>
					<div className={styles.appointmentContainer}>
						<div className={styles.appointmentTimeContainer}
							// unsure how to pull in the timeSlot when the user reloads on the confirmation screen
							style={{ display: timeSlot ? 'flex' : 'none' }}
						>
							<div className={styles.appointmentTimeContainerTitle}>Appointment</div>
							<div className={styles.confirmAppointmentSmallText}>{timestampToDateTime(timeSlot?.fromTime)}</div>
							<div className={styles.confirmAppointmentSmallText}>{`${timestampToTime(timeSlot?.fromTime)} - ${timestampToTime(timeSlot?.toTime)}`}</div>
						</div>
						<div className={styles.appointmentTimeContainer}>
							<div className={styles.appointmentTimeContainerTitle}>Installation Address</div>
							<div className={styles.confirmAppointmentSmallText}>{customerInformation.address}</div>
							<div className={styles.confirmAppointmentSmallText}>{`${customerInformation.city}, ${customerInformation.state} ${customerInformation.zipCode}`}</div>
						</div>
						<div className={styles.appointmentTimeContainer}>
							<div className={styles.appointmentTimeContainerTitle}>Vehicle</div>
							<div className={styles.confirmAppointmentSmallText}>{customerInformation.make}</div>
							<div className={styles.confirmAppointmentSmallText}>{customerInformation.model}</div>
							<div className={styles.confirmAppointmentSmallText}>{customerInformation.year}</div>
							<div className={styles.confirmAppointmentSmallText}>{customerInformation.variant}</div>
						</div>
					</div>
					<div className={styles.appointmentContainer}>
						<div className={styles.appointmentTimeContainer}>
							<div className={styles.appointmentTimeContainerTitle}>Contact</div>
							<div className={styles.confirmAppointmentSmallText}>{`${customerInformation.firstName} ${customerInformation.lastName}`}</div>
							<div className={styles.confirmAppointmentSmallText}>{`${customerInformation.email}`}</div>
							<div className={styles.confirmAppointmentSmallText}>{`${formatPhoneNumber(customerInformation.phone)}`}</div>
						</div>
					</div>
					<OrderSummary {...props} />
				</div>
			</div>
		</div>
	)
}
