import styles from './styles.module.css'
import { IInvoiceStateProps } from '../EditInvoiceModal'
import { IInvoice } from '../../../../../../../../../../app/entities/Invoice'

interface AdjustInvoiceTableProps {
  amount: number
  invoiceState: IInvoiceStateProps
  adjustmentInvoice: Partial<IInvoice>
}

const PLACEHOLDER = '--'

function combineAndDisplay(...valuesToCombine: (number | undefined)[]) {
  valuesToCombine = valuesToCombine.map(Number)
  const allValuesAreNumbers = valuesToCombine.every(
    (value) => (value || value === 0) && !Number.isNaN(value),
  )

  if (!allValuesAreNumbers) {
    return PLACEHOLDER
  }

  const result = valuesToCombine.reduce(
    (acc, value) => (acc as number) + (value as number),
    0,
  )

  const totalString = `$${(result as number).toFixed(2)}`
  return totalString === '$-0.00' ? '$0.00' : totalString
}

export default function AdjustInvoiceTable({
  invoiceState,
  amount: refundAmount,
  adjustmentInvoice,
}: AdjustInvoiceTableProps) {
  const {
    totalNonTaxable: totalNonTaxableToBeRefunded,
    totalTax: totalTaxToBeRefunded,
    totalTaxable: totalTaxableToBeRefunded,
  } = adjustmentInvoice

  const {
    serviceAmount: currentServiceAmount,
    totalTaxAmount: currentTotalTaxAmount,
    totalAmountCharged: currentTotalAmountCharged,
    amountCollected,
  } = invoiceState

  const negativeRefundAmount = Number.isNaN(Number(refundAmount))
    ? 0
    : -Number(refundAmount)

  const balance = combineAndDisplay(
    currentServiceAmount,
    currentTotalTaxAmount,
    negativeRefundAmount,
  )

  const serviceAmountToRefund = combineAndDisplay(
    totalNonTaxableToBeRefunded,
    totalTaxableToBeRefunded,
  )
  const serviceAmountAfterRefund = combineAndDisplay(
    currentServiceAmount,
    totalNonTaxableToBeRefunded,
    totalTaxableToBeRefunded,
  )

  const taxAmountToRefund = combineAndDisplay(totalTaxToBeRefunded)
  const taxAmountAfterRefund = combineAndDisplay(
    currentTotalTaxAmount,
    totalTaxToBeRefunded,
  )

  const totalAmountToRefund = combineAndDisplay(
    totalNonTaxableToBeRefunded,
    totalTaxableToBeRefunded,
    totalTaxToBeRefunded,
  )
  const totalAmountAfterRefund = combineAndDisplay(
    currentTotalAmountCharged,
    totalNonTaxableToBeRefunded,
    totalTaxableToBeRefunded,
    totalTaxToBeRefunded,
  )

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th></th>
            <th scope='col'>Current</th>
            <th scope='col'>Refund</th>
            <th scope='col'>New</th>
          </tr>
          <tr className={styles.trBorderTop}>
            <th
              scope='row'
              className={styles.noBold}
            >
              Invoice Amount
            </th>
            <td>{combineAndDisplay(currentServiceAmount)}</td>
            <td>{serviceAmountToRefund}</td>
            <td>{serviceAmountAfterRefund}</td>
          </tr>
          <tr>
            <th
              scope='row'
              className={styles.noBold}
            >
              Tax
            </th>
            <td>{combineAndDisplay(currentTotalTaxAmount)}</td>
            <td>{taxAmountToRefund}</td>
            <td>{taxAmountAfterRefund}</td>
          </tr>
          <tr>
            <th
              scope='row'
              className={styles.noBold}
            >
              Total
            </th>
            <td>{combineAndDisplay(currentTotalAmountCharged)}</td>
            <td>{totalAmountToRefund}</td>
            <td>{totalAmountAfterRefund}</td>
          </tr>
          <tr className={styles.trBorderTop}>
            <th scope='row'>Amount Collected</th>
            <td>${amountCollected.toFixed(2)}</td>
            <td></td>
          </tr>
          <tr>
            <th scope='row'>Balance</th>
            <td>{balance}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
