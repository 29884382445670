import React from 'react'
import Box from '@mui/material/Box'
import styles from './styles.module.css'
import { useParams } from 'react-router-dom'
import { getOrderData } from '../api'
import UpdateForm from './Form/UpdateForm'
import useFetch from '../../global/hooks/useFetchHook'
import CircularProgress from '@mui/material/CircularProgress'

export default function CreditCardUpdateMain(props: any) {
  const { id: orderId } = useParams()
  const { data, isLoading } = useFetch(getOrderData(orderId ?? ''))

  return (
    <Box className={styles.updateCcPage}>
      {isLoading ? (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      ) : (
        <UpdateForm
          id={orderId as string}
          data={data}
        />
      )}
    </Box>
  )
}
