import { useState } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import LocationServicesTable, {
  ILocationServiceProps,
} from '../table/LocationServiceOrRuleTable'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import ServiceDropDown from '../../global/components/service-drop-down/ServiceDropDown'
import styles from './styles.module.css'
interface IServicesPageHeaders {
  tableFilters: ILocationServiceProps
}
export interface IServiceFilters {
  serviceFilter: string
  setServiceFilter: Function
}
const ServicesPageHeaders = ({
  tableFilters,
}: IServicesPageHeaders): JSX.Element => {
  const { serviceFilters, locationFilters } = tableFilters
  const { setServiceFilter } = serviceFilters
  const { setLocationFilter } = locationFilters
  return (
    <div className={styles.servicesHeaderContainer}>
      <LocationDropDown onLocationChange={setLocationFilter} />
      <ServiceDropDown
        onServiceChange={setServiceFilter}
        additionalOptions={[
          {
            name: 'All Services',
            objectId: '',
          },
        ]}
      />
    </div>
  )
}

export default function ServicesMain() {
  const [locationFilter, setLocationFilter] = useState('')
  const [serviceFilter, setServiceFilter] = useState('')

  return (
    <MainPageWrapper
      titleFields={
        <ServicesPageHeaders
          tableFilters={{
            serviceFilters: { serviceFilter, setServiceFilter },
            locationFilters: { setLocationFilter, locationFilter },
          }}
        />
      }
    >
      <LocationServicesTable
        locationFilters={{ locationFilter, setLocationFilter }}
        serviceFilters={{ serviceFilter, setServiceFilter }}
      />
    </MainPageWrapper>
  )
}
