import React, { useEffect } from 'react'
import styles from './styles.module.css'
import { TireSizeButton } from './TireSizeButton'
import CustomTireSize from '../custom-size/CustomTireSize'
import CircularProgress from '@mui/material/CircularProgress'
import CustomSizeButton from './CustomSizeButton'
import useFetch from '../../../../../../global/hooks/useFetchHook'
import { getTireSize } from '../api'
import { IScheduleModalComponentProps } from '../../container-modal/ScheduleModal'
import { ITiresCombination } from '../../../../../../../../app/entities/TiresCombination'
interface ISelectTireSizeProps {
  selectedItem: any
  setSelectedItem: Function
  schedulerProps: IScheduleModalComponentProps
  mode?: string
}

function formatTireSize(item: any, index: any) {
  const { frontTire, rearTire } = item

  return {
    inches: `${frontTire?.diameter}"`,
    frontSize: `${frontTire?.width} : ${frontTire?.aspectRatio} R${frontTire?.diameter}`,
    rearSize: `${rearTire?.width} : ${rearTire?.aspectRatio} R${rearTire?.diameter}`,
    id: index,
    item: item,
  }
}

export const SelectTireSize = (props: ISelectTireSizeProps) => {
  const [selected, setSelected] = React.useState(0)
  const { schedulerState } = props.schedulerProps
  const { customerInformation, tires } = schedulerState

  const tireInfo = JSON.stringify({
    brand: { name: customerInformation.make },
    year: customerInformation.year,
    model: customerInformation.model,
    variant: customerInformation.variant,
    version: customerInformation.variant,
  })
  const { data, isLoading, error } = useFetch<ITiresCombination[]>(
    getTireSize(tireInfo),
  )

  useEffect(() => {
    if (
      data !== null &&
      data.length > 0 &&
      !tires.frontTire.width &&
      !tires.rearTire.width
    ) {
      const tire = data[0]
      props.setSelectedItem({
        frontTire: {
          aspectRatio: tire.frontTire?.aspectRatio,
          width: tire.frontTire?.width,
          diameter: tire.frontTire?.diameter,
        },
        rearTire: {
          aspectRatio: tire.rearTire?.aspectRatio,
          width: tire.rearTire?.width,
          diameter: tire.rearTire?.diameter,
        },
      })
    } else if (data !== null && data.length > 0) {
      let finalIndex = 100
      data.forEach((tire: ITiresCombination, index: number) => {
        if (
          tire?.frontTire?.width === tires.frontTire.width &&
          tire?.rearTire?.width === tires.rearTire.width &&
          String(tire?.frontTire?.aspectRatio) ===
            tires.frontTire.aspectRatio &&
          String(tire?.rearTire?.aspectRatio) === tires.rearTire.aspectRatio &&
          String(tire?.frontTire?.diameter) === tires.frontTire.diameter &&
          String(tire?.rearTire?.diameter) === tires.rearTire.diameter
        ) {
          finalIndex = index
        }
      })

      setSelected(finalIndex)
    }
  }, [data])

  return (
    <div className={styles.selectTireSizeContainer}>
      <div className={`${styles.selectTireSizeTitle} font--bold`}>
        Select Tire Size
      </div>
      {isLoading || error ? (
        <CircularProgress />
      ) : (
        <>
          <div className={styles.tireSizeButtonContainer}>
            {data &&
              data.map((item: any, index: any) => {
                if (!item.frontTire || !item.rearTire) {
                  return null
                }

                const formatted = formatTireSize(item, index)
                return (
                  <TireSizeButton
                    {...formatted}
                    item={item}
                    selected={selected}
                    setSelectedItem={props.setSelectedItem}
                    onClick={setSelected}
                    key={index}
                  />
                )
              })}
            <CustomSizeButton
              selected={selected}
              id={100}
              onClick={setSelected}
              setSelectedItem={props.setSelectedItem}
            />
          </div>
          {selected === 100 && (
            <CustomTireSize
              selectedItem={props.selectedItem}
              setSelectedItem={props.setSelectedItem}
            />
          )}
        </>
      )}
    </div>
  )
}
