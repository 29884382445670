import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { ChangeEvent } from 'react'

interface IWorkDayDropDownProps {
  value: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
}

export const WorkDayDropDown = ({ value, onChange, disabled }: IWorkDayDropDownProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true'
    onChange && onChange(value)
  }

  return (
    <TextField
      disabled={disabled}
      select
      InputLabelProps={{ shrink: true }}
      sx={{
        width: 70,
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
        },
      }}
      value={value ? 'true' : 'false'}
      onChange={handleChange}>
      <MenuItem value="true">Yes</MenuItem>
      <MenuItem value="false">No</MenuItem>
    </TextField>
  )
}
