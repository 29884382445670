import MainPageWrapper from '../../global/components/MainPageWrapper'
import RouteOptimizationCorrector from './RouteOptimizationCorrector'
import AddCompletedInvoice from './AddCompletedInvoice'
import DriveTimesCalculator from './DriveTimesCalculator'
import RoutingAlgorithmManager from './RoutingAlgorithmManager'

import styles from './styles.module.css'

export default function JmulletTools() {
  return (
    <MainPageWrapper>
      <h1>jmullet tools</h1>
      <div
        className={styles.toolsHolder}
      >
        <RouteOptimizationCorrector />
        <AddCompletedInvoice />
        <DriveTimesCalculator />
        <RoutingAlgorithmManager />
      </div>

    </MainPageWrapper>
  )
}