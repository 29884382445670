import styles from '../../styles.module.css'
import activeCircle from '../../../../assets/images/active_circle.png'
import inactiveCircle from '../../../../assets/images/inactive_circle.png'

export interface OrderTrackerBulletProps {
  isActive: boolean
  message: JSX.Element
}

export default function OrderTrackerBullet(props: OrderTrackerBulletProps) {
  const { isActive, message } = props
  return (
    <div className={styles.bulletContainer}>
      <div className={styles.bullet}>
        <img
          src={isActive ? activeCircle : inactiveCircle}
          alt={isActive ? 'activeCircle' : 'inactiveCircle'}
          className={styles.circle}
        />
        <div className={isActive ? styles.activeMessage : styles.inactiveMessage}>
          {message}
        </div>
      </div>
    </div>
  )
}
