const STATUSES = {
  draft             : 'draft',
  quote             : 'quote',
  scheduled         : 'scheduled',
  optimized         : 'optimized',
  en_route          : 'en_route',
  on_site           : 'on_site',
  started           : 'started',
  reschedule        : 'pending_rescheduling',
  rescheduled       : 'rescheduled',
  completed         : 'completed',
  cancelled         : 'cancelled',
  cancelled_asap    : 'cancelled_asap',
  cancelled_customer: 'cancelled_customer',
  cancelled_onsite  : 'cancelled_onsite',
  cancelled_partial : 'cancelled_partial',
}

const SCHEDULED_STATUSES = [
  STATUSES.scheduled,
  STATUSES.rescheduled,
]

const DISPLAY_STATUSES = {
  ...STATUSES,
  // The statuses below can be displayed in the UI, but are not used in the backend
  almost_late: 'almost_late',
  late: 'late'
}


// The following constants are used in the OrderTracker on the frontend
const ARRIVED_STATUSES = [
  STATUSES.cancelled,
  STATUSES.cancelled_asap,
  STATUSES.cancelled_customer,
  STATUSES.cancelled_onsite,
  STATUSES.cancelled_partial,
  STATUSES.completed,
  STATUSES.on_site,
  STATUSES.started
]
const CANCELLED_STATUSES = [
  STATUSES.cancelled,
  STATUSES.cancelled_asap,
  STATUSES.cancelled_customer,
  STATUSES.cancelled_onsite,
  STATUSES.cancelled_partial
]
const ON_THE_WAY_STATUSES = [
  ...ARRIVED_STATUSES,
  STATUSES.en_route
]

const STARTED_STATUSES = [
  STATUSES.completed,
  STATUSES.started
]

export {
  ARRIVED_STATUSES,
  CANCELLED_STATUSES,
  DISPLAY_STATUSES,
  ON_THE_WAY_STATUSES,
  STARTED_STATUSES,
  STATUSES,
  SCHEDULED_STATUSES
}