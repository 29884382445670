export const getTechById = (technicianId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/byTechId?techObjectId=${technicianId}&relationsDepth=4`,
  }
}
export const getLocationByLocationId = (locationObjectId: string) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}`
	}
}
export const getPicturesByIds = (technicianIds: string[]) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/technicians/pictures?techObjectIds=${JSON.stringify(technicianIds)}`
	}
}
export const editTech = (body: any) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/technicians`,
    body,
  }
}
export const editTechWeekDays = (body: any, technicianId: string | undefined) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}/week-calendar`,
    body,
  }
}
export const createTech = (body: any) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/technicians`,
    body,
  }
}
export const createDaySetting = (body: any, technicianId: string | undefined) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}/day-settings`,
    body,
  }
}
export const updateDaySetting = (body: any, technicianId: string | undefined) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}/day-settings`,
    body,
  }
}
export const deleteDaySetting = (technicianId: string | undefined, daySettingId: string) => {
  return {
    method: 'DELETE',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}/day-settings/${daySettingId}`,
  }
}
export const setTechToLocation = (locationId: string | null, technicianId: string) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/technicians/${technicianId}`,
  }
}
export const deleteTechnician = (technicianId: string | null) => {
  return {
    method: 'DELETE',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}`,
  }
}
export const uploadTechAvatar = (technicianId: string, image: FormData) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/technicians/${technicianId}/avatar`,
		body: image
	}
}
