import { IRouteBuilderFormProps } from './reports/cs-team-tools-modal/route-builder/RouteBuilder'
import { IDailyWarehouseReportsFormData } from './reports/daily-warehouse-reports-modal/DailyWarehouseReportsModal'

export function postDailyWarehouseReports(body: IDailyWarehouseReportsFormData) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/daily-warehouse-reports`,
		body
	}
}
export function getTechsByLocationId(locationId: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/technicians/availability/${locationId}`
	}
}
export function setRouteAndRelations(formData: IRouteBuilderFormProps) {
	const { date, technicianId } = formData
	const body = {
		technicianObjectId: technicianId,
		day: new Date(date).toISOString()
	}
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/routes/for-technician`,
		body
	}
}
export function getOrderById(orderId: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/orders/getAllOrders?sortBy=%60workOrderId%60%20DESC&pageSize=1&offSet=0&columnFilters={"workOrderId":"${orderId}"}`
	}
}
export function getOrderVehicleData(orderId: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/byOrderObjectId?orderObjectId=${orderId}`
	}
}
export function getInvoices(orderId: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/invoice/order/${orderId}`
	}
}
export function getReport(url: string, params: any) {
	return {
		method: 'GET',
		url: url,
		params
	}
}
export function quickReports(viewName: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/view/${viewName}`
	}
}
export function quickReportsWithParams(viewName: string, params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/view/${viewName}`,
		params
	}
}

export function mileageReporting(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/mileage-reporting`,
		params
	}
}
export function preAppointmentCancellationAndRescheduleReport(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/pre-appointment-cancellation-and-reschedule-report`,
		params
	}
}
export function dvirReport(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/dvir-report`,
		params
	}
}

export function monthlyServiceAndTaxAmounts(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/monthly-service-and-tax-amounts`,
		params
	}
}

export function transactionReport(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/transaction-report`,
		params
	}
}

export function inventoryReport(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/inventory-report`,
		params
	}
}

export function taxCorrections(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/tax-corrections`,
		params
	}
}

export function tireSales(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/tire-sales`,
		params
	}
}

export function priorMonthPaidInCurrentMonth(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/prior-month-paid-in-current-month`,
		params
	}
}
export function refunds(params: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/refunds`,
		params
	}
}
export function availabilityReport() {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/availability`
	}
}
export function unpaidBalancesBadDebt(params?: any) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/unpaid-balances-bad-debt`,
		params
	}
}
export function daysToAppointment() {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/days-to-appointment-dashboard`
	}
}

export function uploadStateTireFees(formData: FormData) {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/files/state-tire-fees`,
		body: formData,
		headersToRemove: ['Content-Type'],
	}
}
export function getStandbyQueueReport(numberOfWeeks: number) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/reportsV2/standby-queue-report`,
		params: {
			numberOfWeeks: `${numberOfWeeks}`
		}
	}
}