import { Invoice } from '../../../../../../app/entities/Invoice'
import { STATUSES } from '../../../constants/invoice'

const {
  GATEWAY_REJECTED,
  PROCESSOR_DECLINED,
  REFUND_CANCELLED,
  SETTLEMENT_DECLINED,
} = STATUSES

// This function should be mirrored in app/eworkflows/invoice/status/is-transaction-declined.ts
function isTransactionDeclined(invoice: Invoice) {
  return [
    GATEWAY_REJECTED,
    PROCESSOR_DECLINED,
    REFUND_CANCELLED,
    SETTLEMENT_DECLINED,
  ].includes(invoice.status as STATUSES)
}

export default isTransactionDeclined
