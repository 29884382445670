import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import EditInvoiceModal from './edit-invoice-modal/EditInvoiceModal'
import retrieveData from '../../../../../../global/components/table/helpers/retrieveData'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IInvoice } from '../../../../../../../../app/entities/Invoice'
import { STATUSES, TYPES } from '../../../../../../global/constants/invoice'
import CancelRequestedRefundModal from '../../sub-modals/modal-content/delete-requested-refund/CancelRequestedRefundModal'
import isInvoiceRefund from '../../../../../../global/utils/invoice/type/is-invoice-refund'
import {
  generateRowClickDisabledList,
  transformInvoices,
} from './edit-invoice-modal/helper-functions/helpers'

interface IInvoiceTableProps {
  isReadOnly: boolean
}

const SUB_MODAL_NAMES = {
  EDIT_INVOICE_MODAL: 'EDIT_INVOICE_MODAL',
  DELETE_REFUND_MODAL: 'DELETE_REFUND_MODAL',
}

export default function InvoiceTable({ isReadOnly }: IInvoiceTableProps) {
  const { id } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [modalInformation, setModalInformation] = useState<any>({
    modalName: '',
    modalOpen: false,
    invoice: undefined,
    associatedAdjustmentInvoices: [],
  })
  const [disableRowClickList, setDisableRowClickList] = useState<number[]>([])
  const [tableState, setTableState] = useState({
    data: [] as any[],
    allInvoices: [] as any[],
    isLoading: false,
  })

  function canUserAdjustInvoice() {
    const { allInvoices } = tableState
    const ableToAdjust = allInvoices.find(
      (invoice: any) => invoice.type === TYPES.COMPLETED,
    )

    return ableToAdjust
  }

  function onRecordClickHandler(row: any) {
    if (isReadOnly) return

    const { original } = row
    const { status, type } = original

    const isAdjustmentAllowed = canUserAdjustInvoice()
    const shouldOpenAdjustmentModal =
      type === TYPES.COMPLETED && isAdjustmentAllowed
    const shouldOpenDeleteRefundModal =
      isInvoiceRefund(original) &&
      isAdjustmentAllowed &&
      status === STATUSES.REFUND_REQUESTED
    if (shouldOpenAdjustmentModal) {
      setModalInformation({
        ...modalInformation,
        modalName: SUB_MODAL_NAMES.EDIT_INVOICE_MODAL,
        modalOpen: true,
        invoice: original,
        associatedAdjustmentInvoices: tableState.allInvoices.filter(
          (invoice: IInvoice) =>
            invoice.transactionIdToRefund === original.transactionId,
        ),
      })
    } else if (shouldOpenDeleteRefundModal) {
      setModalInformation({
        modalName: SUB_MODAL_NAMES.DELETE_REFUND_MODAL,
        modalOpen: true,
        invoice: original,
      })
    }
  }

  function handleClosed() {
    setModalInformation({
      modalName: '',
      modalOpen: false,
      invoice: undefined,
    })
  }
  useEffect(() => {
    generateRowClickDisabledList(tableState, setDisableRowClickList)
  }, [tableState])

  const endpoint = `${process.env.REACT_APP_ORIGIN}/invoice/byOrderId?`
  useEffect(() => {
    if (isOpen) {
      retrieveData(setTableState, {
        endpoint,
        pageSize: 50,
        offSet: 0,
        orderObjectId: id,
        additionalData: true,
      })
    }
  }, [isOpen, id, endpoint])

  const columns: Column<any>[] = [
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Non Taxable',
      accessor: 'totalNonTaxable',
    },
    {
      Header: 'Taxable',
      accessor: 'totalTaxable',
    },
    {
      Header: 'Tax',
      accessor: 'totalTax',
    },
    {
      Header: 'Invoice Amount',
      accessor: 'totalAmountCharged',
    },
    {
      Header: 'Invoice Amount Collected',
      accessor: 'totalAmountCollected',
    },
    {
      Header: 'Tip Amount',
      accessor: 'totalTipCharged',
    },
    {
      Header: 'Tip Amount Collected',
      accessor: 'totalTipCollected',
    },
    {
      Header: 'Transaction Id',
      accessor: 'transactionId',
    },
    {
      Header: 'Refunded Transaction Id',
      accessor: 'transactionIdToRefund',
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      {modalInformation.modalName === SUB_MODAL_NAMES.EDIT_INVOICE_MODAL ? (
        <EditInvoiceModal
          isOpen={modalInformation.modalOpen}
          handleClosed={handleClosed}
          invoice={modalInformation.invoice}
          associatedAdjustmentInvoices={
            modalInformation.associatedAdjustmentInvoices
          }
        />
      ) : (
        <CancelRequestedRefundModal
          isOpen={modalInformation.modalOpen}
          handleClosed={handleClosed}
          invoice={modalInformation.invoice}
        />
      )}
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Invoice</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={transformInvoices(tableState.data)}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        rowClickDisabledList={disableRowClickList}
        isOpen={isOpen}
        isDisplayMode={true}
        highlightLastRow={true}
        disableSortBy={true}
      />
    </div>
  )
}
