import React, { createContext, useEffect, useState } from 'react'
import { initialVanValues } from './initial-values'
import { IVanPartial } from '../../../../../app/entities/Van'
import { getLocationByObjectId, getVanByObjectId } from './api'
import { fetchData } from '../../utils/fetch'

interface IContextProps {
  clearVanData: () => void
  queryVanData: (id?: string) => Promise<IVanPartial>
  resetVanData: () => IVanPartial
  setVanData: (vanData: IVanPartial) => void
  vanData: IVanPartial

  // signifies if the van data is actively being fetched
  isVanDataLoading: boolean

  setLocation: (locationObjectId: string) => void
}

interface IProviderProps {
  children: React.ReactNode
}

export const VanContext = createContext<IContextProps>({} as IContextProps)

export function VanContextProvider({ children }: IProviderProps) {
  const [vanDataState, setVanDataState] =
    useState<IVanPartial>(initialVanValues)
  const [vanDataOriginal, setVanDataOriginal] =
    useState<IVanPartial>(initialVanValues)
  const [isVanDataLoading, setIsVanDataLoading] = useState(false)

  function clearVanData() {
    setVanDataState(initialVanValues)
  }

  async function queryVanData(
    vanObjectId: string | undefined,
    updateContext: boolean = true,
  ) {
    setIsVanDataLoading(true)

    const vanObjectIdToQuery = vanObjectId || vanDataState.objectId
    if (vanObjectIdToQuery) {
      try {
        const vanResults = await fetchData<IVanPartial>(
          getVanByObjectId(vanObjectIdToQuery),
        )

        if (updateContext) {
          setVanData(vanResults)
          setVanDataOriginal(vanResults)
        }

        return vanResults
      } catch (error) {
        console.error('Failed to fetch van data:', error)
        throw error
      } finally {
        setIsVanDataLoading(false)
      }
    } else {
      return vanDataState
    }
  }

  function resetVanData(): IVanPartial {
    setVanData(vanDataOriginal)

    return vanDataOriginal
  }

  function setVanData(newData: Partial<IVanPartial>) {
    setVanDataState(
      (prevData) =>
        ({
          ...prevData,
          ...newData,
        }) as any,
    )
  }

  async function setLocation(locationObjectId: string) {
    const location = await fetchData<Location>(
      getLocationByObjectId(locationObjectId),
    )

    setVanDataState(
      (prevData) =>
        ({
          ...prevData,
          location,
        }) as any,
    )
  }

  return (
    <VanContext.Provider
      value={{
        clearVanData,
        queryVanData,
        resetVanData,
        setVanData,
        vanData: vanDataState,
        isVanDataLoading,

        setLocation,
      }}
    >
      {children}
    </VanContext.Provider>
  )
}
