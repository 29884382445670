import { useEffect } from 'react'
import styles from './styles.module.css'
import CasesCreate from './CasesCreate'
import CircularProgress from '@mui/material/CircularProgress'
import useFetch from '../../../../../../../../../global/hooks/useFetchHook'
import { ICaseFormInfoProps, ICaseFormProps } from './CasesModal'
import { getCasesByOrderId } from '../../api'
import { IEditCaseDataData } from '../../../CasesWoDetailTable'
interface ICasesEditProps {
  formProps: ICaseFormProps
  editCaseData: IEditCaseDataData
  isNewCase?: boolean
}

//the func in backend transforms the Case object to match the output of BE so it no longer follows ICase structure, so im redefining it here.
interface ITransformedCase {
  objectId: string
  created: number
  updated: number
  openDateGenerated: string
  statusDateGenerated: string
  openDate: string
  statusDate: string
  createdBy: string
  status: string
  summary: string
  type: string
  damageCost: number
  firstName: string
  lastName: string
  customerObjectId: string
  orderObjectId: string
  workOrderId: number
  caseObjectId: string
}
interface IGetCasesByOrderIdProps {
  items: ITransformedCase[]
  count: number
}
export default function CasesEdit({
  editCaseData,
  formProps,
  isNewCase,
}: ICasesEditProps) {
  const { setCaseFormInfo, caseFormInfo } = formProps
  const { data, isLoading } = useFetch<IGetCasesByOrderIdProps>(
    getCasesByOrderId(editCaseData.orderObjectId),
  )

  useEffect(() => {
    if (data && data.items) {
      const filteredData = data.items.filter(
        (item: ITransformedCase) =>
          item.caseObjectId === editCaseData.caseObjectId,
      )[0]
      setCaseFormInfo((prevState: ICaseFormInfoProps) => ({
        ...prevState,
        orderId: filteredData.workOrderId,
        status: filteredData.status,
        damageCost: filteredData.damageCost,
        summary: filteredData.summary,
        type: filteredData.type,
        createdBy: filteredData.createdBy,
      }))
    }
  }, [data])

  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <CasesCreate
      formProps={{ caseFormInfo, setCaseFormInfo }}
      isNewCase={isNewCase}
    />
  )
}
