import { IDiscount } from '../../../../../../../../../app/entities/Discount'

export function setDiscountToOrder(orderId: string, body: any, username?: string) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/discount${username ? `?username=${username}` : ''}`,
		body: body,
	}
}
export function createDiscount(discount: Partial<IDiscount>) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/discounts`,
		body: discount
	}
}

export function updateDiscount(discount: Partial<IDiscount>) {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/discounts`,
		body: discount,
	}
}

export function setRelationToFreeOrderReason(orderObjectId: string, freeOrderReasonObjectId: string) {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/free-order-reason/${freeOrderReasonObjectId}`,
	}
}

export const removeDiscountFromOrder = (orderObjectId: string, discountObjectId: string, username?: string) => {
	let url = `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/discount/${discountObjectId}`

	const query = new URLSearchParams()
	if (username) {
		query.set('username', username)
	}

	if (query.toString()) {
		url += `?${query.toString()}`
	}

	return {
		method: 'DELETE',
		url,
	}
}