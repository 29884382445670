import { useEffect, useState } from 'react'
import { IOrder } from '../../../../../app/entities/Order'
import styles from '../styles.module.css'
import { TYPES } from '../../../global/constants/invoice'

interface ServiceSummaryProps {
  order: IOrder | null
}

export default function ServiceSummary(props: ServiceSummaryProps) {
  const { order } = props
  const [discountStrings, setDiscountStrings] = useState<string[]>([])
  const [serviceStrings, setServiceStrings] = useState<string[]>([])
  const [invoiceTotal, setInvoiceTotal] = useState('$0.00')

  useEffect(() => {
    const discounts = order?.discounts
    const discountStrings = discounts?.map((discount) => {
      if (discount.measure === '') return `$${discount.value}`
      else return `${discount.value}${discount.measure}`
    })

    /**
     * This section has been commented out beacuse our Order Services is displaying incorrect information in PROD
     * This should be the standard flow of things
     */

    const orderServices = order?.orderServices
    const validService = orderServices?.filter(
      (orderService) => orderService.quantity !== 0,
    )
    const servicesStrings = validService?.map((orderService) => {
      const defaultServicePrice = orderService?.price
      const locationServicePrice = orderService?.locationService?.price
      const priceOverride = orderService?.priceOverride
      const finalPrice =
        priceOverride ?? locationServicePrice ?? defaultServicePrice
      return `${orderService.quantity} ${orderService.label} $${finalPrice}`
    })
    /**
     * This section has been added to replace order services and display line items from the invoice which are correct
     */
    // const existsOrderInvoice = order?.invoices && order?.invoices.length !== 0
    // const lineItems = existsOrderInvoice
    //   ? order?.invoices[0].lineItems
    //   : ([] as LineItem[])
    // const lineItemStrings = lineItems?.map((lineItem) => {
    //   const price = lineItem.unit_price //this will update with price overrides
    //   return `${lineItem.quantity} ${lineItem.description} $${price}`
    // })

    const invoices = order?.invoices
    const quoteInvoice = invoices?.find(
      (invoice) => invoice.type === TYPES.QUOTE,
    )
    const invoiceTotal = `$${(quoteInvoice?.totalAmountCharged ?? 0).toFixed(2)}`

    setDiscountStrings(discountStrings ?? [])
    setServiceStrings(servicesStrings ?? [])
    setInvoiceTotal(invoiceTotal)
  }, [order])

  return (
    <div className={styles.serviceSummaryContainer}>
      <div className={styles.serviceSummaryHeader}>Service Summary</div>
      <div className={styles.discounts}>
        {serviceStrings.length > 0
          ? serviceStrings.map((serviceString) => {
              return (
                <div
                  key={serviceString}
                  className={styles.discount}
                >
                  {serviceString}
                </div>
              )
            })
          : ''}
      </div>
      {discountStrings.length > 0 ? (
        <>
          <div>Discounts:</div>
          <div className={styles.discounts}>
            {discountStrings.length > 0
              ? discountStrings.map((discountString) => {
                  return <div className={styles.discount}>{discountString}</div>
                })
              : ''}
          </div>
        </>
      ) : (
        <div />
      )}
      <div className={styles.invoiceTotalHeader}>
        Invoice Total: {invoiceTotal}
      </div>
    </div>
  )
}
