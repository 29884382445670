import React, { ChangeEvent } from 'react'
import { Select } from '../../../../../../global/components/select/Select'
import { tireCountData } from '../select-quantity/SelectQuantity'
import styles from './styles.module.css'
import { ISchedulerStateProps } from '../../container-modal/initialData'
export interface IDisposalQtyProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
}
const TireDisposalQuantity = (props: IDisposalQtyProps) => {
  const { schedulerState, setSchedulerState } = props
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (Number(value) === 0) {
      setSchedulerState({ ...schedulerState, disposalQty: value, tireDisposal: false })
    } else setSchedulerState({ ...schedulerState, disposalQty: value, tireDisposal: true })
  }
  return (
    <div className={styles.disposalQtyContainer}>
      <div className={`font--bold ${styles.title}`}>Disposal</div>
      <Select
        name="disposalQuantity"
        label="Quantity"
        width="156px"
        data={tireCountData}
        defaultValue={schedulerState.disposalQty}
        onChange={onChange}
      />
    </div>
  )
}

export default TireDisposalQuantity
