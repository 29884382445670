import styles from './styles.module.css'
import arrowSvg from '../../../../../assets/images/arrow.svg'
import { MouseEventHandler } from 'react'

interface TableTopperProps {
  children: JSX.Element | JSX.Element[]
  isOpen?: boolean
  style?: React.CSSProperties
  onToggleClick?: MouseEventHandler<HTMLDivElement> | undefined
  className?: string
}

function TableTopper({
  children,
  isOpen,
  onToggleClick,
  style,
  className,
}: TableTopperProps) {
  const shouldShowToggle = !!onToggleClick

  const showOpen = typeof isOpen === 'undefined' ? true : isOpen

  return (
    <div
      className={`${styles.tableTopperMain} ${showOpen ? styles.open : ''}`}
      onClick={onToggleClick}
    >
      <div
        className={`${className ?? ''} ${styles.tableTopperContainer}`}
        style={style || {}}
      >
        {children}
      </div>
      <div
        className={`${styles.toggleHolder} ${isOpen ? styles.open : ''}`}
        hidden={!shouldShowToggle}
      >
        <img
          src={arrowSvg}
          alt=''
        />
      </div>
    </div>
  )
}

export default TableTopper
