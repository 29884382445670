import { ChangeEvent, useState, useEffect } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { getServiceList } from './api'
import useFetch from '../../hooks/useFetchHook'
import { IService } from '../../../../../app/entities/Service'
import {
  SERVICE_NAMES,
  TIRE_INSTALL_TIRE_RACK_NAMES,
} from '../../constants/service'
import { MODES } from '../../constants/scheduler'

const {
  TIRE_INSTALL,
  TIRE_PREMOUNTED,
  TIRE_ROTATION,
  TPMS_SERVICE,
  TIRE_DROP_OFF,
  TIRE_DISPOSAL,
  REWORK,
} = SERVICE_NAMES

interface ServiceDropdownProps {
  onServiceChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
  mode?: string
  additionalOptions?: { name: string; objectId: string }[]
}

export default function ServiceDropDown({
  onServiceChange,
  disabled,
  defaultValue,
  fullWidth,
  mode,
  additionalOptions,
}: ServiceDropdownProps) {
  const [value, setValue] = useState(defaultValue || '')
  const { data, isLoading } = useFetch<IService[]>(getServiceList())

  useEffect(() => {
    if (defaultValue && data) {
      const defaultService = data.find(
        (service: IService) => service.objectId === defaultValue,
      )
      if (defaultService) {
        setValue(defaultService.objectId)
      }
    }
  }, [defaultValue, data])

  const onServiceChangeWrapper = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onServiceChange && onServiceChange(newValue)
  }

  const dataWithAdditionalOptions = data
    ? [...data, ...(additionalOptions || [])]
    : []

  const sortedServices = (dataWithAdditionalOptions || [])
    .filter((service: Partial<IService>) => service.name)
    .sort((a: Partial<IService>, b: Partial<IService>) =>
      (a.name || '').localeCompare(b.name || ''),
    )

  const filteredServicesForCS = sortedServices.filter(
    (service: Partial<IService>) =>
      [
        TIRE_INSTALL,
        TIRE_PREMOUNTED,
        TIRE_ROTATION,
        TPMS_SERVICE,
        TIRE_DROP_OFF,
      ].includes(service?.name || ''),
  )

  const filteredServicesForCustomers = sortedServices
    .filter((service: Partial<IService>) => {
      return [TIRE_INSTALL, TIRE_PREMOUNTED, TIRE_ROTATION].includes(
        service?.name || '',
      )
    })
    .map((service: Partial<IService>) => {
      if (
        service.name === TIRE_INSTALL &&
        service.tireRackName === TIRE_INSTALL_TIRE_RACK_NAMES.WHEELS_ONLY
      ) {
        return { ...service, name: 'Wheel Install' }
      }
      if (service.name === TIRE_PREMOUNTED) {
        return { ...service, name: 'Wheel and Tire Package Install' }
      }
      if (service.name === TIRE_ROTATION) {
        return { ...service, name: 'Tire Rotation and Rebalance' }
      }
      return service
    })

  let endResultServices: Partial<IService>[] = sortedServices

  if (mode === MODES.CUSTOMER) {
    endResultServices = filteredServicesForCustomers
  } else if (mode === MODES.EDIT) {
    endResultServices = filteredServicesForCS
  }

  let menuItems

  if (isLoading) {
    menuItems = (
      <MenuItem
        value=''
        className={styles.dropDownOption}
      >
        Loading...
      </MenuItem>
    )
  } else {
    menuItems = endResultServices
      .filter(Boolean)
      .map((service: Partial<IService>) => {
        return (
          <MenuItem
            key={service.objectId}
            value={service.objectId}
            className={styles.dropDownOption}
          >
            {service.name}
            {mode !== MODES.CUSTOMER && service.tireRackName
              ? ` (${service.tireRackName})`
              : ''}
          </MenuItem>
        )
      })
  }

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label='Service'
        select
        fullWidth={fullWidth}
        name='serviceObjectId'
        disabled={disabled}
        onChange={onServiceChangeWrapper}
        value={value}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}
      >
        {menuItems}
      </TextField>
    </div>
  )
}
