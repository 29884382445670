export const listSmallPartSupplies = () => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies`,
	}
}
export const createSmallPartSupplies = (body: any) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies`,
    body,
	}
}
export const updateSmallPartSupplies = (body: any) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies`,
    body,
	}
}
export const deleteSmallPartSupplies = (objectId: string) => {
	return {
		method: 'DELETE',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies/${objectId}`,
	}
}
export const getSmallPartSupplyByBin = (bin: string) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies/${bin}`,
	}
}
export const uploadSmallPartSuppliesQrCode = (bin: string, image: FormData) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies/${bin}/qr-code`,
		body: image
	}
}
export const getSmallPartSuppliesQrCode = (bin: string) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/small-part-supplies/${bin}/qr-code`,
	}
}