export function timestampToDateTime(timestamp?: Date | number) {
  const date = new Date(timestamp || Date.now())
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const dayOfWeek = daysOfWeek[date.getDay()]
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${dayOfWeek}, ${month} ${day}, ${year}`
}

export function timestampToTime(timestamp?: Date | number) {
  const date = new Date(timestamp || Date.now())
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

  const time = `${formattedHours}:${formattedMinutes} ${ampm}`
  return time
}
export function formatPhoneNumber(phoneNum: string) {
  let str = phoneNum.toString()

  let a = str.substring(0, 3)
  let b = str.substring(3, 6)
  let c = str.substring(6, 10)

  let phoneNumber = '(' + a + ') ' + b + '-' + c

  return phoneNumber
}
