import MainPageWrapper from '../../../global/components/MainPageWrapper'
import WorkOrdersTable from '../table/WorkOrdersTable'
import { WoDetailContextProvider } from '../context/context'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { useState } from 'react'

export interface ILocationFilters {
  locationFilter: string
  setLocationFilter: Function
}

function WorkOrdersMain() {
  const [locationFilter, setLocationFilter] = useState('')

  return (
    <WoDetailContextProvider>
      <MainPageWrapper
        titleFields={
          <div style={{ marginRight: 'auto', marginLeft: '1rem' }}>
            <LocationDropDown
              onLocationChange={(val: string) => {
                setLocationFilter(val)
              }}
            />
          </div>
        }>
        <WorkOrdersTable locationFilters={{ locationFilter, setLocationFilter }} />
      </MainPageWrapper>
    </WoDetailContextProvider>
  )
}

export default WorkOrdersMain
