import { TextField } from '@mui/material'
import { ISchedulerStateProps } from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import PrimaryCheckbox from '../checkbox/Checkbox'
import { useEffect } from 'react'

interface ServicePriceOverrideProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
  priceOverrideIsDisabled: boolean
  setPriceOverrideIsDisabled: Function
}

function ServicePriceOverride({
  schedulerState,
  setSchedulerState,
  priceOverrideIsDisabled,
  setPriceOverrideIsDisabled,
}: ServicePriceOverrideProps) {

  useEffect(() => {
    const priceOverrideHasBeenTurnedOn = priceOverrideIsDisabled

    if (priceOverrideHasBeenTurnedOn) {
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        servicePriceOverride: null,
      }))
    }
  }, [priceOverrideIsDisabled])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            fontSize: '12px',
          }}
        >
          Override price?
        </div>
        <PrimaryCheckbox
          checked={!priceOverrideIsDisabled}
          onChange={() => setPriceOverrideIsDisabled(!priceOverrideIsDisabled)}
        />
      </div>
      <TextField
        label="Price per tire override"
        value={schedulerState.servicePriceOverride ?? 30}
        style={{ display: priceOverrideIsDisabled ? 'none' : 'block' }}
        type='number'
        onChange={(e) => {
          const stringValue = e.target.value

          setSchedulerState((prevState: ISchedulerStateProps) => ({
            ...prevState,
            servicePriceOverride: parseFloat(stringValue),
          }))
        }}
      />
    </>
  )
}

export default ServicePriceOverride