import { ChangeEvent, useState, useEffect } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { getTaxRuleList } from './api'
import useFetch from '../../hooks/useFetchHook'
import { ITaxRule } from '../../../../../app/entities/TaxRule'

interface TaxRuleDropdownProps {
  onTaxRuleChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
  className?: string
  label?: string,
}

export default function TaxRuleDropDown({
  onTaxRuleChange,
  disabled,
  defaultValue,
  fullWidth,
  className,
  label = 'Tax Rule'
}: TaxRuleDropdownProps) {
  const [value, setValue] = useState(defaultValue || '')
  const { data, isLoading } = useFetch<ITaxRule[]>(getTaxRuleList())

  useEffect(() => {
    if (defaultValue && data) {
      const defaultTaxRule = data.find((taxRule: ITaxRule) => taxRule.objectId === defaultValue)
      if (defaultTaxRule) {
        setValue(defaultTaxRule.objectId)
      }
    }
  }, [defaultValue, data])
  const onTaxRuleChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onTaxRuleChange && onTaxRuleChange(newValue)
  }

  const sortedTaxRules = (data || [])
    .filter((taxRule: ITaxRule) => taxRule.rule ?? false)
    .sort((a: ITaxRule, b: ITaxRule) => a.rule?.localeCompare(b.rule))

  const dataWithDefault = [{ rule: 'Choose one', objectId: '' } as ITaxRule].concat(sortedTaxRules)

  let menuItems

  if (isLoading) {
    menuItems = (
      <MenuItem
        value=""
        className={styles.dropDownOption}>
        Loading...
      </MenuItem>
    )
  } else {
    menuItems = dataWithDefault.filter(Boolean).map((taxRule: ITaxRule) => {
      return (
        <MenuItem
          key={taxRule.objectId}
          value={taxRule.objectId}
          className={styles.dropDownOption}>
          {taxRule.rule}
        </MenuItem>
      )
    })
  }

  return (
    <div>
      <TextField
        className={`${styles.dropDownSelect} ${className}`}
        label={label}
        select
        fullWidth={fullWidth}
        name="taxRuleObjectId"
        disabled={disabled}
        onChange={onTaxRuleChangeWrapper}
        value={data ? value : ''}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                // width: 250,
                fontFamily: '',
              },
            },
          },
        }}>
        {menuItems}
      </TextField>
    </div>
  )
}
