import React, { useState, ChangeEvent } from 'react'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { WorkDayDropDown } from '../custom-inputs/CustomInputs'
import { ITechFormProps } from '../container-modal/TechnicianEdit'
import { initialDaySettingValues } from '../utils'
import { fetchData } from '../../../../../global/utils/fetch'
import { createDaySetting, getLocationByLocationId } from '../../api'
import { ILocation } from '../../../../../../../app/entities/Location'

interface IAddUniqueDayProps {
  cancel: React.MouseEventHandler
  formInfo: ITechFormProps
  reloadModal?: Function
  techId?: string
}
interface INewDaySettingsProps {
  workDay: boolean
  reason: string
  startTime: string | null
  endTime: string | null
  dayDateFrom: string | null
  dayDateTo: string | null
  lunchFrom: string | null
  lunchTo: string | null
  day: number
  occursOnce: boolean
  occursYearly: boolean
  lunchDuration: number | null
}

const LUNCH_DURATION_FIELD_TITLE = 'lunchDuration'

const nonWorkingDayTextFieldStyles = {
  width: 130,
}
const workingDayTextFieldStyles = {
  width: 130,
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
}

export default function AddUniqueDay(props: IAddUniqueDayProps) {
  const [newDaySetting, setNewDaySetting] = useState<INewDaySettingsProps>(
    initialDaySettingValues,
  )
  const { reloadModal, techId, formInfo } = props
  const { technicianFormInfo } = formInfo
  const [isWorkingDay, setIsWorkingDay] = useState<boolean>(false)
  const {
    useDefaultLunch,
    useDefaultWorkDay,
    defaultWorkDayFrom,
    defaultWorkDayTo,
    defaultLunchFrom,
    defaultLunchTo,
    lunchDuration,
    workDayFrom,
    workDayTo,
    lunchFrom,
    lunchTo,
  } = technicianFormInfo

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    setNewDaySetting({ ...newDaySetting, [name]: value })
    if (name === 'dayDate') {
      setNewDaySetting({
        ...newDaySetting,
        dayDateFrom: value,
        dayDateTo: value,
        day: Date.parse(value),
      })
    } else if (name === 'dayDateTo') {
      setNewDaySetting({ ...newDaySetting, [name]: value })
    }
  }
  async function handleWorkDay(value: boolean) {
    if (value) {
      setIsWorkingDay(true)

      if (!technicianFormInfo.locationId) {
        throw new Error('Location ID is missing')
      }

      const location = useDefaultLunch
        ? await fetchData<ILocation>(
            getLocationByLocationId(technicianFormInfo.locationId),
          )
        : undefined

      const lunchDurationValue = useDefaultLunch
        ? location?.lunchDuration ?? null
        : lunchDuration
      const lunchFromValue = useDefaultLunch ? defaultLunchFrom : lunchFrom
      const lunchToValue = useDefaultLunch ? defaultLunchTo : lunchTo
      const startTimeFromValue = useDefaultWorkDay
        ? defaultWorkDayFrom
        : workDayFrom
      const startTimeToValue = useDefaultWorkDay ? defaultWorkDayTo : workDayTo
      setNewDaySetting({
        ...newDaySetting,
        workDay: value,
        startTime: startTimeFromValue,
        endTime: startTimeToValue,
        lunchFrom: lunchFromValue,
        lunchTo: lunchToValue,
        lunchDuration: lunchDurationValue,
      })
    } else {
      setIsWorkingDay(false)

      setNewDaySetting({
        ...newDaySetting,
        workDay: value,
      })
    }
  }
  function cleanDaySetting(daySetting: INewDaySettingsProps) {
    // Clean the day setting of some fields if it is a non-working day
    if (!daySetting.workDay) {
      daySetting.lunchDuration = null
      daySetting.lunchFrom = null
      daySetting.lunchTo = null
      daySetting.startTime = null
      daySetting.endTime = null
    }

    return daySetting
  }

  async function addSingleDaySetting() {
    try {
      await fetchData(createDaySetting(cleanDaySetting(newDaySetting), techId))
      if (reloadModal) {
        reloadModal()
      }
    } catch (err) {
      alert('error adding day settings: ' + err)
      console.error(err)
    }
  }
  function generateDateRangeArray(startDate: number, endDate: number) {
    const ONE_DAY_IN_MS = 86400000
    const totalNumOfDays = Math.round(
      (endDate - startDate + ONE_DAY_IN_MS) / ONE_DAY_IN_MS,
    )
    const dateRangeArray: any[] = []

    let nextDay = startDate
    for (let i = 0; i < totalNumOfDays; i++) {
      let newDaySettingCopy = { ...newDaySetting, day: nextDay }
      dateRangeArray.push(newDaySettingCopy)
      nextDay += ONE_DAY_IN_MS
    }
    return dateRangeArray
  }

  async function addRangeDaySettings(startDate: number, endDate: number) {
    const dateRangeArray = generateDateRangeArray(startDate, endDate)
    const createdDaySettingPromise = dateRangeArray.map((daySetting: any) =>
      fetchData(createDaySetting(cleanDaySetting(daySetting), techId)),
    )

    try {
      await Promise.all(createdDaySettingPromise)
      if (reloadModal) {
        reloadModal()
      }
    } catch (err) {
      alert(err)
    }
  }

  async function addDaySettings() {
    const { dayDateFrom, dayDateTo, reason } = newDaySetting
    if (!reason) {
      alert('Missing fields required')
      return
    }
    if (dayDateFrom && dayDateTo) {
      const dayDateFromParsed = Date.parse(dayDateFrom)
      const dayDateToParsed = Date.parse(dayDateTo)

      if (dayDateFromParsed > dayDateToParsed) {
        alert('The range Start day should be less than the End one')
      } else if (dayDateFromParsed === dayDateToParsed) {
        await addSingleDaySetting()
      } else {
        await addRangeDaySettings(dayDateFromParsed, dayDateToParsed)
      }
    }
  }

  return (
    <tr className={`${styles.bodyRow} ${styles.addNewRow}`}>
      <td className={`${styles.bodyRowCell}`}>
        <TextField
          onChange={handleChange}
          value={newDaySetting.dayDateFrom}
          InputLabelProps={{ shrink: true }}
          type='Date'
          label='From'
          name='dayDate'
          sx={{ width: 130 }}
        />
        <TextField
          onChange={handleChange}
          value={newDaySetting.dayDateTo}
          InputLabelProps={{ shrink: true }}
          type='Date'
          label='To'
          name='dayDateTo'
          sx={{ width: 130 }}
        />
      </td>
      <td className={styles.bodyRowCell}>
        <WorkDayDropDown
          value={newDaySetting.workDay}
          onChange={handleWorkDay}
        />
      </td>
      <td className={styles.bodyRowCell}>
        <TextField
          className={styles.noIcon}
          onChange={handleChange}
          value={newDaySetting.reason}
          label='Reason'
          name='reason'
          InputLabelProps={{ shrink: true }}
          sx={
            isWorkingDay
              ? workingDayTextFieldStyles
              : nonWorkingDayTextFieldStyles
          }
        />
      </td>
      <td className={styles.bodyRowCell}>
        <div>
          <TextField
            type='time'
            disabled={!isWorkingDay}
            onChange={handleChange}
            value={!isWorkingDay ? ' ' : newDaySetting.startTime} // The ' ' is a hack to make the time field not move the label down
            name='startTime'
            label='From'
            sx={
              isWorkingDay
                ? workingDayTextFieldStyles
                : nonWorkingDayTextFieldStyles
            }
          />
          <TextField
            type='time'
            disabled={!isWorkingDay}
            onChange={handleChange}
            value={!isWorkingDay ? ' ' : newDaySetting.endTime} // The ' ' is a hack to make the time field not move the label down
            name='endTime'
            label='To'
            sx={
              isWorkingDay
                ? workingDayTextFieldStyles
                : nonWorkingDayTextFieldStyles
            }
          />
        </div>
      </td>
      <td className={styles.bodyRowCell}>
        <TextField
          type='number'
          disabled={!isWorkingDay}
          onChange={handleChange}
          value={
            !isWorkingDay || Number.isNaN(newDaySetting.lunchDuration)
              ? ' '
              : newDaySetting.lunchDuration
          } // The ' ' is a hack to make the time field not move the label down
          label='Lunch Duration'
          sx={
            isWorkingDay
              ? workingDayTextFieldStyles
              : nonWorkingDayTextFieldStyles
          }
          name={LUNCH_DURATION_FIELD_TITLE}
        />
      </td>
      <td className={styles.bodyRowCell}>
        <div className={styles.buttonCell}>
          <a
            onClick={props.cancel}
            className={styles.buttonText}
          >
            Cancel
          </a>
          <a
            className={styles.buttonText}
            onClick={addDaySettings}
          >
            Add
          </a>
        </div>
      </td>
    </tr>
  )
}
