import WorkingDaySettingIcon from './WorkingDaySettingIcon'
import { determineWorkingStatus, displayTechWorkHours } from './helperFunctions'
import React, { useContext, useState } from 'react'
import styles from './styles.module.css'
import { ReactComponent as CopyFileIcon } from '../../../../../assets/images/copy-file-icon.svg'
import WODetailsSubModal from '../../../../../work-orders/components/work-order-detail/components/sub-modals/WODetailsSubModal'
import { IGetTechScheduleRouteArea } from '../../../../../schedule/components/technician-table/table-container/TechTableContainer'
import techAvatarIcon from '../../../../../assets/images/tech-avatar-icon.svg'
import { ToastNotificationContext } from '../../../../context/toast-context/ToastNotificationContext'
import removeDoubleCommas from '../../../../utils/removeDoubleCommas'
import { SERVICE_NAMES } from '../../../../constants/service'
import { SUB_MODAL_NAMES } from '../../../../../work-orders/components/work-order-detail/components/sub-modals/constants'
interface TechAvatarCardProps {
  techInfo: IGetTechScheduleRouteArea
  techAvatar: string
  date: string
  triggerRerender: () => void
  blank: boolean
}

export default function TechAvatarCard(props: TechAvatarCardProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const { date, techInfo, techAvatar, blank } = props
  const {
    technician: { name },
    location: { name: locationName },
    schedule,
  } = techInfo
  const dateToMs = new Date(date).getTime()
  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleCopyToClipboard = async () => {
    let data = [] as any
    const scheduleData = Object.entries(schedule)
    scheduleData.forEach((timeSlot: any) => {
      if (timeSlot[1]) {
        data.push(timeSlot[0])
        timeSlot[1].forEach((order: any) => {
          const {
            additionalTireRackOrderNumber,
            customer,
            installationPoint,
            orderServices,
            tireRackOrders,
            workOrderId,
          } = order

          const orderServiceQuantity =
            orderServices?.find(
              (orderService: any) =>
                orderService.label !== SERVICE_NAMES.TIRE_DISPOSAL,
            )?.quantity ?? 0
          const tireRackOrderNumberStr = tireRackOrders[0]?.orderNumber
            ? ` ${tireRackOrders[0]?.orderNumber}`
            : ''
          const additionalTireRackOrderNumberStr = additionalTireRackOrderNumber
            ? ` ${additionalTireRackOrderNumber}`
            : ''

          const topRow = `${workOrderId} [${orderServiceQuantity}]${tireRackOrderNumberStr}${additionalTireRackOrderNumberStr}`

          const routeDetails = `${topRow} \n ${order.startTime} - ${order.endTime} \n ${order.customer.fullName} \n ${removeDoubleCommas(
            installationPoint.fullAddress,
          )} \n ${customer.email} \n ${customer.trimmedPhoneNumber}`
          data.push(routeDetails)
        })
      }
    })
    const textToCopy = data.join('\n\n')
    try {
      await navigator.clipboard.writeText(textToCopy)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Route copied to clipboard!',
        severity: 'info',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Route could not be copied to clipboard.',
        severity: 'error',
      })
    }
  }
  let techName = techInfo.unassigned ? 'Unassigned' : name

  const styleLogic = [
    techInfo.unassigned ? styles.unassigned : styles.assigned,
    blank ? styles.blank : '',
  ].join(' ')

  const workingStatus = determineWorkingStatus(techInfo, dateToMs)

  return (
    <>
      <WODetailsSubModal
        isOpen={modalOpen}
        actionName={SUB_MODAL_NAMES.TECH_ASSIGNMENT}
        handleClosed={handleCloseModal}
        techInfo={techInfo}
        date={props.date}
        triggerRerender={props.triggerRerender}
      />
      <div className={styles.techInfoContainer}>
        <div
          className={`${styles.container} ${styleLogic}`}
          onClick={
            techInfo.unassigned || blank ? undefined : () => handleOpenModal()
          }
        >
          <img
            className={styles.techAvatar}
            src={
              techAvatar && !techAvatar?.endsWith('.png')
                ? techAvatar
                : techAvatarIcon
            }
            alt='tech avatar'
          />
          <div style={{ width: '100%' }}>
            <div className={styles.name}>
              <div style={{ maxWidth: 140 }}>{techName}</div>
              <div style={{ justifySelf: 'end' }}>
                {!techInfo.unassigned && (
                  <WorkingDaySettingIcon {...workingStatus} />
                )}
              </div>
            </div>
            <div>{locationName}</div>
          </div>
        </div>
        <div className={styles.containerTwo}>
          <div style={{ width: '100%' }}>
            {!techInfo.unassigned && (
              <div className={styles.time}>
                {displayTechWorkHours(techInfo)}
                {blank && (
                  <div className={styles.blankNotification}>No Orders</div>
                )}
              </div>
            )}
            {!blank && (
              <div
                className={styles.copyRoute}
                onClick={handleCopyToClipboard}
              >
                Copy Route{' '}
                <CopyFileIcon style={{ height: '13px', fill: '#D70000' }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
