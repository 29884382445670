import { useEffect, useState } from 'react'
import Table from '../../../../../../global/components/table/Table'
import { ISchedulerStateProps } from '../../container-modal/initialData'
import { Column, Row } from 'react-table'
import { OrderService } from '../../../../../../../../app/entities/OrderService'
import { TIRE_PRODUCT_SERVICE_NAMES } from '../../../../../../global/constants/service'

interface IGenericTiresTableProps {
  schedulerState: ISchedulerStateProps
  setSeeGenericTireModal: Function
  setGenericItemIdxToEdit: Function
}

function GenericTiresTable({
  schedulerState,
  setSeeGenericTireModal,
  setGenericItemIdxToEdit,
}: IGenericTiresTableProps) {
  const [tableState, setTableState] = useState({
    data: [] as Row<any>[],
    isLoading: false,
  })

  useEffect(() => {
    setTableState({
      data: (schedulerState.genericItems ??
        []) as unknown as Row<OrderService>[],
      isLoading: false,
    })
  }, [schedulerState.genericItems])

  if (!schedulerState.genericItems?.length) {
    return <></>
  }

  function onRowClick(row: Row<OrderService>) {
    const orderService = row.original
    const isGenericTire = TIRE_PRODUCT_SERVICE_NAMES.includes(
      orderService.label,
    )
    if (isGenericTire) {
      const genericTireIndex = row.index
      setGenericItemIdxToEdit(genericTireIndex)
      setSeeGenericTireModal(true)
    }
    //todo add in add wipers modal
  }

  const columns: Column<OrderService>[] = [
    {
      Header: 'Product',
      accessor: (row) => [row.category, row.description], // Combine category and description
      Cell: ({ value }: { value: string[] }) => {
        return (
          <>
            {value[1]} {value[0].charAt(0).toUpperCase() + value[0].slice(1)}
          </>
        )
      },
    },
    {
      Header: 'Qty.',
      accessor: 'quantity',
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ value }) => {
        return <>${value}</>
      },
    },
    {
      Header: 'Cost',
      accessor: 'cost',
      Cell: ({ value }) => {
        if (!value && value !== 0) {
          return <>N/A</>
        }

        return <>${value}</>
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={tableState.data}
        isLoading={tableState.isLoading}
        onRowClick={onRowClick}
        isOpen={true}
        isDisplayMode={true}
        disableSortBy={true}
      />
    </div>
  )
}

export default GenericTiresTable
