import { useState, useEffect, useContext } from 'react'
import { Column } from 'react-table'
import Table from '../../../../global/components/table/Table'
import TableTopper from '../../../../global/components/table/helpers/table-topper/TableTopper'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import Box from '@mui/material/Box'
import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import { fetchData } from '../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import TableTitle from '../../../../global/components/table/helpers/table-title/TableTitle'
import { quickReportsWithParams } from '../../api'
import WorkOrderDetailModal from '../../../../work-orders/components/work-order-detail/components/main/WorkOrderDetailModal'
import { useNavigate, useParams } from 'react-router-dom'
import { WoDetailContext } from '../../../../work-orders/components/context/context'
import moment from 'moment'

interface IWorkOrderWorkListModalProps {
  isOpen: boolean
  handleClosed: Function
}
interface IWorkOrderWorkListParamsProps {
  csv: boolean
  fromDate?: number
  toDate?: number
  location?: string
}
interface IFormDataProps {
  fromDate: number
  toDate: number
  location: string
}

export default function WorkOrderWorkListModal(
  props: IWorkOrderWorkListModalProps,
) {
  const { id } = useParams()
  const navigate = useNavigate()
  const { queryOrderData, clearData } = useContext(WoDetailContext)
  const [workOrderDetailsModalOpen, setWorkOrderDetailsModalOpen] =
    useState(false)
  const [formData, setFormData] = useState<IFormDataProps>({
    fromDate: 0,
    toDate: 0,
    location: '',
  })
  const [isToDateDisabled, setIsToDateDisabled] = useState(true)
  const [minToDate, setMinToDate] = useState(moment.utc(0).format('YYYY-MM-DD'))
  const [tableState, setTableState] = useState([])
  const handleChange = (e: any) => {
    setFormData((prevState: IFormDataProps) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  const fetchWorkOrderWorkList = async () => {
    const params: IWorkOrderWorkListParamsProps = { csv: false }
    if (formData.fromDate)
      params['fromDate'] = moment.utc(formData.fromDate).toDate().getTime()
    if (formData.toDate)
      params['toDate'] = moment.utc(formData.toDate).toDate().getTime()
    if (formData.location) params['location'] = formData.location
    try {
      const res = await fetchData<any>(
        quickReportsWithParams('rpt_workOrderWorkList', params),
      )
      setTableState(res)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    fetchWorkOrderWorkList()
  }, [formData])

  useEffect(() => {
    if (id) {
      handleOpen(id)
    }
  }, [id])

  const handleOpen = async (id: string) => {
    const url = `/reports/work-order/${id}`
    navigate(url)
    setWorkOrderDetailsModalOpen(true)
    await queryOrderData(id)
  }
  const handleClosed = () => {
    setWorkOrderDetailsModalOpen(false)
    navigate('/reports')
    fetchWorkOrderWorkList()
    clearData()
  }
  const onRecordClickHandler = (row: any) => {
    const {
      original: { OrderObjectId },
    } = row
    handleOpen(OrderObjectId)
  }
  const columns: Column<any>[] = [
    {
      Header: 'Location',
      accessor: 'Location',
    },
    {
      Header: 'Date',
      accessor: (data: any) => {
        const date = data.Date.split('T')[0]
        return date
      },
    },
    {
      Header: 'Work Order Id',
      accessor: 'Work Order Id',
    },
    {
      Header: 'TR Order',
      accessor: 'TR Order',
    },
    {
      Header: 'Front PSI',
      accessor: 'Front PSI',
    },
    {
      Header: 'Rear PSI',
      accessor: 'Rear PSI',
    },
    {
      Header: 'Torque',
      accessor: 'torque',
    },
    {
      Header: 'Order Vehicle',
      accessor: 'lineItemVehicleId',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
  ]

  // toDate input validation
  useEffect(() => {
    if (formData.fromDate !== 0) {
      // once a fromDate has been chosen, we can display the toDate calendar correctly
      setIsToDateDisabled(false)

      setMinToDate(moment.utc(formData.fromDate).format('YYYY-MM-DD'))

      // If the fromDate becomes greater than the toDate, we need to reset the toDate
      if (formData.fromDate > formData.toDate) {
        // set the toDate state
        setFormData((prevData) => ({
          ...prevData,
          toDate: 0,
        }))

        // reset the toDate
        const toDateInput = document.getElementsByName(
          'toDate',
        )[0] as HTMLInputElement
        if (toDateInput) {
          toDateInput.value = ''
        }
      }
    }
  }, [formData.fromDate])

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => props.handleClosed()}
      >
        <Box className={styles.workOrderWorkListModal}>
          <WorkOrderDetailModal
            handleClosed={handleClosed}
            isOpen={workOrderDetailsModalOpen}
          />
          <div style={{ width: '100%', overflowY: 'scroll' }}>
            <TableTopper>
              <div className={styles.flexTitle}>
                <TableTitle>Work Order Work List</TableTitle>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <TextField
                    type='date'
                    label='From Date'
                    name='fromDate'
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '162px' }}
                  />
                  <TextField
                    type='date'
                    label='To Date'
                    name='toDate'
                    disabled={isToDateDisabled}
                    onChange={handleChange}
                    inputProps={{ min: minToDate }}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '162px' }}
                  />
                  <LocationDropDown
                    showInactive
                    onLocationChange={(val: string) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        location: val,
                      }))
                    }}
                  />
                </div>
              </div>
            </TableTopper>
            <Table
              columns={columns}
              data={tableState ?? []}
              onRowClick={onRecordClickHandler}
              isDisplayMode
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
