import { ILocation } from '../../../../../../../../../bi/entities/Location'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateLocationColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Location Moniker',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ locationMoniker: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { locationMoniker } = data
        return generateTextField(
          'locationMoniker',
          locationMoniker,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Start Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ startDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { startDate } = data
        return generateTextField(
          'startDate',
          startDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'End Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ endDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { endDate } = data
        return generateTextField(
          'endDate',
          endDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR CID 1',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tireRackCid1: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { tireRackCid1 } = data
        return generateTextField(
          'tireRackCid1',
          tireRackCid1?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR CID 2',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tireRackCid2: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { tireRackCid2 } = data
        return generateTextField(
          'tireRackCid2',
          tireRackCid2?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR Cost Center ID',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ tireRackCostCenterId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { tireRackCostCenterId } = data
        return generateTextField(
          'tireRackCostCenterId',
          tireRackCostCenterId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR Parent Cost Center ID',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ tireRackParentCostCenterId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { tireRackParentCostCenterId } = data
        return generateTextField(
          'tireRackParentCostCenterId',
          tireRackParentCostCenterId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Type',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ type: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { type } = data
        return generateTextField(
          'type',
          type,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Brand',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ brand: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { brand } = data
        return generateTextField(
          'brand',
          brand,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Address',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ address: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { address } = data
        return generateTextField(
          'address',
          address,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'City',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ city: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { city } = data
        return generateTextField(
          'city',
          city,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'State',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ state: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { state } = data
        return generateTextField(
          'state',
          state,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Zip Code',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zipCode: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { zipCode } = data
        return generateTextField(
          'zipCode',
          zipCode?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Phone Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ phoneNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ILocation, rowIndex: number) => {
        const { phoneNumber } = data
        return generateTextField(
          'phoneNumber',
          phoneNumber,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateLocationColumns
