import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import TableTopper from '../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../global/components/table/helpers/table-title/TableTitle'
import MakePriceChange from '../../main/make-price-change/MakePriceChange'
import useFetch from '../../../global/hooks/useFetchHook'
import { getLocationRulesForLocationByRule, getLocationServicesForLocationByService } from './api'
import { consolidateLocationServicesOrRules } from '../utils/consolidate-location-services-or-rules'
import { ILocationServiceOrRule } from '../../../../../app/types/location-service-or-rule'
import isEntityALocationService from '../utils/is-entity-a-location-service'
import { ILocationRule } from '../../../../../app/entities/LocationRule'

interface ILocationServiceRecordsProps {
  isOpen: boolean
  handleClosed: Function
  chosenLocationServiceOrRule: ILocationServiceOrRule
}
export default function LocationServiceOrRulesRecordsModal({
  isOpen,
  handleClosed,
  chosenLocationServiceOrRule,
}: ILocationServiceRecordsProps) {

  const columns: Column<ILocationServiceOrRule>[] = [
    {
      Header: 'Price',
      accessor: (sr: ILocationServiceOrRule) => '$' + (isEntityALocationService(sr) ? sr.price : (sr as ILocationRule).value),
    },
    {
      Header: 'Effective Date',
      accessor: ({ created }) => [new Date(created).toLocaleDateString(), new Date(created).toLocaleTimeString()].join(' '),
    },
  ]

  const isAService = isEntityALocationService(chosenLocationServiceOrRule)

  let dataCall

  if (isAService) {
    dataCall = getLocationServicesForLocationByService(
      chosenLocationServiceOrRule?.location?.objectId || '',
      chosenLocationServiceOrRule?.service?.objectId || ''
    )
  } else {
    const locationRule = chosenLocationServiceOrRule as ILocationRule
    dataCall = getLocationRulesForLocationByRule(
      locationRule?.location?.objectId || '',
      locationRule?.name || ''
    )
  }

  const {
    data: locationServicesOrRules,
    isLoading,
    refetch: refetchLocationServicesHistory,
  } = useFetch<ILocationServiceOrRule[]>(dataCall)

  const serviceName = isAService 
    ? chosenLocationServiceOrRule?.service?.name 
      + (chosenLocationServiceOrRule.service?.tireRackName ? `(${chosenLocationServiceOrRule.service.tireRackName})` : '') 
    : (chosenLocationServiceOrRule as ILocationRule).name

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed()}>
        <Box className={styles.recordsModal}>
          <div style={{ width: '100%', overflowY: 'auto' }}>
            <TableTopper>
              {chosenLocationServiceOrRule ? (
                <div style={{ display: 'grid' }}>
                  <TableTitle> Location Service History for: </TableTitle>
                  <div>Service: {serviceName}</div>
                  <div>Location: {chosenLocationServiceOrRule?.location?.name}</div>
                </div>
              ) : (
                <></>
              )}
              <MakePriceChange
                chosenLocationServiceOrRule={chosenLocationServiceOrRule}
                refetchLocationServicesHistory={refetchLocationServicesHistory}
              />
            </TableTopper>
            <Table
              columns={columns}
              data={locationServicesOrRules ? consolidateLocationServicesOrRules(locationServicesOrRules) : []}
              isLoading={isLoading}
              isDisplayMode
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
