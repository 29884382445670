export const routes = [
  { title: 'Customer Information', path: 'customer-info' },
  { title: 'Item Details', path: 'size-quantity' },
  { title: 'Select an Appointment Slot', path: 'appointment-slots' },
  { title: 'Payment Information', path: 'payment-information' },
  { title: 'Confirm Appointment Information', path: 'confirm-appointment' },
  { title: 'Customer Information', path: 'customer-info' },
  { title: 'Item Details', path: 'size-quantity' },
  { title: 'Select an Appointment Slot', path: 'appointment-slots' },
  { title: 'Payment Information', path: 'payment-information' },
  { title: 'Confirm Appointment Information', path: 'confirm-appointment' },
]
