export const SERVICE_NAMES = {
  GENERAL_TASK: 'General Task',
  TIRE_DISPOSAL: 'Disposal Fee',
  TIRE_INSTALL: 'Tire Install',
  TIRE_PURCHASE: 'Tire Purchase',

  TIRE_PREMOUNTED: 'Pre-Mounted Tire',
  TIRE_DROP_OFF: 'Tire Drop Off',
  TPMS_SERVICE: 'TPMS Service',
  TIRE_ROTATION: 'Tire Rotation',
  REWORK: 'Rework',

  GENERIC_TIRE: 'Generic Tire',
  GENERIC_ITEM: 'Generic Item',
  STATE_TIRE_FEE: 'State Tire Fee',

  WIPER_BLADE: 'Wiper Blade',

  MINIMUM_SERVICE_CHARGE: 'Minimum Service Charge',
}

export const NON_TIRE_PRODUCT_SERVICE_NAMES = [SERVICE_NAMES.WIPER_BLADE]
export const TIRE_PRODUCT_SERVICE_NAMES = [
  SERVICE_NAMES.GENERIC_ITEM,
  SERVICE_NAMES.GENERIC_TIRE,
]
export const PRODUCT_SERVICE_NAMES = [
  ...NON_TIRE_PRODUCT_SERVICE_NAMES,
  ...TIRE_PRODUCT_SERVICE_NAMES,
]

export enum CATEGORIES {
  LABOR = 'labor',
  DISPOSAL = 'disposal',
  ACCESSORIES = 'accessories',
  TIRES = 'tires',
  FEE = 'fee',
  WIPERS = 'wipers', //requested category from Miles / accounting
}

export const TIRE_INSTALL_TIRE_RACK_NAMES = {
  WHEELS_ONLY: 'WheelsOnly',
  TIRE_MOUNTING: 'TireMounting',
}
