export const setVehicleTiresCombination = (tireData: any, orderId: string, orderVehicleId: string) => {
	const body = {
		objectId: tireData.objectId,
		frontTire: tireData.tires.frontTire,
		rearTire: tireData.tires.rearTire,
		frontCount: tireData.frontCount,
		rearCount: tireData.rearCount,
	}
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/${orderVehicleId}/tires`,
		body,
	}
}
export const updateVehicleMileage = (orderVehicleId: string, mileage: number) => {
	const body = {
		orderVehicleId,
		mileage,
	}
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/tech_app/addMileage`,
		body: body,
	}
}
export const deleteOrderVehicle = (orderVehicleObjId: any) => {
	return {
		method: "DELETE",
		url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/${orderVehicleObjId}`
	}
}
