export const getLocationById = (locationId: string) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}`,
	}
}
export const updateLocation = (locationData: any) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/locations`,
		body: locationData,
	}
}
export const createLocation = (locationData: any) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/locations`,
		body: locationData,
	}
}
export const createZoneDefinition = (zoneDef: any, locationId: string | undefined) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/zone-definitions`,
		body: zoneDef,
	}
}
export const updateZoneDefinition = (zoneDef: any, locationId: string | undefined) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/zone-definitions`,
		body: zoneDef,
	}
}
export const removeZoneDefinition = (locationId: string | undefined, zoneDefinitionId: string) => {
	return {
		method: 'DELETE',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/zone-definitions/${zoneDefinitionId}`,
	}
}
export const setTabulationArea = (locationId: string | undefined, tabArea: any) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/tabulation-area`,
		body: tabArea,
	}
}
export const createTabulationArea = (locationId: string | undefined, tabArea: any) => {
	if (Date.now() > 0 ) throw new Error('Not a real endpoint, use the setTabulationArea endpoint instead')

	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/tabulation-area`,
		body: tabArea,
	}
}

export const uploadZipCSV = (locationId: string | undefined, csvFile: FormData) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/bulk-tabulation-areas`,
		body: csvFile,
	}
}
export const downloadZipCSV = (locationId: string | undefined) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/bulk-tabulation-areas`,
	}
}
