import { useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { ILocationFilters } from '../main/QueuesMain'
import {
  IOrderService,
  OrderService,
} from '../../../../../app/entities/OrderService'
import GenericTiresModal from '../../../schedule/components/schedule-modal/components/choose-service/generic-tires/GenericTiresModal'
import { ISchedulerStateProps } from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { updateOrderService } from '../api'
import { fetchData } from '../../../global/utils/fetch'
interface IWorkOrdersTableProps {
  locationFilters: ILocationFilters
}
function TireRackDataTable(props: IWorkOrdersTableProps) {
  const { locationFilter } = props.locationFilters
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const [schedulerState, setSchedulerState] = useState<
    Partial<ISchedulerStateProps>
  >({
    genericItems: [],
  })

  function onRecordClickHandler(row: any) {
    setSchedulerState({
      genericItems: [row.original],
    })

    setDetailsModalOpen(true)
  }

  const onColumnFilterUpdate = (filtersUpdate: object) => {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  async function handleOnSave(genericTire: OrderService) {
    try {
      await fetchData(updateOrderService(genericTire))
      retrieveTableData()
    } catch (err) {
      console.error(err)
    }
  }

  const endpoint = `${process.env.REACT_APP_ORIGIN}/orders/missing-order-service-tire-rack-data?`
  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortOrder: 'DESC',
      selectedLocationId: locationFilter,
      columnFilters,
    })
  }

  useEffect(retrieveTableData, [
    locationFilter,
    tableState.pageSize,
    tableState.offSet,
  ])

  const columns: Column<IOrderService | any>[] = [
    {
      Header: 'Order ID',
      accessor: 'order.workOrderId',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ order: { workOrderId: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'TR Order Number',
      accessor: 'status',
      Cell: ({ row: { original: orderServiceData } }) => {
        const orderService = orderServiceData as OrderService

        return <>{orderService?.tireRackOrder?.orderNumber ?? 'N/A'}</>
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ tireRackOrder: { orderNumber: val } }),
        onEnter: retrieveTableData,
      }),
    },
  ]

  // we only need to update the "shipped on" and "cost" values
  // from this view so we can disable all other inputs
  const inputsToDisable = [
    'description',
    'quantity',
    'price',
    'tireRackOrderNumber',
  ]

  // this can be "hard-coded" since we will only be editing
  // the first item in the genericItems array
  const genericItemIdxToEdit = 0
  const genericItem = schedulerState.genericItems?.[0]

  const customerName = genericItem
    ? genericItem?.order?.customer?.firstName +
      ' ' +
      genericItem?.order?.customer?.lastName
    : ''

  return (
    <div style={{ width: 'fit-content' }}>
      <GenericTiresModal
        seeGenericTireModal={detailsModalOpen}
        setSeeGenericTireModal={setDetailsModalOpen}
        schedulerState={schedulerState}
        setSchedulerState={setSchedulerState}
        genericItemIdxToEdit={genericItemIdxToEdit}
        onSave={handleOnSave}
        inputsToDisable={inputsToDisable}
        hideRemoveButton={true}
        customerName={customerName}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
        disableSortBy
      />
    </div>
  )
}

export default TireRackDataTable
