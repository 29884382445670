const DEBUG_PHONE_NUMBER = '+12702664516'

const INFO_EMAIL_ADDRESS = 'info@asaptire.com'

const NOTIFICATION_TYPES = {
  SMS  : 'SMS',
  EMAIL: 'EMAIL',
}

const SUPPORT_PHONE_NUMBER = '1-833-702-8764'

const TWILIO_SUCCESS_STATUS = 'queued'

const QUIQ_PHONE_NUMBER = '1-574-281-9803'

export {
  DEBUG_PHONE_NUMBER,
  INFO_EMAIL_ADDRESS,
  NOTIFICATION_TYPES,
  SUPPORT_PHONE_NUMBER,
  TWILIO_SUCCESS_STATUS,
  QUIQ_PHONE_NUMBER
}