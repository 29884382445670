import { useEffect, useState } from 'react'
import { TireRackOrder } from '../../../../../app/entities/TireRackOrder'
import createTrackingLinks from '../../utils/tire-rack-order/create-tracking-links'
import TrackingLinksModal from '../cards/technician/technician-route-card/tracking-links/TrackingLinksModal'

interface TrackItProps {
  orderObjectId: string
  tireRackOrder: TireRackOrder
  newTabTrackItId?: string
  multiLinkTrackItId?: string
}

const TRACK_IT_STR = 'track it'

export default function TrackIt(props: TrackItProps) {
  const { multiLinkTrackItId, newTabTrackItId, tireRackOrder } = props

  const trackingInfo = createTrackingLinks(tireRackOrder)
  const { trackingLinks } = trackingInfo

  const newTabTrackingLinkElementId = newTabTrackItId ?? 'newTabTrackingLink'
  const multiLinkTrackingLinkElementId =
    multiLinkTrackItId ?? 'multiLinkTrackingLink'

  const [modalOpen, setModalOpen] = useState(false)
  function handleClosed() {
    setModalOpen(false)
  }

  function generateTrackItLink(): JSX.Element {
    if (!trackingLinks || trackingLinks.length <= 0) {
      return <div />
    } else if (trackingLinks.length === 1) {
      return (
        <a
          href={trackingLinks[0]}
          target='_blank'
          rel='noreferrer'
          id={newTabTrackingLinkElementId}
        >
          {TRACK_IT_STR}
        </a>
      )
    } else {
      // if there are multiple tracking links, we know that we need to
      // open a modal to display them
      return (
        <a
          href='#'
          id={multiLinkTrackingLinkElementId}
        >
          {TRACK_IT_STR}
        </a>
      )
    }
  }

  // prevent work orders detail modal from opening when clicking on tracking links
  useEffect(() => {
    const ontracTrackingLink = document.getElementById(
      multiLinkTrackingLinkElementId,
    )
    const newTabTrackingLink = document.getElementById(
      newTabTrackingLinkElementId,
    )
    if (ontracTrackingLink) {
      ontracTrackingLink.addEventListener('click', function (e) {
        setModalOpen(true)

        e.stopPropagation()
      })
    }
    if (newTabTrackingLink) {
      newTabTrackingLink.addEventListener('click', function (e) {
        e.stopPropagation()
      })
    }
  }, [trackingLinks])

  return (
    <div>
      <TrackingLinksModal
        trackingInfo={trackingInfo}
        isOpen={modalOpen}
        handleClosed={handleClosed}
      />
      {generateTrackItLink()}
    </div>
  )
}
