import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import retrieveData from '../../../global/components/table/helpers/retrieveData'

interface ILocationTableProps {
  setOpen: Function
}

function LocationsTable(props: ILocationTableProps) {
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })
  const [typedFilter, setTypedFilter] = useState('')
  const endpoint = `${process.env.REACT_APP_ORIGIN}/locations/locationsData?`

  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
    })
  }, [typedFilter, tableState.pageSize, tableState.offSet])

  const columns: Column<any>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`name like '%${val}%'`),
      }),
    },
    {
      Header: 'Active',
      accessor: 'active',
      Filter: BasicFilter({
        onEnter: (val: string) =>
          setTypedFilter(`customerFirstName like '%${val}%'`),
      }),
      Cell: ({ value }: { value: boolean }) =>
        value ? <span>true</span> : <span>false</span>,
    },
    {
      Header: 'State Abbr',
      accessor: 'stateAbbr',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`stateAbbr like '%${val}%'`),
      }),
    },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`zipCode like '%${val}%'`),
      }),
    },
    {
      Header: 'Address',
      accessor: 'address',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`address like '%${val}%'`),
      }),
    },
    {
      Header: 'Timezone Offset',
      accessor: 'timezoneOffset',
      Filter: BasicFilter({
        onEnter: (val: string) =>
          setTypedFilter(`timezoneOffset like '%${val}%'`),
      }),
    },
    {
      Header: 'Work Day from',
      accessor: 'workDayFrom',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`workDayFrom like '%${val}%'`),
      }),
    },
    {
      Header: 'Work Day to',
      accessor: 'workDayTo',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`workDayTo like '%${val}%'`),
      }),
    },
    {
      Header: 'Lunch from',
      accessor: 'lunchFrom',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`lunchFrom like '%${val}%'`),
      }),
    },
    {
      Header: 'Lunch to',
      accessor: 'lunchTo',
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`lunchTo like '%${val}%'`),
      }),
    },
    {
      Header: 'Tire Install Time',
      accessor: 'tireInstallTime',
      Filter: BasicFilter({
        onEnter: (val: string) =>
          setTypedFilter(`tireInstallTime like '%${val}%'`),
      }),
    },
    {
      Header: 'Production Mode',
      accessor: (data: any) => {
        const { productionMode } = data
        return productionMode ? 'True' : 'False'
      },
      Filter: BasicFilter({
        onEnter: (val: string) =>
          setTypedFilter(`productionMode like '%${val}%'`),
      }),
    },
    {
      Header: 'Using Tolls',
      accessor: (data: any) => {
        const { usingTolls } = data
        return usingTolls ? 'True' : 'False'
      },
      Filter: BasicFilter({
        onEnter: (val: string) => setTypedFilter(`usingTolls like '%${val}%'`),
      }),
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isDisplayMode
        isLoading={tableState.isLoading}
        onRowClick={(row: Row) => {
          const original: any = row.original
          props.setOpen({ isOpen: true, locationId: original.objectId })
        }}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default LocationsTable
