import { useAuthenticator } from '@aws-amplify/ui-react'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IOrderVehicle } from '../../../../../../../../../app/entities/OrderVehicle'
import DeleteButton from '../../../../../../../global/components/buttons/delete-button/DeleteButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getOrderVehiclesById } from '../../../../../api'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import { deleteOrderVehicle } from './api'

interface ITire {
  objectId: string
  model: string | null
  width: string
  manufacturer: string | null
  torque: number | null
  diameter: number | null
  aspectRatio: number | null
  psi: number | null
}

interface IVehicleData {
  objectId: string
  orderVehicleObjectId: string
  rearCount: number | null
  frontCount: number | null
  mileage: number | null
  orderVehicleId: string
  tires: {
    rearTire: ITire
    frontTire: ITire
  }
}

interface IEditVehicleModalProps {
  handleClose: Function
  triggerRerender: (() => void) | undefined
  isDeleteOrderVehicleButtonVisible?: boolean
  orderVehicleObjId?: string
}

export default function DeleteVehicleModal(props: IEditVehicleModalProps) {
  const { orderVehicleObjId } = props
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData, data: orderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [vehicleData, setVehicleData] = useState<IVehicleData>({
    objectId: '',
    rearCount: null,
    frontCount: null,
    mileage: null,
    orderVehicleId: '',
    orderVehicleObjectId: '',
    tires: {
      rearTire: {
        objectId: '',
        model: '',
        width: '',
        manufacturer: '',
        torque: null,
        diameter: null,
        aspectRatio: null,
        psi: null,
      },
      frontTire: {
        objectId: '',
        model: '',
        width: '',
        manufacturer: '',
        torque: null,
        diameter: null,
        aspectRatio: null,
        psi: null,
      },
    },
  })
  const { id } = useParams()
  const { data, isLoading } = useFetch<IOrderVehicle[]>(
    getOrderVehiclesById(id),
  )
  useEffect(() => {
    if (data && data.length > 0) {
      const { tiresCombination, mileage, objectId, orderVehicleId } = data[0]
      if (tiresCombination) {
        const { rearTire, frontTire, frontCount, rearCount } = tiresCombination

        setVehicleData({
          objectId: tiresCombination.objectId,
          rearCount,
          frontCount,
          mileage,
          orderVehicleId: orderVehicleId,
          orderVehicleObjectId: objectId,
          tires: {
            rearTire: {
              objectId: rearTire?.objectId || '',
              model: rearTire?.model || '',
              width: rearTire?.width || '',
              manufacturer: rearTire?.manufacturer || '',
              torque: rearTire?.torque || null,
              diameter: rearTire?.diameter || null,
              aspectRatio: rearTire?.aspectRatio || null,
              psi: rearTire?.psi || null,
            },
            frontTire: {
              objectId: frontTire?.objectId || '',
              model: frontTire?.model || '',
              width: frontTire?.width || '',
              manufacturer: frontTire?.manufacturer || '',
              torque: frontTire?.torque || null,
              diameter: frontTire?.diameter || null,
              aspectRatio: frontTire?.aspectRatio || null,
              psi: frontTire?.psi || null,
            },
          },
        })
      }
    }
  }, [data])

  const handleDeleteVehicle = async () => {
    try {
      setDisableDeleteButton(true)
      await fetchData<IOrderVehicle>(deleteOrderVehicle(orderVehicleObjId))
      if (id) {
        queryOrderData(id)
      }
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Vehicle successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There has been an error deleting Vehicle ${vehicleData.orderVehicleId}.`,
        severity: 'error',
      })
    } finally {
      setDisableDeleteButton(false)
    }
  }

  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={styles.inputsContainer}>
      <div style={{ display: 'flex', gap: 24 }}>
        <div className={styles.topInputsContainerLineItem}>
          <div className={styles.notesTitle}></div>
          <div className={styles.checkBoxContainer}>
            <Checkbox onChange={() => setIsChecked(!isChecked)} />
            <div>Yes I want to delete this vehicle from the Work Order</div>
          </div>
        </div>
      </div>
      <DeleteButton
        buttonName='Delete'
        onClick={handleDeleteVehicle}
        disabled={!isChecked || disableDeleteButton}
      />
    </div>
  )
}
