import { BI_DATA_WAREHOUSE_TABLE_NAMES } from '../../../../../../global/constants/bi-reports'
import CreateAccountingTransactionAdjustmentRow from './CreateAccountingTransactionAdjustmentRow'
import CreateDtcStoreRow from './CreateDtcStoreRow'
import CreateHubSpokeStoreMapping from './CreateHubSpokeStoreMapping'
import CreateLocationRow from './CreateLocation'

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  DTC_STORE,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

function createRow(
  entityName: string,
  handleSaveNewRow: Function,
  handleCancel: Function,
): JSX.Element {
  if (entityName === ACCOUNTING_TRANSACTION_ADJUSTMENT) {
    return (
      <CreateAccountingTransactionAdjustmentRow
        handleSaveNewRow={handleSaveNewRow}
        handleCancel={handleCancel}
      />
    )
  }
  if (entityName === DTC_STORE) {
    return (
      <CreateDtcStoreRow
        handleSaveNewRow={handleSaveNewRow}
        handleCancel={handleCancel}
      />
    )
  }
  if (entityName === HUB_SPOKE_STORE_MAPPING) {
    return (
      <CreateHubSpokeStoreMapping
        handleSaveNewRow={handleSaveNewRow}
        handleCancel={handleCancel}
      />
    )
  }
  if (entityName === LOCATION) {
    return (
      <CreateLocationRow
        handleSaveNewRow={handleSaveNewRow}
        handleCancel={handleCancel}
      />
    )
  }

  throw new Error(`Cannot create row for ${entityName}`)
}

export default createRow
