import { IOrder } from '../../../../../app/entities/Order'
import styles from '../styles.module.css'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import { MouseEventHandler, useEffect, useState } from 'react'
import { UNIVERSAL_TR_ORDER_NUMBERS_ARRAY } from '../../../global/constants/tire-rack-order'

interface TireRackOrderEntryProps {
  handleOpenEditTireRackOrder: MouseEventHandler
  order: IOrder | null
}

const TRO_NUMBERS_TO_NOT_DISPLAY = [...UNIVERSAL_TR_ORDER_NUMBERS_ARRAY]

export default function TireRackOrderEntry(props: TireRackOrderEntryProps) {
  const { handleOpenEditTireRackOrder, order } = props
  const tireRackOrder = order?.tireRackOrders[0] ?? undefined

  const [tireRackOrderNumber, setTireRackOrderNumber] = useState(tireRackOrder?.orderNumber ?? '')
  const [additionalTireRackOrderNumber, setAdditionalTireRackOrderNumber] = useState(order?.additionalTireRackOrderNumber ?? '')

  useEffect(() => {
    const tmpTireRackOrderNumber = TRO_NUMBERS_TO_NOT_DISPLAY.includes(tireRackOrderNumber) || tireRackOrderNumber === ''
      ? ''
      : tireRackOrderNumber

    let tmpAdditionalTireRackOrderNumber = additionalTireRackOrderNumber
    TRO_NUMBERS_TO_NOT_DISPLAY.forEach((troNumber) => {
      tmpAdditionalTireRackOrderNumber = tmpAdditionalTireRackOrderNumber.replace(troNumber, '')
    })
    tmpAdditionalTireRackOrderNumber = tmpAdditionalTireRackOrderNumber.trim()

    const shouldDisplayNoTireRackOrder = tmpTireRackOrderNumber === '' && tmpAdditionalTireRackOrderNumber === ''

    setTireRackOrderNumber(shouldDisplayNoTireRackOrder ? 'No Tire Rack Order' : tmpTireRackOrderNumber)
    setAdditionalTireRackOrderNumber(tmpAdditionalTireRackOrderNumber)
  }, [order, tireRackOrder])

  return (
    <div className={styles.tireRackOrderContainer}>
      <div className={styles.tireRackOrderLeftContainer}>
        <div className={styles.tireRackOrderHeader}>
          Tire Rack Order
        </div>
        <div>
          {tireRackOrderNumber} {additionalTireRackOrderNumber}
        </div>
      </div>
      <div className={styles.tireRackOrderRightContainer}>
        <PrimaryButton
          buttonName='Edit'
          onClick={handleOpenEditTireRackOrder}
          style={{ minWidth: '10px', width: '70px' }}
        />
      </div>
    </div>
  )
}
