import React, { useContext } from 'react'
import styles from '../styles/styles.module.css'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getInstallationImages } from './api'
import { WoDetailContext } from '../../../../../context/context'
import { sortImagesByInstallationImageType } from './utils'
import { CircularProgress } from '@mui/material'
interface IProps {
  handleClose: Function
}
export interface InstallationImage {
  url: string
  type: string
}
export default function ViewInstallationImages(props: IProps) {
  const { data: orderData } = useContext(WoDetailContext)
  const { data: installImages } = useFetch<InstallationImage[]>(
    getInstallationImages(orderData.orderVehicles[0].objectId),
  )

  const renderImages = () => {
    if (!installImages) return <CircularProgress />

    const sortedImages = sortImagesByInstallationImageType(installImages)

    return sortedImages.map((image) => (
      <div
        key={image.url}
        className={styles.imageContainer}>
        <p className={styles.imageLabel}>{image.type}</p>
        <img
          src={image.url}
          alt={image.type}
          className={styles.installImage}
        />
      </div>
    ))
  }

  return (
    <div className={styles.installImagesMainContainer}>
      <div className={styles.imagesFlexContainer}>{renderImages()}</div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName="Close"
          onClick={() => props.handleClose()}
        />
      </div>
    </div>
  )
}
