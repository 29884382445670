import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { ILocationServiceOrRule } from '../../../../../../app/types/location-service-or-rule'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PriceChangeForm from './PriceChangeForm'
import ConfirmPriceChangeModal from './confirmation-modal/ConfirmPriceChangeModal'
import styles from './styles.module.css'

interface IMakePriceChangeModalProps {
  isOpen: boolean
  handleClosed: Function
  chosenLocationServiceOrRule: ILocationServiceOrRule
}
interface IFormData {
  price: number | null
  created: number | null
}
export default function MakePriceChangeModal({
  isOpen,
  handleClosed: handleFormClosed,
  chosenLocationServiceOrRule,
}: IMakePriceChangeModalProps) {
  const [confirmModal, setConfirmModal] = useState(false)
  const [formData, setFormData] = useState<IFormData>({
    price: null,
    created: new Date().valueOf(),
  })

  const handleClose = () => {
    setFormData({
      price: null,
      created: new Date().valueOf(),
    })
    handleFormClosed()
  }
  const formFieldCheck = () => {
    let pass = true

    for (const [key, value] of Object.entries(formData)) {
      if (value === '' || value === null) {
        pass = false
        break
      }
    }

    return pass
  }

  return (
    <div>
      <Modal open={isOpen}>
        <Box className={styles.makePriceChangeModal}>
          <div className={`${styles.priceChangeModalPageTitle} font--bold`}>Price Change</div>
          <ConfirmPriceChangeModal
            isOpen={confirmModal}
            handleFormClosed={handleFormClosed}
            handleClosed={() => setConfirmModal(false)}
            formData={formData}
            setFormData={setFormData}
            chosenLocationServiceOrRule={chosenLocationServiceOrRule}
          />
          <PriceChangeForm
            formData={formData}
            setFormData={setFormData}
            chosenLocationServiceOrRule={chosenLocationServiceOrRule}
          />
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName="Cancel"
              onClick={() => handleClose()}
            />
            <PrimaryButton
              buttonName="Change"
              onClick={() => setConfirmModal(true)}
              disabled={!formFieldCheck()}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
