import { useAuthenticator } from '@aws-amplify/ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { MouseEventHandler, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IDiscount } from '../../../../../../../../../app/entities/Discount'
import { IFreeOrderReason } from '../../../../../../../../../app/entities/FreeOrderReason'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import { IPromoCode } from '../../../../../../../../../app/entities/PromoCode'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import FreeOrderReasonDropDown from '../../../../../../../global/components/free-order-reason-drop-down/FreeOrderReasonDropDown'
import LineItemAllocator from '../../../../../../../global/components/line-item-allocator/LineItemAllocator'
import PromoCodeDropDown from '../../../../../../../global/components/promo-code-drop-down/PromoCodeDropDown'
import {
  DISCOUNT_TYPES,
  MEASURES,
  USER_SELECTABLE_TYPES,
} from '../../../../../../../global/constants/discount'
import { TYPES } from '../../../../../../../global/constants/invoice'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import getUsername from '../../../../../../../global/utils/getUsername'
import { CalculatePriceResponse } from '../../../../../../../global/types/CalculatePriceResponse'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import {
  createDiscount,
  removeDiscountFromOrder,
  setDiscountToOrder,
  setRelationToFreeOrderReason,
  updateDiscount,
} from './api'

export default function ManageDiscount({
  handleClose,
  discountForEdit,
}: {
  handleClose: MouseEventHandler
  discountForEdit?: IDiscount
}) {
  const { user } = useAuthenticator((context) => [context.user])
  const { id: orderObjectId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  let initialRadioButton = USER_SELECTABLE_TYPES.PROMO

  // initially, we're only allowing "custom" discounts to be edited so if we have a discount
  // for edit, we know it's a custom discount
  if (discountForEdit) {
    initialRadioButton = USER_SELECTABLE_TYPES.DOLLAR
  }

  const [selectedRadioButton, setSelectedRadioButton] =
    useState<string>(initialRadioButton)
  const [selectedFreeOrderReason, setSelectedFreeOrderReason] =
    useState<IFreeOrderReason | null>(null)

  const [discount, setDiscount] = useState<Partial<IDiscount>>(
    discountForEdit ?? {
      value: 0,
      measure: '',
      reason: '',
      type: '',
      allocations: {},
    },
  )

  const [orderWillBeDiscountedToFree, setOrderWillBeDiscountedToFree] =
    useState(false)

  const [requiredPercentage, setRequiredPercentage] = useState<
    undefined | number
  >(undefined)
  const [discountOptionsToDisable, setDiscountOptionsToDisable] = useState<
    string[]
  >([])
  const [lineItemAllocatorHelperText, setLineItemAllocatorHelperText] =
    useState<string | undefined>(undefined)

  const { data: order, queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const invoices = order?.invoices || []
  const quoteInvoice = invoices.find((invoice) => invoice.type === TYPES.QUOTE)

  const maxAllowablePercentageDiscount = (order?.discounts || []).reduce(
    (acc, curr) => {
      if (curr.measure === MEASURES.PERCENTAGE) {
        return acc - curr.value
      }
      return acc
    },
    100,
  )

  // once we disable a discount option, we need to find a selectable
  // option to set as the selected radio button
  function decideSelectedButtonAfterDisabledOptionsUpdate() {
    const discountOptionsAreDisabled = discountOptionsToDisable.length > 0

    if (discountOptionsAreDisabled) {
      const nonDisabledDiscountOption = Object.values(
        USER_SELECTABLE_TYPES,
      ).find(
        (discountOption) => !discountOptionsToDisable.includes(discountOption),
      )

      if (nonDisabledDiscountOption) {
        setSelectedRadioButton(nonDisabledDiscountOption)
      }
    }
  }

  useEffect(decideSelectedButtonAfterDisabledOptionsUpdate, [
    discountOptionsToDisable,
  ])

  function decideDiscountOptionsToDisable() {
    const customAmountDiscountAlreadyExists = order?.discounts.some(
      (discount) => discount.measure === MEASURES.CUSTOM,
    )

    const discountWithoutAllocationsExists = order?.discounts.some(
      (discount) => !discount.allocations,
    )

    // if custom amount discount already exists, disable the other discount options
    // since mixing a percentage discount with a custom amount discount is not a stakeholder defined
    // behavior
    if (customAmountDiscountAlreadyExists || discountWithoutAllocationsExists) {
      setDiscountOptionsToDisable([
        USER_SELECTABLE_TYPES.PROMO,
        USER_SELECTABLE_TYPES.PERCENT,
      ])

      let helperText = ''

      if (discountForEdit !== undefined) {
        helperText = ''
      } else if (customAmountDiscountAlreadyExists) {
        helperText =
          'Custom dollar amount discount already exists. You cannot add percentage discounts after adding a dollar discount.'
      } else if (discountWithoutAllocationsExists) {
        helperText =
          'Discount without allocations exists. Only dollar amount discounts can be used in this scenario.'
      }

      setLineItemAllocatorHelperText(helperText)
    }
  }

  useEffect(decideDiscountOptionsToDisable, [order])

  function disabledButtonLogic() {
    switch (selectedRadioButton) {
      case USER_SELECTABLE_TYPES.PROMO:
        return !discount.value
      case USER_SELECTABLE_TYPES.PERCENT:
      case USER_SELECTABLE_TYPES.DOLLAR:
        return !discount.value || !discount.reason
      default:
        return true
    }
  }

  function handleDiscountChange() {
    if (discount.measure === MEASURES.PERCENTAGE) {
      setRequiredPercentage(discount.value as number)
    }
  }

  useEffect(handleDiscountChange, [discount])

  function handleDiscountTypeChange() {
    const discountUpdates: Partial<IDiscount> = discountForEdit ?? {
      value: 0,
      measure: MEASURES.PERCENTAGE,
      reason: '',
      type: DISCOUNT_TYPES.CSPortalDiscount,
    }

    if (selectedRadioButton === USER_SELECTABLE_TYPES.PROMO) {
      discountUpdates.type = DISCOUNT_TYPES.PromoCodeDiscount
    } else if (selectedRadioButton === USER_SELECTABLE_TYPES.DOLLAR) {
      discountUpdates.measure = MEASURES.CUSTOM
      setRequiredPercentage(undefined)
    }

    setDiscount(discountUpdates)
  }

  useEffect(handleDiscountTypeChange, [selectedRadioButton])

  function setPromoCodeValuesOnDiscount(promoCode: IPromoCode) {
    setDiscount((prevState) => ({
      ...prevState,
      value: promoCode.discountPercent,
      measure: MEASURES.PERCENTAGE,
      reason: promoCode.value,
      type: DISCOUNT_TYPES.PromoCodeDiscount,
    }))
  }

  function handleAllocationsChange(allocations: Record<string, number>) {
    setDiscount((prevState) => ({
      ...prevState,
      allocations,
    }))

    if (selectedRadioButton === USER_SELECTABLE_TYPES.DOLLAR) {
      const totalAmount = Object.values(allocations).reduce(
        (acc, curr) => acc + curr,
        0,
      )

      setDiscount((prevState) => ({
        ...prevState,
        value: totalAmount,
      }))
    }
    const totalAllocations = Object.values(allocations).reduce(
      (acc, curr) => acc + curr,
      0,
    )
    // if we are editing a discount, we need to check if the total amount of the allocations
    // is greater than the total amount of the order which is the sum of the `totalAmountCharged`
    // and the `totalAmountDiscounted` columns
    const totalAmount = discountForEdit
      ? (quoteInvoice?.totalAmountCharged || 0) +
        (quoteInvoice?.totalDiscount || 0)
      : quoteInvoice?.totalAmountCharged || 0

    const orderWillBeDiscountedToFree = totalAllocations >= totalAmount

    setOrderWillBeDiscountedToFree(orderWillBeDiscountedToFree)
  }

  async function handleDeleteDiscount() {
    if (!orderObjectId) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order id is null!',
        severity: 'error',
      })
      return
    }

    try {
      setIsLoading(true)

      await fetchData(
        removeDiscountFromOrder(
          orderObjectId,
          discount?.objectId ?? '',
          getUsername(user),
        ),
      )

      if (orderObjectId) {
        await queryOrderData(orderObjectId)
      }

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Discount deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to delete discount.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSaveDiscount() {
    if (!orderObjectId) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order id is null!',
        severity: 'error',
      })
      return
    }

    try {
      setIsLoading(true)

      if (orderWillBeDiscountedToFree) {
        if (!selectedFreeOrderReason?.objectId) {
          throw new Error('Reload page, free order reason is missing')
        }

        await fetchData<IOrder>(
          setRelationToFreeOrderReason(
            orderObjectId,
            selectedFreeOrderReason?.objectId,
          ),
        )
      }

      if (discountForEdit) {
        const updatedDiscount = await fetchData<IDiscount>(
          updateDiscount(discount),
        )
        await fetchData<CalculatePriceResponse>(
          setDiscountToOrder(orderObjectId, updatedDiscount, getUsername(user)),
        )
      } else {
        const newDiscount = await fetchData<IDiscount>(createDiscount(discount))
        await fetchData<CalculatePriceResponse>(
          setDiscountToOrder(orderObjectId, newDiscount, getUsername(user)),
        )
      }

      if (orderObjectId) {
        await queryOrderData(orderObjectId)
      }

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Discount added!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to add discount.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleDiscountValueChange(event: any) {
    let discountValue = Number(event.target.value)

    const isDiscountValueValid =
      !Number.isNaN(discountValue) && discountValue >= 0
    if (!isDiscountValueValid) {
      return
    }

    if (selectedRadioButton === USER_SELECTABLE_TYPES.PERCENT) {
      discountValue = Math.min(discountValue, maxAllowablePercentageDiscount)
    }

    setDiscount((prevState) => ({
      ...prevState,
      value: discountValue,
    }))
  }

  function renderDiscountFields() {
    switch (selectedRadioButton) {
      case USER_SELECTABLE_TYPES.PROMO:
        return (
          <div className={styles.radialValuesContainer}>
            <PromoCodeDropDown
              locationObjectId={order?.location?.objectId ?? ''}
              onPromoCodeChange={setPromoCodeValuesOnDiscount}
            />
          </div>
        )
      case USER_SELECTABLE_TYPES.PERCENT:
      case USER_SELECTABLE_TYPES.DOLLAR:
        return (
          <div className={styles.radialValuesContainer}>
            <TextField
              name='value'
              fullWidth
              label={`${selectedRadioButton.charAt(0).toUpperCase() + selectedRadioButton.slice(1)} Value`}
              onChange={handleDiscountValueChange}
              disabled={selectedRadioButton === USER_SELECTABLE_TYPES.DOLLAR}
              value={discount.value}
            />
            <FreeOrderReasonDropDown
              fullWidth
              onFreeOrderReasonChange={(freeOrderReason: IFreeOrderReason) => {
                setSelectedFreeOrderReason(freeOrderReason)
                setDiscount((prevState) => ({
                  ...prevState,
                  reason: freeOrderReason.reason,
                }))
              }}
              defaultValue={discount.reason}
              title='Reason'
            />
          </div>
        )
    }
  }
  return (
    <div className={styles.addDiscountsContainer}>
      {quoteInvoice && (
        <LineItemAllocator
          orderServices={order.orderServices}
          discounts={order.discounts}
          lineItems={quoteInvoice.lineItems}
          invoices={invoices}
          onAllocationsChange={handleAllocationsChange}
          requiredPercentage={requiredPercentage}
          helperText={lineItemAllocatorHelperText}
          discountForEdit={discountForEdit}
        />
      )}
      <RadioGroup
        name='radioValue'
        value={selectedRadioButton}
        onChange={(event) => setSelectedRadioButton(event.target.value)}
      >
        <div
          style={{
            display: discountOptionsToDisable.includes(
              USER_SELECTABLE_TYPES.PROMO,
            )
              ? 'none'
              : 'block',
          }}
        >
          <Radio value={USER_SELECTABLE_TYPES.PROMO} />
          Set Discount by Promo Code
        </div>
        <div
          style={{
            display: discountOptionsToDisable.includes(
              USER_SELECTABLE_TYPES.PERCENT,
            )
              ? 'none'
              : 'block',
          }}
        >
          <Radio value={USER_SELECTABLE_TYPES.PERCENT} />
          Set Discount by Percent Value
        </div>
        <div
          style={{
            display: discountOptionsToDisable.includes(
              USER_SELECTABLE_TYPES.DOLLAR,
            )
              ? 'none'
              : 'block',
          }}
        >
          <Radio value={USER_SELECTABLE_TYPES.DOLLAR} />
          Set Discount by Dollar
        </div>
      </RadioGroup>
      {renderDiscountFields()}
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={handleClose}
        />
        {discountForEdit && (
          <SecondaryButton
            buttonName='Delete'
            onClick={handleDeleteDiscount}
          />
        )}
        <PrimaryButton
          buttonName={discountForEdit ? 'Edit Discount' : 'Add Discount'}
          onClick={handleSaveDiscount}
          disabled={disabledButtonLogic() || isLoading}
        />
      </div>
      {orderWillBeDiscountedToFree ? (
        <div className={styles.willBeFreeMessage}>
          Discount equals or exceeds order total. Order will be updated to Free.
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}
