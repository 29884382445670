// import { ISmallPartSuppliesFormDataProps } from '../container/SmallPartSuppliesModal';
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import PrimaryCheckbox from '../../../../global/components/checkbox/Checkbox'
import { ChangeEvent, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fileToDataUri } from '../../../../technicians/components/technician-modal/components/utils'
import { uploadSmallPartSuppliesQrCode } from '../../../api'
import { CircularProgress } from '@mui/material'
import {
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_HEIGHT,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_WIDTH,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES_ARRAY,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_MB,
} from '../../../../global/constants/small-part-supplies'
import { ISmallPartSuppliesPartial } from '../../../../../../app/entities/SmallPartSupplies'

interface ISmallPartSuppliesFormProps {
  formData: ISmallPartSuppliesPartial
  setFormData: Function
  deleteIsChecked: boolean
  setDeleteIsChecked: Function
}

export default function SmallPartSuppliesModalForm(
  props: ISmallPartSuppliesFormProps,
) {
  const {
    bin,
    minimumOrder,
    description,
    partNumber,
    tireRackPartNumber,
    fullQuantity,
    qrCode: qrCodeUrl,
  } = props.formData

  const { user } = useAuthenticator((context) => [context.user])
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setFormData({ ...props.formData, [e.target.name]: e.target.value })
  }

  const handleImageFileInput = async (e: any) => {
    const idToken = user.getSignInUserSession()?.getIdToken()
    const imageFormData = new FormData()
    const file = e.target.files[0]
    const fileType = file.type.split('/')[1]
    const image = new Image()

    await fileToDataUri(file).then((dataUri) => {
      image.src = dataUri as string
    })

    if (!SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES_ARRAY.includes(fileType)) {
      alert('You must upload a jpeg or png!')
      return
    }
    if (
      image.height > SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_HEIGHT &&
      image.width > SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_WIDTH
    ) {
      alert(
        `Your image's height and width must be under ${SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_HEIGHT}px x ${SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_WIDTH}px!`,
      )
      return
    }
    if (file.size > SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_MB) {
      alert(`Your image must be under 3MB!`)
      return
    }

    imageFormData.append('file', file)
    const input = uploadSmallPartSuppliesQrCode(
      props.formData.bin || '',
      imageFormData,
    )
    try {
      setIsLoading(true)
      const resp = await fetch(input.url, {
        method: input.method,
        headers: {
          Authorization: `Bearer ${idToken?.getJwtToken()}` || '',
        },
        body: input.body,
      })
      console.log(resp)
      if (!resp.ok) throw new Error(`Request failed with status ${resp.status}`)
    } catch (error: any) {
      console.log(error)
      alert(error)
      e.target.value = ''
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.smallPartSuppliesFormContainer}>
      <div>
        <div
          className={styles.qrCodeContainer}
          style={{
            display: qrCodeUrl ? 'flex' : 'none',
          }}
        >
          <img src={qrCodeUrl} alt='qr code' width='150' height='150' />
        </div>
        <input
          style={{ display: 'none' }}
          id='qrCodeUpload'
          className={styles.uploadedFile}
          onChange={handleImageFileInput}
          type='file'
        />
        <label htmlFor='qrCodeUpload' className={styles.uploadButton}>
          {qrCodeUrl ? 'Edit QR Code' : 'Upload QR Code'}
        </label>
        {isLoading && <CircularProgress size={20} />}
      </div>
      <div className={styles.inputsContainer}>
        <TextField
          label='Bin'
          name='bin'
          value={bin || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label='Part Number'
          name='partNumber'
          value={partNumber || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label='Tire Rack Part Number'
          name='tireRackPartNumber'
          value={tireRackPartNumber || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label='Description'
          name='description'
          value={description || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label='Full Quantity'
          name='fullQuantity'
          value={fullQuantity || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label='Minimum Order'
          name='minimumOrder'
          value={minimumOrder || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <div className={styles.checkBoxContainer}>
          {props.formData.objectId && (
            <div className={styles.deleteContainer}>
              <PrimaryCheckbox
                checked={props.deleteIsChecked}
                onChange={(e) => props.setDeleteIsChecked(e.target.checked)}
                label='Remove Small Part from Inventory?'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
