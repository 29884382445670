import React, { useContext } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { TechnicianModal } from '../../technician-modal/components/container-modal/TechnicianModal'
import styles from './styles.module.css'
import { ILocationFilters } from '../../../../work-orders/components/main/WorkOrdersMain'
import { IWeekTypeFilters } from '../TechniciansMain'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import WeekTypeDropDown from '../../../../global/components/week-type-drop-down/WeekTypeDropDown'
import hasPermission from '../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../global/constants/permissions'
interface ITechnicianHeaderProps {
  locationFilters: ILocationFilters
  weekTypeFilters: IWeekTypeFilters
}
export default function TechnicianPageHeader(props: ITechnicianHeaderProps) {
  const { user } = useContext(UserContext)
  const { setLocationFilter } = props.locationFilters
  const { setWeekTypeFilter } = props.weekTypeFilters
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
  }

  return (
    <div className={styles.techPageHeaderContainer}>
      <div className={styles.inputsContainer}>
        <LocationDropDown
          onLocationChange={(val: string) => {
            setLocationFilter(val)
          }}
        />
        <WeekTypeDropDown
          onWeekTypeChange={(val: string) => setWeekTypeFilter(`${val}`)}
        />
      </div>
      <div className={styles.inputsContainer}>
        <PrimaryButton
          buttonName='New Technician'
          onClick={handleOpen}
          disabled={
            !hasPermission(user).allow(
              PERMISSIONS.TECHNICIANS.CATEGORY,
              PERMISSIONS.TECHNICIANS.ENTRIES.NEW_TECHNICIAN_BUTTON.NAME,
            )
          }
        />
      </div>
      {open && (
        <TechnicianModal
          isOpen={open}
          handleClosed={handleClosed}
        />
      )}
    </div>
  )
}
