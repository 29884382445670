import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Column, Row } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { ILocationFilters } from '../../../work-orders/components/main/WorkOrdersMain'
import { IWeekTypeFilters } from '../main/TechniciansMain'
import { ITechnician } from '../../../../../app/entities/Technician'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
import hasPermission from '../../../global/utils/user/has-permission'

interface ITechnicianTableProps {
  locationFilters: ILocationFilters
  weekTypeFilters: IWeekTypeFilters
  setOpen: Function
}
function TechniciansTable(props: ITechnicianTableProps) {
  const { user } = useContext(UserContext)
  const { user: amplifyUser } = useAuthenticator((context) => [context.user])

  const { locationFilter } = props.locationFilters
  const { weekTypeFilter } = props.weekTypeFilters
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    pageSize: 100,
    offSet: 0,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const isTechEditDisabled = !hasPermission(user).allow(
    PERMISSIONS.TECHNICIANS.CATEGORY,
    PERMISSIONS.TECHNICIANS.ENTRIES.EDIT_TECHNICIANS.NAME,
  )

  const endpoint = `${process.env.REACT_APP_ORIGIN}/technicians/byWeekAndLocation?`
  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      locationId: locationFilter,
      weekName: weekTypeFilter,
      columnFilters,
    })
  }, [
    locationFilter,
    weekTypeFilter,
    columnFilters,
    tableState.pageSize,
    tableState.offSet,
  ])

  function TechStatusDot(status: string) {
    return status === 'true' ? (
      <div className={styles.true}></div>
    ) : (
      <div className={styles.false}></div>
    )
  }

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  const columns: Column<ITechnician>[] = [
    {
      Header: 'Employee ID',
      accessor: 'employeeId',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ employeeId: val }),
      }),
    },
    {
      Header: 'Samsara ID',
      accessor: 'samsaraId',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ samsaraId: val }),
      }),
    },
    {
      Header: 'Technician',
      accessor: (technician: any) => technician.techName,
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ name: val }),
      }),
    },
    {
      Header: 'Status',
      accessor: (data: any) => {
        const { inactive } = data
        return inactive ? 'Inactive' : 'Active'
      },
      Filter: NoFilter,
    },
    {
      Header: 'Is Primary',
      accessor: (data: any) => {
        const { isPrimary } = data
        return isPrimary ? 'Primary' : 'Not Primary'
      },
      Filter: NoFilter,
    },
    {
      Header: 'Location',
      accessor: 'location',
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
      }),
    },
    {
      Header: 'Week',
      accessor: (technician: any) => technician.weekName,
      Filter: NoFilter,
    },
    {
      Header: 'Monday',
      accessor: (d: any) => {
        return d.monday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Tuesday',
      accessor: (d: any) => {
        return d.tuesday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Wednesday',
      accessor: (d: any) => {
        return d.wednesday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Thursday',
      accessor: (d: any) => {
        return d.thursday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Friday',
      accessor: (d: any) => {
        return d.friday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Saturday',
      accessor: (d: any) => {
        return d.saturday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
    {
      Header: 'Sunday',
      accessor: (d: any) => {
        return d.sunday ? TechStatusDot('true') : TechStatusDot('false')
      },
      Filter: NoFilter,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={
          isTechEditDisabled
            ? () => {}
            : (row: Row) => {
                const original: any = row.original

                props.setOpen({
                  isOpen: true,
                  techId: original.techObjectId,
                })
              }
        }
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default TechniciansTable
