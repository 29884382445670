import React, { useContext, useState } from 'react'
import UsersDropDown from './UsersDropDown'
import { IUser } from '../../../../../../app/entities/User'
import Button from '@mui/material/Button'
import { ToastNotificationContext } from '../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../global/utils/fetch'
import { assignUserToOrder } from '../../api'

interface IAssignUserCellProps {
  assignedTo?: string
  usersList?: IUser[]
  orderObjectId: string
  retrieveTableData: Function
  disabled: boolean
}

const AssignUserCell: React.FC<IAssignUserCellProps> = ({
  assignedTo,
  usersList = [],
  orderObjectId,
  retrieveTableData,
  disabled,
}) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    const userValue = value === 'Unassign User' ? null : value
    const assignedOrUnassigned =
      value === 'Unassign User' ? 'unassigned' : 'assigned'

    try {
      setIsLoading(true)
      await fetchData(assignUserToOrder(orderObjectId, userValue))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Successfully ${assignedOrUnassigned} user to order.`,
        severity: 'success',
      })
    } catch (error) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error assigning user to order.',
        severity: 'Error',
      })
    } finally {
      retrieveTableData()
      setIsLoading(false)
    }
  }

  if (showDropDown) {
    return (
      <UsersDropDown
        assignedUser={assignedTo}
        users={usersList}
        handleChange={handleChange}
        isLoading={isLoading}
      />
    )
  }

  return (
    <div>
      <Button
        onClick={() => setShowDropDown(true)}
        variant='outlined'
        color={assignedTo ? 'success' : 'warning'}
        size={'small'}
        style={{ textTransform: 'none' }}
        sx={{
          '&:hover': { backgroundColor: assignedTo ? '#6fbf73' : '#FFB000' },
        }}
        disabled={disabled}
      >
        {assignedTo ? assignedTo : 'Assign User'}
      </Button>
    </div>
  )
}

export default AssignUserCell
