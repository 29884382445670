import styles from './styles.module.css'
import TechnicianBio from '../tech-profile/TechnicianBio'
import TechInputBar from '../tech-input-bar/TechInputBar'
import WorkingDaysTable from '../working-days-table/WorkingDaysTable'
import UniqueDaySettingsTable from '../unique-day-settings-table/UniqueDaySettingsTable'
import { ITechFormProps, IWeekCalendarsProps, IDaySettingsProps } from './TechnicianEdit'
interface ITechCreateProps {
  formInfo: ITechFormProps
  weekCalendars: IWeekCalendarsProps
  daySettings: IDaySettingsProps
  reloadModal?: Function
  techId?: string
  refetchAvatar?: Function
}
export default function TechnicianCreate(props: ITechCreateProps) {
  const { formInfo, weekCalendars, daySettings, reloadModal, techId, refetchAvatar } = props
  return (
    <div className={styles.mainContainer}>
      <TechnicianBio
        refetchAvatar={refetchAvatar}
        {...formInfo}
      />
      <div className={styles.tableContainer}>
        <TechInputBar formInfo={formInfo} />
        <WorkingDaysTable weekCalendars={weekCalendars} />
        <UniqueDaySettingsTable
          formInfo={formInfo}
          daySettings={daySettings}
          reloadModal={reloadModal}
          techId={techId}
        />
      </div>
    </div>
  )
}
