import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useEffect, useState } from 'react'
import { IPromoCode } from '../../../../../app/entities/PromoCode'
import useFetch from '../../hooks/useFetchHook'
import { getPromoCodesList } from './api'
import styles from './styles.module.css'


interface PromoCodeDropDownProps {
  locationObjectId: string
  onPromoCodeChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
}

export default function PromoCodeDropDown({
  locationObjectId,
  onPromoCodeChange,
  disabled,
  defaultValue,
  fullWidth,
}: PromoCodeDropDownProps) {
  const [value, setValue] = useState(defaultValue || '')
  const { data, isLoading } = useFetch<{items: IPromoCode[]}>(getPromoCodesList(locationObjectId))

  const promoCodes = data?.items || []

  useEffect(() => {
    if (defaultValue && promoCodes) {
      const defaultPromoCode = promoCodes.find((promoCode: IPromoCode) => promoCode.objectId === defaultValue)
      if (defaultPromoCode) {
        setValue(defaultPromoCode.objectId)
      }
    }
  }, [defaultValue, promoCodes])

  const onPromoCodeChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: newValue },
    } = event

    setValue(newValue)

    const promoCode = promoCodes.find((promoCode: IPromoCode) => promoCode.objectId === newValue)
    onPromoCodeChange && onPromoCodeChange(promoCode)
  }


  const sortedPromoCodes = (promoCodes || [])
    .filter((promoCode: IPromoCode) => promoCode.value)
    .sort((a: IPromoCode, b: IPromoCode) => a.value?.localeCompare(b.value))

  let menuItems

  if (isLoading) {
    menuItems = (
      <MenuItem
        value=""
        className={styles.dropDownOption}>
        Loading...
      </MenuItem>
    )
  } else {
    menuItems = sortedPromoCodes.filter(Boolean).map((promoCode: IPromoCode) => {
      return (
        <MenuItem
          key={promoCode.objectId}
          value={promoCode.objectId}
          className={styles.dropDownOption}
        >
          {promoCode.value}, {promoCode.discountPercent}%
        </MenuItem>
      )
    })
  }

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label="Promo Code"
        select
        fullWidth={fullWidth}
        name="promoCode"
        disabled={disabled}
        onChange={onPromoCodeChangeWrapper}
        value={value}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}>
        {menuItems}
      </TextField>
    </div>
  )
}
