import React, { ChangeEvent } from "react"
import styles from "../styles.module.css"
import TextField from "@mui/material/TextField"
import LocationDropDown from "../../../../global/components/location-drop-down/LocationDropDown"
import { IPromoCodeFormDataProps } from "../container/PromoCodeModal"
interface IPromoCodeFormProps {
	promoCodeFormData: IPromoCodeFormDataProps
	setPromoCodeFormData: Function
}
export default function PromoCodeForm(props: IPromoCodeFormProps) {
	const { setPromoCodeFormData, promoCodeFormData } = props
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPromoCodeFormData((prevState: IPromoCodeFormDataProps) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}
	return (
		<div className={styles.promoFormContainer}>
			<div className={styles.inputsContainer}>
				<LocationDropDown
					onLocationChange={(val: string) =>
						setPromoCodeFormData((prevState: IPromoCodeFormDataProps) => ({
							...prevState,
							locationId: val,
						}))
					}
				/>
				<TextField
					label="Code Name"
					name="code"
					fullWidth
					onChange={handleChange}
				/>
				<div>
					<TextField
						type="date"
						label="Start Date"
						name="startDate"
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
					/>
					<TextField
						type="date"
						label="End Date"
						name="endDate"
						onChange={handleChange}
						InputLabelProps={{ shrink: true }}
					/>
				</div>
			</div>
			<div className={styles.inputsContainer}>
				<TextField
					label="Discount Type"
					value="PromoCodeDiscount"
					InputLabelProps={{ shrink: true }}
					disabled
				/>
				<TextField
					label="Measure"
					value="%"
					InputLabelProps={{ shrink: true }}
					disabled
				/>
				<TextField
					label="Value"
					name="value"
					type="number"
					onChange={handleChange}
				/>
			</div>
		</div>
	)
}
