import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import WorkOrderDetailModal from '../work-order-detail/components/main/WorkOrderDetailModal'
import { WoDetailContext } from '../context/context'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import createSearchParamsString from '../../../global/utils/createSearchParamsString'
import { IOrder, Order } from '../../../../../app/entities/Order'
import { ILocationFilters } from '../main/WorkOrdersMain'
import removeDoubleCommas from '../../../global/utils/removeDoubleCommas'
import { ROUTING_ALGORITHMS } from '../../../global/constants/scheduling'
import { Technician } from '../../../../../app/entities/Technician'
import moment from 'moment'

interface IWorkOrdersTableProps {
  locationFilters: ILocationFilters
}
function WorkOrdersTable(props: IWorkOrdersTableProps) {
  const { locationFilter } = props.locationFilters
  const { id } = useParams()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 100,
    offSet: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})
  const { queryOrderData, clearData } = useContext(WoDetailContext)
  const [searchParams] = useSearchParams()
  const rescheduleOptions = {
    isRescheduling: searchParams.get('isRescheduling') === 'true',
    rescheduleReason: (searchParams.get('reason') !== null
      ? searchParams.get('reason')
      : undefined) as string | undefined,
  }

  async function handleOpen(id: string) {
    setModalOpen(true)
    await queryOrderData(id)

    const queryStringParams = await createSearchParamsString(searchParams)
    const url = `/work-orders/${id}` + queryStringParams
    navigate(url)
  }

  function handleClosed() {
    setModalOpen(false)
    navigate('/work-orders')
    clearData()
    retrieveTableData()
  }
  function onRecordClickHandler(row: any) {
    const {
      original: { objectId },
    } = row
    handleOpen(objectId)
  }

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  useEffect(() => {
    if (id && !modalOpen) {
      handleOpen(id)
    }
  }, [id])

  const endpoint = `${process.env.REACT_APP_ORIGIN}/orders/getAllOrders?`
  const retrieveTableData = () => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortBy: '`workOrderId` DESC',
      selectedLocationId: locationFilter,
      columnFilters,
      dataTransformer: (data: any) => {
        data.items.forEach((item: any) => {
          if (item.installationPoint) {
            item.installationPoint.fullAddress = removeDoubleCommas(
              item.installationPoint?.fullAddress,
            )
          }
        })
        return data.items
      },
    })
  }

  useEffect(retrieveTableData, [
    locationFilter,
    tableState.pageSize,
    tableState.offSet,
  ])

  const columns: Column<IOrder | any>[] = [
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ workOrderId: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'TR Order ID',
      accessor: 'tireRackOrders.0.orderNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            tireRackOrders: {
              orderNumber: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Location',
      accessor: 'location.name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Technician Name',
      // accessor: 'routeArea.technician.name',
      Cell: ({ row: { original } }) => {
        const { routeInstructions, technician, location, routeArea } =
          original as Order & { technician: Partial<Technician> }

        let technicianName: string = ''

        const routeInstruction = routeInstructions?.[0]

        // if already routed
        if (routeInstruction?.route?.technician?.name) {
          technicianName = routeInstruction?.route?.technician?.name
        }
        // if only planned
        else if (technician?.name) {
          technicianName = technician?.name
        }

        return <div>{technicianName}</div>
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            routeArea: {
              technician: {
                name: val,
              },
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ status: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Service Appt',
      accessor: 'timeSlot.fromDateGenerated',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            timeSlot: {
              fromDateGenerated: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
      sortInverted: true,
    },
    {
      Header: 'Time Slot',
      accessor: 'timeSlot.type',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({
            timeSlot: {
              type: val,
            },
          }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'First Name',
      accessor: 'customer.firstName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { firstName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Last Name',
      accessor: 'customer.lastName',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { lastName: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Email',
      accessor: 'customer.email',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { email: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Customer Phone',
      accessor: 'customer.trimmedPhoneNumber',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ customer: { trimmedPhoneNumber: val } }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Installation Address',
      accessor: 'installationPoint.fullAddress',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationPoint: { fullAddress: val } }),
        onEnter: retrieveTableData,
      }),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <WorkOrderDetailModal
        handleClosed={handleClosed}
        isOpen={modalOpen}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default WorkOrdersTable
