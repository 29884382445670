import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { getStandbyQueueReport } from '../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import CircularProgress from '@mui/material/CircularProgress'
import NumberDropDown from '../../../../global/components/number-drop-down/NumberDropDown'
import DownloadButton from '../buttons/download'

interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
}

type StandbyQueueReportResponseType = {
  success: boolean
  csv?: string
  error?: string
}

export default function StandbyQueueReportModal(props: IScheduleModalProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const exportButton = (
    <PrimaryButton
      buttonName='Export'
      onClick={handleExport}
      style={{ height: '40px', width: '120px' }}
      disabled={isLoading}
    />
  )

  const [successMessage, setSuccessMessage] = useState<string>()
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>(4)
  const [reportData, setReportData] = useState<string>('')
  const [activeButton, setActiveButton] = useState(exportButton)

  function handleClosed() {
    setSuccessMessage('')
    setNumberOfWeeks(4)
    props.handleClosed()
  }

  async function handleExport() {
    try {
      setIsLoading(true)
      if (successMessage) {
        setSuccessMessage('')
      }
      const getStandbyQueueReportCall = getStandbyQueueReport(numberOfWeeks)
      const res = await fetchData<StandbyQueueReportResponseType>(
        getStandbyQueueReportCall,
      )

      if (res.success && res.csv) {
        setReportData(res.csv)
      } else if (res.success && !res.csv) {
        throw new Error('Success response received but no csv data found')
      } else if (res.error) {
        throw new Error(res.error)
      }
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      setIsLoading(false)
    }
  }

  function handleNumberOfWeeksChange(number: number) {
    setNumberOfWeeks(number)
    setReportData('')
  }

  function setButton() {
    if (reportData) {
      setActiveButton(<DownloadButton data={reportData} />)
    } else {
      setActiveButton(exportButton)
    }
  }
  useEffect(() => {
    setButton()
  }, [reportData, isLoading])

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={handleClosed}
      >
        <Box className={styles.scheduleModal}>
          <div className={styles.reportTitle}>Standby Queue Report</div>
          <div className={styles.inputsContainer}>
            <NumberDropDown
              numberOfOptions={52}
              onNumberChange={(number: number) =>
                handleNumberOfWeeksChange(number)
              }
              defaultValue={4}
              fieldLabel='Number of Weeks'
            />
          </div>
          <div className={styles.buttonsContainer}>
            {activeButton}
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <div style={{ height: '30px', width: '30px' }} />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  )
}
