import React from 'react'
import styles from './styles.module.css'

export default function TechTableHeader() {
  return (
    <div className={styles.tableHeadersContainer}>
      <div className={styles.tech}>
        <div className={styles.headerText}>Technician</div>
      </div>
      <div className={styles.timeSlot}>
        <div className={styles.headerText}>Morning</div>
      </div>
      <div className={styles.timeSlot}>
        <div className={styles.headerText}>Afternoon</div>
      </div>
      <div className={styles.timeSlot}>
        <div className={styles.headerText}>Evening</div>
      </div>
    </div>
  )
}
