import { MouseEventHandler } from 'react'
import styles from './styles.module.css'

interface SecondaryButtonProps {
	buttonName: string
	onClick: MouseEventHandler
	disabled?: boolean
	style?: React.CSSProperties
	children?: React.ReactNode
}

export default function SecondaryButton({
	buttonName,
	disabled,
	onClick,
	style,
	children,
}: SecondaryButtonProps) {

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={styles.secondaryButton}
			style={style}
		>
			<span className={styles.secondaryButtonText}>{children || buttonName}</span>
		</button>
	)
}
