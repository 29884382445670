import styles from '../../styles.module.css'
import { MouseEventHandler } from 'react'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import { QUIQ_PHONE_NUMBER, SUPPORT_PHONE_NUMBER } from '../../../../global/notifications'
import { SES_EMAILS } from '../../../../global/constants/emails'

interface ContactUsProps {
  handleClose: MouseEventHandler
  isOpen: boolean
}

export default function ContactUs(props: ContactUsProps) {
  const { handleClose, isOpen } = props

  return (
    <>
      {isOpen && (
        <div className={styles.inputsContainer}>
          <div className={styles.subModalHeader}>
            Contact Us
          </div>
          <div className={styles.contactInfoContainer}>
            <div className={styles.supportEmail}>
              <div>Text: <a target="_blank" rel="noreferrer" href={`sms:+${QUIQ_PHONE_NUMBER}`}>{QUIQ_PHONE_NUMBER}</a></div>
            </div>
            <div className={styles.supportEmail}>
              <div>Call: <a target="_blank" rel="noreferrer" href={`tel:+${SUPPORT_PHONE_NUMBER}`}>{SUPPORT_PHONE_NUMBER}</a></div>
            </div>
            <div className={styles.supportEmail}>
              <div>Email: <a target="_blank" rel="noreferrer" href={`mailto:${SES_EMAILS.MOBILE_INSTALL}`}>{SES_EMAILS.MOBILE_INSTALL}</a></div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName="Close"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </>
  )
}