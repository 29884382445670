import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useEffect, useState } from 'react'
import { IFreeOrderReason } from '../../../../../app/entities/FreeOrderReason'
import useFetch from '../../hooks/useFetchHook'
import { getFreeOrderReasons } from './api'
import styles from './styles.module.css'

interface FreeOrderReasonDropdownProps {
  onFreeOrderReasonChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
  title?: string
}

export default function FreeOrderReasonDropDown({
  onFreeOrderReasonChange,
  disabled,
  defaultValue,
  fullWidth,
  title,
}: FreeOrderReasonDropdownProps) {
  const [value, setValue] = useState(defaultValue || '')

  const { data: freeOrderReasonData, isLoading } = useFetch<IFreeOrderReason[]>(getFreeOrderReasons())

  useEffect(() => {
    if (freeOrderReasonData) {
      if (!defaultValue) {
        setValue('')
      } else {
        const defaultFreeOrderReason = freeOrderReasonData.find((freeOrderReason: IFreeOrderReason) => freeOrderReason.reason === defaultValue)
        if (defaultFreeOrderReason) {
          setValue(defaultFreeOrderReason.reason)
        }
      }
    }
  }, [defaultValue, freeOrderReasonData])

  const onFreeOrderReasonChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value: freeOrderReasonReason },
    } = event

    const freeOrderReason = freeOrderReasonData
      ?.find((freeOrderReason: IFreeOrderReason) => freeOrderReason.reason === freeOrderReasonReason)

    setValue(freeOrderReason?.reason || '')
    onFreeOrderReasonChange && onFreeOrderReasonChange(freeOrderReason)
  }

  const sortedFreeOrderReasons = (freeOrderReasonData || [])
    .filter((freeOrderReason: IFreeOrderReason) => freeOrderReason.reason)
    .sort((a: IFreeOrderReason, b: IFreeOrderReason) => a.reason?.localeCompare(b.reason))

  let menuItems

  if (isLoading) {
    menuItems = (
      <MenuItem
        value=""
        className={styles.dropDownOption}>
        Loading...
      </MenuItem>
    )
  } else {
    menuItems = sortedFreeOrderReasons.map((freeOrderReason: IFreeOrderReason) => {
      return (
        <MenuItem
          key={freeOrderReason.objectId}
          value={freeOrderReason.reason}
          className={styles.dropDownOption}
        >
          {freeOrderReason.reason}
        </MenuItem>
      )
    })
  }

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label={title || 'Free Order Reason'}
        select
        fullWidth={fullWidth}
        disabled={disabled}
        onChange={onFreeOrderReasonChangeWrapper}
        value={value}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                fontFamily: '',
              },
            },
          },
        }}>
        {menuItems}
      </TextField>
    </div>
  )
}
