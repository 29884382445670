import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { CANCELLATION_REASONS } from '../../../../../../../global/constants/orders'
import { updateOrderStatusToCanceled } from '../../../../../api'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../../context/context'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

//TODO plug in the cancellationNote api once created
export default function CancelOrder(props: any) {
  const { user } = useAuthenticator((context) => [context.user])
  const { id } = useParams()
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [formData, setFormData] = useState({
    reason: '',
    note: '',
  })
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function handleDropDown(e: React.ChangeEvent<HTMLSelectElement>) {
    setFormData((prevState) => ({
      ...prevState,
      reason: e.target.value,
    }))
  }

  async function handleCancelOrder() {
    try {
      setIsLoading(true)
      await fetchData<IOrder>(
        updateOrderStatusToCanceled(id, formData.reason, formData.note),
      )
      await queryOrderData(id ?? '')
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order canceled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error canceling order!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>
        <ReasonDropDown
          handleDropDown={handleDropDown}
          reason={formData.reason}
        />
      </div>
      <div className={styles.topInputsContainer}>
        <TextField
          fullWidth
          label='Note'
          value={formData.note}
          multiline
          rows={4}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              note: e.target.value,
            }))
          }
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to cancel this order</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Confirm Cancelation'
          disabled={!isChecked || formData.reason === '' || isLoading}
          onClick={handleCancelOrder}
        />
      </div>
    </div>
  )
}
interface IReasonDropDownProps {
  handleDropDown: Function
  reason: string
}
const ReasonDropDown = (props: IReasonDropDownProps) => {
  const reasons = CANCELLATION_REASONS
  const reasonsMap = Object.values(reasons).map(
    (reason: string, index: number) => {
      return (
        <MenuItem
          key={index}
          value={reason}
        >
          {reason}
        </MenuItem>
      )
    },
  )
  return (
    <TextField
      value={props.reason}
      fullWidth
      onChange={(e) => props.handleDropDown(e)}
      label='Reason'
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 200,
            },
          },
        },
      }}
    >
      {reasonsMap}
    </TextField>
  )
}
