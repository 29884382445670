import { useContext } from 'react'
import { WoDetailContext } from '../../../../context/context'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import OrderActions from './OrderActions'
import { IOrder, Order } from '../../../../../../../../app/entities/Order'
import removeDoubleCommas from '../../../../../../global/utils/removeDoubleCommas'
import moment from 'moment'

interface IOverviewSummaryTableProps {
  isReadOnly: boolean
}
function OverviewSummaryTable({ isReadOnly }: IOverviewSummaryTableProps) {
  const { data } = useContext(WoDetailContext)
  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  const columns: Column<IOrder | any>[] = [
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Service Appt',
      Cell: ({ row: { original } }) => {
        const { timeSlot, routeInstructions } = original as any

        if (routeInstructions.length)
          return <div>{routeInstructions[0].startTimeGenerated}</div>

        if (timeSlot) return <div>{timeSlot.fromTimeGenerated}</div>

        return <div>Appointment information not available.</div>
      },
    },
    {
      Header: 'Prelim Appt',
      accessor: 'timeSlot.fromDateGenerated',
      Cell: ({ row: { original } }) => {
        const { timeSlot, routeInstructions } = original as Order

        if (routeInstructions.length)
          return (
            <div>
              {moment.utc(routeInstructions[0].startTime).format('MM/DD/YY')}
            </div>
          )

        if (timeSlot) return <div>{timeSlot.fromDateGenerated}</div>

        return <div>-</div>
      },
    },
    {
      Header: 'Time Slot',
      accessor: 'timeSlot.type',
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Customer Information',
      Cell: ({ row: { original } }) => {
        const { customer, installationPoint } = original as any
        if (customer) {
          return (
            <div>
              <div>
                {customer.firstName ?? ''} {customer.lastName ?? ''}
              </div>
              <div>
                {installationPoint
                  ? removeDoubleCommas(installationPoint.fullAddress)
                  : ''}
              </div>
              <div>{customer.email ?? ''}</div>
              <div>{formatPhoneNumber(customer.trimmedPhoneNumber)}</div>
            </div>
          )
        } else {
          return <div>Customer information not available.</div>
        }
      },
    },
    {
      Header: 'Van',
      accessor: 'van.name',
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper>
        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          <TableTitle>Overview / Summary Info</TableTitle>
          <OrderActions isReadOnly={isReadOnly} />
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={[data] as any}
        isLoading={false}
        onRowClick={console.log}
        isDisplayMode={true}
      />
    </div>
  )
}

export default OverviewSummaryTable
