const NOTIFICATION_RULES = {
  transactionalSms: 'transactionalSms',
  marketingSms    : 'marketingSms',
  marketingEmail  : 'marketingEmail',
}

const NOTIFICATION_RULE_NAMES = Object.keys(NOTIFICATION_RULES)

export {
  NOTIFICATION_RULE_NAMES,
  NOTIFICATION_RULES,
}