import { Invoice } from '../../../../../../app/entities/Invoice'
import { TYPES } from '../../../constants/invoice'

function getMostRecentServiceInvoice(invoices: Invoice[]) {
  if (!invoices || !invoices.length) {
    return undefined
  }

  // A 'service invoice' is an invoice that is not a tip and is of type 'COMPLETED'
  const nonTipInvoices = invoices.filter((invoice) => !invoice.tip && [TYPES.COMPLETED, TYPES.QUOTE].includes(invoice.type))

  // The 'invoiced' field is the date the transaction was created
  return nonTipInvoices.sort((a, b) => new Date(b.invoiced).getTime() - new Date(a.invoiced).getTime())[0] // [0] gets the most recent
}

export default getMostRecentServiceInvoice