import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IOrder } from '../../../../../app/entities/Order'
import { ITechnician } from '../../../../../app/entities/Technician'
import logo from '../../../assets/images/logo-285x65.png'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import { CATEGORIES } from '../../../global/constants/service'
import { fetchData } from '../../../global/utils/fetch'
import useFetch from '../../../global/hooks/useFetchHook'
import { getOrderById, getTechnician, payOrderTips } from '../../api'
import { ITipConfirmationDataProps, ITipConfirmationProps } from '../TipsPage'
import styles from '../styles.module.css'
import TipAmountOptions from './TipAmountOptions'
import ComplianceLinks from '../../../global/components/compliance-links/ComplianceLinks'
export interface ISelectedTipFormProps {
  selectedTip: ISelectedTipProps
  setSelectedTip: Function
}
interface ISelectedTipProps {
  percentage: string
  amount: string
}
interface ITipContainerProps {
  tipConfirmation: ITipConfirmationProps
}
export default function TipContainer(props: ITipContainerProps) {
  const { id: orderObjectId } = useParams()
  const [selectedTip, setSelectedTip] = useState<ISelectedTipProps>({
    percentage: '',
    amount: '',
  })
  const [isTipLoading, setIsTipLoading] = useState(false)
  const { data: technician, isLoading: isTechnicianLoading } =
    useFetch<ITechnician>(getTechnician(orderObjectId))
  const { data: order, isLoading: isOrderLoading } = useFetch<IOrder>(
    getOrderById(orderObjectId as string),
  )

  const [tippableAmount, setTippableAmount] = useState<number | undefined>(
    undefined,
  )

  useEffect(() => {
    if (order) {
      const { orderServices, discounts } = order

      const tippableAmount = orderServices.reduce((acc, orderService) => {
        const orderServiceIsTippable = [CATEGORIES.LABOR, null].includes(
          orderService.category as CATEGORIES | null,
        )

        if (orderServiceIsTippable) {
          const pricePerUnit = orderService.priceOverride ?? orderService.price

          const netPrice = pricePerUnit * orderService.quantity

          const discountForService = discounts.reduce((acc, discount) => {
            const discountAllocatedToService =
              (discount.allocations || {})[orderService.objectId] ?? 0

            return acc + discountAllocatedToService
          }, 0)

          const price = netPrice - discountForService

          return acc + price
        }

        return acc
      }, 0)

      setTippableAmount(tippableAmount)
    }
  }, [order])

  const { setTipConfirmation } = props.tipConfirmation

  const handleSendTip = async () => {
    try {
      setIsTipLoading(true)
      const response = await fetchData<[]>(
        payOrderTips(orderObjectId, Number(selectedTip.amount)),
      )
      if (Array.isArray(response) && response.length === 0) {
        //successful response comes back as an empty array []
        setTipConfirmation((prevState: ITipConfirmationDataProps) => ({
          ...prevState,
          amount: selectedTip.amount,
          confirmed: true,
        }))
      }
      setIsTipLoading(false)
    } catch (error: any) {
      alert('There has been an error processing tip')
      console.error(error)
      setIsTipLoading(false)
    }
  }

  return (
    <div className={styles.tipsContainer}>
      {isOrderLoading ||
        isTechnicianLoading ||
        (isTipLoading && (
          <div className={styles.progressContainer}>
            <CircularProgress />
          </div>
        ))}
      {!isOrderLoading &&
        !isTechnicianLoading &&
        !isTipLoading &&
        tippableAmount !== undefined && (
          <>
            <div>
              <img
                src={logo}
                alt='logo'
                className={styles.logo}
              />
            </div>
            <div className={styles.header}>
              Thank you for using Tire Rack Mobile Installation!
            </div>
            <div className={styles.text}>
              We hope you were satisfied with our service. 100% of any tip you
              leave will go to your technician
              {technician?.name ? `, ${technician?.name}` : ''}.
            </div>
            <div>
              <TipAmountOptions
                formProps={{ selectedTip, setSelectedTip }}
                amount={tippableAmount}
              />
            </div>
            <div className={styles.complianceAndButtonContainer}>
              <div className={styles.complianceLinks}>
                <ComplianceLinks />
              </div>
              <PrimaryButton
                buttonName='Send Tip'
                disabled={!selectedTip.amount || isTipLoading}
                onClick={handleSendTip}
                style={{ width: '100px' }}
              />
            </div>
          </>
        )}
    </div>
  )
}
