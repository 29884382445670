export const disableNotification = (customerId: string, notificationRuleName: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/customer_notifications/${customerId}/disable/${notificationRuleName}`,
  }
}

export const enableNotification = (customerId: string, notificationRuleName: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/customer_notifications/${customerId}/enable/${notificationRuleName}`,
  }
}
