import { IInvoiceStateProps } from '../EditInvoiceModal'
import styles from './styles.module.css'

interface AdjustInvoiceTableProps {
  amount : any
  invoiceState: IInvoiceStateProps
}

const PLACEHOLDER = '--'
export default function AdjustTipTable(props: AdjustInvoiceTableProps) {
	const { amount, invoiceState } = props
	const { serviceAmount, amountCollected } = invoiceState

	const newTipAmount = props.amount !== '' ? '$' + (serviceAmount - Number(props.amount)).toFixed(2) : PLACEHOLDER

	const newTipAmountFloat = Number.parseFloat(newTipAmount.substring(1))

	// -1 indicates the '- $ Refund Amount' field is blank
  const tipRefundFloat = Number.isNaN(newTipAmountFloat) ? 0 : (serviceAmount - newTipAmountFloat)
  const tipRefundString = tipRefundFloat === 0 ? PLACEHOLDER : `$-${tipRefundFloat.toFixed(2)}`

	const balance = (serviceAmount - amountCollected - amount).toFixed(2)

	return (
		<div className={styles.tableContainer}>
			<table className={styles.table}>
				<tr>
					<th></th>
					<th scope='col'>Current</th>
					<th scope='col'>Refund</th>
					<th scope='col'>New</th>
				</tr>
				<tr className={styles.trBorderTop}>
					<th
						scope='row'
						className={styles.noBold}>
						Tip
					</th>
					<td>${serviceAmount.toFixed(2)}</td> {/* Current */}
					<td>{tipRefundString}</td> {/* Refund */}
					<td>{newTipAmount}</td> {/* New */}
				</tr>
				<tr className={styles.trBorderTop}>
					<th scope='row'>Amount Collected</th> {/* Current */}
					<td>${amountCollected.toFixed(2)}</td> {/* Refund */}
					<td></td> {/* New */}
				</tr>
				<tr>
					<th scope='row'>Balance</th> {/* Current */}
					<td>${balance}</td> {/* Refund */}
					<td></td> {/* New */}
				</tr>
			</table>
		</div>
	)
}
