import { IAtaGlTransaction } from '../../../../../../../../../bi/entities/AtaGlTransaction'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateAtaGlTransactionColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Posting Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ postingDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { postingDate } = data
        return generateTextField(
          'postingDate',
          postingDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Posting Period',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ postingPeriod: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { postingPeriod } = data
        return generateTextField(
          'postingPeriod',
          postingPeriod,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Modified Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ modifiedDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { modifiedDate } = data
        return generateTextField(
          'modifiedDate',
          modifiedDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cost Center Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ costCenterId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { costCenterId } = data
        return generateTextField(
          'costCenterId',
          costCenterId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Account Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ accountNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { accountNumber } = data
        return generateTextField(
          'accountNumber',
          accountNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Src',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ src: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { src } = data
        return generateTextField(
          'src',
          src,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Reference Number',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ referenceNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { referenceNumber } = data
        return generateTextField(
          'referenceNumber',
          referenceNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Vendor Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vendorNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { vendorNumber } = data
        return generateTextField(
          'vendorNumber',
          vendorNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Amount',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ amount: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { amount } = data
        return generateTextField(
          'amount',
          amount?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Vendor Name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vendorName: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { vendorName } = data
        return generateTextField(
          'vendorName',
          vendorName ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Comments',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ comments: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaGlTransaction, rowIndex: number) => {
        const { comments } = data
        return generateTextField(
          'comments',
          comments ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateAtaGlTransactionColumns
