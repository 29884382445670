import { useAuthenticator } from '@aws-amplify/ui-react'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { Column } from 'react-table'
import { IDaySetting } from '../../../../../../../app/entities/DaySetting'
import Table from '../../../../../global/components/table/Table'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import { ROUTING_ALGORITHMS } from '../../../../../global/constants/scheduling'
import { reformatDate } from '../../../../../global/utils/date/dates'
import { fetchData } from '../../../../../global/utils/fetch'
import { updateDaySetting } from '../../api'
import {
  IDaySettingsProps,
  ITechFormProps,
} from '../container-modal/TechnicianEdit'
import { WorkDayDropDown } from '../custom-inputs/CustomInputs'
import AddUniqueDay from './AddUniqueDay'
import DaySettingActivityLog from './activity-log/DaySettingActivityLog'
import DeleteDaySettingConfirmation from './delete-confirmation/DeleteDaySettingConfirmation'
import styles from './styles.module.css'

interface IUniqueDayTableProps {
  daySettings: IDaySettingsProps
  formInfo: ITechFormProps
  reloadModal?: Function
  techId?: string
}

const nonWorkingDayTextFieldStyles = {
  width: 130,
}
const workingDayTextFieldStyles = {
  width: 130,
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'black',
  },
}

export default function UniqueDaySettingsTable({
  daySettings,
  reloadModal,
  techId,
  formInfo,
}: IUniqueDayTableProps) {
  const { locationRoutingAlgorithm } = formInfo.technicianFormInfo
  const { daySettingsFormInfo } = daySettings

  const { user } = useAuthenticator((context) => [context.user])

  const [editingRowIndex, setEditingRowIndex] = useState(-1)
  const [isEditing, setIsEditing] = useState(false)
  const [row, setRow] = useState<JSX.Element | null>(null)
  const [editedDaySetting, setEditedDaySetting] = useState<any>(null)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false)
  const [activityLogIsOpen, setActivityLogIsOpen] = useState(false)
  const [daySettingForActivityLog, setDaySettingForActivityLog] =
    useState<IDaySetting | null>(null)

  function cancelUniqueDayRow() {
    return setRow(null)
  }
  function addUniqueDayRow() {
    return setRow(
      <AddUniqueDay
        cancel={cancelUniqueDayRow}
        reloadModal={reloadModal}
        techId={techId}
        formInfo={formInfo}
        key={Date.now()}
      />,
    )
  }
  function handleChange(
    newValue: string | boolean,
    valueName: string,
    rowIndex: number,
  ) {
    if (rowIndex === editingRowIndex) {
      setEditedDaySetting({ ...editedDaySetting, [valueName]: newValue })
    }
  }

  function handleRemoveDaySetting(rowIndex: number) {
    let currentDaySetting = daySettingsFormInfo.daySettings.filter(
      (daySetting: any, index: number) => index === rowIndex,
    )
    setEditedDaySetting(currentDaySetting[0])
    setDeleteConfirmationModalOpen(true)
    setEditingRowIndex(-1)
  }

  function handleEditRow(rowIndex: number) {
    setEditingRowIndex(rowIndex)
    setIsEditing(true)
    let currentDaySetting = daySettingsFormInfo.daySettings.filter(
      (daySetting: any, index: number) => index === rowIndex,
    )[0]
    let copyOfCurrentDaySetting = { ...currentDaySetting }
    copyOfCurrentDaySetting.dayDate = reformatDate(currentDaySetting.dayDate)
    setEditedDaySetting(copyOfCurrentDaySetting)
  }

  function handleCancelEdit() {
    setEditedDaySetting(null)
    setEditingRowIndex(-1)
    setIsEditing(false)
  }
  function handleCloseModal() {
    setDeleteConfirmationModalOpen(false)
    setEditedDaySetting(null)
  }
  async function handleSaveEditRow(rowIndex: number) {
    if (rowIndex !== editingRowIndex) return
    try {
      let newDaySettingCopy = editedDaySetting
      const { dayDate } = newDaySettingCopy
      if (dayDate) {
        // we need to convert the date(day) to UTC and remove the `dayDate` property from the object
        // because the API doesn't accept it
        const [year, month, day] = dayDate.split('-')
        const dateString = `${year}-${month}-${day}T00:00:00.000Z`
        const utcConverted = new Date(dateString)
        newDaySettingCopy.day = utcConverted
        delete newDaySettingCopy.dayDate
        await fetchData(updateDaySetting(editedDaySetting, techId))
        reloadModal && reloadModal()
      }
    } catch (err) {
      alert(`Error updating day setting: ${err}`)
      console.error(err)
    }
    setIsEditing(false)
    setEditingRowIndex(-1)
  }
  function determineValue(value: any, index: number, name: string) {
    if (editingRowIndex === index) {
      return editedDaySetting[name]
    } else if (name === 'dayDate') {
      return reformatDate(value)
    } else return value
  }

  function getWorkDayDependentStyling(
    workDay: boolean,
    isEditingThisRow: boolean,
  ) {
    return workDay && isEditingThisRow
      ? workingDayTextFieldStyles
      : nonWorkingDayTextFieldStyles
  }

  const columns: Column<IDaySetting>[] = [
    {
      Header: 'Date',
      accessor: (data: any) => {
        const { dayDate } = data
        return dayDate
      },
      Cell: ({ value, row: { index } }: any) => {
        const isEditing = index !== editingRowIndex
        return (
          <TextField
            onChange={(event) =>
              handleChange(event.target.value, event.target.name, index)
            }
            value={determineValue(value, index, 'dayDate')}
            InputLabelProps={{ shrink: true }}
            type='Date'
            label='Date'
            name='dayDate'
            disabled={isEditing}
            sx={{
              width: 125,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
              },
            }}
          />
        )
      },
    },
    {
      Header: 'Work Day',
      accessor: (data: any, rowIndex: number) => {
        const { workDay } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <WorkDayDropDown
            disabled={isEditing}
            value={determineValue(workDay, rowIndex, 'workDay') || ''}
            onChange={(newValue: boolean) =>
              handleChange(newValue, 'workDay', rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'Reason',
      accessor: (data: any, rowIndex: number) => {
        const { reason } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            value={determineValue(reason, rowIndex, 'reason') || ''}
            label='Reason'
            name='reason'
            disabled={isEditing}
            InputLabelProps={{ shrink: true }}
            sx={workingDayTextFieldStyles}
            onChange={(event) =>
              handleChange(event.target.value, event.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'Working Hours',
      accessor: (daySetting: IDaySetting, rowIndex: number) => {
        const { startTime, endTime } = daySetting

        const isWorkingDay = editedDaySetting?.workDay

        let workingHoursAreEnabled = false

        const isEditingThisRow = isEditing && rowIndex === editingRowIndex

        const workDayDependentStyling = getWorkDayDependentStyling(
          isWorkingDay,
          isEditingThisRow,
        )

        if (isEditingThisRow) {
          workingHoursAreEnabled = editedDaySetting.workDay
        }

        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <TextField
              type='time'
              label='From'
              name='startTime'
              sx={{ ...workDayDependentStyling, width: 120 }}
              value={determineValue(startTime, rowIndex, 'startTime') || ''}
              disabled={!workingHoursAreEnabled}
              InputLabelProps={{ shrink: true }}
              onChange={(event) =>
                handleChange(event.target.value, event.target.name, rowIndex)
              }
            />
            <TextField
              type='time'
              label='To'
              name='endTime'
              sx={{ ...workDayDependentStyling, width: 120 }}
              value={determineValue(endTime, rowIndex, 'endTime') || ''}
              disabled={!workingHoursAreEnabled}
              InputLabelProps={{ shrink: true }}
              onChange={(event) =>
                handleChange(event.target.value, event.target.name, rowIndex)
              }
            />
          </div>
        )
      },
    },
    {
      Header: 'Lunch Duration',
      accessor: (data: any, rowIndex: number) => {
        const { lunchDuration, lunchFrom, lunchTo } = data

        const isWorkingDay = editedDaySetting?.workDay

        let lunchHoursAreEnabled = false

        const isEditingThisRow = isEditing && rowIndex === editingRowIndex

        if (isEditingThisRow) {
          lunchHoursAreEnabled = editedDaySetting.workDay
        }

        const workDayDependentStyling = getWorkDayDependentStyling(
          isWorkingDay,
          isEditingThisRow,
        )

        return (
          <div>
            <TextField
              type='number'
              label='Lunch Duration'
              name='lunchDuration'
              sx={{ ...workDayDependentStyling, width: 120 }}
              value={
                determineValue(lunchDuration, rowIndex, 'lunchDuration') || ''
              }
              disabled={isEditing || !isWorkingDay}
              InputLabelProps={{ shrink: true }}
              onChange={(event) =>
                handleChange(event.target.value, event.target.name, rowIndex)
              }
            />
          </div>
        )
      },
    },
    {
      Header: ' ',
      accessor: (daySetting: IDaySetting, rowIndex: number) => {
        const isEditingThisRow = isEditing && rowIndex === editingRowIndex
        const hasActivityLog =
          daySetting.activityLog && daySetting.activityLog.length > 0

        return (
          <div className={styles.buttonCell}>
            <a
              className={styles.buttonText}
              onClick={() =>
                isEditingThisRow
                  ? handleCancelEdit()
                  : handleRemoveDaySetting(rowIndex)
              }
            >
              {isEditingThisRow ? 'Cancel' : 'Remove'}
            </a>
            <a
              className={styles.buttonText}
              onClick={() =>
                isEditingThisRow
                  ? handleSaveEditRow(rowIndex)
                  : handleEditRow(rowIndex)
              }
            >
              {isEditingThisRow ? 'Save' : 'Edit'}
            </a>
            {hasActivityLog && (
              <a
                className={styles.buttonText}
                onClick={() => {
                  setDaySettingForActivityLog(daySetting)
                  setActivityLogIsOpen(true)
                }}
              >
                View Log
              </a>
            )}
          </div>
        )
      },
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <DaySettingActivityLog
        isOpen={activityLogIsOpen}
        handleClosed={() => setActivityLogIsOpen(false)}
        daySetting={daySettingForActivityLog}
      />
      <TableTopper>
        <div className={styles.titleButtonContainer}>
          <DeleteDaySettingConfirmation
            isOpen={deleteConfirmationModalOpen}
            techId={techId}
            handleClosed={handleCloseModal}
            daySetting={editedDaySetting}
            reloadModal={reloadModal}
          />
          <TableTitle>Unique Day Settings Table</TableTitle>
          <button
            onClick={addUniqueDayRow}
            style={{
              all: 'unset',
              cursor: !techId ? 'not-allowed' : 'pointer',
              color: !techId ? '#999' : '#d70000',
            }}
            disabled={!techId}
          >
            Add New
          </button>
        </div>
      </TableTopper>
      <div style={{ width: '100%' }}>
        <Table
          columns={columns}
          data={daySettingsFormInfo.daySettings as any}
          isDisplayMode={true}
          isLoading={false}
          customRow={row}
        />
      </div>
    </div>
  )
}
