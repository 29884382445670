import { useState, ChangeEvent } from 'react'
import TextField from '@mui/material/TextField'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import styles from './styles.module.css'
import { fetchData } from '../../../../global/utils/fetch'
import { getGeocode } from '../../../../schedule/components/api'

interface IGoogleAddressSearchBarProps {
  addressInput: string
  setAddressInput: Function
  setIsZipCodeServiced: Function
  setFormattedAddress: Function
}
export default function GoogleAddressSearchBar(
  props: IGoogleAddressSearchBarProps,
) {
  const {
    addressInput,
    setAddressInput,
    setIsZipCodeServiced,
    setFormattedAddress,
  } = props
  const [hasChosenAddress, setHasChosenAddress] = useState(false)

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      options: {
        input: addressInput,
        types: ['address'],
        componentRestrictions: {
          country: 'us',
        },
      },
    })

  const handlePredictionClick = async (
    address: google.maps.places.AutocompletePrediction,
  ) => {
    setHasChosenAddress(true)
    setAddressInput(address.description)
    getPlacePredictions({
      input: '',
    })

    const { results } = (await fetchData(
      getGeocode(address.description),
    )) as google.maps.GeocoderResponse

    if (!(results && results.length > 0))
      return alert('No address found, try again')

    const chosenAddress = results[0]

    const zipCode = chosenAddress.address_components.find((component) =>
      component.types.includes('postal_code'),
    )

    const isServiced = await fetchData<any>({
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode?.long_name}/availability`,
    })
    console.log(chosenAddress)

    if (isServiced.serviced) {
      const formattedAddress = {
        address: `${
          chosenAddress.address_components.find((component) =>
            component.types.includes('street_number'),
          )?.long_name || ''
        } ${
          chosenAddress.address_components.find((component) =>
            component.types.includes('route'),
          )?.long_name || ''
        }`,
        city:
          chosenAddress.address_components.find((component) =>
            component.types.includes('locality'),
          )?.long_name || '',
        state:
          chosenAddress.address_components.find((component) =>
            component.types.includes('administrative_area_level_1'),
          )?.short_name || '',
        zipCode:
          chosenAddress.address_components.find((component) =>
            component.types.includes('postal_code'),
          )?.long_name || '',
      }
      setFormattedAddress(formattedAddress)
    } else {
      setFormattedAddress({
        address: '',
        city: '',
        state: '',
        zipCode: '',
      })
    }
    setIsZipCodeServiced(isServiced.serviced)
  }

  const addressHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    setHasChosenAddress(false)
    setIsZipCodeServiced(undefined)
    const addressValue = event.target.value

    setAddressInput(addressValue)

    getPlacePredictions({
      input: addressValue,
    })
  }

  return (
    <div className={styles.addressContainer}>
      <div>
        <TextField
          name='address'
          label={'Address'}
          onChange={addressHandler}
          value={addressInput}
          autoComplete='off'
          style={{ width: '400px' }}
        />
        <div
          className={styles.addressResultsHolder}
          style={{ display: placePredictions?.length > 0 ? 'block' : 'none' }}
        >
          {isPlacePredictionsLoading ? (
            <div className={styles.addressResult}>
              <i>Loading...</i>
            </div>
          ) : (
            placePredictions &&
            placePredictions.length > 0 &&
            !hasChosenAddress &&
            placePredictions.map(
              (
                prediction: google.maps.places.AutocompletePrediction,
                idx: number,
              ) => {
                return (
                  <div
                    key={idx}
                    className={styles.addressResult}
                    onClick={() => handlePredictionClick(prediction)}
                  >
                    <div className={styles.addressResultIcon} />
                    <div className={styles.addressResultDescription}>
                      {prediction.description}
                    </div>
                  </div>
                )
              },
            )
          )}
        </div>
      </div>
    </div>
  )
}
