import Checkbox from '@mui/material/Checkbox'
import React, { useState, useContext } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import { sendCustomerPaymentMethodUpdate } from '../../../../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

export default function SendCcUpdate(props: any) {
  const { id } = useParams()
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthenticator((context) => [context.user])

  const { setToastStatus, toastStatus } = useContext(ToastNotificationContext)

  const handleSendUpdate = async () => {
    setIsLoading(true)
    try {
      if (!id) {
        throw new Error('There is no orderId in the url.')
      }
      await fetchData<void>(sendCustomerPaymentMethodUpdate(id))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Email and text sent to customer! ',
        severity: 'success',
      })
      props.handleClose()
    } catch (error) {
      console.error(error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error sending email and text to customer! ',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          value={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div>Yes I want to send customer the update link</div>
      </div>
      {isLoading && (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Send'
          disabled={!isChecked || isLoading}
          onClick={() => handleSendUpdate()}
        />
      </div>
    </div>
  )
}
