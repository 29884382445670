export const getServicedZipCodes = () => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips`,
  }
}
export const exportServicedZips = (params: any) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/reportsV2/serviced-zips-report`,
    params,
  }
}
