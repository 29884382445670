import { useState, useEffect, useContext } from 'react'
import { Column } from 'react-table'
import styles from './styles.module.css'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Table from '../../../../global/components/table/Table'
import TableTopper from '../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../global/components/table/helpers/table-title/TableTitle'
import { ToastNotificationContext } from '../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../global/utils/fetch'
import {
  exportCsvToS3,
  getFileUploadCredentials,
  processFile,
  refreshTableauData,
} from '../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { handleColumnFilterUpdate } from '../../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../../global/components/table/helpers/retrieveData'
import generateColumns from './helpers/generate-columns/generate-columns'
import getRequestInformation from './helpers/get-request-information'
import saveEditedRow from './helpers/save-edited-row'
import deleteRow from './helpers/delete-row'
import {
  BI_DATA_WAREHOUSE_TABLE_NAMES,
  BI_REPORT_MODAL_DISPLAY_MODES,
  BiReportModalDisplayModes,
} from '../../../../global/constants/bi-reports'
import createRow from './helpers/create-row'
import saveNewRow from './helpers/save-new-row'
import getS3, { S3TemporaryCredentials } from '../../../../global/aws'

interface IBiReportModalProps {
  entityName: string
}

const { EDIT, DELETE } = BI_REPORT_MODAL_DISPLAY_MODES

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  DTC_STORE,
  DTMI_WORK_ORDER,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
  TRMI_WORK_ORDER,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

export const ENTITIES_AVAILABLE_FOR_INDIVIDUAL_OPERATIONS = [
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  DTC_STORE,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
]

export default function BiReportModal(props: IBiReportModalProps) {
  const { entityName } = props
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [row, setRow] = useState<JSX.Element | null>(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1)
  const [mode, setMode] = useState<BiReportModalDisplayModes>(EDIT)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [columnFilters, setColumnFilters] = useState({})
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    count: 0,
    pageSize: 10,
    offSet: 0,
  })

  const shouldDisableAddEntryButton =
    !ENTITIES_AVAILABLE_FOR_INDIVIDUAL_OPERATIONS.includes(entityName)
  const shouldDisableCSVUpload = [DTMI_WORK_ORDER, TRMI_WORK_ORDER].includes(
    entityName,
  )

  const { endpoint, entityNameQueryValue, sortBy } =
    getRequestInformation(entityName)
  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      entityName: entityNameQueryValue,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortBy: sortBy,
      columnFilters,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [entityName, tableState.pageSize, tableState.offSet])

  async function uploadToS3(file: any, credentials: S3TemporaryCredentials) {
    const { bucket, key } = credentials.s3

    const s3 = getS3(credentials)

    const uploadParams = {
      Bucket: bucket,
      Key: key,
      Body: file,
    }

    await s3.upload(uploadParams).promise()

    return key
  }

  async function uploadCsvToS3(file: any) {
    const fileName = file.name

    const isInvalidFileName =
      typeof fileName !== 'string' || fileName.length === 0
    if (isInvalidFileName) {
      throw new Error('File name is not a string')
    }

    const csvUploadCredentials = await fetchData<S3TemporaryCredentials>(
      getFileUploadCredentials(entityName, fileName),
    )

    return await uploadToS3(file, csvUploadCredentials)
  }

  async function handleFileInput(e: any) {
    setIsDataLoading(true)
    try {
      const fileKey = await uploadCsvToS3(e.target.files[0])
      const key = fileKey.split('/')[1]
      const hasProcessingStarted = await fetchData<boolean>(
        processFile(entityName, key),
      )

      if (hasProcessingStarted) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: [
            'File uploaded successfully! You will be emailed when the change',
            'is visible in Tableau.',
          ].join(' '),
          severity: 'info',
        })
      } else {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: [
            'An unknown error occurred. Please try again. If the problem',
            'persists, contact support.',
          ].join(' '),
          severity: 'error',
        })
      }
    } catch (error: any) {
      console.error(error)

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: error.message ?? '',
        severity: 'error',
      })
    } finally {
      // clear the file input so the same file can be uploaded again
      e.target.value = null
      setIsDataLoading(false)
    }
  }

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  function handleEditRow(rowIndex: number) {
    setSelectedRowIndex(rowIndex)
    setMode(EDIT)
  }
  function handleCancel() {
    setSelectedRowIndex(-1)
    setRow(null)
    retrieveTableData()
  }
  function handleDelete(rowIndex: number) {
    setSelectedRowIndex(rowIndex)
    setMode(DELETE)
  }
  function handleDeleteConfirmed(rowIndex: number) {
    deleteRow(
      entityName,
      rowIndex,
      tableState,
      toastStatus,
      setToastStatus,
      retrieveTableData,
    )
    setSelectedRowIndex(-1)
  }
  function handleSaveEditRow(rowIndex: number) {
    saveEditedRow(
      entityName,
      rowIndex,
      tableState,
      toastStatus,
      setToastStatus,
      setSelectedRowIndex,
      retrieveTableData,
    )
  }
  function handleSaveNewRow(entityData: any) {
    saveNewRow(
      entityName,
      entityData,
      toastStatus,
      setToastStatus,
      retrieveTableData,
      setRow,
    )
  }
  function handleChange(
    newValue: string | boolean,
    valueName: string,
    rowIndex: number,
  ) {
    setTableState((prevState: any) => {
      const prevData = prevState.data
      const rowToModify = prevData[rowIndex]

      // prevData now contains the modified row
      prevData[rowIndex] = { ...rowToModify, [valueName]: newValue }

      return { ...prevState, data: prevData }
    })
  }

  let columns: Column<any>[] = generateColumns(
    entityName,
    onColumnFilterUpdate,
    selectedRowIndex,
    mode,
    handleCancel,
    handleDelete,
    handleDeleteConfirmed,
    handleSaveEditRow,
    handleEditRow,
    handleChange,
    retrieveTableData,
  )

  function addRow() {
    try {
      setRow(createRow(entityName, handleSaveNewRow, handleCancel))
    } catch (error: any) {
      console.error(error)

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: error.message,
        severity: 'error',
      })
    }
  }

  async function handleExportCsvToS3() {
    setIsExportLoading(true)
    try {
      const response = await fetchData(exportCsvToS3(entityName))

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: [
          'Export started. You will receive an email with a link to download',
          'the file when it is complete.',
        ].join(' '),
        severity: 'info',
      })
    } catch (error: any) {
      console.error(error)

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: error.message,
        severity: 'error',
      })
    } finally {
      setIsExportLoading(false)
    }
  }

  async function handleRefreshTableauData() {
    try {
      const response = await fetchData(refreshTableauData(entityName))

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: [
          'Refresh started. You will receive an email when the data is',
          'visible in Tableau.',
        ].join(' '),
        severity: 'info',
      })
    } catch (error: any) {
      console.error(error)

      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <div className={styles.modalWrapper}>
      <TableTopper>
        <TableTitle style={{ whiteSpace: 'nowrap' }}>{entityName}</TableTitle>
        <div className={styles.buttonCell}>
          {!shouldDisableAddEntryButton && (
            <>
              <button
                style={{ whiteSpace: 'nowrap' }}
                onClick={addRow}
                className={styles.buttonText}
              >
                Add Entry
              </button>
              <div className={styles.line} />
            </>
          )}
          {!shouldDisableCSVUpload && (
            <>
              <input
                style={{ display: 'none' }}
                id='fileUploadCreds'
                className={styles.uploadedFile}
                onChange={handleFileInput}
                type='file'
              />
              <label
                htmlFor='fileUploadCreds'
                style={{ whiteSpace: 'nowrap' }}
                className={styles.uploadButton}
              >
                Upload File
              </label>
              {isDataLoading && <CircularProgress size={20} />}
              <div className={styles.line} />
            </>
          )}
          <button
            style={{ whiteSpace: 'nowrap' }}
            onClick={handleExportCsvToS3}
            className={styles.buttonText}
          >
            Export CSV
          </button>
          {isExportLoading && <CircularProgress size={20} />}
          <div className={styles.line} />
          <button
            style={{ whiteSpace: 'nowrap' }}
            onClick={handleRefreshTableauData}
            className={styles.buttonText}
          >
            Refresh Tableau Data
          </button>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data}
        isLoading={tableState.isLoading}
        isPaginated
        pagination={{ setTableState, tableState }}
        customRow={row}
        maxHeight='94%'
      />
    </div>
  )
}
