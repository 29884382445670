import React, { ChangeEvent, useState, useEffect, useContext } from 'react'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import ScheduleModal from '../schedule-modal/components/container-modal/ScheduleModal'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useFetchNoEffect } from '../../../global/hooks/useFetchNoUseEffectHook'
import { getFirstAvailableApp, isLocationServicedCheck } from '../api'
import ScheduleFilterContainer from '../technician-table/header-container/schedule-filter-container/ScheduleFilterContainer'
import { IScheduleFilter } from '../main/ScheduleMain'
import hasPermission from '../../../global/utils/user/has-permission'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
interface IGetNextAvailableDateResponse {
  nextAvailableDate: string
}
interface IAvailabilityCheckResponse {
  serviced: boolean
}
interface INewAppAndScheduleHeaderProps {
  filterInfo: IScheduleFilter
}

export default function SchedulePageHeaders(
  props: INewAppAndScheduleHeaderProps,
) {
  const { user } = useContext(UserContext)
  const { filterInfo } = props
  const navigate = useNavigate()
  const [zipCodeInput, setZipCodeInput] = useState<string>('')
  const [shouldDisableNewAptButton, setShouldDisableNewAptButton] =
    useState<boolean>(false)
  const [open, setOpen] = useState(false)
  function handleOpen() {
    navigate('customer-info')
    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
    navigate('/')
  }

  const { handleFetchData: getNextAvailApp, response: nextAvailableResponse } =
    useFetchNoEffect<IGetNextAvailableDateResponse>(
      getFirstAvailableApp(zipCodeInput),
    )
  const { handleFetchData: isLocationServiced, response: isServicedResponse } =
    useFetchNoEffect<IAvailabilityCheckResponse>(
      isLocationServicedCheck(zipCodeInput),
    )
  const { isLoading, data } = nextAvailableResponse
  const { data: isServicedData } = isServicedResponse

  useEffect(() => {
    if (zipCodeInput.length === 5) {
      getNextAvailApp()
      isLocationServiced()
      localStorage.setItem('zipCode', zipCodeInput)
    } else if (zipCodeInput.length !== 5) {
      setShouldDisableNewAptButton(false)
      localStorage.removeItem('zipCode')
    }
  }, [zipCodeInput])

  useEffect(() => {
    const shouldEnableNewAptButton =
      isServicedData?.serviced === true &&
      hasPermission(user).allow(
        PERMISSIONS.SCHEDULE_VIEW.CATEGORY,
        PERMISSIONS.SCHEDULE_VIEW.ENTRIES.CAN_SCHEDULE.NAME,
      )

    setShouldDisableNewAptButton(!shouldEnableNewAptButton)
  }, [isServicedData])

  const zipCodeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let zipCodeValue = event.target.value
    const maxZipCodeLength = 5
    zipCodeValue = zipCodeValue.replace(/[^0-9]/g, '')
    if (zipCodeValue.length > maxZipCodeLength) {
      zipCodeValue = zipCodeValue.slice(0, maxZipCodeLength)
    }

    setZipCodeInput(zipCodeValue)
  }

  const nextAvailable = isLoading
    ? 'Loading...'
    : data
      ? data.nextAvailableDate
      : ''

  //Feedback text color logic
  const avail = zipCodeInput.length === 5 ? styles.availableText : null

  return (
    <div className={styles.pageHeaderContainer}>
      <ScheduleFilterContainer filterInfo={filterInfo} />
      <div className={styles.makeAppContainer}>
        <div className={`${styles.nextAvailableText} ${avail}`}>
          Next available date: {zipCodeInput.length === 5 ? nextAvailable : ''}
        </div>
        <div className={styles.inputsContainer}>
          <TextField
            name='zipcode'
            sx={{ width: '113px' }}
            label='Zip'
            className={styles.zipInput}
            onChange={zipCodeHandler}
            value={zipCodeInput}
          />

          <PrimaryButton
            buttonName='New Appointment'
            disabled={shouldDisableNewAptButton}
            onClick={handleOpen}
          />
        </div>
        <ScheduleModal
          isOpen={open}
          handleClosed={handleClosed}
          chosenAddress={null}
        />
      </div>
    </div>
  )
}
