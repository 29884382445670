import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

export interface SelectListObject {
  name?: string
  reason?: string
  objectId: string | number
}

export interface SelectProps {
  data: Array<SelectListObject>
  label: string
  width: string
  onChange?: Function
  size?: 'small'
  defaultValue?: number | string
  name?: string
  disabled?: boolean
}

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export function Select(props: SelectProps) {
  let menuItems = props.data.map((item: SelectListObject) => {
    return (
      <MenuItem
        key={item.objectId}
        value={item.objectId}>
        {item.name ? item.name : item.reason}
      </MenuItem>
    )
  })

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return (
    <div>
      <TextField
        style={{ width: props.width, background: '#FFFFFF' }}
        disabled={props.disabled}
        label={props.label}
        size={props.size || 'medium'}
        value={props.defaultValue}
        name={props.name}
        onChange={onChange}
        select
        SelectProps={selectProps}>
        {menuItems}
      </TextField>
    </div>
  )
}
