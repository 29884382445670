import { MouseEventHandler } from "react"
import styles from "./styles.module.css"

export interface TireSizeButtonProps {
	inches: string
	frontSize: string
	rearSize: string
	id: number
	onClick: Function
	setSelectedItem: Function
	selected: number
	item: any
}

export function TireSizeButton(props: TireSizeButtonProps) {
	let borderStyles = {}

	if (props.id === props.selected) {
		borderStyles = styles.tireSizeButtonRadioBorder1
	} else {
		borderStyles = styles.tireSizeButtonRadioBorder2
	}

	const click = () => {
		props.onClick(props.id)
		props.setSelectedItem((prevState: any) => ({
			...prevState,
			frontTire: {
				...prevState.frontTire,
				aspectRatio: props.item.frontTire.aspectRatio,
				width: props.item.frontTire.width,
				diameter: props.item.frontTire.diameter,
			},
			rearTire: {
				...prevState.rearTire,
				aspectRatio: props.item.rearTire.aspectRatio,
				width: props.item.rearTire.width,
				diameter: props.item.rearTire.diameter,
			},
		}))
	}

	return (
		<div
			onClick={click}
			className={styles.tireSizeButton}>
			<div className={`${styles.tireSizeButtonRadio} ${borderStyles}`}></div>
			<div className={styles.tireSizeButtonInches}>{props.inches}</div>
			<div className={styles.tireSizeButtonTextContainer}>
				<div className={styles.tireSizeButtonTextInnerContainer}>
					<div className={styles.tireSizeButtonTextType}>Front:</div>
					<div className={styles.tireSizeButtonTextValue}>{props.frontSize}</div>
				</div>
				<div className={styles.tireSizeButtonTextInnerContainer}>
					<div className={styles.tireSizeButtonTextType}>Rear:</div>
					<div className={styles.tireSizeButtonTextValue}>{props.rearSize}</div>
				</div>
			</div>
		</div>
	)
}
