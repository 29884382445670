import Checkbox from '@mui/material/Checkbox'
import React, { useState, useContext } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { splitTires } from './api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../../context/context'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

interface ISplitTiresProps {
  handleClose: Function
}
export default function SplitTires(props: ISplitTiresProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const orderId = id ?? ''

  const handleSplitTires = async () => {
    try {
      setIsLoading(true)
      await fetchData<void>(splitTires(orderId))
      await queryOrderData(orderId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Tires have been split!',
        severity: 'success',
      })
    } catch (err: any) {
      console.error(err.message)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Could not split tires. May already be split.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to split the tires associated with this order</div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Change'
          disabled={!isChecked || isLoading}
          onClick={handleSplitTires}
        />
      </div>
    </div>
  )
}
