import { PostalAddress } from '../../../../../../../app/types/scheduling'

export const getBrands = () => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/brands`,
  }
}

export const getBrandYears = (brand: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/years?brand=${brand}`,
  }
}

export const getModels = (brand: string, year: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/models?brand=${brand}&year=${year}`,
  }
}

export const getVariants = (brand: string, year: string, model: string) => {
  const encodedModel = encodeURIComponent(model)
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/variants?brand=${encodeURIComponent(brand)}&year=${encodeURIComponent(year)}&model=${encodedModel}`,
  }
}

export const getAvailabilityCalendar = (
  address: any,
  numberOfTires: number,
  serviceObjectId: string,
  fromDateOverride?: number,
) => {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setUTCDate(today.getUTCDate() + 1)
  tomorrow.setUTCHours(0, 0, 0, 0)

  const fromDate = Number(fromDateOverride || tomorrow.getTime())

  return {
    method: 'POST',
    body: {
      address: address,
      numberOfTires: numberOfTires,
      serviceObjectIds: [serviceObjectId],
      fromDate,
    },
    url: `${process.env.REACT_APP_ORIGIN}/scheduling/available-calendar`,
  }
}
export const getAvailableCalendarForOrder = (
  orderId: string,
  showAdditionalDates?: boolean,
) => {
  const query: string = showAdditionalDates
    ? `?showAdditionalDates=${showAdditionalDates}`
    : ''
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/available-calendar${query}`,
  }
}

export const updateCustomer = (body: any) => {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/customers`,
  }
}

export const updateCustomerVehicle = (body: any) => {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/customer_vehicles`,
  }
}
export const createCustomerVehicle = (body: any, customerId: string) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/customer_vehicles/${customerId}`,
    body: body,
  }
}

export const setCustomerVehicle = (
  orderId: string,
  vehicleObjectId: string,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/bulk`,
    body: [vehicleObjectId],
  }
}

export const createInstallationPoint = (body: any) => {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/installation_point`,
  }
}

export const setInstallationPoint = (
  installationPoint: any,
  orderId: string,
) => {
  return {
    method: 'POST',
    body: installationPoint,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/installation-point`,
  }
}
export const updateInstallationPoint = (body: any) => {
  return {
    method: 'PUT',
    body,
    url: `${process.env.REACT_APP_ORIGIN}/installation_point`,
  }
}
export const getStateByZip = (zipCode: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/states`,
  }
}

export const getAddressValidation = (postalAddress: PostalAddress) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/scheduling/address-validation`,
    body: postalAddress,
  }
}
