import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import retrieveData from '../../../../../global/components/table/helpers/retrieveData'
import { fetchData } from '../../../../../global/utils/fetch'
import {
  createLocationRuleForLocationByRule,
  createLocationServicesForLocationByService,
} from './api'
import ToastNotification, {
  IAlertStatusProps,
} from '../../../../../global/components/toast-alert/ToastNotification'
import { ILocationServiceOrRule } from '../../../../../../../app/types/location-service-or-rule'
import isEntityALocationService from '../../../../table/utils/is-entity-a-location-service'
import { ILocationService } from '../../../../../../../app/entities/LocationService'
import { ILocationRule } from '../../../../../../../app/entities/LocationRule'

interface IConfirmModalProps {
  isOpen: boolean
  handleClosed: Function
  handleFormClosed: Function
  formData: any
  setFormData: Function
  chosenLocationServiceOrRule: ILocationServiceOrRule
}

export default function ConfirmPriceChangeModal({
  isOpen,
  handleClosed,
  handleFormClosed,
  formData,
  chosenLocationServiceOrRule,
}: IConfirmModalProps) {
  const isAService = isEntityALocationService(chosenLocationServiceOrRule)

  const [alertStatus, setAlertStatus] = useState<IAlertStatusProps>({
    isOpen: false,
    message: '',
    severity: 'info',
  })

  const query = new URLSearchParams({
    locationObjectId: chosenLocationServiceOrRule?.location?.objectId || '',
    serviceObjectId: chosenLocationServiceOrRule?.service?.objectId || '',
    ruleName: chosenLocationServiceOrRule?.name || '',
  })

  const [{ data: existingLocationServices }, setExistingLocationServices] =
    useState<any>({})

  const entityString = isAService ? 'service' : 'rule'

  const getLocationServiceOrRuleEndpoint = `${process.env.REACT_APP_ORIGIN}/location_${entityString}?${query}&`
  useEffect(() => {
    retrieveData(setExistingLocationServices, {
      endpoint: getLocationServiceOrRuleEndpoint,
      dataTransformer: (data: any) => data,
    })
  }, [getLocationServiceOrRuleEndpoint])

  const mostRecentLocationServiceOrRule = existingLocationServices?.sort(
    (a: ILocationServiceOrRule, b: ILocationServiceOrRule) =>
      b.created - a.created,
  )[0]

  //query locationService for price comparison
  const handleClosing = () => {
    handleClosed()
    handleFormClosed()
  }

  const CurrentPrice = () => (
    <b>
      $
      {isAService
        ? mostRecentLocationServiceOrRule.price
        : mostRecentLocationServiceOrRule.value}
    </b>
  )

  const ServiceName = () => (
    <b>
      {isAService
        ? chosenLocationServiceOrRule?.service?.name
        : (chosenLocationServiceOrRule as ILocationRule).name}
    </b>
  )

  const NewLocationServiceWarning = () => (
    <i>This is a new LocationService (ensure all details are correct)</i>
  )

  async function handleCreateLocationService() {
    try {
      await fetchData<ILocationService>(
        createLocationServicesForLocationByService({
          locationObjectId:
            chosenLocationServiceOrRule?.location?.objectId || '',
          serviceObjectId: chosenLocationServiceOrRule?.service?.objectId || '',
          price: formData.price,
          created: formData.created,
        }),
      )
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Location Service created!',
        severity: 'success',
      })
      setTimeout(handleClosing, 1000)
    } catch (error) {
      console.error('Error creating LocationService:', error)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Location Service not created, please try again.',
        severity: 'error',
      })
    }
  }

  async function handleCreateLocationRule() {
    try {
      await fetchData<ILocationService>(
        createLocationRuleForLocationByRule({
          locationObjectId:
            chosenLocationServiceOrRule?.location?.objectId || '',
          ruleName: chosenLocationServiceOrRule?.name || '',
          value: formData.price,
          created: formData.created,
        }),
      )
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Location Rule created!',
        severity: 'success',
      })
      setTimeout(handleClosing, 1000)
    } catch (error) {
      console.error('Error creating LocationRule:', error)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Location Rule not created, please try again.',
        severity: 'error',
      })
    }
  }

  return (
    <div>
      <ToastNotification
        severity={alertStatus.severity}
        open={alertStatus.isOpen}
        message={alertStatus.message}
        handleClose={() => setAlertStatus({ ...alertStatus, isOpen: false })}
      />
      <Modal
        open={isOpen}
        onClose={() => handleClosing()}
      >
        <Box className={styles.makePriceChangeModal}>
          <div className={`${styles.priceChangeModalPageTitle} font--bold`}>
            Confirm Price Change
          </div>
          <div className={styles.priceComparison}>
            <div>
              Location: <b>{chosenLocationServiceOrRule?.location?.name}</b>
            </div>
            <div>Service: {ServiceName()}</div>
            <div>
              Current Price:{' '}
              {mostRecentLocationServiceOrRule ? (
                <>{CurrentPrice()}</>
              ) : (
                <>{NewLocationServiceWarning()}</>
              )}
            </div>
            <div>
              New Price: <b>${formData.price}</b>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => handleClosed()}
            />
            <PrimaryButton
              buttonName='Confirm'
              onClick={
                isAService
                  ? handleCreateLocationService
                  : handleCreateLocationRule
              }
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
