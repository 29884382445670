import MainPageWrapper from '../../../global/components/MainPageWrapper'
import { WoDetailContextProvider } from '../../../work-orders/components/context/context'
import RescheduleQueueTable from '../tables/RescheduleQueueTable'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { useState } from 'react'
import QueueChooser from './QueueChooser'
import TireRackDataTable from '../tables/TireRackDataTable'

export interface ILocationFilters {
  locationFilter: string
  setLocationFilter: Function
}

interface IQueuesMainProps {
  queueType?: 'reschedule' | 'tire-rack-data'
}

function QueuesMain({ queueType }: IQueuesMainProps) {
  const [locationFilter, setLocationFilter] = useState('')
  let table = null

  if (queueType === 'reschedule') {
    table = (
      <RescheduleQueueTable
        locationFilters={{ locationFilter, setLocationFilter }}
      />
    )
  } else if (queueType === 'tire-rack-data') {
    table = (
      <TireRackDataTable
        locationFilters={{ locationFilter, setLocationFilter }}
      />
    )
  }

  return (
    <WoDetailContextProvider>
      <MainPageWrapper
        title='Queues'
        titleFields={
          <div style={{ marginRight: 'auto', marginLeft: '1rem' }}>
            <LocationDropDown
              onLocationChange={(val: string) => {
                setLocationFilter(val)
              }}
            />
          </div>
        }
      >
        <QueueChooser />
        {table}
      </MainPageWrapper>
    </WoDetailContextProvider>
  )
}

export default QueuesMain
