import { useContext, useState } from 'react'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import MakePriceChangeModal from './modal/MakePriceChangeModal'
import { ILocationServiceOrRule } from '../../../../../app/types/location-service-or-rule'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
import hasPermission from '../../../global/utils/user/has-permission'

interface IMakePriceChangeProps {
  chosenLocationServiceOrRule: ILocationServiceOrRule
  refetchLocationServicesHistory: Function
}

export default function MakePriceChange({
  chosenLocationServiceOrRule,
  refetchLocationServicesHistory,
}: IMakePriceChangeProps) {
  const { user } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  async function handleClosed() {
    await refetchLocationServicesHistory()
    setIsOpen(false)
  }

  return (
    <div>
      <MakePriceChangeModal
        isOpen={isOpen}
        handleClosed={handleClosed}
        chosenLocationServiceOrRule={chosenLocationServiceOrRule}
      />
      <PrimaryButton
        buttonName='Make Price Change'
        onClick={() => setIsOpen(true)}
        disabled={
          !hasPermission(user).allow(
            PERMISSIONS.SERVICES.CATEGORY,
            PERMISSIONS.SERVICES.ENTRIES.MAKE_PRICE_CHANGE_BUTTON.NAME,
          )
        }
      />
    </div>
  )
}
