import styles from "./styles.module.css"
interface IWorkingDaySettingIconProps {
	isWorking: boolean
	isDaySetting: boolean
}
export default function WorkingDaySettingIcon({isWorking, isDaySetting}: IWorkingDaySettingIconProps) {
	const className = [
		styles.technicianAvailability,
		isDaySetting ? styles.daySetting : '',
		isWorking ? styles.working : '',
	].join(' ')

	return <div className={className}></div>
}
