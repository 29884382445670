import { useState } from 'react'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import TechniciansTable from '../table/TechniciansTable'
import { TechnicianModal } from '../technician-modal/components/container-modal/TechnicianModal'
import TechnicianPageHeader from './new-technician/TechnicianPageHeader'

export interface IDefaultLocationTimesProps {
  locationId: string | null
  defaultWorkDayFrom: string | null
  defaultWorkDayTo: string | null
  defaultLunchFrom: string | null
  defaultLunchTo: string | null
}

export interface IWeekTypeFilters {
  weekTypeFilter: string
  setWeekTypeFilter: Function
}

function TechniciansMain() {
  const [open, setOpen] = useState({
    isOpen: false,
    techId: '',
  })
  const [locationFilter, setLocationFilter] = useState('')
  const [weekTypeFilter, setWeekTypeFilter] = useState('odd')

  const handleClosed = () => {
    setOpen({ isOpen: false, techId: '' })
  }

  return (
    <MainPageWrapper
      titleFields={
        <TechnicianPageHeader
          locationFilters={{ locationFilter, setLocationFilter }}
          weekTypeFilters={{ weekTypeFilter, setWeekTypeFilter }}
        />
      }>
      <TechnicianModal
        techId={open.techId}
        isOpen={open.isOpen}
        handleClosed={handleClosed}
      />
      <TechniciansTable
        setOpen={setOpen}
        locationFilters={{ locationFilter, setLocationFilter }}
        weekTypeFilters={{ weekTypeFilter, setWeekTypeFilter }}
      />
    </MainPageWrapper>
  )
}

export default TechniciansMain
