import { useState } from 'react'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { fetchData } from '../../global/utils/fetch'
import { runRouteOptimizationForLocation, testORTools } from '../api'
import { IRoute } from '../../../../app/entities/Route'

export default function RouteOptimizationCorrector() {
  const [form, setForm] = useState({
    date: '',
    locationObjectId: '',
  })

  const handleRunROForLocation = async () => {
    const { date, locationObjectId } = form
    if (!date || !locationObjectId)
      return alert('Please select a date and location')

    try {
      const res = await fetchData<IRoute[]>(
        runRouteOptimizationForLocation({
          locationId: locationObjectId,
          date: new Date(date).setUTCHours(0, 0, 0, 0).valueOf(),
        }),
      )
      console.log(res)
      alert('Ran RO for location')
    } catch (error) {
      console.error(error)
      alert('Error running RO for location')
    }
  }

  async function handleTestORTools() {
    const { date, locationObjectId } = form
    if (!date || !locationObjectId)
      return alert('Please select a date and location')

    try {
      const res = await fetchData<IRoute[]>(
        testORTools({
          locationId: locationObjectId,
          date: new Date(date).setUTCHours(0, 0, 0, 0).valueOf(),
        }),
      )
      console.log(res)
      alert('Ran OR tools test')
    } catch (error) {
      console.error(error)
      alert('Error running test for OR tools')
    }
  }

  return (
    <div>
      <h2>Routing Stuff</h2>
      <div className={styles.inputsContainer}>
        <LocationDropDown
          onLocationChange={(locationObjectId: any) =>
            setForm({ ...form, locationObjectId })
          }
        />
        <TextField
          type='date'
          label='Date'
          name='date'
          value={form.date}
          onChange={(e) => setForm({ ...form, date: e.target.value })}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <PrimaryButton
          buttonName='Run RO for location'
          onClick={handleRunROForLocation}
        />
        <PrimaryButton
          buttonName='Test OR Tools'
          onClick={handleTestORTools}
        />
      </div>
    </div>
  )
}
