export default function formatPrice(price?: number | string): string | undefined {
  if (typeof price === 'string') {
    price = Number.parseFloat(price)

  }
  if (typeof price !== 'number') {
    price = Number(price)
  }

  if (isNaN(price)) {
    return 'NaN'
  }

  return `$${price.toFixed(2)}`
}