import { InstallationPoint } from '../../../../../../../../../app/entities/InstallationPoint'

export const changeAddress = (body: Partial<InstallationPoint> & { orderObjectId: string }) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/change-address`,
		body,
	}
}

export const checkIfCanMoveOrderWithoutRescheduling = (
	orderObjectId: string,
	newInstallationPoint: Partial<Pick<InstallationPoint, 'city' | 'address' | 'state' | 'zipCode' | 'aptOrSuite'>>,
) => {
	const query = new URLSearchParams({
		newInstallationPoint: JSON.stringify(newInstallationPoint),
	})

	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/can-move-address-without-rescheduling?${query.toString()}`,
	}
}