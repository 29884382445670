const TIME_SLOT_TYPES = {
  MORNING  : 'morning',
  AFTERNOON: 'afternoon',
  EVENING  : 'evening',
  ANYTIME  : 'anytime',
  FIXED    : 'fixed',
}

const TIME_SLOT = {
  [TIME_SLOT_TYPES.MORNING]: {
    from12Hrs: '09:00:00 AM',
    from24Hrs: '09:00:00',
    to12Hrs  : '12:00:00 PM',
    to24Hrs  : '12:00:00'
  },
  [TIME_SLOT_TYPES.AFTERNOON]: {
    from12Hrs: '12:00:00 PM',
    from24Hrs: '12:00:00',
    to12Hrs  : '03:00:00 PM',
    to24Hrs  : '15:00:00'
  },
  [TIME_SLOT_TYPES.EVENING]: {
    from12Hrs: '03:00:00 PM',
    from24Hrs: '15:00:00',
    to12Hrs  : '06:00:00 PM',
    to24Hrs  : '18:00:00'
  },
  [TIME_SLOT_TYPES.ANYTIME]: {
    from12Hrs: '09:00:00 AM',
    from24Hrs: '09:00:00',
    to12Hrs  : '06:00:00 PM',
    to24Hrs  : '18:00:00'
  },
  [TIME_SLOT_TYPES.FIXED]: {
    from12Hrs: '09:00:00 AM',
    from24Hrs: '09:00:00',
    to12Hrs  : '06:00:00 PM',
    to24Hrs  : '18:00:00'
  }
}

const STANDART_TIME_SLOT_TYPES = [
  TIME_SLOT_TYPES.MORNING,
  TIME_SLOT_TYPES.AFTERNOON,
  TIME_SLOT_TYPES.EVENING,
]

export {
  STANDART_TIME_SLOT_TYPES,
  TIME_SLOT_TYPES,
  TIME_SLOT,
}