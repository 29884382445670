import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../global/components/buttons/secondary-button/SecondaryButton'
import { useEffect } from 'react'

function QueueChooser() {
  const navigate = useNavigate()

  const links = [
    {
      name: 'Reschedule',
      path: '/queues/reschedule',
    },
    {
      name: 'Wholesale Purchase',
      path: '/queues/tire-rack-data',
    },
  ]

  const activePath = useLocation().pathname

  const noActivePath = activePath === '/queues'

  useEffect(() => {
    if (noActivePath) {
      const lastQueuePath = localStorage.getItem('lastQueuePath')

      navigate(lastQueuePath || '/queues/reschedule')
    } else {
      const pathToSave =
        links.find((link) => link.path === activePath)?.path ||
        '/queues/reschedule'

      localStorage.setItem('lastQueuePath', pathToSave)
    }
  }, [noActivePath, navigate])

  return (
    <div className={styles.buttonsHolder}>
      {links.map((link, idx) =>
        link.path === activePath ? (
          <Link
            className={styles.link}
            to={link.path}
            key={idx}
          >
            <PrimaryButton
              buttonName={link.name}
              onClick={() => {}}
              style={{ width: 'max-content', height: 'max-content' }}
            >
              {link.name}
            </PrimaryButton>
          </Link>
        ) : (
          <Link
            className={styles.link}
            to={link.path}
            key={idx}
          >
            <SecondaryButton
              buttonName={link.name}
              onClick={() => {}}
              style={{ width: 'max-content', height: 'max-content' }}
            >
              {link.name}
            </SecondaryButton>
          </Link>
        ),
      )}
    </div>
  )
}

export default QueueChooser
