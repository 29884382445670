import { useAuthenticator } from '@aws-amplify/ui-react'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IOrderVehicle } from '../../../../../../../../../app/entities/OrderVehicle'
import { ITiresCombination } from '../../../../../../../../../app/entities/TiresCombination'
import DeleteButton from '../../../../../../../global/components/buttons/delete-button/DeleteButton'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getOrderVehiclesById } from '../../../../../api'
import { WoDetailContext } from '../../../../../context/context'
import styles from '../styles/styles.module.css'
import {
  deleteOrderVehicle,
  setVehicleTiresCombination,
  updateVehicleMileage,
} from './api'

interface ITire {
  objectId: string
  model: string | null
  width: string
  manufacturer: string | null
  torque: number | null
  diameter: number | null
  aspectRatio: number | null
  psi: number | null
}

interface IVehicleData {
  objectId: string
  orderVehicleObjectId: string
  rearCount: number | null
  frontCount: number | null
  mileage: number | null
  orderVehicleId: string
  tires: {
    rearTire: ITire
    frontTire: ITire
  }
}

interface IEditVehicleModalProps {
  handleClose: Function
  triggerRerender: (() => void) | undefined
  isDeleteOrderVehicleButtonVisible?: boolean
  orderVehicleObjId?: string
}

export default function EditVehicleModal(props: IEditVehicleModalProps) {
  const { handleClose, isDeleteOrderVehicleButtonVisible, orderVehicleObjId } =
    props
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData, data: orderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [vehicleData, setVehicleData] = useState<IVehicleData>({
    objectId: '',
    rearCount: null,
    frontCount: null,
    mileage: null,
    orderVehicleId: '',
    orderVehicleObjectId: '',
    tires: {
      rearTire: {
        objectId: '',
        model: '',
        width: '',
        manufacturer: '',
        torque: null,
        diameter: null,
        aspectRatio: null,
        psi: null,
      },
      frontTire: {
        objectId: '',
        model: '',
        width: '',
        manufacturer: '',
        torque: null,
        diameter: null,
        aspectRatio: null,
        psi: null,
      },
    },
  })
  const { id } = useParams()
  const uniformWidth = {
    width: 300,
  }
  const { data, isLoading } = useFetch<IOrderVehicle[]>(
    getOrderVehiclesById(id),
  )
  useEffect(() => {
    if (data && data.length > 0) {
      const { tiresCombination, mileage, objectId, orderVehicleId } = data[0]
      if (tiresCombination) {
        const { rearTire, frontTire, frontCount, rearCount } = tiresCombination

        setVehicleData({
          objectId: tiresCombination.objectId,
          rearCount,
          frontCount,
          mileage,
          orderVehicleId: orderVehicleId,
          orderVehicleObjectId: objectId,
          tires: {
            rearTire: {
              objectId: rearTire?.objectId || '',
              model: rearTire?.model || '',
              width: rearTire?.width || '',
              manufacturer: rearTire?.manufacturer || '',
              torque: rearTire?.torque || null,
              diameter: rearTire?.diameter || null,
              aspectRatio: rearTire?.aspectRatio || null,
              psi: rearTire?.psi || null,
            },
            frontTire: {
              objectId: frontTire?.objectId || '',
              model: frontTire?.model || '',
              width: frontTire?.width || '',
              manufacturer: frontTire?.manufacturer || '',
              torque: frontTire?.torque || null,
              diameter: frontTire?.diameter || null,
              aspectRatio: frontTire?.aspectRatio || null,
              psi: frontTire?.psi || null,
            },
          },
        })
      }
    }
  }, [data])

  const {
    tires: { frontTire, rearTire },
  } = vehicleData
  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }
  const handleChangeFrontTire = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    let updatedValue = Number(value)
    if (name === 'count') {
      setVehicleData((prevState: IVehicleData) => ({
        ...prevState,
        frontCount: updatedValue,
      }))
    }
    setVehicleData((prevState: IVehicleData) => ({
      ...prevState,
      tires: {
        ...prevState.tires,
        frontTire: {
          ...prevState.tires.frontTire,
          [name]: updatedValue,
        },
      },
    }))
  }
  const handleChangeRearTire = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    let updatedValue = name === 'width' ? value : Number(value)
    if (name === 'count') {
      setVehicleData((prevState: IVehicleData) => ({
        ...prevState,
        rearCount: Number(value),
      }))
    }
    setVehicleData((prevState: IVehicleData) => ({
      ...prevState,
      tires: {
        ...prevState.tires,
        rearTire: {
          ...prevState.tires.rearTire,
          [name]: updatedValue,
        },
      },
    }))
  }

  const handleOtherFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const updatedValue =
      name === 'mileage' || name === 'torque' ? Number(value) : value

    setVehicleData((prevState: IVehicleData) => ({
      ...prevState,
      mileage: name === 'mileage' ? Number(value) : prevState.mileage,
      tires: {
        ...prevState.tires,
        frontTire: {
          ...prevState.tires.frontTire,
          [name]: updatedValue,
        },
        rearTire: {
          ...prevState.tires.rearTire,
          [name]: updatedValue,
        },
      },
    }))
  }
  //
  const handleUpdateVehicle = async () => {
    try {
      setDisableSaveButton(true)
      await fetchData<ITiresCombination>(
        setVehicleTiresCombination(
          vehicleData,
          orderData.objectId,
          orderVehicleObjId || '',
        ),
      )
      await fetchData<void>(
        updateVehicleMileage(
          vehicleData.orderVehicleId,
          Number(vehicleData.mileage),
        ),
      )
      if (id) {
        queryOrderData(id)
      }
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Vehicle info successfully modified!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating vehicle info.',
        severity: 'error',
      })
    } finally {
      setDisableSaveButton(false)
    }
  }

  const handleDeleteVehicle = async () => {
    try {
      setDisableDeleteButton(true)
      await fetchData<IOrderVehicle>(deleteOrderVehicle(orderVehicleObjId))
      if (id) {
        queryOrderData(id)
      }
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Vehicle successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There has been an error deleting Vehicle ${vehicleData.orderVehicleId}.`,
        severity: 'error',
      })
    } finally {
      setDisableDeleteButton(false)
    }
  }

  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={styles.inputsContainer}>
      <div style={{ display: 'flex', gap: 24 }}>
        <div className={styles.topInputsContainerLineItem}>
          <div className={styles.notesTitle}>Front Tires</div>
          <TextField
            InputLabelProps={{ shrink: true }}
            label='Count'
            name='count'
            sx={uniformWidth}
            value={vehicleData.frontCount || ''}
            onChange={handleChangeFrontTire}
          />
          <TextField
            label='Width'
            name='width'
            sx={uniformWidth}
            value={frontTire.width || ''}
            onChange={handleChangeFrontTire}
          />
          <TextField
            label='Aspect Ratio'
            name='aspectRatio'
            sx={uniformWidth}
            value={frontTire.aspectRatio || ''}
            onChange={handleChangeFrontTire}
          />
          <TextField
            label='Diameter'
            name='diameter'
            sx={uniformWidth}
            value={frontTire.diameter || ''}
            onChange={handleChangeFrontTire}
          />
          <TextField
            label='Psi'
            name='psi'
            sx={uniformWidth}
            value={frontTire.psi || ''}
            onChange={handleChangeFrontTire}
          />

          {isDeleteOrderVehicleButtonVisible ? (
            <div className={styles.checkBoxContainer}>
              <Checkbox onChange={() => setIsChecked(!isChecked)} />
              <div>Yes I want to delete this vehicle from the Work Order</div>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <div className={styles.topInputsContainerLineItem}>
          <div className={styles.notesTitle}>Rear Tires</div>
          <TextField
            InputLabelProps={{ shrink: true }}
            label='Count'
            name='count'
            sx={uniformWidth}
            value={vehicleData.rearCount || ''}
            onChange={handleChangeRearTire}
          />
          <TextField
            label='Width'
            name='width'
            sx={uniformWidth}
            value={rearTire.width || ''}
            onChange={handleChangeRearTire}
          />
          <TextField
            label='Aspect Ratio'
            name='aspectRatio'
            sx={uniformWidth}
            value={rearTire.aspectRatio || ''}
            onChange={handleChangeRearTire}
          />
          <TextField
            label='Diameter'
            name='diameter'
            sx={uniformWidth}
            value={rearTire.diameter || ''}
            onChange={handleChangeRearTire}
          />
          <TextField
            label='Psi'
            name='psi'
            sx={uniformWidth}
            value={rearTire.psi || ''}
            onChange={handleChangeRearTire}
          />
        </div>
        <div className={styles.topInputsContainerLineItem}>
          <div className={styles.notesTitle}>Other</div>
          <TextField
            label='Torque'
            name='torque'
            sx={uniformWidth}
            value={rearTire.torque || frontTire.torque || ''}
            onChange={handleOtherFormChange}
          />
          <TextField
            label='Tire Make'
            name='manufacturer'
            sx={uniformWidth}
            value={rearTire.manufacturer || frontTire.manufacturer || ''}
            onChange={handleOtherFormChange}
          />
          <TextField
            label='Tire Model'
            name='model'
            sx={uniformWidth}
            value={rearTire.model || frontTire.model || ''}
            onChange={handleOtherFormChange}
          />
          <TextField
            label='Vehicle Mileage'
            name='mileage'
            sx={uniformWidth}
            value={vehicleData.mileage || ''}
            onChange={handleOtherFormChange}
          />
        </div>
      </div>
      {isDeleteOrderVehicleButtonVisible ? (
        <div className={styles.deleteButtonVisibleFooterContainer}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DeleteButton
              buttonName='Delete'
              onClick={handleDeleteVehicle}
              disabled={!isChecked || disableDeleteButton}
            />
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => handleClose()}
            />
          </div>
          <div>
            <PrimaryButton
              buttonName='Save'
              onClick={handleUpdateVehicle}
              disabled={disableSaveButton}
            />
          </div>
        </div>
      ) : (
        <div className={styles.deleteButtonInvisibleFooterContainer}>
          <SecondaryButton
            buttonName='Cancel'
            onClick={() => handleClose()}
          />
          <PrimaryButton
            buttonName='Save'
            onClick={handleUpdateVehicle}
            disabled={disableSaveButton}
          />
        </div>
      )}
    </div>
  )
}
