enum MODES {
  // used when the an Order has been created from a Tire Rack order
  // and will be completed via CS Portal
  SCHEDULER = 'scheduler',
  FINISH = 'finish',
  EDIT = 'edit',
  CUSTOMER = 'customer',
  CUSTOMER_RESCHEDULE = 'customer-reschedule',
  TR_SALES_BOOKING = 'tr-sales-booking',
}

enum PAGES {
  CUSTOMER_INFORMATION = 'Customer Information',
  CHOOSE_A_SERVICE = 'Choose a Service',
  APPOINTMENT_SLOT = 'Appointment Slot',
  PAYMENT_INFORMATION = 'Payment Information',
  CONFIRM_APPOINTMENT = 'Confirm Appointment',
}

export { MODES, PAGES }
