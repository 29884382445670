import { Dispatch, SetStateAction, Suspense, useRef, useState } from 'react'
import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { AppointmentSlots } from '../../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { MODES } from '../../../../global/constants/scheduler'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import styles from './styles.module.css'
import moment from 'moment'
import ConfirmTrSalesOrderModal from '../confirm-order-modal/confirmOrderModal'
import { CircularProgress } from '@mui/material'
import { setShouldRequestPaymentAfterScheduling } from '../../../../schedule/components/schedule-modal/components/payment-information/api'
import { fetchData } from '../../../../global/utils/fetch'
import { toggleTireDisposal } from '../../../../schedule/components/schedule-modal/components/order-summary/api'
import { IOrder } from '../../../../../../app/entities/Order'
import {
  getOrderByOrderId,
  setTimeSlot,
} from '../../../../schedule/components/schedule-modal/components/appointment-slot/api'
import { AvailabilityCalendarSlot } from '../../../../../../app/types/scheduling'

interface ITrSchedulerComponentProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Dispatch<SetStateAction<ISchedulerStateProps>>
  isAddressUpdating: boolean | undefined
  setTrNumber: Function
}

const TrScheduler = (props: ITrSchedulerComponentProps) => {
  const { schedulerState, setSchedulerState, isAddressUpdating, setTrNumber } =
    props
  const [isAdditionalDates, setIsAdditionalDates] = useState(false)
  const [isSlotNotSelected, setIsSlotNotSelected] = useState(true)
  const [isConfirmTrSaleOrderModalOpen, setIsConfirmTrSaleOrderModalOpen] =
    useState(false)
  const ref = useRef<any>(null)
  const isConfirmDisabled = !(!isSlotNotSelected && !isAddressUpdating)

  const confirmAppointment = async () => {
    try {
      //%%% Customer Information %%%
      // this information is recieved in the webhook
      // from TireRack. We do not need to add it to
      // the order. It is in the scheduler state.

      //%%% Tire Size Quantity %%%
      // this part of the flow adds the invoice to the order
      await fetchData(
        toggleTireDisposal(
          schedulerState.orderId,
          schedulerState.orderVehicleId,
          schedulerState.tireDisposal,
          schedulerState.disposalQty,
        ),
      )

      //%%% Appointment Slots %%%
      await fetchData<IOrder>(getOrderByOrderId(schedulerState.orderId))
      const { timeSlot } =
        schedulerState.selectedAvailabilityCalendarSlot as AvailabilityCalendarSlot
      await fetchData<IOrder>(setTimeSlot(timeSlot, schedulerState.orderId))
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        shouldRequestPaymentAfterScheduling: true,
        freeOrderReason: {
          objectId: '',
          reason: '',
          isFree: false,
        },
      }))
      await fetchData(
        setShouldRequestPaymentAfterScheduling(schedulerState.orderId, true),
      )
    } catch {
      throw new Error('Something went wrong. Please try again.')
    } finally {
      setIsConfirmTrSaleOrderModalOpen(true)
    }

    //%%% Payment Information %%%
    // this part of the flow does not add anything to the order

    //%%% Appointment Confirmation %%%
    // this part of the flow is handled in the confirmOrderModal
  }

  const fromDateOverride = moment()
    .utc()
    .add(2, 'weeks')
    .startOf('day')
    .valueOf()

  const seeAdditionalDates = () => {
    setSchedulerState((prevState) => ({
      ...prevState,
      availableCalenderFromDateOverride: fromDateOverride,
      showAdditionalDates: true,
    }))
    setIsAdditionalDates(true)
  }

  const resetAdditionalDates = () => {
    setSchedulerState((prevState) => ({
      ...prevState,
      availableCalenderFromDateOverride: undefined,
      showAdditionalDates: false,
    }))
    setIsAdditionalDates(false)
  }

  const handleClosed = () => {
    setIsConfirmTrSaleOrderModalOpen(false)
  }

  return (
    <div className={styles.calendarContainer}>
      <div>
        <div className={styles.dateText}>
          See calendar for available appointments:
          <div className={styles.prevNextContainer}>
            <div
              className={`${styles.blueLinkButton} ${!isAdditionalDates ? styles.disabled : ''}`}
              onClick={isAdditionalDates ? resetAdditionalDates : undefined}
            >
              {'< Prev'}
            </div>
            <div
              className={`${styles.blueLinkButton} ${isAdditionalDates ? styles.disabled : ''}`}
              onClick={!isAdditionalDates ? seeAdditionalDates : undefined}
            >
              {'Next >'}
            </div>
          </div>
        </div>
        <AppointmentSlots
          schedulerState={schedulerState}
          ref={ref}
          setSchedulerState={setSchedulerState}
          setDisabled={setIsSlotNotSelected}
          mode={MODES.TR_SALES_BOOKING}
          toggleToastError={() => {}}
        />
      </div>
      <PrimaryButton
        buttonName='Schedule Appointment'
        onClick={confirmAppointment}
        disabled={isConfirmDisabled}
      />
      {isConfirmTrSaleOrderModalOpen && (
        <Suspense fallback={<CircularProgress />}>
          <div>
            <ConfirmTrSalesOrderModal
              isOpen={isConfirmTrSaleOrderModalOpen}
              handleClosed={handleClosed}
              schedulerState={schedulerState}
              setSchedulerState={setSchedulerState}
              setTrNumber={setTrNumber}
            />
          </div>
        </Suspense>
      )}
    </div>
  )
}

export default TrScheduler
