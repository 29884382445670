import styles from './styles.module.css'
import TechRouteCard from '../../../../../global/components/cards/technician/technician-route-card/TechRouteCard'
import TechAvatarCard from '../../../../../global/components/cards/technician/technician-avatar-card/TechAvatarCard'
import { IExtendedOrder, IGetTechScheduleRouteArea } from '../TechTableContainer'

interface TechTableRowProps {
  techRoute: IGetTechScheduleRouteArea
  techAvatar: string
  date: string
  triggerRerender: () => void
}

export default function TechTableRow(props: TechTableRowProps) {
  const { date, techRoute, techAvatar, triggerRerender } = props
  const {
    schedule: { morning, afternoon, evening },
    blank,
    location,
  } = techRoute

  const morningRoutes =
    morning !== null && morning[0] !== null
      ? morning.filter(Boolean).map((routeInfo: IExtendedOrder, index: number) => {
          return (
            <TechRouteCard
              key={index}
              order={routeInfo}
              location={location}
            />
          )
        })
      : null

  const afternoonRoutes =
    afternoon !== null && afternoon[0] !== null
      ? afternoon.filter(Boolean).map((routeInfo: IExtendedOrder, index: number) => {
          return (
            <TechRouteCard
              key={index}
              order={routeInfo}
              location={location}
            />
          )
        })
      : null

  const eveningRoutes =
    evening !== null && evening[0] !== null
      ? evening.filter(Boolean).map((routeInfo: IExtendedOrder, index: number) => {
          return (
            <TechRouteCard
              key={index}
              order={routeInfo}
              location={location}
            />
          )
        })
      : null

  return (
    <div
      className={`${styles.rowContainer} ${blank ? styles.blank : ''}`}
      style={{
        height: blank ? 'auto' : 'auto',
      }}>
      <div className={styles.techInfo}>
        <TechAvatarCard
          date={date}
          techAvatar={techAvatar}
          techInfo={techRoute}
          triggerRerender={triggerRerender}
          blank={blank}
        />
      </div>
      <div className={styles.timeSlot}>{morningRoutes}</div>
      <div className={styles.timeSlot}>{afternoonRoutes}</div>
      <div className={styles.timeSlot}>{eveningRoutes}</div>
    </div>
  )
}
