export function getFuturePercentBookedData(csv?: boolean) {
  let url = `${process.env.REACT_APP_ORIGIN}/reportsV2/future-percent-booked`
  if (csv) {
    url += `?csv=${csv}`
  }
  return {
    method: "GET",
    url
  }
}
