import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { updateBrainTreeToken } from './api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useParams } from 'react-router-dom'
import { WoDetailContext } from '../../../../../context/context'
import { UpdateResult } from 'typeorm'
interface IUpdateBrainTreeModalProps {
  handleClose: Function
}
export default function UpdateBtToken(props: IUpdateBrainTreeModalProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData } = useContext(WoDetailContext)
  const { id } = useParams()
  const orderId = id ?? ''
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const handleUpdateBrainTreeToken = async () => {
    try {
      setIsLoading(true)
      await fetchData<UpdateResult>(updateBrainTreeToken(orderId, token))
      await queryOrderData(orderId)
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>
        <TextField
          label='Updated BT Token'
          fullWidth
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </div>
      <div className={styles.braintreeText}>
        *Leave blank to clear BT token*
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to change the Braintree Method Token</div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Change'
          disabled={!isChecked || isLoading}
          onClick={handleUpdateBrainTreeToken}
        />
      </div>
    </div>
  )
}
