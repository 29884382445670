import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import retrieveData from '../../../../../../global/components/table/helpers/retrieveData'
import { CasesModal } from './cases-modal/components/container-modal/CasesModal'
import { ICase } from '../../../../../../../../app/entities/Case'

export interface IEditCaseDataData {
  orderObjectId: string
  caseObjectId: string
}

interface ICasesTableProps {
  isReadOnly: boolean
}

export default function CasesTable({ isReadOnly }: ICasesTableProps) {
  const { id } = useParams()
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [isNewCase, setIsNewCase] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [editCaseData, setEditCaseData] = useState<IEditCaseDataData>({
    orderObjectId: '',
    caseObjectId: '',
  })
  const [refreshKey, setRefreshKey] = useState(0)
  const handleOpen = (e: any) => {
    setIsNewCase(true)
    setModalOpen(true)
  }
  const handleClosed = () => {
    setEditCaseData({
      orderObjectId: '',
      caseObjectId: '',
    })
    setModalOpen(false)
  }
  const onRecordClickHandler = (row: any) => {
    const { original } = row
    setIsNewCase(false)
    setEditCaseData((prevState) => ({
      ...prevState,
      orderObjectId: original.orderObjectId,
      caseObjectId: original.objectId,
    }))
    setModalOpen(true)
  }

  const endpoint = `${process.env.REACT_APP_ORIGIN}/cases/byOrderId/workOrderDetail?`

  useEffect(() => {
    if (isOpen) {
      retrieveData(setTableState, {
        endpoint,
        pageSize: 10,
        offSet: 0,
        orderObjectId: id,
      })
    }
  }, [isOpen, refreshKey])

  const columns: Column<ICase>[] = [
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Damage Cost',
      accessor: 'damageCost',
    },
    {
      Header: 'Summary',
      accessor: 'summary',
    },
    {
      Header: 'Open Date',
      accessor: (data: any) => {
        const date = data.openDate?.split('T')[0]
        return date || ''
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Status Date',
      accessor: (data: any) => {
        const date = data.statusDate?.split('T')[0]
        return date || ''
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
    },
    {
      Header: 'Assigned To',
      accessor: (aCase: any) => aCase.assignedToName,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <CasesModal
        isOpen={modalOpen}
        handleClosed={handleClosed}
        editCaseData={editCaseData}
        triggerRefresh={() => setRefreshKey(refreshKey + 1)}
        isNewCase={isNewCase}
      />
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Cases</TableTitle>
          <button
            onClick={handleOpen}
            style={{
              all: 'unset',
              cursor: isReadOnly ? 'not-allowed' : 'pointer',
              color: isReadOnly ? '#999' : '#d70000',
            }}
            disabled={isReadOnly}
          >
            Add New Case
          </button>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
