import { useState } from 'react'
import styles from './styles.module.css'
import { Box, Modal } from '@mui/material'
import { BI_DATA_WAREHOUSE_TABLE_NAMES } from '../../../global/constants/bi-reports'
import BiReportModal from './modal/BiReportModal'

export default function BiReports() {
  const [entityModalOpen, setEntityModalOpen] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState('')

  function onReportClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const entityName = e.currentTarget.textContent
    setEntityModalOpen(true)
    setSelectedEntity(entityName ?? '')
  }

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.containerHeading}>Reports</div>
        <div className={styles.reportUploadsContainer}>
          <Modal
            open={entityModalOpen}
            onClose={() => {
              setEntityModalOpen(false)
            }}
          >
            <Box className={styles.biReportModal}>
              <BiReportModal entityName={selectedEntity} />
            </Box>
          </Modal>

          {Object.values(BI_DATA_WAREHOUSE_TABLE_NAMES).map(
            (entityName, idx) => (
              <div
                className={styles.link}
                onClick={(e) => onReportClicked(e)}
                key={`${idx}-${entityName}`}
              >
                {entityName}
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  )
}
