import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import CircularProgress from '@mui/material/CircularProgress'
import { useFetchNoEffect } from '../../../../../global/hooks/useFetchNoUseEffectHook'
import { deleteTechnician } from '../../api'
interface IAdjustInvoiceProps {
  isOpen: boolean
  handleClosed: Function
  techId: string | null
}
export default function DeleteConfirmationModal(props: IAdjustInvoiceProps) {
  const handleClosing = () => {
    props.handleClosed()
  }

  let { handleFetchData: deleteTech, response } = useFetchNoEffect(
    deleteTechnician(props.techId),
  )

  const handleDelete = async () => {
    response.isLoading = true
    await deleteTech()
    response.isLoading = false
  }

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => props.handleClosed()}
      >
        <Box className={styles.deleteTechModal}>
          <div className={`${styles.deleteTechModalPageTitle} font--bold`}>
            Are you sure you want to delete this technician?
          </div>

          {response.isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {response.error && (
            <div className={styles.errorMessage}>Error deleting technician</div>
          )}
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={handleClosing}
            />
            <PrimaryButton
              disabled={response.isLoading}
              buttonName='Yes, delete'
              onClick={() => handleDelete()}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
