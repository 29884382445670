import { useEffect } from "react"

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"

import { useNavigate, useLocation } from "react-router"

export function Login() {
	const { route } = useAuthenticator((context) => [context.route])
	const location = useLocation()
	const navigate = useNavigate()
	let from = location.state?.from?.pathname || "/"
	useEffect(() => {
		if (route === "authenticated") {
			navigate(from, { replace: true })
		}
	}, [route, navigate, from])
	return (
		<div className="auth-wrapper">
			<Authenticator socialProviders={["google"]}></Authenticator>
		</div>
	)
}
