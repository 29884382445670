import { useEffect, useState } from 'react'
import { IOrder } from '../../../../../app/entities/Order'
import styles from '../styles.module.css'
import { TYPES } from '../../../global/constants/invoice'
import { fetchData } from '../../../global/utils/fetch'
import { getPicturesByIds } from '../../api'
import { TechnicianPicturesUrls } from '../../../../../app/eworkflows/technician/get-pictures'
import techAvatarIcon from '../../../assets/images/tech-avatar-icon.svg'

interface TechnicianDisplayProps {
  order: IOrder | null
}

export default function TechnicianDisplay(props: TechnicianDisplayProps) {
  const { order } = props
  const [technicianProfilePictureUrl, setTechnicianProfilePictureUrl] =
    useState('')
  const [technicianName, setTechnicianName] = useState('')

  useEffect(() => {
    // If there is a technician assigned via route instructions, the order will be serviced tomorrow
    const routeInstruction = order?.routeInstructions[0]
    const route = routeInstruction?.route
    const technician = route?.technician
    fetchTechnicianProfilePicture(technician?.objectId ?? '')

    setTechnicianName(technician?.name ?? '')
  }, [order])

  async function fetchTechnicianProfilePicture(technicianObjectId: string) {
    const results = await fetchData<TechnicianPicturesUrls[]>(
      getPicturesByIds([technicianObjectId]),
    )
    if (results && results.length > 0) {
      setTechnicianProfilePictureUrl(results[0].profilePictureURL || '')
    }
  }

  return (
    <>
      {technicianName ? (
        <div className={styles.technicianPictureContainer}>
          <div>Meet Your Technician</div>
          <img
            src={
              technicianProfilePictureUrl !== ''
                ? technicianProfilePictureUrl
                : techAvatarIcon
            }
            alt='Your Technician'
            className={styles.technicianPicture}
          />
          <div>{technicianName}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
