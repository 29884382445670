import { ITabulationArea } from '../../../../../../../../app/entities/TabulationArea'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getStateByZip } from '../api'

export const queryStateByZip = async (zipCode: string) => {
  try {
    const state = await fetchData<ITabulationArea[]>(getStateByZip(zipCode))
    if (state[0] === null) {
      return 'NOT SERVICED'
    }
    return state[0].stateAbbr
  } catch (err) {
    console.error(err)
    return ''
  }
}
