import { useContext, useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import Table from '../../global/components/table/Table'
import LocationServiceOrRuleRecordsModal from './location-service-or-rule-records-modal/LocationServiceOrRuleRecordsModal'
import retrieveData from '../../global/components/table/helpers/retrieveData'
import getMostRecentLocationServicesOrRules from './utils/get-most-recent-location-services-or-rules'
import { ILocationFilters } from '../../work-orders/components/main/WorkOrdersMain'
import { IServiceFilters } from '../main/ServicesMain'
import { ILocationRule } from '../../../../app/entities/LocationRule'
import isEntityALocationService from './utils/is-entity-a-location-service'
import { NAMES } from '../../global/constants/location-rule'
import { ILocationServiceOrRule } from '../../../../app/types/location-service-or-rule'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import hasPermission from '../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../global/constants/permissions'

export interface ILocationServiceProps {
  locationFilters: ILocationFilters
  serviceFilters: IServiceFilters
}

function LocationServicesTable(props: ILocationServiceProps) {
  const { user } = useContext(UserContext)
  const { serviceFilters, locationFilters } = props
  const { serviceFilter } = serviceFilters
  const { locationFilter } = locationFilters
  const [servicesTableState, setServicesTableState] = useState({
    data: [],
    isLoading: false,
  })
  const [rulesTableState, setRulesTableState] = useState({
    data: [],
    isLoading: false,
  })

  const isViewServiceHistoryDisabled = !hasPermission(user).allow(
    PERMISSIONS.SERVICES.CATEGORY,
    PERMISSIONS.SERVICES.ENTRIES.VIEW_SERVICE_HISTORY.NAME,
  )

  const mostRecentLocationServices = getMostRecentLocationServicesOrRules(
    servicesTableState.data,
  )
  const mostRecentLocationRules = getMostRecentLocationServicesOrRules(
    rulesTableState.data,
  )

  const mostRecentLocationServicesOrRules = [
    ...mostRecentLocationServices,
    ...mostRecentLocationRules,
  ]
    .sort((a, b) => {
      const aName = a.service?.name || a.name
      const bName = b.service?.name || b.name

      return aName.localeCompare(bName)
    })
    .sort((a, b) => {
      const aLocation = a.location?.name || ''
      const bLocation = b.location?.name || ''

      return aLocation.localeCompare(bLocation)
    })

  const [recordsModalOpen, setRecordsModalOpen] = useState(false)

  const [chosenLocationServiceOrRule, setChosenLocationServiceOrRule] =
    useState<ILocationServiceOrRule | null>(null)

  const query = new URLSearchParams({
    locationObjectId: locationFilter,
    serviceObjectId: serviceFilter,
  })

  const servicesEndpoint = `${process.env.REACT_APP_ORIGIN}/location_service?${query.toString()}&`

  function locationServicesDataRetrieval() {
    retrieveData(setServicesTableState, {
      endpoint: servicesEndpoint,
      dataTransformer: (data: any) => data,
    })
  }
  useEffect(locationServicesDataRetrieval, [locationFilter, serviceFilter])

  function locationRulesDataRetrieval() {
    let rulesEndpoint = `${process.env.REACT_APP_ORIGIN}/location_rule?`

    if (locationFilter) {
      rulesEndpoint += `locationObjectId=${locationFilter}&`
    }

    // we want to retrieve the Minimum Service Charge by default
    // or if it is explicitly selected
    if (!serviceFilter) {
      rulesEndpoint += `name=${NAMES.MINIMUM_SERVICE_CHARGE}&`
    } else {
      rulesEndpoint += `name=${serviceFilter}&`
    }

    retrieveData(setRulesTableState, {
      endpoint: rulesEndpoint,
      dataTransformer: (data: any) => data,
    })
  }

  useEffect(locationRulesDataRetrieval, [locationFilter, serviceFilter])

  async function handleClosed() {
    await locationServicesDataRetrieval()
    setRecordsModalOpen(false)
  }

  const columns: Column<ILocationServiceOrRule>[] = [
    {
      Header: 'Location',
      accessor: (sr: ILocationServiceOrRule) => sr.location?.name,
    },
    {
      Header: 'Service',
      accessor: (sr: ILocationServiceOrRule) => {
        const isAService = isEntityALocationService(sr)

        if (isAService) {
          return (
            sr.service?.name +
            (sr.service?.tireRackName ? ` (${sr.service.tireRackName})` : '')
          )
        } else {
          const ruleSr = sr as ILocationRule
          return ruleSr.name
        }
      },
    },
    {
      Header: 'Price',
      accessor: (sr: ILocationServiceOrRule) => {
        const isAService = isEntityALocationService(sr)

        if (isAService) {
          return '$' + sr.price
        } else {
          const ruleSr = sr as ILocationRule
          return '$' + ruleSr.value
        }
      },
    },
    {
      Header: 'Effective Date',
      accessor: ({ created }) =>
        [
          new Date(created).toLocaleDateString(),
          new Date(created).toLocaleTimeString(),
        ].join(' '),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      {chosenLocationServiceOrRule && (
        <LocationServiceOrRuleRecordsModal
          isOpen={recordsModalOpen}
          handleClosed={handleClosed}
          chosenLocationServiceOrRule={chosenLocationServiceOrRule}
        />
      )}
      <Table
        columns={columns}
        data={mostRecentLocationServicesOrRules ?? []}
        isLoading={servicesTableState.isLoading || rulesTableState.isLoading}
        onRowClick={
          isViewServiceHistoryDisabled
            ? () => {}
            : (row: Row) => {
                const original: any = row.original
                setChosenLocationServiceOrRule(original)
                setRecordsModalOpen(true)
              }
        }
        isDisplayMode
        noDataMessage='Choose a location to see services.'
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default LocationServicesTable
