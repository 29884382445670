import {
  availabilityReport,
  daysToAppointment,
  dvirReport,
  mileageReporting,
  preAppointmentCancellationAndRescheduleReport,
} from '../../../api'
import { ReportButtonProps } from '../DownloadReportsModal'

const quickReportButtons: ReportButtonProps[] = [
  {
    buttonName: '7 Day Dashboard (D2A Planning)',
    reportName: 'rpt_7dayDashboard',
    reportCall: daysToAppointment,
  },
  {
    buttonName: 'Availability Report',
    reportName: 'rpt_availabilityReport',
    reportCall: availabilityReport,
  },
  {
    buttonName: 'Cases Export',
    reportName: 'rpt_casesExport',
  },
  {
    buttonName: 'Pipeline Orders',
    reportName: 'rpt_trPipelineOrders',
  },
  {
    buttonName: 'Pipeline Quotes',
    reportName: 'rpt_trPipelineQuotes',
  },
  {
    buttonName: 'Technician Schedule Export',
    reportName: 'rpt_technicianScheduleExport',
  },
  {
    buttonName: "Upcoming Week's Summary",
    reportName: 'rpt_upcomingWeeksSummary',
  },
]

const quickReportButtonsWithDates: ReportButtonProps[] = [
  {
    buttonName: 'DVIR Report',
    reportName: 'rpt_dvirReport',
    reportCall: dvirReport,
  },
  {
    buttonName: 'Mileage Reporting',
    reportName: 'rpt_mileageReporting',
    reportCall: mileageReporting,
  },
  {
    buttonName: 'Install Timing',
    reportName: 'rpt_installTiming',
  },
  {
    buttonName: 'Integrated Scheduler Orders',
    reportName: 'rpt_integratedSchedulerOrders',
  },
  {
    buttonName: 'Onsite Cancellations',
    reportName: 'rpt_onsiteCancellations',
  },
  {
    buttonName: 'Pre-Appointment Cancellations and Rescheduled',
    reportName: 'rpt_preAppointmentCancellationsAndRescheduled',
    reportCall: preAppointmentCancellationAndRescheduleReport,
  },
  {
    buttonName: 'Quotes With Payment Information',
    reportName: 'rpt_quotesWithPaymentInformation',
  },
  {
    buttonName: 'Marketing Customers',
    reportName: 'rpt_customerMarketing',
  },
]

export { quickReportButtons, quickReportButtonsWithDates }
