import { ChangeEvent, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { IContactProps } from '../CustomerInformation'
import styles from './styles.module.css'
import { queryStateByZip } from './utils'
import { isFiveDigitZipCode } from '../../../../../../global/utils/formValidation'
import { MODES } from '../../../../../../global/constants/scheduler'

const { SCHEDULER, CUSTOMER, FINISH } = MODES

export default function InstallationAddressForm({
  formData,
  setFormData,
  error,
  mode,
}: IContactProps) {
  const [formError, setFormError] = useState({
    zipCode: false,
  })

  // we want to disable the installation point inputs unless it is a new order
  const installationPointInputsAreDisabled = ![
    SCHEDULER,
    CUSTOMER,
    FINISH,
  ].includes(mode)

  // const { formData, error } = props
  const localStorageZip = localStorage.getItem('zipCode')
  const updateCustInfoForm = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name
    const value = e.target.value
    // this is a way to speed up vehicle selection
    if (field === 'zipCode' && !isFiveDigitZipCode(value)) {
      setFormError((prevState: any) => ({
        ...prevState,
        zipCode: true,
      }))
    } else if (field === 'zipCode' && isFiveDigitZipCode(value)) {
      setFormError((prevState: any) => ({
        ...prevState,
        zipCode: false,
      }))
    }
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    fetchState()
  }, [formData.zipCode])

  const fetchState = async () => {
    if (formData.zipCode.length === 5) {
      const state = await queryStateByZip(formData.zipCode)
      setFormData({ ...formData, state: state })
    } else if (localStorageZip && !formData.state) {
      const state = await queryStateByZip(localStorageZip)
      setFormData({ ...formData, state: state })
    }
  }

  return (
    <div className={styles.contactFormContainer}>
      <div className={`${styles.contactTitle} font--bold`}>
        Installation Address
      </div>
      {installationPointInputsAreDisabled && (
        <div className='warning-message'>
          Looking to change the address? Use the "Change Address" button on the
          Work Order Details page.
        </div>
      )}
      <TextField
        className={styles.textfieldStyles}
        value={formData.address}
        error={error}
        type='text'
        name='address'
        label='Address'
        disabled={installationPointInputsAreDisabled}
        onChange={updateCustInfoForm}
      />
      <TextField
        className={styles.textfieldStyles}
        value={formData.aptOrSuite}
        type='text'
        label='address'
        name='aptOrSuite'
        disabled={installationPointInputsAreDisabled}
        onChange={updateCustInfoForm}
      />
      <TextField
        className={styles.textfieldStyles}
        value={formData.city}
        type='text'
        error={error}
        name='city'
        label='City'
        disabled={installationPointInputsAreDisabled}
        onChange={updateCustInfoForm}
      />
      <div className={styles.zipStateContainer}>
        <TextField
          value={formData.state}
          type='text'
          name='state'
          error={error}
          label='State'
          disabled={installationPointInputsAreDisabled}
          helperText={error ? 'Please enter a valid address' : ''}
          onChange={updateCustInfoForm}
        />
        <TextField
          type='tel'
          value={formData.zipCode}
          name='zipCode'
          error={error || formError.zipCode}
          helperText={
            error || formError.zipCode
              ? 'Please enter a valid 5-digit zip code'
              : ''
          }
          label='Zip'
          inputProps={{ maxLength: 5 }}
          sx={{ width: 113 }}
          disabled={installationPointInputsAreDisabled}
          onChange={updateCustInfoForm}
        />
      </div>
      <TextField
        className={styles.textfieldStyles}
        value={formData.additionalInfo}
        type='text'
        label='Additional Information'
        name='additionalInfo'
        rows={4}
        multiline
        onChange={updateCustInfoForm}
      />
    </div>
  )
}
