export const runRouteOptimizationForLocation = (body: {
  locationId: string
  date: number
}) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/test/route-optimization`,
    body,
  }
}

export function testORTools(body: { locationId: string; date: number }) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/test-or-tools`,
    body,
  }
}

export const getOrderByWorkOrderId = (workOrderId: number) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/order-by-work-order-id?workOrderId=${workOrderId}`,
  }
}

export const addCompletedInvoice = (body: any) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/add-completed-invoice`,
    body,
  }
}

export const getDriveTimeDetails = (
  locationObjectId: string,
  fromDate: string,
  toDate: string,
) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/drive-time-details?locationObjectId=${locationObjectId}&fromDate=${fromDate}&toDate=${toDate}`,
  }
}

export const updateRoutingAlgorithm = (
  routingAlgorithm: string,
  locationObjectId: string,
) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/update-routing-algorithm`,
    body: { routingAlgorithm, locationObjectId },
  }
}

export const migrateFutureRouteAreasToDayPlans = (locationObjectId: string) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/migrate-future-route-areas-to-day-plans`,
    body: { locationObjectId },
  }
}

export const migrateFutureDayPlansToRouteAreas = (locationObjectId: string) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/migrate-future-day-plans-to-route-areas`,
    body: { locationObjectId },
  }
}
