const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR
const HOURS_IN_DAY = 24

const EST_TO_UTC_OFFSET = 4

const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR

const SECONDS_IN_DAY = MINUTES_IN_DAY * SECONDS_IN_MINUTE

const MS_IN_DAY = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY

const DATE_TIME_FORMAT = 'DD.MM.YYYY hh:mm'

const DATE_FORMAT = 'L'

const TWELVE_HOURS_FORMAT = 'hh:mm a'

const ARRIVAL_DATE_FORMAT = 'dddd MMMM D'

const HOURS_FORMAT = 'HH:mm'

const USA_DATE_FORMAT = 'MM/DD/YYYY'

const FIRST_DAY_OF_WEEK_INDEX = 1

const LAST_DAY_OF_WEEK_INDEX = 7

const ORDER_ASSIGNMENT_START_HOUR = 15

const DAYS_IN_ONE_WEEK = 7

const BUSINESS_DAYS_IN_ONE_WEEK = 5

const DAYS_IN_TWO_WEEKS = 14

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const

const WEEK_NAMES = ['even', 'odd'] as const

type WeekDayName = (typeof DAYS_OF_WEEK)[number]
type WeekName = (typeof WEEK_NAMES)[number]

export {
  EST_TO_UTC_OFFSET,
  HOURS_FORMAT,
  USA_DATE_FORMAT,
  DAYS_OF_WEEK,
  ORDER_ASSIGNMENT_START_HOUR,
  FIRST_DAY_OF_WEEK_INDEX,
  LAST_DAY_OF_WEEK_INDEX,
  MILLISECONDS_IN_SECOND,
  SECONDS_IN_MINUTE,
  MINUTES_IN_HOUR,
  SECONDS_IN_HOUR,
  HOURS_IN_DAY,
  MINUTES_IN_DAY,
  SECONDS_IN_DAY,
  MS_IN_DAY,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TWELVE_HOURS_FORMAT,
  ARRIVAL_DATE_FORMAT,
  DAYS_IN_ONE_WEEK,
  BUSINESS_DAYS_IN_ONE_WEEK,
  DAYS_IN_TWO_WEEKS,
  WEEK_NAMES
}

export type {
  WeekName,
  WeekDayName
}

