import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import styles from './styles.module.css'

interface ISlotProps {
  onClick: Function
  selectedAvailabilityCalendarSlot?: AvailabilityCalendarSlot
  availabilityCalendarSlot: AvailabilityCalendarSlot
}

export function Slot({
  onClick,
  selectedAvailabilityCalendarSlot,
  availabilityCalendarSlot,
}: ISlotProps) {
  if (!availabilityCalendarSlot) return null

  const { available, timeSlot } = availabilityCalendarSlot

  const {
    fromTime: slotFromTime,
    toTime: slotToTime,
    type: slotType,
  } = timeSlot

  let clickHandler = () => {
    // attach time slot details so that the backend can use it to make a timeSlot
    onClick(availabilityCalendarSlot)
  }

  let backgroundColor = '#FFFFFF'

  if (!available) {
    clickHandler = () => {}
    backgroundColor = '#EEEEEE'
  }

  const isSlotSelected =
    selectedAvailabilityCalendarSlot?.fromTime ===
      new Date(slotFromTime).valueOf() &&
    // below prevents "anytime" and "morning" both appearing selected
    selectedAvailabilityCalendarSlot?.type === slotType

  if (isSlotSelected) {
    backgroundColor = '#FFE785'
  }

  // Only used for debugging
  // const { incomingPlannedRoutes } = timeSlot.routePlanning || {}
  // function getIncomingOrderTechnicianId() {
  //   let incomingTechnicianObjectId = ''

  //   Object.keys(incomingPlannedRoutes || {}).forEach((technicianId) => {
  //     if (incomingPlannedRoutes[technicianId].some((pathName) => pathName === 'incoming')) {
  //       incomingTechnicianObjectId = technicianId
  //     }
  //   })

  //   return incomingTechnicianObjectId
  // }

  function formatTime(time: Date | number) {
    return new Date(time).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    })
  }

  return (
    <div
      onClick={clickHandler}
      className={styles.slotCard}
      style={{ background: backgroundColor }}
    >
      <div className={styles.slotCardWindow}>{slotType}</div>
      <div className={styles.slotCardTimeRange}>
        {formatTime(slotFromTime || 0)} - {formatTime(slotToTime || 0)}
      </div>
      {/* <div className={styles.slotCardTimeRange}>{available ? getIncomingOrderTechnicianId() : ''}</div> */}
    </div>
  )
}
