import { SECONDS_IN_MINUTE } from './date'

const CALENDAR_RANGE = 1 // in month

const RESERVE_TIME_MINUTES = 15

const RESERVE_TIME = RESERVE_TIME_MINUTES * SECONDS_IN_MINUTE

const SOURCES = {
  internal: 'internal',
  external: 'external',
  tirerack: 'tirerack',
  customer_support: 'customer_support',
}

// This is a trimmed down version of the reschedule reasons in /app/constants/order-history.ts
// to prevent users from selecting reasons that are not applicable to the customer scheduler
const RESCHEDULE_REASONS = {
  bumped_by_system: 'Bumped by System',
  bumped_in_routing: 'Bumped in Routing',
  cs_portal_issue: 'CS Portal Issue',
  customer_requested: 'Customer Requested',
  quote_with_payment: 'Quote with Payment',
  sever_wether: 'Severe Weather',
  shipping_delay: 'Shipping Delay',
  task_appt_or_test: 'Task Appt / Test',
  tech_called_out: 'Tech Called Out',
  tech_day_setting_issue: 'Tech Day Setting Issue',
  tech_injured: 'Tech Injured / Sick',
  tech_late: 'Tech Late',
  tr_order_not_released: 'TR Order Not Released',
  van_down: 'Van Down',
  warehouse_issue: 'Warehouse Issue',
}

const CANCELLATION_REASONS = {
  customer_cancelled: 'Customer Cancelled',
  customer_unavailable: 'Customer Unavailable',
  on_site_cancellation: 'On-site Cancellation',
  completed: 'Completed',
  missed: 'Missed',
  other: 'Other',
  test: 'Test',
}

const CANCELLATION_DB_VALUES = {
  cancelled: 'cancelled',
  cancelled_on_site: 'cancelled_onsite',
}

export {
  RESERVE_TIME,
  CALENDAR_RANGE,
  SOURCES,
  RESCHEDULE_REASONS,
  CANCELLATION_REASONS,
  CANCELLATION_DB_VALUES,
}
