const DISCOUNT_TYPES = {
  Proximity        : 'Proximity Between Appointment',
  ExistingRouteArea: 'Existing Route Area Discount',
  ServiceWindow    : 'Service Window Discount',
  TimeSlotDiscount : 'TimeSlotDiscount',
  CSPortalDiscount : 'CSPortalDiscount',
  PromoCodeDiscount: 'PromoCodeDiscount',
}

const MEASURES = {
  PERCENTAGE: '%',
  CUSTOM    : ''
}

const discountTypes = [
  DISCOUNT_TYPES.ExistingRouteArea,
  DISCOUNT_TYPES.Proximity,
  DISCOUNT_TYPES.ServiceWindow,
]

const USER_SELECTABLE_TYPES = {
  DOLLAR: 'dollar',
  PERCENT: 'percent',
  PROMO: 'promo'
}

export {
  // discountTypes,
  DISCOUNT_TYPES,
  MEASURES,
  USER_SELECTABLE_TYPES
}