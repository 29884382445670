export const calculatePrice = (orderId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/calculate-price`,
  }
}

export const toggleTireDisposal = (orderId: string, orderVehicleId: string, toggle: boolean, disposalQty: number) => {
  let url = `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/${orderVehicleId}/tire-disposal`

  if (disposalQty) {
    url += `?disposalQuantity=${disposalQty}`
  }
  return {
    method: 'POST',
    body: toggle,
    url,
  }
}
