import { ChangeEvent, useEffect, useState } from 'react'
import { ITechFormProps } from '../container-modal/TechnicianEdit'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { uploadTechAvatar } from '../../api'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import PrimaryCheckbox from '../../../../../global/components/checkbox/Checkbox'
import techAvatarIcon from '../../../../../assets/images/tech-avatar-icon.svg'
import styles from './styles.module.css'
import { fileToDataUri } from '../utils'
import { ROUTING_ALGORITHMS } from '../../../../../global/constants/scheduling'
import {
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_HEIGHT,
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_WIDTH,
  TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_MB,
  TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES_ARRAY,
} from '../../../../../global/constants/technician'

export default function TechnicianBio(props: ITechFormProps) {
  const {
    name,
    email,
    bio,
    techAvatar,
    workDayFrom,
    workDayTo,
    lunchFrom,
    lunchTo,
    lunchDuration,
    defaultWorkDayFrom,
    defaultWorkDayTo,
    defaultLunchFrom,
    defaultLunchTo,
    defaultLunchDuration,
    useDefaultLunch,
    useDefaultWorkDay,
    locationRoutingAlgorithm,
  } = props.technicianFormInfo
  const { user } = useAuthenticator((context) => [context.user])
  const [isLoading, setIsLoading] = useState(false)

  const updateTechBio = (e: ChangeEvent<HTMLInputElement>) => {
    props.setTechnicianFormInfo({
      ...props.technicianFormInfo,
      [e.target.name]: e.target.value,
    })
  }
  const updateCheckStatus = (e: ChangeEvent<HTMLInputElement>) => {
    props.setTechnicianFormInfo({
      ...props.technicianFormInfo,
      [e.target.name]: e.target.checked,
    })
  }

  const handleImageFileInput = async (e: any) => {
    const idToken = user.getSignInUserSession()?.getIdToken()
    const formData = new FormData()
    const file = e.target.files[0]
    const fileType = file.type.split('/')[1]
    const image = new Image()

    if (!TECHNICIAN_PROFILE_IMAGE_INPUT_TYPES_ARRAY.includes(fileType)) {
      alert('You must upload a jpeg or png!')
      return
    }

    await fileToDataUri(file).then((dataUri) => {
      image.src = dataUri as string
    })
    if (
      image.height > TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_HEIGHT &&
      image.width > TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_WIDTH
    ) {
      alert(
        `Your image's height and width must be under ${TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_HEIGHT}px x ${TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_IMAGE_WIDTH}px!`,
      )
      return
    }
    if (file.size > TECHNICIAN_PROFILE_IMAGE_INPUT_MAX_SIZE_MB) {
      alert(`Your image must be under 3MB!`)
      return
    }

    formData.append('file', file)
    const input = uploadTechAvatar(
      props.technicianFormInfo.technicianId,
      formData,
    )

    try {
      setIsLoading(true)
      // Can't use fetchData(...) for this request since it uses FormData and
      // JSON.stringify won't work with it
      const resp = await fetch(input.url, {
        method: input.method,
        headers: {
          Authorization: `Bearer ${idToken?.getJwtToken()}` || '',
        },
        body: input.body,
      })
      if (!resp.ok) throw new Error(`Request failed with status ${resp.status}`)
      if (props.refetchAvatar) props.refetchAvatar()
    } catch (error: any) {
      console.log(error)
      alert(error)
      e.target.value = ''
    } finally {
      setIsLoading(false)
    }
  }
  const isValidTechnicianWorkTime = workDayTo > workDayFrom
  //default values - checks for checked status of checkbox
  const workDayFromValue = useDefaultWorkDay
    ? defaultWorkDayFrom
    : workDayFrom ?? ''
  const workDayToValue = useDefaultWorkDay ? defaultWorkDayTo : workDayTo ?? ''
  const lunchDayFromValue = useDefaultLunch ? defaultLunchFrom : lunchFrom ?? ''
  const lunchDayToValue = useDefaultLunch ? defaultLunchTo : lunchTo ?? ''
  const lunchDurationValue = useDefaultLunch
    ? defaultLunchDuration
    : lunchDuration ?? ''
  return (
    <div className={styles.mainContainer}>
      <div className={styles.avatarContainer}>
        <div className={styles.imageContainer}>
          <img
            src={techAvatar ? techAvatar : techAvatarIcon}
            alt='tech avatar'
          />
        </div>
        <input
          style={{ display: 'none' }}
          id='imageUpload'
          className={styles.uploadedFile}
          onChange={handleImageFileInput}
          type='file'
        />
        <label
          htmlFor='imageUpload'
          className={styles.uploadButton}
        >
          Upload Profile Image
        </label>
        {isLoading && <CircularProgress size={20} />}
      </div>
      <div className={styles.inputsContainer}>
        <TextField
          label='Name'
          name='name'
          value={name || ''}
          onChange={updateTechBio}
          fullWidth
        />
        <TextField
          label='Email'
          name='email'
          value={email || ''}
          onChange={updateTechBio}
          fullWidth
        />
        <TextField
          multiline
          rows={5}
          label='Bio'
          fullWidth
          name='bio'
          value={bio || ''}
          onChange={updateTechBio}
        />
        <div className={styles.hours}>
          <div className={styles.hoursHeading}>Working Hours</div>
          <div className={styles.useLocationDefault}>
            <PrimaryCheckbox
              name='useDefaultWorkDay'
              checked={useDefaultWorkDay}
              onChange={updateCheckStatus}
              label='Use Location Default'
            />
          </div>
          <div className={styles.hoursInputs}>
            <TextField
              InputLabelProps={{ shrink: true }}
              type='time'
              label='From'
              name='workDayFrom'
              value={workDayFromValue}
              onChange={updateTechBio}
              disabled={useDefaultWorkDay}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              type='time'
              label='To'
              name='workDayTo'
              value={workDayToValue}
              onChange={updateTechBio}
              disabled={useDefaultWorkDay}
            />
          </div>
          {!isValidTechnicianWorkTime && (
            <div className={styles.technicianTimeError}>
              End time must be after start time
            </div>
          )}
        </div>
        <div className={styles.hours}>
          <div className={styles.hoursHeading}>Lunch Duration</div>
          <div className={styles.useLocationDefault}>
            <PrimaryCheckbox
              name='useDefaultLunch'
              checked={useDefaultLunch}
              onChange={updateCheckStatus}
              label='Use Location Default'
            />
          </div>
          <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              type='number'
              label='Lunch Duration'
              name='lunchDuration'
              value={lunchDurationValue}
              onChange={updateTechBio}
              disabled={useDefaultLunch}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
