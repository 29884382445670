// Update the app/eworkflows/users/hasPermission.ts file when this file is updated
import { IPermission } from '../../../../../app/entities/Permission'
import { IUser } from '../../../../../app/entities/User'
import { ACTIONS } from '../../constants/permissions'
import getUserPermissions from './get'

function checkPermission(
  permissions: IPermission[],
  permissionCategory: string,
  permissionName: string,
  actions: string[],
): boolean {
  const hasPermission = permissions.some((permission) => {
    return (
      permission.category === permissionCategory &&
      permission.name === permissionName &&
      actions.includes(permission.action)
    )
  })
  return hasPermission
}

function hasPermission(input: IUser | IPermission[] | null) {
  const userPermissions: IPermission[] = []
  if (input instanceof Array) {
    userPermissions.push(...input)
  } else if (input !== null) {
    userPermissions.push(...(input.role?.permissions ?? []))
  }

  return {
    allow: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [ACTIONS.ALLOW]),
    edit: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [ACTIONS.EDIT]),
    view: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [
        ACTIONS.EDIT,
        ACTIONS.VIEW,
      ]),
  }
}

export default hasPermission
