import { IDtcStore } from '../../../../../../../../../bi/entities/DtcStore'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateDtcStoreColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Store Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ storeId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { storeId } = data
        return generateTextField(
          'storeId',
          storeId,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Sap Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ sapId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { sapId } = data
        return generateTextField(
          'sapId',
          sapId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Address',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ address: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { address } = data
        return generateTextField(
          'address',
          address,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'City',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ city: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { city } = data
        return generateTextField(
          'city',
          city,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'State',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ state: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { state } = data
        return generateTextField(
          'state',
          state,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Region',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ region: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { region } = data
        return generateTextField(
          'region',
          region,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Zip',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ zip: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { zip } = data
        return generateTextField(
          'zip',
          zip,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Store Open Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ storeOpenDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { storeOpenDate } = data
        return generateTextField(
          'storeOpenDate',
          storeOpenDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Store Close Date',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ storeCloseDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { storeCloseDate } = data
        return generateTextField(
          'storeCloseDate',
          storeCloseDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Brand',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ brand: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { brand } = data
        return generateTextField(
          'brand',
          brand,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR Cust 1',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ trCust1: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { trCust1 } = data
        return generateTextField(
          'trCust1',
          trCust1?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR Cust 2',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ trCust2: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtcStore, rowIndex: number) => {
        const { trCust2 } = data
        return generateTextField(
          'trCust2',
          trCust2?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateDtcStoreColumns
