export const getOrderServiceById = (orderObjectId: string | undefined) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/order_service/byOrderObjectId?orderObjectId=${orderObjectId}`,
	}
}

export const getServicesByLocation = (locationId: string) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/location_service/byLocationId?locationObjectId=${locationId}`,
	}
}
export const deleteOrderService = (orderServiceId: string) => {
	return {
		method: "DELETE",
		url: `${process.env.REACT_APP_ORIGIN}/order_service/${orderServiceId}`,
	}
}
export const updateOrderService = (formData: any) => {
	const price = formData.locationPrice || formData.defaultPrice
	const body = {
		objectId: formData.objectId,
		price,
		quantity: formData.quantity,
		label: formData.label,
		service: {
			objectId: formData.serviceId,
		},
		orderVehicle: {
			objectId: formData.orderVehicleObjectId,
		},
	}
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/order_service`,
		body,
	}
}
export const createOrderService = (formData: any) => {
	const body = {
		price: formData.defaultPrice,
		quantity: formData.quantity,
		label: formData.label,
		service: {
			objectId: formData.serviceId,
			defaultPrice: formData.defaultPrice
		},
		orderVehicle: {
			objectId: formData.orderVehicleObjectId,
		},
	}
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/order_service`,
		body,
	}
}

export const calculatePrice = (orderObjectId: string | undefined) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/calculate-price`,
	}
}
