export const createPromo = (body: any, locationObjectId: string) => {
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/promocodes?locationObjectId=${locationObjectId}`,
		body,
	}
}
export const deletePromoCode = (promoCodeObjectId: string | undefined) => {
	return {
		method: "DELETE",
		url: `${process.env.REACT_APP_ORIGIN}/promocodes?promoId=${promoCodeObjectId}`,
	}
}

export const createDiscount = (body: any) => {
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/discounts`,
		body,
	}
}
//create discount && create promo response needed
export const setPromoCodeToDiscountRelation = (discountObjectId: string, promoCodeObjectId: string) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/discounts/${discountObjectId}/promo-codes/${promoCodeObjectId}`,
	}
}
//create promo response needed
export const setPromoCodeToLocationRelation = (locationObjectId: string, promoCodeObjectId: string) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}/promo-codes/${promoCodeObjectId}`,
	}
}
