import { useState, useEffect, useContext } from 'react'
import { Column } from 'react-table'
import { ITabulationAreasProps } from '../container-modal/LocationModal'
import CircularProgress from '@mui/material/CircularProgress'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import CreateZipCodeRow from './CreateZipCodeRow'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import Table from '../../../../../global/components/table/Table'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import { downloadZipCSV, uploadZipCSV } from '../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import { fetchData } from '../../../../../global/utils/fetch'
import downloadCsvToComputer from '../../../../../global/utils/downloadCsvToComputer'
import getCurrentDay from '../../../../../global/utils/date/get-current-day'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../global/constants/permissions'

interface IZipCodeTableProps {
  tabulationAreasProps: ITabulationAreasProps
  isEditOrCreate: boolean
  locationId?: string
  refetch?: Function
}

export default function ZipCodeTable(props: IZipCodeTableProps) {
  const { user } = useContext(UserContext)
  const { user: amplifyUser } = useAuthenticator((context) => [context.user])
  const {
    tabulationAreasProps: { tabulationAreasData, setTabulationAreasData },
  } = props
  const [row, setRow] = useState<JSX.Element | null>(null)
  const [editingRowIndex, setEditingRowIndex] = useState(-1)
  const [isEditing, setIsEditing] = useState(false)
  const [zipCodes, setZipCodes] = useState<any>([])
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isZipCodesDisabled =
    props.isEditOrCreate ||
    !hasPermission(user).edit(
      PERMISSIONS.LOCATIONS.CATEGORY,
      PERMISSIONS.LOCATIONS.ENTRIES.ZIP_CODES.NAME,
    )
  const isZipCodesViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.ZIP_CODES.NAME,
  )

  function cancelZipCodeRow() {
    return setRow(null)
  }

  function addZipCodeRow() {
    return setRow(
      <CreateZipCodeRow
        cancel={cancelZipCodeRow}
        tabulationAreasProps={{ tabulationAreasData, setTabulationAreasData }}
      />,
    )
  }

  function generateZipCodeFileName(locationName: string) {
    const today = new Date(getCurrentDay())
    const month = today.getMonth() + 1
    const day = today.getDate()
    const year = today.getFullYear()

    return `${locationName.toLowerCase().replaceAll(' ', '-')}-zip-codes-${month}${day}${year}.csv`
  }

  async function downloadZips() {
    const data = await fetchData<{ csv: string; locationName: string }>(
      downloadZipCSV(props.locationId),
    )
    if (data instanceof Error) {
      alert(data.message)
    } else {
      downloadCsvToComputer(
        generateZipCodeFileName(data.locationName),
        data.csv,
      )
    }
  }
  function handleEditRow(rowIndex: number) {
    setEditingRowIndex(rowIndex)
    setIsEditing(true)
  }
  function handleCancelEdit() {
    setEditingRowIndex(-1)
    setIsEditing(false)
  }
  //not in use right now, as there is no endpoint to delete tab area (remove btn is disabled for now)
  function handleRemoveTabArea(index: number) {
    setTabulationAreasData((prevState: any) => {
      const newTabAreas = [...prevState.tabulationAreas]
      const deletedItem = newTabAreas.splice(index, 1)
      const newDeletedSettings = [...prevState.deleted, deletedItem]
      return {
        ...prevState,
        tabulationAreas: newTabAreas,
        deleted: newDeletedSettings,
      }
    })
    setEditingRowIndex(-1)
  }
  function handleSaveEditRow(rowIndex: number) {
    let updatedTabArea = tabulationAreasData.tabulationAreas.filter(
      (tabAreaObj: any, index: number) => index === rowIndex,
    )
    setTabulationAreasData((prevState: any) => {
      const updatedCopy = prevState.updated.map((tabArea: any) => {
        if (tabArea.objectId === updatedTabArea[0].objectId) {
          return updatedTabArea[0]
        } else {
          return tabArea
        }
      })
      if (!updatedCopy.includes(updatedTabArea[0])) {
        updatedCopy.push(updatedTabArea[0])
      }
      return { ...prevState, updated: updatedCopy }
    })
    setIsEditing(false)
    setEditingRowIndex(-1)
  }
  function handleChange(
    newValue: string | boolean,
    valueName: string,
    rowIndex: number,
  ) {
    setTabulationAreasData((prevState: any) => {
      const newTabArea = prevState.tabulationAreas.map(
        (tabAreaObj: any, index: number) => {
          if (index === rowIndex) {
            return { ...tabAreaObj, [valueName]: newValue }
          }
          return tabAreaObj
        },
      )
      return { ...prevState, tabulationAreas: newTabArea }
    })
  }
  const columns: Column<ITabulationArea>[] = [
    {
      Header: 'Zip Code',
      accessor: (data: any, rowIndex: number) => {
        const { zipCode } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='zipCode'
            value={zipCode}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'State Abbr',
      accessor: (data: any, rowIndex: number) => {
        const { stateAbbr } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='stateAbbr'
            value={stateAbbr}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: 'Status',
      accessor: (data: any, rowIndex: number) => {
        const { status } = data
        const isEditing = rowIndex !== editingRowIndex
        return (
          <TextField
            size='small'
            name='status'
            value={status}
            disabled={isEditing}
            sx={{ width: 100 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChange(e.target.value, e.target.name, rowIndex)
            }
          />
        )
      },
    },
    {
      Header: ' ',
      accessor: (data: any, rowIndex: number) => {
        return (
          <div className={styles.buttonCell}>
            {isEditing && rowIndex === editingRowIndex ? (
              <button
                className={styles.button}
                onClick={() => handleCancelEdit()}
                disabled={isZipCodesDisabled}
              >
                Cancel
              </button>
            ) : (
              <button
                className={styles.button}
                style={{ color: '#c2c2c2' }}
                // onClick={() => handleRemoveTabArea(rowIndex)}
                disabled={isZipCodesDisabled}
              >
                Remove
              </button>
            )}
            <div className={styles.line}></div>
            {isEditing && rowIndex === editingRowIndex ? (
              <button
                className={styles.button}
                onClick={() => handleSaveEditRow(rowIndex)}
                disabled={isZipCodesDisabled}
              >
                Save
              </button>
            ) : (
              <button
                className={styles.button}
                onClick={() => handleEditRow(rowIndex)}
                disabled={isZipCodesDisabled}
              >
                Edit
              </button>
            )}
          </div>
        )
      },
    },
  ]

  function filterZipCodes() {
    if (searchFilter.length === 5) {
      const filteredZipCodes = tabulationAreasData.tabulationAreas.filter(
        (zip) => {
          return zip.zipCode.includes(searchFilter)
        },
      )
      return filteredZipCodes
    } else if (tabulationAreasData.tabulationAreas.length > 0) {
      return [tabulationAreasData.tabulationAreas[0]]
    } else {
      return []
    }
  }

  useEffect(() => {
    const filteredZipCodes = filterZipCodes()
    setZipCodes(filteredZipCodes)
  }, [searchFilter, tabulationAreasData])

  async function handleFileInput(e: any) {
    const idToken = amplifyUser.getSignInUserSession()?.getIdToken()
    const formData = new FormData()
    const file = e.target.files[0]
    setSelectedFile(file.name)
    formData.append('file', file)

    const input = uploadZipCSV(props.locationId, formData)

    try {
      setIsLoading(true)
      const resp = await fetch(input.url, {
        method: input.method,
        headers: {
          Authorization: `Bearer ${idToken?.getJwtToken()}` || '',
        },
        body: input.body,
      })

      if (!resp.ok) {
        throw new Error(`Request failed with status ${resp.status}`)
      }

      const body = await resp.json()
      const createdMessage = body.createdZipsSuccessful
        ? `Created ${body.createdZipsStat} zip codes`
        : 'Could not create zip codes. Please check the file and try again.'
      const updatedMessage = body.updatedZipsSuccessful
        ? `Updated ${body.updatedZipsStat} zip codes`
        : 'Could not update zip codes. Please check the file and try again.'
      const deletedMessage = body.deletedZipsSuccessful
        ? `Deleted ${body.deletedZipsStat} zip codes`
        : 'Could not delete zip codes. Please check the file and try again.'

      alert(`${createdMessage}\n${updatedMessage}\n${deletedMessage}`)

      if (props.refetch) {
        props.refetch()
      }
    } catch (error: any) {
      console.log(error)
      alert(error)
      e.target.value = ''
      setSelectedFile('')
    } finally {
      setIsLoading(false)
    }
  }

  return isZipCodesViewable ? (
    <div style={{ width: '800px' }}>
      <TableTopper>
        <div className={styles.titleButtonContainer}>
          <TableTitle>Zip Codes</TableTitle>
          <div className={styles.buttonCell}>
            <button
              onClick={addZipCodeRow}
              className={styles.button}
              disabled={isZipCodesDisabled}
            >
              Add Zip Code
            </button>
            <div className={styles.line}></div>
            {!isZipCodesDisabled && (
              <input
                style={{ display: 'none' }}
                id='fileUpload'
                disabled={props.isEditOrCreate}
                className={styles.uploadedFile}
                onChange={handleFileInput}
                type='file'
              />
            )}
            <label
              htmlFor='fileUpload'
              style={{ color: isZipCodesDisabled ? '#c2c2c2' : '' }}
              className={`${isZipCodesDisabled ? styles.uploadButtonDisabled : styles.uploadButton}`}
            >
              Upload File
            </label>
            <span style={{ fontSize: '11px' }}>{selectedFile}</span>
            {isLoading && <CircularProgress size={20} />}
            <div className={styles.line}></div>
            <button
              onClick={downloadZips}
              className={styles.button}
              disabled={isZipCodesDisabled}
            >
              Download Zips
            </button>
          </div>
        </div>
        <TextField
          size='small'
          label='Search Zip Code'
          onChange={(e: any) => {
            const {
              target: { value: val },
            } = e
            setSearchFilter(val)
          }}
        />
      </TableTopper>
      <Table
        columns={columns}
        data={zipCodes}
        isDisplayMode={true}
        isLoading={false}
        customRow={row}
      />
    </div>
  ) : (
    <></>
  )
}
