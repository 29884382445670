import React, { FC, useState } from 'react'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import { IRefreshCounterProps } from '../../../vans/main/VansMain'
import SmallPartSuppliesModal from '../small-part-supplies-modal/container/SmallPartSuppliesModal'
import SmallPartSuppliesTable from '../table/SmallPartSuppliesTable'
import NewSmallPartSupply from './new-small-part-supply/NewSmallPartSupply'

const SmallPartSupplies: FC<IRefreshCounterProps> = (
  refresh: IRefreshCounterProps,
) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [smallPartSuppliesData, setSmallPartSuppliesData] = useState({})

  function handleClosed() {
    setModalIsOpen(false)
    setSmallPartSuppliesData({})
  }
  return (
    <>
      <SmallPartSuppliesModal
        isOpen={modalIsOpen}
        handleClosed={handleClosed}
        smallPartSuppliesRowData={smallPartSuppliesData}
        refresh={refresh}
      />
      <SmallPartSuppliesTable
        setModalIsOpen={setModalIsOpen}
        setSmallPartSuppliesData={setSmallPartSuppliesData}
        refresh={refresh}
      />
    </>
  )
}

function SmallPartSuppliesMain() {
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const refresh: IRefreshCounterProps = {
    refreshCounter: refreshCounter,
    setRefreshCounter: setRefreshCounter,
  }
  return (
    <MainPageWrapper titleFields={<NewSmallPartSupply {...refresh} />}>
      <SmallPartSupplies {...refresh} />
    </MainPageWrapper>
  )
}

export default SmallPartSuppliesMain
