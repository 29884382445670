export const getOrderData = (orderId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}`,
  }
}
export const payOrderInvoice = (orderId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/pay-invoice`,
  }
}
