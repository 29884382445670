export const getLocations = () => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/location`,
  }
}

export const getServices = () => {
  return {
    method: "GET",
    url: `${process.env.REACT_APP_ORIGIN}/service/`,
  }
}