import { Chip } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { ILocationFormProps } from '../location-form/LocationForm'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../global/constants/permissions'

export default function SurveyUrlManager(props: ILocationFormProps) {
  const { user } = useContext(UserContext)
  const { locationFormInfo, setLocationFormInfo } = props
  const [surveyUrlBackgroundColor, setSurveyUrlBackgroundColor] =
    useState('#f5f5f5')
  const [surveyUrlValue, setSurveyUrlValue] = useState('')

  const isSurveyUrlManagerDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.SURVEY_URLS.NAME,
  )
  const isSurveyUrlManagerViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.SURVEY_URLS.NAME,
  )

  const surveyUrls = locationFormInfo.surveyUrls
    ? locationFormInfo.surveyUrls
    : []
  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isSurveyUrlManagerDisabled ? 'not-allowed' : 'text',
        backgroundColor: surveyUrlBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Survey Url'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={surveyUrlValue}
          autoComplete='off'
          // allows for pasting multiple urls at once assuming
          // it is a list of urls separated by newlines
          onChange={(e) => {
            setSurveyUrlBackgroundColor('#f5f5f5')
            setSurveyUrlValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          disabled={isSurveyUrlManagerDisabled}
        />
      }
    />
  )

  const surveyUrlChips = surveyUrls.map((surveyUrl: string) => {
    return (
      <Chip
        key={surveyUrl}
        label={surveyUrl}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        disabled={isSurveyUrlManagerDisabled}
        onDelete={() => {
          const remainingSurveyUrls = surveyUrls.filter(
            (su: string) => su !== surveyUrl,
          )
          setLocationFormInfo({
            ...locationFormInfo,
            surveyUrls: remainingSurveyUrls,
          })
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newSurveyUrl = e.currentTarget.value
      const currentSurveyUrls = locationFormInfo.surveyUrls
        ? locationFormInfo.surveyUrls
        : []
      currentSurveyUrls.push(newSurveyUrl)

      setLocationFormInfo({
        ...locationFormInfo,
        surveyUrls: currentSurveyUrls,
      })
      setSurveyUrlValue('')
    }
  }

  return isSurveyUrlManagerViewable ? (
    <div className={styles.technicianEmailsManagerMain}>
      <div className={styles.technicianEmailsManagerContainer}>
        <div className={styles.technicianEmailsManagerHeader}>
          <div className={`${styles.technicianEmailsManagerTitle} font--bold`}>
            Survey Urls
          </div>
          <div className={styles.technicianEmailsManagerSubtitle}>
            Survey Urls added here will be used to send the customer a survey
            after their order is completed. Use the string '
            {'{tireRackOrderNumber}'}' to insert the Tire Rack order number into
            the survey url.
          </div>
        </div>
        <div className={styles.technicianEmailsManagerChipsContainer}>
          {inputChip}
          {surveyUrlChips}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
