import MainPageWrapper from '../../../global/components/MainPageWrapper'
import BiReports from '../reports/BiReports'

function BiReportsMain() {
  return (
    <MainPageWrapper>
      <BiReports />
    </MainPageWrapper>
  )
}

export default BiReportsMain
