import { MAX_YEAR, MIN_YEAR } from '../../../../global/constants/van'
import determineIsYearValid from '../../../../global/utils/determine-is-year-valid'

const ERR_INVALID_YEAR = `Year must be between ${MIN_YEAR} and ${MAX_YEAR}.`

function handleYearChange(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setVanData: Function,
  setYearError: Function,
) {
  const isYearEmpty = e.target.value === ''
  if (isYearEmpty) {
    setVanData({ year: undefined })
    setYearError('')

    return
  }

  const tmpYear = Number.parseInt(e.target.value)

  const isYearNotNan = !isNaN(tmpYear)
  const isYearValid = isYearNotNan && determineIsYearValid(tmpYear)
  if (isYearValid) {
    setVanData({ year: `${tmpYear}` })
    setYearError('')
  } else if (isYearNotNan) {
    setVanData({ year: `${tmpYear}` })
    setYearError(ERR_INVALID_YEAR)
  } else {
    setYearError(ERR_INVALID_YEAR)
  }
}

export default handleYearChange
