import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { RESCHEDULE_REASONS } from '../../../../../../../global/constants/orders'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { scheduledToPending } from './api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../../context/context'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import ReasonDropDown from '../../../../../../../global/components/reason-drop-down/ReasonDropDown'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import getUsername from '../../../../../../../global/utils/getUsername'

interface IProps {
  handleCancel: Function
  isOpen?: boolean
}
interface IScheduledToPendingFormDataProps {
  orderObjectId: string
  reason: string
}
export default function AddToRescheduledQueue(props: IProps) {
  const { isOpen, handleCancel } = props
  const { id } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData, data: contextData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<IScheduledToPendingFormDataProps>({
    orderObjectId: id ?? '',
    reason: '',
  })

  const handleDropDown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      reason: e.target.value,
    }))
  }

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      reason: '',
    }))
    setIsChecked(false)
  }, [isOpen])

  const handleScheduledToPending = async () => {
    const orderId = id ?? ''
    try {
      setIsLoading(true)
      await fetchData<void>(
        scheduledToPending({
          orderObjectId: formData.orderObjectId,
          username: getUsername(user),
          reason: formData.reason,
        }),
      )
      await queryOrderData(orderId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order status changed to pending rescheduled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error changing order status!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const content = (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>
        <ReasonDropDown
          handleDropDown={handleDropDown}
          reasons={RESCHEDULE_REASONS}
          reason={formData.reason}
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox
          onChange={() => setIsChecked(!isChecked)}
          disabled={!formData.reason}
        />
        <div>Yes, I want to add this order to the rescheduled queue.</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => handleCancel()}
        />
        <PrimaryButton
          buttonName='Confirm'
          disabled={!isChecked || isLoading}
          onClick={handleScheduledToPending}
        />
      </div>
    </div>
  )

  if (isOpen !== undefined) {
    return (
      <div>
        <Modal open={isOpen}>
          <Box className={styles.scheduleModal}>
            <div className={`${styles.scheduleModalPageTitle} font--bold`}>
              Add To Reschedule Queue
            </div>
            {content}
          </Box>
        </Modal>
      </div>
    )
  } else {
    return content
  }
}
