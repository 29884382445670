const SCHEDULED_ORDER_FORMAT_TIME = 'hhA'
const ROUTE_OPTIMIZATION_HOUR_UTC = 19
const START_OF_DAY_HOUR_EST = 5

enum ROUTING_ALGORITHMS {
  ROUTE_AREA = 'route_area',
  GREEDY = 'greedy',
}

export {
  SCHEDULED_ORDER_FORMAT_TIME,
  ROUTE_OPTIMIZATION_HOUR_UTC,
  START_OF_DAY_HOUR_EST,
  ROUTING_ALGORITHMS,
}