import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from '../styles.module.css'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchData } from '../../../../global/utils/fetch'
import {
  createSmallPartSupplies,
  deleteSmallPartSupplies,
  getSmallPartSuppliesQrCode,
  updateSmallPartSupplies,
} from '../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { DeleteResult } from 'typeorm'
import DeleteButton from '../../../../global/components/buttons/delete-button/DeleteButton'
import {
  ISmallPartSupplies,
  ISmallPartSuppliesPartial,
} from '../../../../../../app/entities/SmallPartSupplies'
import SmallPartSuppliesModalForm from '../form/SmallPartSuppliesModalForm'
import { IRefreshCounterProps } from '../../../../vans/main/VansMain'
import { SmallPartSuppliesQrCodeUrl } from '../../../../../../app/types/small-part-supplies'

interface ISmallPartSuppliesModalProps {
  isOpen: boolean
  handleClosed: Function
  refresh: IRefreshCounterProps
  smallPartSuppliesRowData?: ISmallPartSuppliesPartial
}

export default function SmallPartSuppliesModal(
  props: ISmallPartSuppliesModalProps,
) {
  const { user } = useAuthenticator((context) => [context.user])
  const { smallPartSuppliesRowData } = props
  const existsSmallPartSuppliesRowData =
    Object.keys(smallPartSuppliesRowData ?? {}).length !== 0
  const [qrCodeData, setQrCodeData] = useState<SmallPartSuppliesQrCodeUrl>()

  const [formStatuses, setFormStatuses] = useState({
    isLoading: false,
    checked: false,
    errorMessage: '',
  })
  const [formData, setFormData] = useState(smallPartSuppliesRowData)
  const [deleteIsChecked, setDeleteIsChecked] = useState(false)
  // const { data: qrCodeData } = useFetch<SmallPartSuppliesQrCodeUrl>(getSmallPartSuppliesQrCode(smallPartSuppliesRowData?.bin || ''))

  useEffect(() => {
    const fetchQrCodeData = async () => {
      const fetchedQrCodeData: SmallPartSuppliesQrCodeUrl =
        await fetchData<SmallPartSuppliesQrCodeUrl>(
          getSmallPartSuppliesQrCode(smallPartSuppliesRowData?.bin || ''),
        )
      setQrCodeData(fetchedQrCodeData)
    }
    if (smallPartSuppliesRowData?.bin) {
      fetchQrCodeData()
    }
  }, [smallPartSuppliesRowData?.bin, user])

  const setLoader = (bool: boolean) => {
    return setFormStatuses((prevState) => ({
      ...prevState,
      isLoading: bool,
    }))
  }

  const handleClose = () => {
    setFormStatuses({
      isLoading: false,
      checked: false,
      errorMessage: '',
    })
    props.handleClosed()
  }

  useEffect(() => {
    if (qrCodeData) {
      setFormData({
        ...smallPartSuppliesRowData,
        qrCode: qrCodeData?.qrCodeUrl || '',
      })
    }
  }, [smallPartSuppliesRowData, qrCodeData])

  const handleCreateSmallPartSupply = async () => {
    const { objectId, updated, created, ...rest } = formData ?? {}
    const body = { ...rest }
    try {
      setLoader(true)
      await fetchData<ISmallPartSupplies>(createSmallPartSupplies(body))
      setLoader(false)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage:
          'There has been an error adding this Small Part to the Inventory',
      }))
    }
  }
  const handleDeleteSmallPartSupply = async () => {
    try {
      setLoader(true)
      await fetchData<DeleteResult>(
        deleteSmallPartSupplies(formData?.objectId || ''),
      )
      setLoader(false)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage:
          'There has been an error deleting this Small Part from the Inventory',
      }))
    }
  }

  const handleUpdateSmallPartSupply = async () => {
    const { updated, created, ...rest } = formData ?? {}
    const body = { ...rest }
    try {
      setLoader(true)
      await fetchData<ISmallPartSupplies>(updateSmallPartSupplies(body))
      setLoader(false)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage:
          'There has been an error updating this Small Part in the Inventory',
      }))
    }
  }
  return (
    <div>
      <Modal open={props.isOpen}>
        <Box className={styles.smallPartSuppliesModal}>
          <div
            className={`${styles.smallPartSuppliesModalPageTitle} font--bold`}
          >
            {existsSmallPartSuppliesRowData
              ? 'Edit Small Part'
              : 'Add New Small Part'}
          </div>
          <SmallPartSuppliesModalForm
            formData={formData ?? {}}
            setFormData={setFormData}
            deleteIsChecked={deleteIsChecked}
            setDeleteIsChecked={setDeleteIsChecked}
          />
          {formStatuses.isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {formStatuses.errorMessage !== '' && (
            <div className={styles.errorMessage}>
              {formStatuses.errorMessage}
            </div>
          )}
          {existsSmallPartSuppliesRowData ? (
            <div className={styles.buttonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClose}
              />
              {deleteIsChecked ? (
                <DeleteButton
                  buttonName='Remove'
                  disabled={formStatuses.isLoading}
                  onClick={handleDeleteSmallPartSupply}
                />
              ) : (
                <PrimaryButton
                  buttonName='Save'
                  disabled={formStatuses.isLoading}
                  onClick={handleUpdateSmallPartSupply}
                />
              )}
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClose}
              />
              <PrimaryButton
                buttonName='Add'
                disabled={formStatuses.isLoading}
                onClick={handleCreateSmallPartSupply}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  )
}
