import { useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { CasesModal } from '../../../work-orders/components/work-order-detail/components/tables/cases/cases-modal/components/container-modal/CasesModal'
import { IEditCaseDataData } from '../../../work-orders/components/work-order-detail/components/tables/cases/CasesWoDetailTable'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import { ICase } from '../../../../../app/entities/Case'

function CasesTable() {
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    count: 0,
    pageSize: 100,
  })
  const [refreshKey, setRefreshKey] = useState(0)
  const [columnFilters, setColumnFilters] = useState({})
  const [casesModalIsOpen, setCasesModalIsOpen] = useState(false)
  const [editCaseData, setEditCaseData] = useState<IEditCaseDataData>({
    orderObjectId: '',
    caseObjectId: '',
  })

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  const endpoint = `${process.env.REACT_APP_ORIGIN}/cases?`

  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      sortBy: '`created` DESC',
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
    })
  }, [refreshKey, columnFilters, tableState.pageSize, tableState.offSet])
  const handleClosed = () => {
    setEditCaseData({
      orderObjectId: '',
      caseObjectId: '',
    })
    setCasesModalIsOpen(false)
  }

  const onRecordClickHandler = (row: any) => {
    const {
      original: { caseObjectId, orderObjectId },
    } = row
    setEditCaseData({
      orderObjectId: orderObjectId,
      caseObjectId: caseObjectId,
    })
    setCasesModalIsOpen(true)
  }

  const columns: Column<ICase>[] = [
    {
      Header: 'Work Order ID',
      accessor: (data: any) => data.workOrderId,
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({ order: { workOrderId: val } }),
      }),
    },
    {
      Header: 'First Name',
      accessor: (data: any) => data.firstName,
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({
            order: {
              customer: {
                firstName: val,
              },
            },
          }),
      }),
    },
    {
      Header: 'Last Name',
      accessor: (data: any) => data.lastName,
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({
            order: {
              customer: {
                lastName: val,
              },
            },
          }),
      }),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ type: val }),
      }),
    },
    {
      Header: 'Damage Cost',
      accessor: 'damageCost',
      Filter: NoFilter(),
    },
    {
      Header: 'Summary',
      accessor: 'summary',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ summary: val }),
      }),
    },
    {
      Header: 'Note',
      accessor: (data: any) => data.note,
      Filter: NoFilter(),
    },
    {
      Header: 'Open Date',
      accessor: (data: any) => {
        const date = data.openDate?.split('T')[0]
        return date || ''
      },
      Filter: NoFilter(),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ status: val }),
      }),
    },
    {
      Header: 'Status Date',
      accessor: (data: any) => {
        const date = data.statusDate?.split('T')[0]
        return date || ''
      },
      Filter: NoFilter(),
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ createdBy: val }),
      }),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <CasesModal
        isOpen={casesModalIsOpen}
        editCaseData={editCaseData}
        handleClosed={handleClosed}
        triggerRefresh={() => setRefreshKey(refreshKey + 1)}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={onRecordClickHandler}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default CasesTable
