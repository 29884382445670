import { IGetOrderByIdResponse } from './context'

export const initialOrderValues: IGetOrderByIdResponse = {
  addBtIds: null,
  additionalTireRackOrderNumber: null,
  assignedTo: null,
  bookings: [],
  cancellationNote: '',
  cancellationReason: null,
  cancelled_onsite_flag: 0,
  cases: [],
  created: 0,
  createdFrom: null,
  discounts: [],
  ecomId: null,
  estimateFormUrl: null,
  memberId: null,
  note: null,
  notificationsLog: undefined,
  objectId: '',
  orderHistory: [],
  orderServices: [],
  orderVehicles: [],
  originZipCode: null,
  rating: 0,
  routeInstructions: [],
  scheduleLeadTime: 0,
  scheduledMessages: [],
  services: [],
  shouldRequestPaymentAfterScheduling: false,
  source: null,
  standbyQueueMessages: [],
  status: null,
  step: null,
  surveyUrl: null,
  timeOptedIntoStandbyQueue: null,
  tireArrivalStatus: '',
  tireRackOrder: null,
  tireRackOrders: [],
  updated: 0,
  vehicles: [],
  invoices: [],
  workOrderId: 0,
}