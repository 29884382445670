import { Invoice } from '../../../../../../app/entities/Invoice'
import { STATUSES } from '../../../constants/invoice'

// This function should be mirrored in app/eworkflows/invoice/status/is-transaction-processing.ts
function isTransactionProcessing(invoice: Invoice) {
  // If the invoice.status "REFUND_REQUESTED" it will not have a transactionId
  // but is still considered processing
  if (invoice.status === STATUSES.REFUND_REQUESTED) {
    return true
  }

  if (!invoice.transactionId) {
    return false
  }

  return [
    STATUSES.REFUND_REQUESTED,
    STATUSES.SUBMITTED_FOR_SETTLEMENT,
    STATUSES.SETTLEMENT_PENDING,
    STATUSES.SETTLING
  ].includes(invoice.status as STATUSES)
}

export default isTransactionProcessing
