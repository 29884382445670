import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import CsTeamToolsModal from './cs-team-tools-modal/CsTeamToolsModal'
import WorkOrderWorkListModal from './work-orders-work-list/WorkOrdersWorkListModal'
import { WoDetailContextProvider } from '../../../work-orders/components/context/context'
import { useParams } from 'react-router-dom'
import ServicedZipsModal from './serviced-zips/ServicedZipsModal'
import DailyWarehouseReportsModal from './daily-warehouse-reports-modal/DailyWarehouseReportsModal'
import ExportReportToEmailModal from './export-report-to-email-modal/ExportReportToEmailModal'
import DownloadReportsModal from './download-reports-modal/DownloadReportsModal'
import { DOWNLOAD_REPORTS_CATEGORY } from '../../../global/constants/reports'
import StandbyQueueReportModal from './standby-queue-report-modal/StandbyQueueReportModal'

export default function AllReports() {
  const [open, setOpen] = useState(false)
  const [standbyQueueOpen, setStandbyQueueOpen] = useState(false)
  const [downloadReportsCategory, setDownloadReportsCategory] = useState(DOWNLOAD_REPORTS_CATEGORY.NEW_INVOICE_REPORTS.valueOf())
  const [downloadReportsOpen, setDownloadReportsOpenOpen] = useState(false)
  const [dailyWarehouseReportsOpen, setDailyWarehouseReportsOpen] = useState(false)
  const [teamToolsOpen, setTeamToolsOpen] = useState(false)
  const [workOrderWorkListOpen, setWorkOrderWorkListOpen] = useState(false)
  const [servicedZipsModalOpen, setServicedZipsModalOpen] = useState(false)
  const [reportName, setReportName] = useState('')
  const { id } = useParams()

  function handleOpen(str: string) {
    setReportName(str)
    setOpen(true)
  }
  function handleClosed() {
    setReportName('')
    setOpen(false)
  }
  function handleStandbyQueueOpen() {
    setStandbyQueueOpen(true)
  }
  function handleStandbyQueueClosed() {
    setStandbyQueueOpen(false)
  }
  function handleOpenZipsModal() {
    setServicedZipsModalOpen(true)
  }
  function handleClosedZipsModal() {
    setServicedZipsModalOpen(false)
  }
  function handleOpenQuickReports() {
    setDownloadReportsCategory(DOWNLOAD_REPORTS_CATEGORY.QUICK_REPORTS.valueOf())
    setDownloadReportsOpenOpen(true)
  }
  function handleOpenNewInvoiceReports() {
    setDownloadReportsCategory(DOWNLOAD_REPORTS_CATEGORY.NEW_INVOICE_REPORTS.valueOf())
    setDownloadReportsOpenOpen(true)
  }
  function handleClosedDownloadReports() {
    setDownloadReportsOpenOpen(false)
  }
  function handleOpenDailyWarehouseReports() {
    setDailyWarehouseReportsOpen(true)
  }
  function handleCloseDailyWarehouseReports() {
    setDailyWarehouseReportsOpen(false)
  }
  function handleOpenTeamTools() {
    setTeamToolsOpen(true)
  }
  function handleCloseTeamTools() {
    setTeamToolsOpen(false)
  }
  function handleOpenWorkOrderWorkList() {
    setWorkOrderWorkListOpen(true)
  }
  function handleCloseWorkOrderWorkList() {
    setWorkOrderWorkListOpen(false)
  }
  useEffect(() => {
    if (id) {
      handleOpenWorkOrderWorkList()
    }
  }, [id])
  return (
    <WoDetailContextProvider>
      <ExportReportToEmailModal
        isOpen={open}
        handleClosed={handleClosed}
        reportName={reportName}
      />
      <StandbyQueueReportModal
        isOpen={standbyQueueOpen}
        handleClosed={handleStandbyQueueClosed}
      />
      <DailyWarehouseReportsModal
        isOpen={dailyWarehouseReportsOpen}
        handleClosed={handleCloseDailyWarehouseReports}
      />
      <DownloadReportsModal
        isOpen={downloadReportsOpen}
        handleClosed={handleClosedDownloadReports}
        category={downloadReportsCategory === DOWNLOAD_REPORTS_CATEGORY.NEW_INVOICE_REPORTS.valueOf()
          ? DOWNLOAD_REPORTS_CATEGORY.NEW_INVOICE_REPORTS
          : DOWNLOAD_REPORTS_CATEGORY.QUICK_REPORTS}
      />
      <CsTeamToolsModal
        isOpen={teamToolsOpen}
        handleClosed={handleCloseTeamTools}
      />
      <WorkOrderWorkListModal
        isOpen={workOrderWorkListOpen}
        handleClosed={handleCloseWorkOrderWorkList}
      />
      <ServicedZipsModal
        isOpen={servicedZipsModalOpen}
        handleClosed={handleClosedZipsModal}
      />
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.containerHeading}>General</div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Completed Work Orders')}>
              Completed Work Orders
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenZipsModal()}>
              Serviced Zips
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenWorkOrderWorkList()}>
              Work Order Work List
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Work Orders With Tire Rack Orders')}>
              Work Orders With Tire Rack Orders
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleStandbyQueueOpen()}>
              Standby Queue
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.containerHeading}>Legacy</div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Customer Rating By Technician')}>
              Customer Rating By Technician
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Free Discounted and Cancelled Onsite')}>
              Free Discounted and Cancelled Onsite
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.containerHeading}>Accounting</div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Accounting Report: Invoice Reconciliation')}>
              Accounting Report: Invoice Reconciliation
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenNewInvoiceReports()}>
              New Invoice Reports
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpen('Work Order / Invoices - Accounting')}>
              Work Order / Invoices - Accounting
            </button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.containerHeading}>Utility</div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenTeamTools()}>
              CS Team Tools
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenDailyWarehouseReports()}>
              Daily Warehouse Report
            </button>
          </div>
          <div className={styles.link}>
            <button
              className={styles.navLink}
              onClick={() => handleOpenQuickReports()}>
              Quick Reports
            </button>
          </div>
        </div>
      </div>
    </WoDetailContextProvider>
  )
}
