const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME ?? ''

// There can be more environment names for ephemeral environments, but we can't
// enumerate them. They should be considered as "non-prod" environments.
const ENVIRONMENT_NAMES = {
  prod: 'prod',
  train: 'train',
  qa: 'qa',
  dev: 'dev',
  local: 'local',
}

const ENVIRONMENT_NAMES_ARRAY = Object.values(ENVIRONMENT_NAMES)

const IS_ENV_PROD = ENVIRONMENT_NAME === ENVIRONMENT_NAMES.prod
const IS_ENV_QA = ENVIRONMENT_NAME === ENVIRONMENT_NAMES.qa
const IS_ENV_TRAIN = ENVIRONMENT_NAME === ENVIRONMENT_NAMES.train
const IS_ENV_DEV = ENVIRONMENT_NAME === ENVIRONMENT_NAMES.dev
const IS_ENV_LOCAL = ENVIRONMENT_NAME === ENVIRONMENT_NAMES.local
const IS_ENV_EPHEMERAL = !ENVIRONMENT_NAMES_ARRAY.includes(ENVIRONMENT_NAME)

export {
  ENVIRONMENT_NAMES,
  ENVIRONMENT_NAMES_ARRAY,
  IS_ENV_PROD,
  IS_ENV_QA,
  IS_ENV_TRAIN,
  IS_ENV_DEV,
  IS_ENV_LOCAL,
  IS_ENV_EPHEMERAL,
}
