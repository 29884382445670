import { IInvoice } from '../../../../app/entities/Invoice'
import { STATUSES } from '../../global/constants/order-status'
import {
  CANCELLATION_REASONS,
  CANCELLATION_DB_VALUES,
} from './../../global/constants/orders'

//work order modal context api call
export const getOrderDataById = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}`,
  }
}
//order vehicles table
export const getOrderVehiclesById = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/byOrderObjectId?orderObjectId=${orderId}`,
  }
}
//line items table
export const getOrderServicesById = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/order-services`,
  }
}
//discounts table
export const getDiscountsAttachedToOrderById = (
  orderId: string | undefined,
) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/discounts/byOrderId?orderObjectId=${orderId}`,
  }
}
//tech table
export const getTechAssignedToWorkOrder = (orderId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/assigned-to-work-order?orderObjectId=${orderId}`,
  }
}
//work order history table
export const getOrderHistoryById = (orderId: string | undefined) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/orderHistory?orderObjectId=${orderId}`,
  }
}
//cancel order sub modal
export const updateOrderStatusToCanceled = (
  id: string | undefined,
  reason: string,
  note: string | undefined,
) => {
  let statusOfOrder =
    reason === CANCELLATION_REASONS.on_site_cancellation
      ? CANCELLATION_DB_VALUES.cancelled_on_site
      : CANCELLATION_DB_VALUES.cancelled
  return {
    method: 'POST',
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${id}/status/${statusOfOrder}?reason=${reason}` +
      (note ? `&note=${encodeURIComponent(note)}` : ''),
  }
}

export const updateOrderStatusToPendingRescheduling = (
  orderObjectId: string,
  reason: string,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/status/${STATUSES.reschedule}?reason=${reason}`,
  }
}
//cancel order sub modal
//edit order note
export const overwriteOrderNote = (orderId: string, note: string) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/note`,
    body: note,
  }
}
//send customer CC update
export const sendCustomerPaymentMethodUpdate = (orderId: string) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/payment-method-update-invite`,
    body: {
      orderId,
      origin: window.location.origin,
    },
  }
}

export const adjustInvoices = (
  orderId: string,
  adjustmentInvoice: Partial<IInvoice>,
) => {
  const body = {
    adjustmentInvoice,
  }

  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/adjust`,
    body,
  }
}
