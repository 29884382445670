const NEW_INVOICE_REPORTS = 'New Invoice Reports' as const
const QUICK_REPORTS = 'Quick Reports' as const

export type DOWNLOAD_REPORTS_CATEGORY_TYPE = typeof NEW_INVOICE_REPORTS | typeof QUICK_REPORTS

const DOWNLOAD_REPORTS_CATEGORY = {
  NEW_INVOICE_REPORTS,
  QUICK_REPORTS
}

export {
  DOWNLOAD_REPORTS_CATEGORY
}