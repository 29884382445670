import { useState, useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import TextField from '@mui/material/TextField'

import styles from './styles.module.css'
import { fetchData } from '../../global/utils/fetch'
import { getOrderByWorkOrderId, addCompletedInvoice } from '../api'
import { IOrder } from '../../../../app/entities/Order'
import { STATUSES } from '../../global/constants/invoice'

export default function AddCompletedInvoice() {
  const { user } = useAuthenticator((context) => [context.user])
  const [invoice, setInvoice] = useState({
    transactionId: '',
    totalNonTaxable: '',
    totalTaxable: '',
    totalTax: '',
    totalDiscount: '',
    status: '',
    paymentMethodToken: '',
    totalPromoCode: '',
  } as Record<string, string>)

  const [workOrderId, setWorkOrderId] = useState('')
  const [order, setOrder]: any = useState(null)
  const [message, setMessage] = useState('')

  const onFormChange = (e: any) => {
    setInvoice({ ...invoice, [e.target.name]: e.target.value })
  }

  const searchForOrder = async () => {
    const numberfiedWorkOrderId = Number(workOrderId)
    if (!workOrderId || Number.isNaN(numberfiedWorkOrderId)) return

    try {
      setMessage('Fetching order...')
      const fetchedOrder = await fetchData<any>(
        getOrderByWorkOrderId(numberfiedWorkOrderId),
      )

      const { invoices } = fetchedOrder
      if (!invoices || invoices.length === 0) {
        setMessage('No invoices found.')
        return
      }

      const quoteInvoice = invoices.find(
        (invoice: any) => invoice.type === 'quote',
      )
      if (!quoteInvoice) {
        setMessage('No quote invoice found.')
        return
      }

      setOrder(fetchedOrder)

      const invoiceForm = Object.keys(quoteInvoice)
        .filter((key) => Object.keys(invoice).includes(key))
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: key === 'status' ? STATUSES.SETTLED : quoteInvoice[key],
          }),
          {},
        )

      setInvoice(invoiceForm)
      setMessage('Found it.')
    } catch (err) {
      setMessage(`Order not found. (query: ${workOrderId})`)
    }
  }

  const addInvoice = async () => {
    if (!order) return
    setMessage('Adding invoice...')
    try {
      const response = await fetchData(
        addCompletedInvoice({
          invoice,
          orderObjectId: order.objectId,
        }),
      )
      setMessage('Invoice added.')
      setInvoice(
        Object.keys(invoice).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
      )
      setOrder(null)
    } catch (err) {
      setMessage('Error adding invoice.')
    }
  }

  return (
    <div>
      <h2>Add Completed Invoice</h2>
      <div className={styles.inputsContainer}>
        <TextField
          label='Work Order ID'
          name='workOrderId'
          value={workOrderId}
          onChange={(e) => setWorkOrderId(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && searchForOrder()}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        {message && <i>{message}</i>}
        {order &&
          Object.keys(invoice).map((field) => (
            <TextField
              key={field}
              label={field}
              name={field}
              value={invoice[field] ?? ''}
              onChange={onFormChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          ))}

        {order && (
          <PrimaryButton
            buttonName='Add Completed Invoice'
            onClick={addInvoice}
          />
        )}
      </div>
    </div>
  )
}
