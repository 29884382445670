import React, { useState } from 'react'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import SchedulePageHeaders from '../schedule-page-headers/SchedulePageHeaders'
import TechTableContainer, {
  IScheduleFilterProps,
} from '../technician-table/table-container/TechTableContainer'
import { getCurrentDate } from '../../../global/utils/date/dates'

export interface IScheduleFilter {
  scheduleFilter: IScheduleFilterProps
  setScheduleFilter: Function
}
function ScheduleMain() {
  const initialDate = () => {
    const savedDate = localStorage.getItem('selectedDate')
    return savedDate ? savedDate : getCurrentDate()
  }
  const initialLocation = () => {
    return localStorage.getItem('selectedLocationObjectId') ?? ''
  }
  const [scheduleFilter, setScheduleFilter] = useState<IScheduleFilterProps>({
    date: initialDate(),
    locationId: initialLocation(),
  })
  return (
    <MainPageWrapper
      titleFields={
        <SchedulePageHeaders
          filterInfo={{ scheduleFilter, setScheduleFilter }}
        />
      }
      title='Schedule'
      stickyTitle={true}
    >
      <TechTableContainer filters={{ scheduleFilter, setScheduleFilter }} />
    </MainPageWrapper>
  )
}

export default ScheduleMain
