import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import styles from './styles.module.css'
import Portal from '@mui/material/Portal'
import React from 'react'

interface IToastNotification {
  severity: 'error' | 'info' | 'success' | 'warning'
  open: boolean
  handleClose: any
  message: string
}

export interface IAlertStatusProps {
  isOpen: boolean
  message: string
  severity: 'error' | 'info' | 'success' | 'warning'
}

export default function ToastNotification(props: IToastNotification) {
  const { severity, open, handleClose, message } = props

  // Split the message by newline
  const lines = message.split('\n')
  const formattedLines = lines.flatMap((line, index) => {
    // Split each line by tab characters
    const parts = line.split('\t')
    const formattedLine = parts.map((part, i) => (
      <React.Fragment key={`part-${index}-${i}`}>
        {part}
        {i < parts.length - 1 && (
          <span style={{ whiteSpace: 'pre' }}>{'\t'}</span>
        )}
      </React.Fragment>
    ))

    // Add a line break after each line except the last one
    if (index < lines.length - 1) {
      formattedLine.push(<br key={`br-${index}`} />)
    }

    return formattedLine
  })

  return (
    <Portal>
      <div style={{ width: '100%' }}>
        <Snackbar
          style={{ top: '40px' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity={severity}
            variant='filled'
            onClose={handleClose}
            className={styles.alert}
          >
            {formattedLines}
          </Alert>
        </Snackbar>
      </div>
    </Portal>
  )
}
