import React, { ChangeEvent, useState, useEffect } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { getLocationList } from './api'
import useFetch from '../../hooks/useFetchHook'
interface ILocation {
  name: string
  objectId: string
  active?: boolean
}
interface LocationDropdownProps {
  onLocationChange?: Function
  disabled?: boolean
  defaultValue?: string | null
  fullWidth?: boolean
  showInactive?: boolean
  label?: string
  shouldIncludeDefault?: boolean
  required?: boolean
}

export default function LocationDropDown({
  onLocationChange,
  disabled,
  defaultValue,
  fullWidth,
  showInactive,
  label,
  shouldIncludeDefault = true,
  required = false,
}: LocationDropdownProps) {
  const [value, setValue] = useState(defaultValue || '')
  const { data: locations, isLoading } =
    useFetch<ILocation[]>(getLocationList())

  useEffect(() => {
    if (defaultValue && locations) {
      const defaultLocation = locations.find(
        (location: ILocation) => location.objectId === defaultValue,
      )
      if (defaultLocation) {
        setValue(defaultLocation.objectId)
      }
    }
  }, [defaultValue, locations])
  function onLocationChangeWrapper(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onLocationChange && onLocationChange(newValue)
  }

  let sortedLocations = (locations || [])
    .filter((location: ILocation) => location.name)
    .filter((location: ILocation) => showInactive || location.active)
    .sort((a: ILocation, b: ILocation) => a.name?.localeCompare(b.name))

  const data = shouldIncludeDefault
    ? [{ name: 'Default (all)', objectId: '' }].concat(sortedLocations)
    : sortedLocations

  let menuItems

  if (isLoading) {
    menuItems = (
      <MenuItem
        value=''
        className={styles.dropDownOption}
      >
        Loading...
      </MenuItem>
    )
  } else {
    menuItems = data.filter(Boolean).map((location: ILocation) => {
      return (
        <MenuItem
          key={location.objectId}
          value={location.objectId}
          className={styles.dropDownOption}
        >
          {location.name}
        </MenuItem>
      )
    })
  }

  return (
    <div>
      <TextField
        className={styles.dropDownSelect}
        label={label ?? 'Location'}
        select
        fullWidth={fullWidth}
        name='locationId'
        disabled={disabled}
        onChange={onLocationChangeWrapper}
        value={locations ? value : ''}
        required={required}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 250,
                fontFamily: '',
              },
            },
          },
        }}
      >
        {menuItems}
      </TextField>
    </div>
  )
}
