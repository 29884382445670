import { IAccountingTransactionAdjustmentPartial } from '../../../../../../../../bi/entities/AccountingTransactionAdjustment'
import tableStyles from '../../../../../../global/components/table/styles.module.css'
import buttonStyles from '../../styles.module.css'
import TextField from '@mui/material/TextField'
import { useState, ChangeEvent } from 'react'

interface IDtcStoreRowProps {
  handleCancel: Function
  handleSaveNewRow: Function
}

const COLUMN_NAMES = [
  'postingDate',
  'postingPeriod',
  'modifiedDate',
  'costCenterId',
  'accountNumber',
  'src',
  'referenceNumber',
  'vendorNumber',
  'amount',
  'vendorName',
  'comments',
] as (
  | 'postingDate'
  | 'postingPeriod'
  | 'modifiedDate'
  | 'costCenterId'
  | 'accountNumber'
  | 'src'
  | 'referenceNumber'
  | 'vendorNumber'
  | 'amount'
  | 'vendorName'
  | 'comments'
)[]

export default function CreateAccountingTransactionAdjustmentRow(
  props: IDtcStoreRowProps,
) {
  const [newDtcStore, setNewDtcStore] =
    useState<IAccountingTransactionAdjustmentPartial>({
      modifiedDate: undefined,
      costCenterId: undefined,
      accountNumber: undefined,
      postingPeriod: undefined,
      postingDate: undefined,
      src: undefined,
      referenceNumber: undefined,
      vendorNumber: undefined,
      amount: undefined,
      vendorName: undefined,
      comments: undefined,
    })

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setNewDtcStore({ ...newDtcStore, [e.target.name]: e.target.value })
  }

  function addNewDtcStore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    props.handleSaveNewRow(newDtcStore)
  }

  const rows = COLUMN_NAMES.map((columnName) => {
    return (
      <td className={tableStyles.bodyRowCell}>
        <TextField
          size='small'
          name={`${columnName}`}
          value={newDtcStore[`${columnName}`]}
          sx={{ minWidth: 100 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </td>
    )
  })

  return (
    <tr className={tableStyles.bodyRow}>
      {rows}
      <td className={tableStyles.bodyRowCell}>
        <div className={buttonStyles.buttonCell}>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={addNewDtcStore}
            >
              Add
            </a>
          </div>
          <div className={buttonStyles.line}></div>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={() => props.handleCancel()}
            >
              Cancel
            </a>
          </div>
        </div>
      </td>
    </tr>
  )
}
