import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import { MODES } from '../../../../../global/constants/scheduler'
import { TIME_SLOT_TYPES } from '../../../../../global/constants/time-slot'
import { ISchedulerStateProps } from '../container-modal/initialData'
import { Slot } from './Slot'
import styles from './styles.module.css'

interface IDayProps {
  date: number
  slots: {
    [key: string]: AvailabilityCalendarSlot
  }
  schedulerState: ISchedulerStateProps
  setSelectedAvailabilityCalendarSlot: Function
  viewOnly?: boolean
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export default function Day(props: IDayProps) {
  const {
    schedulerState,
    setSelectedAvailabilityCalendarSlot,
    slots,
    viewOnly,
  } = props
  const date = new Date(props.date)
  const formattedDate = `${months[date.getUTCMonth()]} ${date.getUTCDate()}`
  const weekday = `${days[date.getUTCDay()]}`

  function onClick(selectedAvailabilityCalendarSlot: AvailabilityCalendarSlot) {
    setSelectedAvailabilityCalendarSlot(selectedAvailabilityCalendarSlot)
  }

  function isSlotSelectable(slotType: string): string {
    if (viewOnly) {
      return styles.notSelectable
    }
    return slots[slotType]?.available ? '' : styles.notSelectable
  }

  return (
    <div className={styles.day}>
      <div className={styles.dayHeader}>
        <div className={styles.dayHeaderMonthText}>{formattedDate}</div>
        <div className={styles.dayHeaderDayTextContainer}>
          <div className={styles.dayHeaderDayTextWeekday}>{`${weekday}`}</div>
        </div>
      </div>
      <div className={styles.slotContainer}>
        <div className={isSlotSelectable(TIME_SLOT_TYPES.MORNING)}>
          <Slot
            onClick={onClick}
            selectedAvailabilityCalendarSlot={
              schedulerState.selectedAvailabilityCalendarSlot
            }
            availabilityCalendarSlot={slots[TIME_SLOT_TYPES.MORNING]}
            key='morning'
          />
        </div>
        <div className={isSlotSelectable(TIME_SLOT_TYPES.AFTERNOON)}>
          <Slot
            onClick={onClick}
            selectedAvailabilityCalendarSlot={
              schedulerState.selectedAvailabilityCalendarSlot
            }
            availabilityCalendarSlot={slots[TIME_SLOT_TYPES.AFTERNOON]}
            key='afternoon'
          />
        </div>
        <div className={isSlotSelectable(TIME_SLOT_TYPES.EVENING)}>
          <Slot
            onClick={onClick}
            selectedAvailabilityCalendarSlot={
              schedulerState.selectedAvailabilityCalendarSlot
            }
            availabilityCalendarSlot={slots[TIME_SLOT_TYPES.EVENING]}
            key='evening'
          />
        </div>
        <div className={isSlotSelectable(TIME_SLOT_TYPES.ANYTIME)}>
          <Slot
            onClick={onClick}
            selectedAvailabilityCalendarSlot={
              schedulerState.selectedAvailabilityCalendarSlot
            }
            availabilityCalendarSlot={slots[TIME_SLOT_TYPES.ANYTIME]}
            key='anytime'
          />
        </div>
      </div>
    </div>
  )
}
