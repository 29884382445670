import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getOrderServicesById } from '../../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../context/context'
import {
  IOrderService,
  OrderService,
} from '../../../../../../../../app/entities/OrderService'
import { PRODUCT_SERVICE_NAMES } from '../../../../../../global/constants/service'
interface ILineItemTableStateProps {
  data: IOrderService[]
  isLoading: boolean
}

export default function LineItemsTable() {
  const { id } = useParams()
  const { data } = useContext(WoDetailContext)
  const { user } = useAuthenticator((context) => [context.user])
  const [isOpen, setIsOpen] = useState(true)
  const [refreshKey, setRefreshKey] = useState(0)
  const [tableState, setTableState] = useState<ILineItemTableStateProps>({
    data: [],
    isLoading: false,
  })

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<IOrderService[]>(getOrderServicesById(id))
      setTableState((prevState) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    fetchTableData()
  }, [refreshKey, id, user])

  const columns: Column<OrderService | any>[] = [
    {
      Header: 'Service',
      accessor: 'service.name',
      Cell: ({ row: { original } }) => {
        const { service } = original as any

        const serviceIsProduct = PRODUCT_SERVICE_NAMES.includes(
          service?.name ?? '',
        )

        if (serviceIsProduct && original?.description) {
          return `${service?.name}: ${original?.description}`
        }

        return service?.name
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ row: { original } }) => {
        const {
          price: defaultServicePrice,
          priceOverride,
          locationService,
        } = original as OrderService
        const locationServicePrice = locationService?.price
        const finalPrice =
          priceOverride ?? locationServicePrice ?? defaultServicePrice
        return finalPrice
      },
    },
    {
      Header: 'Order Vehicle Id',
      accessor: 'orderVehicle.orderVehicleId',
    },
    {
      Header: 'Make & Model',
      Cell: ({ row: { original } }) => {
        const { orderVehicle } = original as any

        if (orderVehicle) {
          const { customerVehicle } = orderVehicle
          return (
            <div>
              <div>{customerVehicle?.brand?.name}</div>
              <div>{customerVehicle?.model}</div>
              <div>{customerVehicle?.version}</div>
              <div>{customerVehicle?.year}</div>
            </div>
          )
        } else {
          return <div>No customer Vehicle Info Available</div>
        }
      },
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Line Items</TableTitle>
          {/* <button
            disabled={disableButtonLogic}
            onClick={() => handleOpenNewLineItem()}
            style={{ all: 'unset', cursor: 'pointer', color: disableButtonLogic ? 'grey' : '#d70000' }}>
            Add New Line Item
          </button> */}
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data as any}
        isLoading={tableState.isLoading}
        // onRowClick={(onRecordClickHandler)}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
