import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useState } from 'react'
import styles from '../styles.module.css'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { fetchData } from '../../../../../../global/utils/fetch'
import { deleteDaySetting } from '../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import DeleteButton from '../../../../../../global/components/buttons/delete-button/DeleteButton'

interface IDeleteDaySettingModalProps {
  techId?: string
  reloadModal?: Function
  handleClosed: () => void
  isOpen: boolean
  daySetting: any
}

export default function DeleteDaySettingConfirmation(
  props: IDeleteDaySettingModalProps,
) {
  const { user } = useAuthenticator((context) => [context.user])
  const { isOpen, handleClosed, reloadModal, techId, daySetting } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const handleDeleteDaySetting = async () => {
    try {
      setIsLoading(true)
      await fetchData(deleteDaySetting(techId, daySetting.objectId))
      reloadModal && reloadModal()
    } catch (err) {
      setIsError(true)
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => handleClosed()}
      >
        <Box className={styles.deleteConfirmationModal}>
          <div>
            <div className={styles.deleteConfirmMessage}>
              Are you sure you want to delete this day setting?
            </div>
            {isError && (
              <div className={styles.error}>There has been an error </div>
            )}
            <div className={styles.deleteButtonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={() => handleClosed()}
              />
              <DeleteButton
                buttonName='Delete'
                disabled={isLoading}
                onClick={handleDeleteDaySetting}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
