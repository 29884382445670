import { BI_DATA_WAREHOUSE_TABLE_NAMES } from '../../../../../global/constants/bi-reports'

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  ATA_ACCOUNT_DEFINITION,
  ATA_GL_TRANSACTION,
  DTC_INVOICE,
  DTC_STORE,
  DTMI_VAN,
  DTMI_VAN_OPERATING_STATUS,
  DTMI_WORK_ORDER,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
  TRMI_VAN,
  TRMI_VAN_OPERATING_STATUS,
  TRMI_WORK_ORDER,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

function getRequestInformation(entityName: string): {
  endpoint: string
  entityNameQueryValue: string
  sortBy?: string
} {
  let entityNameQueryValue = ''
  let sortBy

  if (entityName === ACCOUNTING_TRANSACTION_ADJUSTMENT) {
    entityNameQueryValue = ACCOUNTING_TRANSACTION_ADJUSTMENT
    sortBy = '`postingDate` DESC'
  } else if (entityName === ATA_ACCOUNT_DEFINITION) {
    entityNameQueryValue = ATA_ACCOUNT_DEFINITION
  } else if (entityName === ATA_GL_TRANSACTION) {
    entityNameQueryValue = ATA_GL_TRANSACTION
  } else if (entityName === DTC_INVOICE) {
    entityNameQueryValue = DTC_INVOICE
  } else if (entityName === DTC_STORE) {
    entityNameQueryValue = DTC_STORE
  } else if (entityName === DTMI_VAN) {
    entityNameQueryValue = DTMI_VAN
  } else if (entityName === DTMI_VAN_OPERATING_STATUS) {
    entityNameQueryValue = DTMI_VAN_OPERATING_STATUS
  } else if (entityName === DTMI_WORK_ORDER) {
    entityNameQueryValue = DTMI_WORK_ORDER
  } else if (entityName === HUB_SPOKE_STORE_MAPPING) {
    entityNameQueryValue = HUB_SPOKE_STORE_MAPPING
  } else if (entityName === LOCATION) {
    entityNameQueryValue = LOCATION
    sortBy = '`locationMoniker` DESC'
  } else if (entityName === TRMI_VAN) {
    entityNameQueryValue = TRMI_VAN
  } else if (entityName === TRMI_VAN_OPERATING_STATUS) {
    entityNameQueryValue = TRMI_VAN_OPERATING_STATUS
  } else if (entityName === TRMI_WORK_ORDER) {
    entityNameQueryValue = TRMI_WORK_ORDER
  }

  return {
    endpoint: `${process.env.REACT_APP_ORIGIN}/bi-reports/get-all?`,
    entityNameQueryValue,
    sortBy,
  }
}

export default getRequestInformation
