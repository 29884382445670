import TextField from '@mui/material/TextField'
import React, { Dispatch, SetStateAction } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import styles from './styles.module.css'
interface ITrOrderNumberSearchBarProps {
  trNumber: string
  setTrNumber: Dispatch<SetStateAction<string>>
  fetchOrderData: () => Promise<void>
}
export default function TrNumberSearchBar(props: ITrOrderNumberSearchBarProps) {
  const { trNumber, setTrNumber, fetchOrderData } = props

  return (
    <div className={styles.searchContainer}>
      <TextField
        label='TR Order'
        style={{ width: '150px' }}
        value={trNumber}
        onChange={(e) => setTrNumber(e.target.value)}
        autoComplete='false'
      />
      <PrimaryButton
        buttonName='Book Appointment'
        onClick={fetchOrderData}
        disabled={!trNumber}
      />
    </div>
  )
}
