export const removeDiscountFromOrder = (orderObjectId: string, discountObjectId: string, username?: string) => {
	let url = `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/discount/${discountObjectId}`

	const query = new URLSearchParams()
	if (username) {
		query.set('username', username)
	}

	if (query.toString()) {
		url += `?${query.toString()}`
	}

	return {
		method: 'DELETE',
		url,
	}
}