import Decimal from 'decimal.js'

/* This file is duplicated at:
 * app/utils/decimal-wrapper/DecimalWrapper.ts.
 *
 * Any changes made here should be replicated there.
 *
 * This class is a wrapper around the Decimal.js library. It is used to
 * perform math without having to worry about floating point errors.
 */
class DecimalWrapper {
  decimal: Decimal

  constructor(value: number) {
    this.decimal = new Decimal(value)
  }

  static mul(decimalPlaces: number, ...args: number[]) {
    const result = args.reduce(
      (acc, curr) => acc.mul(new Decimal(curr)),
      new Decimal(1),
    )
    return result.toDecimalPlaces(decimalPlaces).toNumber()
  }

  static add(decimalPlaces: number, ...args: number[]) {
    const result = args.reduce(
      (acc, curr) => acc.add(new Decimal(curr)),
      new Decimal(0),
    )
    return result.toDecimalPlaces(decimalPlaces).toNumber()
  }

  static sub(decimalPlaces: number, ...args: number[]) {
    if (args.length === 0) return 0
    const result = args
      .slice(1)
      .reduce((acc, curr) => acc.sub(new Decimal(curr)), new Decimal(args[0]))
    return result.toDecimalPlaces(decimalPlaces).toNumber()
  }

  static div(decimalPlaces: number, ...args: number[]) {
    if (args.length === 0) return NaN
    const result = args
      .slice(1)
      .reduce((acc, curr) => acc.div(new Decimal(curr)), new Decimal(args[0]))
    return result.toDecimalPlaces(decimalPlaces).toNumber()
  }

  static round(decimalPlaces: number, value: number) {
    return new Decimal(value).toDecimalPlaces(decimalPlaces).toNumber()
  }
}

export default DecimalWrapper
