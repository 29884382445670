import React, { useContext } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { LocationModal } from '../../location-modal/components/container-modal/LocationModal'
import styles from './styles.module.css'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../global/constants/permissions'

export default function NewLocation() {
  const { user } = useContext(UserContext)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClosed = () => {
    setOpen(false)
  }

  return (
    <div className={styles.makeAppContainer}>
      <div className={styles.inputsContainer}>
        <PrimaryButton
          buttonName='Create New Location'
          onClick={handleOpen}
          disabled={
            !hasPermission(user).allow(
              PERMISSIONS.LOCATIONS.CATEGORY,
              PERMISSIONS.LOCATIONS.ENTRIES.CREATE_BUTTON.NAME,
            )
          }
        />
      </div>
      {open && (
        <LocationModal
          isOpen={open}
          handleClosed={handleClosed}
        />
      )}
    </div>
  )
}
