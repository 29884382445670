export const scheduledToPending = (body: any) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/scheduled-to-pending`,
		body,
	}
}
export const getRescheduledReason = (orderId: string) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/get-rescheduled-reason/${orderId}`,
	}
}