import { ReactNode } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { IVehicleInformationSelectProps } from '../VehicleInformationForm'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getModels } from '../../api'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export default function ModelInformationSelect(
  props: IVehicleInformationSelectProps,
) {
  const { formData } = props

  let menuItems = [] as ReactNode

  let { data, isLoading, error } = useFetch<string[]>(
    getModels(formData.make, formData.year),
  )

  if (data) {
    menuItems = data.map((item: any) => {
      return (
        <MenuItem
          key={item}
          value={item}
        >
          {item}
        </MenuItem>
      )
    })
  } else if (error) {
    menuItems = [
      <MenuItem value={'Cobalt Sedan with 5 Lug Wheels'}>
        Error - No models available
      </MenuItem>,
    ]
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={props.label}
      value={props.formData.model}
      select
      SelectProps={selectProps}
      onChange={(e) =>
        props.onChange({ ...props.formData, model: e.target.value })
      }
    >
      {menuItems}
    </TextField>
  )
}
