// Update the app/constants/permissions.ts file when this file is updated
const ACTIONS = {
  VIEW: 'view',
  EDIT: 'edit',
  ALLOW: 'allow',
}

enum CATEGORIES {
  CASES = 'CASES',
  CS_REPORTS = 'CS_REPORTS',
  DASHBOARDS = 'DASHBOARDS',
  HOLIDAYS = 'HOLIDAYS',
  LOCATIONS = 'LOCATIONS',
  NAV_BAR = 'NAV_BAR',
  PROMO_CODES = 'PROMO_CODES',
  QUEUES = 'QUEUES',
  SCHEDULE_VIEW = 'SCHEDULE_VIEW',
  SERVICES = 'SERVICES',
  SMALL_PART_INVENTORY = 'SMALL_PART_INVENTORY',
  TECHNICIANS = 'TECHNICIANS',
  VANS = 'VANS',
  WORK_ORDERS = 'WORK_ORDERS',
}

type IPermissions = {
  [key in CATEGORIES]: {
    CATEGORY: string
    ENTRIES: {
      [key: string]: {
        NAME: string
        ALLOWED_ACTIONS: string[]
      }
    }
  }
}

const PERMISSIONS: IPermissions = {
  DASHBOARDS: {
    CATEGORY: CATEGORIES.DASHBOARDS,
    ENTRIES: {
      VIEW_FUTURE_PERCENT_BOOKED: {
        NAME: 'View Future Percent Booked',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      DOWNLOAD_CSV: {
        NAME: 'Download CSV',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  CASES: {
    CATEGORY: CATEGORIES.CASES,
    ENTRIES: {
      EDIT_CASES: {
        NAME: 'Edit Cases',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  CS_REPORTS: {
    CATEGORY: CATEGORIES.CS_REPORTS,
    ENTRIES: {
      CS_TEAM_TOOLS_CSV_UPLOADS: {
        NAME: 'CS Team Tools Csv Uploads',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  HOLIDAYS: {
    CATEGORY: CATEGORIES.HOLIDAYS,
    ENTRIES: {
      CREATE_BUTTON: {
        NAME: 'Create Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  LOCATIONS: {
    CATEGORY: CATEGORIES.LOCATIONS,
    ENTRIES: {
      CREATE_BUTTON: {
        NAME: 'Create Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      WORKDAY_INFO: {
        NAME: 'Workday Info',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      LOCATION_INFO: {
        NAME: 'Location Info',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      ROUTING: {
        NAME: 'Routing',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      COST_CENTER: {
        NAME: 'Cost Center',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      ZONE_DEFINITIONS: {
        NAME: 'Zone Definitions',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      ZIP_CODES: {
        NAME: 'Zip Codes',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      SURVEY_URLS: {
        NAME: 'Survey URLs',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      PRODUCTION_MODE_BUTTON: {
        NAME: 'Production Mode Button',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      USING_TOLLS_BUTTON: {
        NAME: 'Using Tolls Button',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      STANDBY_QUEUE_BUTTON: {
        NAME: 'Standby Queue Button',
        ALLOWED_ACTIONS: [ACTIONS.EDIT, ACTIONS.VIEW],
      },
      DEACTIVATE_BUTTON: {
        NAME: 'Deactivate Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  NAV_BAR: {
    CATEGORY: CATEGORIES.NAV_BAR,
    ENTRIES: {
      VIEW_BI_REPORT_UPLOADS: {
        NAME: 'View BI Report Uploads',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_CASES: {
        NAME: 'View Cases',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_DASHBOARDS: {
        NAME: 'View Dashboards',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_HOLIDAYS: {
        NAME: 'View Holidays',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_LOCATIONS: {
        NAME: 'View Locations',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_PROMO_CODES: {
        NAME: 'View Promo Codes',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_QUEUES: {
        NAME: 'View Queues',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_REPORTS: {
        NAME: 'View Reports',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_SERVICES: {
        NAME: 'View Services',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_SMALL_PART_INVENTORY: {
        NAME: 'View Small Part Inventory',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_TECHNICIANS: {
        NAME: 'View Technicians',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_USER_MANAGEMENT: {
        NAME: 'View User Management',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_VANS: {
        NAME: 'View Vans',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  PROMO_CODES: {
    CATEGORY: CATEGORIES.PROMO_CODES,
    ENTRIES: {
      CREATE_BUTTON: {
        NAME: 'Create Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  QUEUES: {
    CATEGORY: CATEGORIES.QUEUES,
    ENTRIES: {
      ASSIGN_RESCHEDULE_ENTRIES: {
        NAME: 'Assign Reschedule Entries',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  SCHEDULE_VIEW: {
    CATEGORY: CATEGORIES.SCHEDULE_VIEW,
    ENTRIES: {
      CAN_SCHEDULE: {
        NAME: 'Can Schedule',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  SERVICES: {
    CATEGORY: CATEGORIES.SERVICES,
    ENTRIES: {
      VIEW_SERVICE_HISTORY: {
        NAME: 'View Service History',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      MAKE_PRICE_CHANGE_BUTTON: {
        NAME: 'Make Price Change Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  SMALL_PART_INVENTORY: {
    CATEGORY: CATEGORIES.SMALL_PART_INVENTORY,
    ENTRIES: {
      EDIT_SMALL_PART_INVENTORY: {
        NAME: 'Edit Small Part Inventory',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      CREATE_BUTTON: {
        NAME: 'Create Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  TECHNICIANS: {
    CATEGORY: CATEGORIES.TECHNICIANS,
    ENTRIES: {
      NEW_TECHNICIAN_BUTTON: {
        NAME: 'New Technician Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_TECHNICIANS: {
        NAME: 'Edit Technicians',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  VANS: {
    CATEGORY: CATEGORIES.VANS,
    ENTRIES: {
      EDIT_MAKE: {
        NAME: 'Edit Make',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_MODEL: {
        NAME: 'Edit Model',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_VAN_DETAILS: {
        NAME: 'Edit Van Details',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_VIN: {
        NAME: 'Edit Vin',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_YEAR: {
        NAME: 'Edit Year',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      NEW_VAN_BUTTON: {
        NAME: 'New Van Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  WORK_ORDERS: {
    CATEGORY: CATEGORIES.WORK_ORDERS,
    ENTRIES: {
      INDIVIDUAL_WORK_ORDER: {
        NAME: 'Individual Work Order',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      FORCE_APPOINTMENT: {
        NAME: 'Force Appointment',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
}

export { CATEGORIES, ACTIONS, PERMISSIONS }
