import { useState, useEffect, useMemo } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { getFuturePercentBookedData } from './api'
import moment from 'moment'

interface LocationData {
  d2a: number
  workOrders: number[]
  availableSlots: number[]
  percentBooked: number[]
}

interface FuturePercentBookedData {
  locationsData: Record<string, LocationData>
  totalD2A: number
  totalPercentBooked: number[]
}

function FuturePercentBooked() {
  const [isLoading, setIsLoading] = useState(true)
  const [tableState, setTableState] = useState<FuturePercentBookedData>({
    locationsData: {},
    totalD2A: 0,
    totalPercentBooked: [],
  })

  async function fetchDashboardData() {
    const data = await fetchData<FuturePercentBookedData>(
      getFuturePercentBookedData(),
    )
    setTableState(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDashboardData()
  }, [])

  const renderTableRows = useMemo(() => {
    const locationData = tableState.locationsData
    const locationKeys = Object.keys(locationData)
    const rows = locationKeys.map((key) => {
      const data: LocationData = locationData[key]

      return (
        <>
          <tr className={styles.row}>
            <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
              {key}
            </td>
            {/* <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}>{data.d2a}</td> */}
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              Work Orders
            </td>
            {data.workOrders.map((workOrder) => (
              <td>{workOrder}</td>
            ))}
          </tr>
          <tr className={styles.row}>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
            ></td>
            {/* <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}></td> */}
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              Available Slots
            </td>
            {data.availableSlots.map((slot) => (
              <td>{slot}</td>
            ))}
          </tr>
          <tr className={styles.row}>
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowOne}`}
            ></td>
            {/* <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}></td> */}
            <td
              className={`${styles.highlightedCell} ${styles.stickyRowTwo} ${styles.test}`}
            >
              %Booked
            </td>
            {data.percentBooked.map((percent) => (
              <td>{percent}</td>
            ))}
          </tr>
        </>
      )
    })

    rows.push(
      <>
        <tr className={styles.row}>
          <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
            TRMI Total
          </td>
          {/* <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}>{tableState.totalD2A}</td> */}
          <td className={`${styles.highlightedCell} ${styles.stickyRowTwo}`}>
            %Booked
          </td>
          {tableState.totalPercentBooked.map((percent) => (
            <td>{percent}</td>
          ))}
        </tr>
      </>,
    )

    return rows
  }, [tableState])

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>
  }

  const today = moment().utc().startOf('day')

  return (
    <div className={styles.tableWrapperContainer}>
      <table className={styles.futurePercentBookedTable}>
        <thead>
          <tr className={styles.headerRow}>
            <th
              className={`${styles.headerCell} ${styles.stickyHeaderOne}`}
            ></th>
            {/* <th className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}>D2A</th> */}
            <th
              className={`${styles.headerCell} ${styles.stickyHeaderTwo}`}
            ></th>
            {Array.from({ length: 14 }, (_, i) => i + 1).map((i: number) => (
              <th
                className={styles.headerCell}
              >{`(${i}) ${today.clone().add(i, 'day').format('M/D')}`}</th>
            ))}
            <th className={styles.headerCell}>7day%</th>
            <th className={styles.headerCell}>10day%</th>
            <th className={styles.headerCell}>14day%</th>
          </tr>
        </thead>
        <tbody>{renderTableRows}</tbody>
      </table>
    </div>
  )
}

export default FuturePercentBooked
