const DEV_TEAM_EMAILS = {
  CHELSEA: 'ckimmell@asaptire.com',
  DYLAN: 'dherthoge@asaptire.com',
  IAN: 'iblakeslee@asaptire.com',
  JOSH: 'jmullet@asaptire.com',
  ROBERT: 'rramsey@asaptire.com',
}

const DEV_TEAM_EMAILS_ARRAY = Object.values(DEV_TEAM_EMAILS)

const SES_EMAILS = {
  MOBILE_INSTALL: 'mobileinstall@tirerack.com',
  NO_REPLY: 'no-reply@mi.tirerack.com',
  INFO_ASAP: 'info@asaptire.com',
  FLEET_SERVICES: 'mobilefleetservices@tirerack.com',
}

export { DEV_TEAM_EMAILS, DEV_TEAM_EMAILS_ARRAY, SES_EMAILS }
