import styles from './styles.module.css'
import PrimaryCheckbox from '../../../../../global/components/checkbox/Checkbox'
import OrderSummary from '../order-summary/OrderSummary'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import { timestampToDateTime, timestampToTime } from './utils'
import { forwardRef, useState, useImperativeHandle, useContext } from 'react'
import { enableNotification, disableNotification } from './api'
import CircularProgress from '@mui/material/CircularProgress'
import { updateOrderStatusToScheduled } from '../choose-service/api'
import { MODES } from '../../../../../global/constants/scheduler'
import { fetchData } from '../../../../../global/utils/fetch'
import { INotificationRule } from '../../../../../../../app/entities/NotificationRule'
import { ISchedulerStateProps } from '../container-modal/initialData'
import { WoDetailContext } from '../../../../../work-orders/components/context/context'
import { GONE, OK } from 'http-status'
import { NOTIFICATION_RULES } from '../../../../../global/constants/notification-rule'

export const ConfirmAppointment = forwardRef(
  (
    {
      setDisabled,
      setSchedulerState,
      schedulerState,
      mode,
      toggleToastError,
      setRouteIndex,
    }: IScheduleModalComponentProps,
    ref?,
  ) => {
    const { data: order } = useContext(WoDetailContext)
    const {
      customerInformation,
      selectedAvailabilityCalendarSlot,
      customerId,
      orderId,
    } = schedulerState

    let fromTime, toTime

    // this flow is for when the user has just selected a time slot
    if (selectedAvailabilityCalendarSlot) {
      fromTime = selectedAvailabilityCalendarSlot.fromTime
      toTime = selectedAvailabilityCalendarSlot.toTime
    }
    // this flow is for when the user previously selected a time slot
    else if (order && order.timeSlot) {
      fromTime = order.timeSlot.fromTime
      toTime = order.timeSlot.toTime
    }

    const [isLoading, setIsLoading] = useState(false)
    const [smsService, setSmsService] = useState(true)
    const [smsUpdates, setSmsUpdates] = useState(true)
    const [emailService, setEmailService] = useState(true)

    async function toggleSmsService() {
      if (smsService) {
        await fetchData<INotificationRule>(
          disableNotification(customerId, NOTIFICATION_RULES.transactionalSms),
        )
      } else {
        await fetchData<INotificationRule>(
          enableNotification(customerId, NOTIFICATION_RULES.transactionalSms),
        )
      }

      setSmsService(!smsService)
    }

    async function toggleSmsUpdate() {
      if (smsUpdates) {
        await fetchData<INotificationRule>(
          disableNotification(customerId, NOTIFICATION_RULES.marketingSms),
        )
      } else {
        await fetchData<INotificationRule>(
          enableNotification(customerId, NOTIFICATION_RULES.marketingSms),
        )
      }
      setSmsUpdates(!smsUpdates)
    }

    async function toggleEmailService() {
      if (emailService) {
        await fetchData<INotificationRule>(
          disableNotification(customerId, NOTIFICATION_RULES.marketingEmail),
        )
      } else {
        await fetchData<INotificationRule>(
          enableNotification(customerId, NOTIFICATION_RULES.marketingEmail),
        )
      }

      setEmailService(!emailService)
    }
    useImperativeHandle(ref, () => ({
      async handleNextClick(callback: Function) {
        setDisabled(true)
        setIsLoading(true)

        try {
          // we retrieve a "raw" response because we want to check the status code
          const updateStatusToScheduledRes = await fetchData<Response>(
            updateOrderStatusToScheduled(orderId),
          )

          const chosenTimeSlotIsNoLongerAvailable =
            updateStatusToScheduledRes.status === GONE
          if (chosenTimeSlotIsNoLongerAvailable) {
            // reset the selected time slot
            setSchedulerState((prevState: ISchedulerStateProps) => ({
              ...prevState,
              selectedAvailabilityCalendarSlot: undefined,
            }))
            alert(
              'The time slot you selected is no longer available. Please select another one.',
            )
            setRouteIndex && setRouteIndex(2) // go back to the time slot selection screen
            return
          } else if (updateStatusToScheduledRes.status !== OK) {
            console.error('An error occurred:', updateStatusToScheduledRes)
            throw new Error('Something went wrong. Please try again.')
          }

          // await queryOrderData(order.objectId)
          // handleClosed()
          ///====
          // await fetchData<IOrder>(updateOrderStatusToScheduled(orderId))
          // console.log('flow 1')
          setSchedulerState((prevState: ISchedulerStateProps) => ({
            ...prevState,
            status: 'scheduled',
          }))
          callback(true)
        } catch (error) {
          console.error('An error occurred:', error)
          if (mode === MODES.CUSTOMER) {
            toggleToastError && toggleToastError(true)
          } else {
            alert(error)
          }
          callback(false)
          setDisabled(false)
        } finally {
          setIsLoading(false)
        }
      },
    }))
    return (
      <div className={styles.confirmAppointmentContainer}>
        {isLoading && (
          <div className={styles.overlay}>
            <div className={styles.progressContainer}>
              <CircularProgress size={80} />
            </div>
          </div>
        )}
        <div className={styles.appointmentContainer}>
          <div className={styles.appointmentTimeContainer}>
            <div className={styles.appointmentTimeContainerTitle}>
              Appointment
            </div>
            <div className={styles.confirmAppointmentSmallText}>
              {timestampToDateTime(fromTime)}
            </div>
            <div
              className={styles.confirmAppointmentSmallText}
            >{`${timestampToTime(fromTime)} - ${timestampToTime(toTime)}`}</div>
          </div>
          <div className={styles.appointmentTimeContainer}>
            <div className={styles.appointmentTimeContainerTitle}>
              Installation Address
            </div>
            <div className={styles.confirmAppointmentSmallText}>
              {customerInformation.address}
            </div>
            <div
              className={styles.confirmAppointmentSmallText}
            >{`${customerInformation.city}, ${customerInformation.state} ${customerInformation.zipCode}`}</div>
          </div>
          <div className={styles.appointmentTimeContainer}>
            <div className={styles.appointmentTimeContainerTitle}>Vehicle</div>
            <div className={styles.confirmAppointmentSmallText}>
              {customerInformation.make}
            </div>
            <div className={styles.confirmAppointmentSmallText}>
              {customerInformation.model}
            </div>
            <div className={styles.confirmAppointmentSmallText}>
              {customerInformation.year}
            </div>
            <div className={styles.confirmAppointmentSmallText}>
              {customerInformation.variant}
            </div>
          </div>
        </div>
        <div className={styles.appointmentContainer}>
          <div className={styles.appointmentTimeContainer}>
            <div className={styles.appointmentTimeContainerTitle}>Contact</div>
            <div
              className={styles.confirmAppointmentSmallText}
            >{`${customerInformation.firstName} ${customerInformation.lastName}`}</div>
            <div
              className={styles.confirmAppointmentSmallText}
            >{`${customerInformation.email}`}</div>
            <div
              className={styles.confirmAppointmentSmallText}
            >{`${customerInformation.phone}`}</div>
          </div>
          <PrimaryCheckbox
            checked={smsService}
            onChange={() => toggleSmsService()}
            label='Updates via SMS text message (data rates may apply)'
            className={`${styles.confirmAppointmentSmallText} ${styles.gap}`}
          />
          <PrimaryCheckbox
            checked={smsUpdates}
            onChange={() => toggleSmsUpdate()}
            label='Service reminders via SMS text message (data rates may apply)'
            className={`${styles.confirmAppointmentSmallText} ${styles.gap}`}
          />
          <PrimaryCheckbox
            checked={emailService}
            onChange={() => toggleEmailService()}
            label='Service reminders and offers via email'
            className={`${styles.confirmAppointmentSmallText} ${styles.gap}`}
          />
        </div>
        <OrderSummary
          schedulerState={schedulerState}
          mode={mode}
          toggleToastError={toggleToastError}
        />
      </div>
    )
  },
)
