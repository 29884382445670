import { S3 } from 'aws-sdk'
import { IS_ENV_LOCAL } from '../constants/env'

export interface S3TemporaryCredentials {
  s3: {
    bucket: string
    key: string
    credentials: {
      accessKeyId: string
      secretAccessKey: string
      sessionToken: string
    }
  }
}

function getS3(credentials: S3TemporaryCredentials) {
  const { accessKeyId, secretAccessKey, sessionToken } =
    credentials.s3.credentials

  const s3 = new S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    sessionToken: sessionToken,
    region: 'us-east-1',
    endpoint: IS_ENV_LOCAL ? 'http://localhost:4566' : undefined,
    s3ForcePathStyle: IS_ENV_LOCAL ? true : undefined,
  })

  return s3
}

export default getS3
