import { refreshToken } from './refreshToken'

export interface IFetchDataProps<T = any> {
  url: string
  method: string
  params?: { [key: string]: string }
  body?: T
  returnRaw?: boolean
  headers?: { [key: string]: any } | null
  headersToRemove?: string[]
}

export const fetchData = async <R, T = any>(
  props: IFetchDataProps<T>,
  signal?: AbortSignal,
): Promise<R> => {
  const idToken = await refreshToken()

  let url = new URL(props.url)

  if (props.params) {
    Object.keys(props.params).forEach((key) =>
      url.searchParams.append(key, props.params![key]),
    )
  }

  const options = {
    method: props.method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken ? `Bearer ${idToken}` : '',
    },
    body:
      props.body instanceof FormData ? props.body : JSON.stringify(props.body),
    signal,
  } as RequestInit

  // Remove headers if null
  if (props.headers === null) {
    delete options.headers
  }
  // Add headers if not null
  else if (props.headers) {
    options.headers = {
      ...options.headers,
      ...props.headers,
    }
  }

  // Remove headers if specified
  if (props.headersToRemove?.length) {
    props.headersToRemove.forEach((header) => {
      delete (options.headers as any)![header]
    })
  }

  try {
    const resp = await fetch(url.toString(), options)

    if (props.returnRaw) return resp as any

    if (resp.status === 204) return [] as any
    const data = await resp.json()

    if (data?.error || !resp.ok) {
      throw new Error(
        data.error?.message ||
          data.error?.stack ||
          data.error?.error ||
          data.error,
      )
    }
    return data as R
  } catch (error: any) {
    if (props.returnRaw) return error as any
    throw new Error(`Error making API call: ${error}`)
  }
}
