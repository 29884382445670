export const getIsOrderPaid = (orderObjectId: string | undefined) => {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/is-order-paid`,
	}
}

export const calculateCost = (orderId: string | undefined) => {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/calculate-cost`,
	}
}