import { useAuthenticator } from '@aws-amplify/ui-react'
import CircularProgress from '@mui/material/CircularProgress'
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { IOrder } from '../../../../../../../app/entities/Order'
import { ITiresCombination } from '../../../../../../../app/entities/TiresCombination'
import {
  Address,
  AvailabilityCalendarDay,
  AvailabilityCalendarSlot,
} from '../../../../../../../app/types/scheduling'
import { STATUSES } from '../../../../../global/constants/order-status'
import { MODES } from '../../../../../global/constants/scheduler'
import { SERVICE_NAMES } from '../../../../../global/constants/service'
import { fetchData } from '../../../../../global/utils/fetch'
import getUsername from '../../../../../global/utils/getUsername'
import { WoDetailContext } from '../../../../../work-orders/components/context/context'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import {
  getAvailabilityCalendar,
  getAvailableCalendarForOrder,
} from '../customer-information/api'
import Day from './Day'
import {
  getCurrentRescheduleReason,
  getOrderByOrderId,
  rescheduleAppointment,
  scheduleAppointment,
  scheduledToPending,
  setTimeSlot,
} from './api'
import styles from './styles.module.css'
import { GONE } from 'http-status'

const STATUSES_AVAILABLE_FOR_RESCHEDULE = [
  STATUSES.scheduled,
  STATUSES.en_route,
]

type scheduleAppointmentResponse = {
  redirectUrl: string
  orderId: string
}

function getTireCount(tires: ITiresCombination | undefined) {
  if (!tires) return 0

  const { rearCount, frontCount } = tires
  return (rearCount || 0) + (frontCount || 0)
}

//TODO rework interfaces to match DayAvailability type
export const AppointmentSlots = forwardRef(
  (props: IScheduleModalComponentProps, ref) => {
    let formattedSlots: Array<JSX.Element> = []
    const [calendarDays, setCalendarDays] = useState(
      [] as AvailabilityCalendarDay[],
    )
    const [isLoading, setLoading] = useState(false)
    const [nextIsLoading, setNextIsLoading] = useState(false)
    const [availableCalendarRefreshCount, setAvailableCalendarRefreshCount] =
      useState(0)

    const { user } = useAuthenticator((context) => [context.user])

    const {
      schedulerState,
      mode,
      toggleToastError,
      rescheduleReason,
      setDisabled,
      viewOnly,
    } = props
    const { customerInformation } = schedulerState
    const { data: order } = useContext(WoDetailContext)
    let tires: ITiresCombination | undefined = undefined

    if (
      (
        [
          MODES.CUSTOMER,
          MODES.CUSTOMER_RESCHEDULE,
          MODES.SCHEDULER,
          MODES.TR_SALES_BOOKING,
        ] as string[]
      ).includes(mode)
    ) {
      // this is the normal CS Portal flow
      tires = schedulerState.tires as unknown as ITiresCombination
    } else if (mode === MODES.FINISH) {
      tires = order.orderVehicles?.[0].tiresCombination
    } else if (mode === MODES.EDIT) {
      tires = order.orderVehicles?.[0].tiresCombination
    } else {
      throw new Error(`Mode not supported: ${mode}`)
    }

    const tireCount = getTireCount(tires)

    const address: Address = {
      address: customerInformation.address,
      state: customerInformation.state,
      zipCode: customerInformation.zipCode,
      city: customerInformation.city,
    }

    function handleRescheduleResponse(httpResponse: Response) {
      if (!httpResponse.ok) {
        console.error('rescheduleAppointment error', httpResponse)
        setSelectedAvailabilityCalendarSlot(undefined)
        alert(
          'There was an error when rescheduling the appointment. The appointment slot you chose may no longer be available. Please try again.',
        )
        setAvailableCalendarRefreshCount((prev) => prev + 1)
        if (httpResponse.status === GONE) {
          throw new Error('Appointment slot is no longer available')
        } else {
          throw new Error('Error rescheduling appointment')
        }
      }
    }

    useImperativeHandle(ref, () => ({
      async handleNextClick(callback: Function) {
        setNextIsLoading(true)
        props.setDisabled(true)
        try {
          const { timeSlot } =
            schedulerState.selectedAvailabilityCalendarSlot as AvailabilityCalendarSlot

          let orderId = schedulerState.orderId
          // if we are scheduling from scratch, we won't have an orderId
          let order = orderId
            ? await fetchData<IOrder>(getOrderByOrderId(orderId))
            : undefined

          // if we are "finishing" an order, we don't need to schedule an
          // appointment since it is already scheduled, just update the time slot
          const customerCanReschedule =
            STATUSES_AVAILABLE_FOR_RESCHEDULE.includes(order?.status ?? '')
          const customerShouldReschedule =
            mode === MODES.CUSTOMER_RESCHEDULE && customerCanReschedule
          const shouldSetTimeSlot =
            mode === MODES.CUSTOMER ||
            mode === MODES.FINISH ||
            mode === MODES.TR_SALES_BOOKING //presently TR Sales has its own flow and this is unused
          if (shouldSetTimeSlot) {
            await fetchData<IOrder>(setTimeSlot(timeSlot, orderId))
          } else if (customerShouldReschedule) {
            const needToMoveToPendingFirst =
              order?.status === STATUSES.scheduled
            if (needToMoveToPendingFirst) {
              await fetchData(
                scheduledToPending({
                  orderObjectId: orderId,
                  rescheduleReason,
                }),
              )
            }
            const resp = await fetchData<Response>(
              rescheduleAppointment(
                timeSlot,
                orderId,
                rescheduleReason,
                getUsername(user),
              ),
            )
            handleRescheduleResponse(resp)
          } else {
            let res = await fetchData<scheduleAppointmentResponse>(
              scheduleAppointment(schedulerState),
            )
            orderId = res.orderId
            order = await fetchData<IOrder>(getOrderByOrderId(orderId))
          }

          const stateTireFee = order?.orderServices?.find(
            (orderService) =>
              orderService.label === SERVICE_NAMES.STATE_TIRE_FEE,
          )

          props.setSchedulerState({
            ...schedulerState,
            orderId,
            orderVehicleId: order?.orderVehicles?.[0]?.objectId,
            customerId: order?.customer?.objectId,
            stateTireFee,
          })
          callback(true)
        } catch (error) {
          callback(false)
          toggleToastError && toggleToastError(true)
          console.error(error)
          alert(error)
        } finally {
          props.setDisabled(false)
          setNextIsLoading(false)
        }
      },
      async handleSaveClick() {
        props.setDisabled(true)
        try {
          const { selectedAvailabilityCalendarSlot } = schedulerState
          const { timeSlot } =
            selectedAvailabilityCalendarSlot as AvailabilityCalendarSlot

          if (schedulerState.status === STATUSES.quote) {
            const timeSlot =
              schedulerState.selectedAvailabilityCalendarSlot?.timeSlot
            await fetchData<IOrder>(setTimeSlot(timeSlot, order.objectId))
          } else {
            // if there is no rescheduleReason, we should try to use the most recent one
            let tmpRescheduleReason = rescheduleReason
            if (!tmpRescheduleReason) {
              tmpRescheduleReason = await fetchData<string>(
                getCurrentRescheduleReason(order.objectId),
              )
            }
            const resp = await fetchData<Response>(
              rescheduleAppointment(
                timeSlot,
                order.objectId,
                tmpRescheduleReason,
                getUsername(user),
              ),
            )
            handleRescheduleResponse(resp)
          }
        } catch (error) {
          throw error
        }
        props.setDisabled(false)
      },
    }))
    function setSelectedAvailabilityCalendarSlot(
      availabilityCalendarSlot: AvailabilityCalendarSlot | undefined,
    ) {
      props.setSchedulerState({
        ...schedulerState,
        selectedAvailabilityCalendarSlot: availabilityCalendarSlot,
      })
      setDisabled(false)
    }

    useEffect(() => {
      async function runFunc() {
        try {
          setLoading(true)

          let getAvailableCalendarCall

          switch (mode) {
            case MODES.SCHEDULER:
              getAvailableCalendarCall = getAvailabilityCalendar(
                address,
                tireCount,
                schedulerState.service,
                schedulerState.availableCalenderFromDateOverride,
              )
              break
            case MODES.TR_SALES_BOOKING:
              getAvailableCalendarCall = getAvailableCalendarForOrder(
                schedulerState.orderId,
                schedulerState.showAdditionalDates,
              )
              break
            default:
              getAvailableCalendarCall = getAvailableCalendarForOrder(
                schedulerState.orderId,
              )
          }

          const res = await fetchData<any>(getAvailableCalendarCall)
          setCalendarDays(res)

          setLoading(false)
        } catch (err) {
          console.error(err)
          alert(err)
        }
      }

      runFunc()
      props.setDisabled(
        Boolean(!schedulerState.selectedAvailabilityCalendarSlot),
      )
    }, [
      schedulerState.tires,
      schedulerState.availableCalenderFromDateOverride,
      availableCalendarRefreshCount,
    ])

    calendarDays.forEach((day, idx) => {
      const { date, slots } = day
      formattedSlots.push(
        <Day
          setSelectedAvailabilityCalendarSlot={
            setSelectedAvailabilityCalendarSlot
          }
          schedulerState={props.schedulerState}
          date={date}
          slots={slots}
          key={idx}
          viewOnly={viewOnly}
        />,
      )
    })

    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <div className={styles.appointmentSlotsContainer}>
        {nextIsLoading && (
          <div className={styles.overlay}>
            <div className={styles.progressContainer}>
              <CircularProgress size={80} />
            </div>
          </div>
        )}
        <div className={styles.monthsHeader}></div>
        <div className={styles.slotsContainer}>
          <div className={styles.daysContainer}>{formattedSlots}</div>
        </div>
      </div>
    )
  },
)
