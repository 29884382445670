import React, { useState } from 'react'
import Box from '@mui/material/Box'
import styles from './styles.module.css'
import TipContainer from './tips-form/TipContainer'
import TipConfirmation from './confirmation-screen/TipConfirmation'
export interface ITipConfirmationProps {
  tipConfirmation: ITipConfirmationDataProps
  setTipConfirmation: Function
}
export interface ITipConfirmationDataProps {
  confirmed: boolean
  amount: string
}
export default function TipsPage() {
  const [tipConfirmation, setTipConfirmation] = useState({
    confirmed: false,
    amount: '0',
  })

  return (
    <Box className={styles.tipsPage}>
      {tipConfirmation.confirmed ? <TipConfirmation amount={tipConfirmation.amount} /> : <TipContainer tipConfirmation={{ tipConfirmation, setTipConfirmation }} />}
    </Box>
  )
}
