import { MouseEventHandler } from 'react'
import styles from './styles.module.css'

interface DeleteButtonProps {
	buttonName: string
	onClick: MouseEventHandler
	disabled?: boolean
}

export default function DeleteButton(props: DeleteButtonProps) {
	const { buttonName, disabled, onClick } = props

	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className={styles.deleteButton}>
			<span className={styles.deleteButtonText}>{buttonName}</span>
		</button>
	)
}
