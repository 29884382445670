import { Box, TextField } from '@mui/material'
import Modal from '@mui/material/Modal'
import { MenuItem } from '@mui/material'
import styles from './styles.module.css'
import generateMenuItems from '../../../../global/utils/generate-menu-items'
import { OPERATING_STATUSES_ARRAY } from '../../../../global/constants/van'
import { VanContext } from '../../../../global/context/van-context/VanContext'
import { useContext, useEffect, useState } from 'react'
import transformDate from '../../common/transform-date'
import formatDate from '../../common/format-date'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import moment from 'moment'
import determineIsDateValid from '../common/determine-is-date-valid'

interface IChangeOperatingStatusModalProps {
  handleClosed: Function
  isOpen: boolean

  setOperatingStatusNote: Function
}

interface IFormData {
  operatingStatus: string
  operatingStatusStartDate: Date | undefined
  operatingStatusNote: string
}

const DEFAULT_FORM_DATA: IFormData = {
  operatingStatus: '',
  operatingStatusStartDate: undefined,
  operatingStatusNote: '',
}

function ChangeOperatingStatusModal({
  handleClosed,
  isOpen,
  setOperatingStatusNote,
}: IChangeOperatingStatusModalProps) {
  const { vanData, setVanData, queryVanData } = useContext(VanContext)

  const [formData, setFormData] = useState<IFormData>(DEFAULT_FORM_DATA)
  const [isFormDataValid, setIsFormDataValid] = useState(false)

  const [menuItems] = useState(
    generateMenuItems(
      OPERATING_STATUSES_ARRAY.filter(
        (operatingStatus) => operatingStatus !== vanData.operatingStatus,
      ),
    ),
  )

  useEffect(() => {
    const isOperatingStatusValid = Boolean(formData.operatingStatus)
    const isOperatingStatusStartDateValid = determineIsDateValid(
      formData.operatingStatusStartDate,
    )

    const tempIsFormDataValid =
      isOperatingStatusValid && isOperatingStatusStartDateValid
    setIsFormDataValid(tempIsFormDataValid)
  }, [formData])

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={() => handleClosed()}
    >
      <Box className={styles.editVanSubModal}>
        <div className={styles.editVanSubModalPageTitle}>
          Change Operating Status
        </div>
        <div className={styles.editVanSubModalContent}>
          <div className={styles.requiredFieldContainer}>
            <TextField
              type='date'
              label='Operating Status Start Date'
              name='startDate'
              InputLabelProps={{ shrink: true }}
              sx={{ width: '162px', display: '' }}
              InputProps={{
                inputProps: {
                  min: moment(vanData.operatingStatusStartDate).format(
                    'YYYY-MM-DD',
                  ),
                },
              }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  operatingStatusStartDate: transformDate(
                    e.target.value,
                  ) as Date,
                })
              }
              value={formatDate(formData.operatingStatusStartDate)}
              required
            />
            <TextField
              className={styles.operatingStatus}
              select
              name='operatingStatus'
              label='Operating Status'
              value={formData.operatingStatus}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  operatingStatus: e.target.value,
                })
              }
              required
            >
              {menuItems}
            </TextField>
          </div>
          <TextField
            sx={{ width: '100%', height: '100%' }}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={10}
            label='Operating Status Note'
            value={formData.operatingStatusNote}
            disabled={false}
            onChange={(e) =>
              setFormData({
                ...formData,
                operatingStatusNote: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.changeOperatingStatusMessage}>
          *Changes will not take effect until you click "Save Changes" on the
          Van Details Page (click "Change" -{'>'} "Save Changes").
        </div>
        <div className={styles.editVanSubModalButtons}>
          <SecondaryButton
            buttonName='Cancel'
            onClick={async () => {
              const originalVanData = await queryVanData(vanData.objectId)
              const { operatingStatus, operatingStatusStartDate } =
                originalVanData

              setFormData(DEFAULT_FORM_DATA)

              setVanData({
                operatingStatus,
                operatingStatusStartDate,
              })

              handleClosed()
            }}
          />
          <PrimaryButton
            buttonName='Change'
            onClick={() => {
              setOperatingStatusNote(formData.operatingStatusNote)
              setVanData({
                operatingStatus: formData.operatingStatus,
                operatingStatusStartDate: formData.operatingStatusStartDate,
              })

              handleClosed()
            }}
            disabled={!isFormDataValid}
          />
        </div>
      </Box>
    </Modal>
  )
}

export default ChangeOperatingStatusModal
