export const forceAppointment = (body: any) => {
	return {
		method: 'PUT',
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/force-appointment`,
		body: body,
	}
}
export const getRoutes = (locationObjectId?: string) => {
	let url = `${process.env.REACT_APP_ORIGIN}/routes/future`

	const query = new URLSearchParams()

	if (locationObjectId) {
		query.append('locationObjectId', locationObjectId)
	}

	if (query.toString()) {
		url += `?${query.toString()}`
	}

	return {
		method: 'GET',
		url,
	}
}
