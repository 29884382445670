import { FC, useContext, useState } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { IRefreshCounterProps } from '../../../../vans/main/VansMain'
import SmallPartSuppliesModal from '../../small-part-supplies-modal/container/SmallPartSuppliesModal'
import hasPermission from '../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../global/constants/permissions'

const NewSmallPartSupply: FC<IRefreshCounterProps> = (
  refresh: IRefreshCounterProps,
) => {
  const { user } = useContext(UserContext)
  const [open, setOpen] = useState(false)

  function handleOpen() {
    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
  }

  return (
    <div>
      <PrimaryButton
        buttonName='Create'
        onClick={handleOpen}
        disabled={
          !hasPermission(user).allow(
            PERMISSIONS.SMALL_PART_INVENTORY.CATEGORY,
            PERMISSIONS.SMALL_PART_INVENTORY.ENTRIES.CREATE_BUTTON.NAME,
          )
        }
      />
      {open && (
        <SmallPartSuppliesModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={refresh}
          smallPartSuppliesRowData={{}}
        />
      )}
    </div>
  )
}

export default NewSmallPartSupply
