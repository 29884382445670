import { useState } from 'react'
import { WoDetailContextProvider } from '../../work-orders/components/context/context'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import UsersTable from '../tables/UsersTable'
import NewUser from './new-user/NewUser'
import RolesTable from '../tables/Roles/RolesTable'
import NewRole from './new-role/NewRole'
import TabChooser from './TabChooser'

export interface ILocationFilters {
  locationFilter: string
  setLocationFilter: Function
}

interface IUserManagementMainProps {
  tabType?: 'users' | 'roles'
}

function UserManagementMain({ tabType }: IUserManagementMainProps) {
  const [refetchIndex, setRefetchIndex] = useState(0)

  let table = null
  const isUsersQueue = tabType === 'users'
  const isRolesQueue = tabType === 'roles'

  if (isUsersQueue) {
    table = (
      <UsersTable
        refetchIndex={refetchIndex}
        setRefetchIndex={setRefetchIndex}
      />
    )
  } else if (isRolesQueue) {
    table = (
      <RolesTable
        refetchIndex={refetchIndex}
        setRefetchIndex={setRefetchIndex}
      />
    )
  }

  function incrementRefetchIndex() {
    setRefetchIndex(refetchIndex + 1)
  }

  return (
    <WoDetailContextProvider>
      <MainPageWrapper
        title='User Management'
        titleFields={
          <div style={{ marginRight: '1rem', marginLeft: 'auto' }}>
            {isUsersQueue ? (
              <NewUser
                setRefetchIndex={incrementRefetchIndex}
                refetchIndex={refetchIndex}
              />
            ) : (
              <></>
            )}
            {isRolesQueue ? (
              <NewRole refetchData={incrementRefetchIndex} />
            ) : (
              <></>
            )}
          </div>
        }
      >
        <TabChooser />
        {table}
      </MainPageWrapper>
    </WoDetailContextProvider>
  )
}

export default UserManagementMain
