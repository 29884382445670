import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { getRouteAreas, pendingToScheduled } from './api'
import { WoDetailContext } from '../../../../../context/context'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

interface IProps {
  handleClose: Function
}
interface IPendingToScheduledFormDataProps {
  routeObjectId: string
  orderObjectId: string
  routeInstructionTime: number | null
}
//TODO waiting on modification to getRouteAreas call, need to filter via location
export default function PendingToScheduled(props: IProps) {
  const { id } = useParams()
  const { queryOrderData, data: contextData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<IPendingToScheduledFormDataProps>({
    routeObjectId: '',
    orderObjectId: id ?? '',
    routeInstructionTime: null,
  })
  const { data } = useFetch(getRouteAreas(contextData.location?.objectId ?? ''))
  const handlePendingToScheduled = async () => {
    const orderId = id ?? ''
    try {
      setIsLoading(true)
      await fetchData<void>(
        pendingToScheduled({
          orderObjectId: formData.orderObjectId,
          routeAreaObjectId: formData.routeObjectId,
          locationObjectId: contextData.location?.objectId,
        }),
      )
      await queryOrderData(orderId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order status changed to scheduled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error changing order status!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const handleFormChange = (e: any) => {
    const { value, name } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  return (
    <div className={styles.inputsContainer}>
      {/* <div className={styles.topInputsContainer}>
				<LocationDropDown />
			</div> */}
      <div className={styles.topInputsContainer}>
        <RouteDropDown
          data={data}
          val={formData.routeObjectId}
          handleChange={handleFormChange}
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to move this order from pending to scheduled</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Schedule'
          disabled={!isChecked || isLoading}
          onClick={handlePendingToScheduled}
        />
      </div>
    </div>
  )
}
const RouteDropDown = ({ data, handleChange, val, location }: any) => {
  if (!data) {
    return <div>Loading...</div>
  }

  let menuItems = data.map((route: any, index: number) => {
    const { technician } = route

    let technicianName = technician ? technician.name : 'No technician'

    return (
      <MenuItem
        key={index}
        value={route.objectId}
      >
        {`${route.reportDate} - ${technicianName}`}
      </MenuItem>
    )
  })
  return (
    <TextField
      fullWidth
      label='Route'
      select
      name='routeObjectId'
      onChange={handleChange}
      value={val}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 200,
            },
          },
        },
      }}
    >
      {menuItems}
    </TextField>
  )
}
const LocationDropDown = ({ location }: any) => {
  return (
    <TextField
      fullWidth
      label='Location'
      select
      disabled
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 200,
            },
          },
        },
      }}
    >
      <MenuItem
        key={1}
        value={1}
      >
        {location}
      </MenuItem>
    </TextField>
  )
}
