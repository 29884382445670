import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SecondaryButton from '../../../../buttons/secondary-button/SecondaryButton'
import { TrackingInfo } from '../../../../../utils/tire-rack-order/create-tracking-links'

interface ITrackingLinksModalProps {
  handleClosed: Function
  isOpen?: boolean
  trackingInfo: TrackingInfo
}

export default function TrackingLinksModal(props: ITrackingLinksModalProps) {
  const { handleClosed, isOpen, trackingInfo } = props
  const content = (
    <div className={styles.inputsContainer}>
      <div className={styles.trackingLinksContainer}>
        {trackingInfo.trackingNumbers.map((trackingNumber, index) => {
          return (
            <a
              href={trackingInfo.trackingLinks[index]}
              target='_blank'
              rel='noreferrer'
            >
              {trackingNumber}
            </a>
          )
        })}
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Close'
          onClick={() => handleClosed()}
        />
      </div>
    </div>
  )

  if (isOpen !== undefined) {
    return (
      <div>
        <Modal
          open={isOpen}
          onClose={() => handleClosed()}
        >
          <Box className={styles.scheduleModal}>
            <div className={`${styles.scheduleModalPageTitle} font--bold`}>
              Tracking Links
            </div>
            {content}
          </Box>
        </Modal>
      </div>
    )
  } else {
    return content
  }
}
