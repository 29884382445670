import React, { useContext } from 'react'
import OverviewSummaryTable from '../tables/overview-summary/OverviewSummaryTable'
import WorkOrderVehiclesTable from '../tables/work-order-vehicles/WorkOrderVehiclesTable'
import LineItemsTable from '../tables/line-items/LineItemsTable'
import DiscountsTable from '../tables/discounts/DiscountsTable'
import TechniciansTable from '../tables/technicians/TechniciansWoDetailTable'
import CasesTable from '../tables/cases/CasesWoDetailTable'
import WorkOrderHistoryEntriesTable from '../tables/work-order-history-entries/WorkOrderHistoryEntriesTable'
import InvoiceTable from '../tables/invoice/InvoiceTable'
import WorkOrderNotes from '../notes/WorkOrderNotes'
import AdditionalActions from '../work-order-utility/AdditionalActions'
import styles from './styles.module.css'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import hasPermission from '../../../../../global/utils/user/has-permission'

export default function ModalContent() {
  const { user } = useContext(UserContext)
  const canUserEdit = hasPermission(user).edit(
    PERMISSIONS.WORK_ORDERS.CATEGORY,
    PERMISSIONS.WORK_ORDERS.ENTRIES.INDIVIDUAL_WORK_ORDER.NAME,
  )

  return (
    <>
      <div className={styles.pageTitle}>Work Order Details</div>
      <div className={styles.woDetailsContent}>
        <div className={styles.tablesWrapper}>
          <OverviewSummaryTable isReadOnly={!canUserEdit} />
          <WorkOrderVehiclesTable />
          <LineItemsTable />
          <DiscountsTable isReadOnly={!canUserEdit} />
          <TechniciansTable />
          <CasesTable isReadOnly={!canUserEdit} />
          <WorkOrderHistoryEntriesTable />
          <InvoiceTable isReadOnly={!canUserEdit} />
        </div>
        <div className={styles.sideFlexWrapper}>
          <div className={styles.sideNotesContainer}>
            <WorkOrderNotes isReadOnly={!canUserEdit} />
          </div>
          <div className={styles.sideAdditionalActionsContainer}>
            <AdditionalActions isReadOnly={!canUserEdit} />
          </div>
        </div>
      </div>
    </>
  )
}
