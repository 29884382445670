import { useContext, useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import Table from '../../../global/components/table/Table'
import { ISmallPartSupplies } from '../../../../../app/entities/SmallPartSupplies'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import { IRefreshCounterProps } from '../../../vans/main/VansMain'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../global/constants/permissions'

interface ISmallPartSuppliesTableProps {
  setModalIsOpen: Function
  setSmallPartSuppliesData: Function
  refresh: IRefreshCounterProps
}

function SmallPartSuppliesTable(props: ISmallPartSuppliesTableProps) {
  const { user } = useContext(UserContext)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const isSmallPartSuppliesEditDisabled = !hasPermission(user).allow(
    PERMISSIONS.SMALL_PART_INVENTORY.CATEGORY,
    PERMISSIONS.SMALL_PART_INVENTORY.ENTRIES.EDIT_SMALL_PART_INVENTORY.NAME,
  )

  const endpoint = `${process.env.REACT_APP_ORIGIN}/small-part-supplies?`

  useEffect(() => {
    retrieveData(setTableState, {
      endpoint,
      columnFilters,
      dataTransformer: (d: any) => d,
    })
  }, [columnFilters, endpoint])

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  const columns: Column<ISmallPartSupplies>[] = [
    {
      Header: 'Bin',
      accessor: 'bin',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ bin: val }),
      }),
    },
    {
      Header: 'Tire Rack Part Number',
      accessor: 'tireRackPartNumber',
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({ tireRackPartNumber: val }),
      }),
    },
    {
      Header: 'Part Number',
      accessor: 'partNumber',
      Filter: NoFilter,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Filter: NoFilter,
    },
    {
      Header: 'Full Bin Quantity',
      accessor: 'fullQuantity',
      Filter: NoFilter,
    },
    {
      Header: 'Minimum Order',
      accessor: 'minimumOrder',
      Filter: NoFilter,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={
          isSmallPartSuppliesEditDisabled
            ? () => {}
            : (row: Row) => {
                const original: any = row.original
                props.setModalIsOpen(true)
                props.setSmallPartSuppliesData({ ...original })
              }
        }
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default SmallPartSuppliesTable
