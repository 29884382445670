interface TableTitleProps {
    children: JSX.Element | string
    style?: React.CSSProperties
}

function TableTitle ({children, style}: TableTitleProps) {

    return (
        <div
            className="font--bold font--x-large"
            style={style}
        >
            {children}
        </div>
    )
}

export default TableTitle