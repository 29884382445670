import moment from 'moment'
import { IDtmiVan } from '../../../../../../../../../bi/entities/DtmiVan'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateDtmiVanColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Entry Creation Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ created: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'created',
          data.created ? moment.utc(data.created).toDate() : moment.invalid(),
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
          '200px',
        )
      },
    },
    {
      Header: 'Name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'name',
          data.name?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Vin',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vin: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'vin',
          data.vin?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Year',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ year: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'year',
          data.year?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Make',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ make: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'make',
          data.make?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Model',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ model: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'model',
          data.model?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'License Plate',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ licensePlate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'licensePlate',
          data.licensePlate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Livery',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ livery: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'livery',
          data.livery?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Location Moniker',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ locationMoniker: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'locationMoniker',
          data.locationMoniker?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Inventory Status',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ inventoryStatus: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'inventoryStatus',
          data.inventoryStatus?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'In Service Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ inServiceDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'inServiceDate',
          data.inServiceDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Out Of Service Date',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ outOfServiceDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'outOfServiceDate',
          data.outOfServiceDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'DOT End Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ dotEndDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'dotEndDate',
          data.dotEndDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Registration End Date',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ registrationEndDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IDtmiVan, rowIndex: number) => {
        return generateTextField(
          'registrationEndDate',
          data.registrationEndDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateDtmiVanColumns
