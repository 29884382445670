import MainPageWrapper from '../../../global/components/MainPageWrapper'
import AllReports from '../reports/AllReports'

function ReportsMain() {
  return (
    <MainPageWrapper>
      <AllReports />
    </MainPageWrapper>
  )
}

export default ReportsMain
