import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { PsiTorqueSuggestion } from '../TireSizeQuantity'
import PsiAndTorqueDropDown from '../../../../../../global/components/psi-and-torque-drop-downs/psi-and-torque-drop-down/PsiAndTorqueDropDown'

interface IProps {
  isOpen: boolean
  psiTorqueSuggestions: PsiTorqueSuggestion[]
  handleCancel: Function
  handleConfirm: Function
}

const TITLES = {
  psiAndTorque: 'Choose PSI and Torque',
  torque: 'Choose Torque',
}

export default function PsiTorqueModal(props: IProps) {
  const { isOpen, psiTorqueSuggestions, handleCancel, handleConfirm } = props
  const [title, setTitle] = useState<string>('')
  const [selectionIdx, setSelectionIdx] = useState(0)

  const handleDropDown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectionIdx(parseInt(e.target.value, 10))
  }
  useEffect(() => {
    setTitle(TITLES.psiAndTorque)
  }, [psiTorqueSuggestions])

  return (
    <Modal open={isOpen}>
      <Box className={styles.psiTorqueModal}>
        <div className={`${styles.psiTorqueModalTitle} font--bold`}>
          {title}
        </div>
        <div className={styles.topInputsContainer}>
          <PsiAndTorqueDropDown
            handleDropDown={handleDropDown}
            options={psiTorqueSuggestions}
          />
        </div>
        <div className={styles.buttonContainer}>
          <SecondaryButton
            buttonName='Skip'
            onClick={() => {
              setSelectionIdx(0)
              handleCancel()
            }}
          />
          <PrimaryButton
            buttonName='Accept'
            onClick={() => {
              setSelectionIdx(0)
              handleConfirm(
                psiTorqueSuggestions.length > 0
                  ? psiTorqueSuggestions[selectionIdx]
                  : psiTorqueSuggestions[selectionIdx],
              )
            }}
          />
        </div>
      </Box>
    </Modal>
  )
}
