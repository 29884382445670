import { MenuItem, TextField } from '@mui/material'
import { useState } from 'react'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import { ROUTING_ALGORITHMS } from '../../global/constants/scheduling'
import styles from './styles.module.css'
import { fetchData } from '../../global/utils/fetch'
import {
  updateRoutingAlgorithm,
  migrateFutureRouteAreasToDayPlans,
  migrateFutureDayPlansToRouteAreas,
} from '../api'

export default function RoutingAlgorithmManager() {
  const [form, setForm] = useState({
    routingAlgorithm: '',
    locationObjectId: '',
  })

  const [loading, setLoading] = useState(false)

  async function onUpdateRoutingAlgorithmClick() {
    const { routingAlgorithm, locationObjectId } = form
    if (!routingAlgorithm || !locationObjectId)
      return alert('Please select a routing algorithm and location')

    setLoading(true)
    try {
      await fetchData(
        updateRoutingAlgorithm(routingAlgorithm, locationObjectId),
      )
      alert('Routing algorithm updated')
      setForm({ ...form, routingAlgorithm: '' })
    } catch (err) {
      console.error(err)
      alert('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  async function onMigrateFutureRouteAreasToDayPlans() {
    const { locationObjectId } = form
    if (!locationObjectId) return alert('Please select a location')

    setLoading(true)
    try {
      const { cost, numberOfOrderBumpedIntoPendingRescheduling, totalOrders } =
        (await fetchData(
          migrateFutureRouteAreasToDayPlans(locationObjectId),
        )) as any
      alert(
        `Migrated future route areas to day plans\n\n${totalOrders} orders were attempted to be scheduled\n\n${numberOfOrderBumpedIntoPendingRescheduling} orders were bumped into pending rescheduling\n\nCost: ${cost}`,
      )
      setForm({ ...form, routingAlgorithm: '' })
    } catch (err) {
      console.error(err)
      alert('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  async function onMigrateFutureDayPlansToRouteAreas() {
    const { locationObjectId } = form
    if (!locationObjectId) return alert('Please select a location')

    setLoading(true)
    try {
      const {
        bumpedOrdersCount,
        newRouteAreaCount,
        orderCount,
        totalRouteAreasDeleted,
      } = (await fetchData(
        migrateFutureDayPlansToRouteAreas(locationObjectId),
      )) as any
      // alert('success')
      alert(
        `Migrated future day plans to routeAreas\n\n${orderCount} orders were attempted to be scheduled\n\n${bumpedOrdersCount} orders were bumped into pending rescheduling\n\n${newRouteAreaCount} new route areas were created\n\n${totalRouteAreasDeleted} route areas were deleted`,
      )
      setForm({ ...form, routingAlgorithm: '' })
    } catch (err) {
      console.error(err)
      alert('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <h2>Routing Algorithm Manager</h2>
      <div className={styles.inputsContainer}>
        <LocationDropDown
          onLocationChange={(locationObjectId: string) =>
            setForm({ ...form, locationObjectId })
          }
        />
        <div className={styles.driveTimeDetailsContainer}>
          <TextField
            className={styles.dropDownSelect}
            label='Routing Algorithm'
            select
            onChange={(e) =>
              setForm({ ...form, routingAlgorithm: e.target.value })
            }
            value={form.routingAlgorithm}
            fullWidth
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    width: 250,
                    fontFamily: '',
                  },
                },
              },
            }}
          >
            {Object.values(ROUTING_ALGORITHMS).map((algorithm) => (
              <MenuItem
                key={algorithm}
                value={algorithm}
              >
                {algorithm}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>Do this...</div>
        <PrimaryButton
          buttonName='Make Day Plans For Location'
          onClick={onMigrateFutureRouteAreasToDayPlans}
          disabled={loading}
          style={{ flexWrap: 'wrap' }}
        />
        <PrimaryButton
          buttonName='Make Route Areas For Location'
          onClick={onMigrateFutureDayPlansToRouteAreas}
          disabled={loading}
          style={{ flexWrap: 'wrap' }}
        />
        <div>...before this.</div>
        <PrimaryButton
          buttonName='Update Routing Algorithm'
          onClick={onUpdateRoutingAlgorithmClick}
          disabled={loading}
        />
      </div>
    </div>
  )
}
