import { ChangeEvent, useEffect, useState } from 'react'
import { IOrder } from '../../../../../app/entities/Order'
import styles from '../styles.module.css'
import {
  ARRIVED_STATUSES,
  CANCELLED_STATUSES,
  STATUSES,
} from '../../../global/constants/order-status'
import moment from 'moment'
import determineHalvedTimeSlot from '../../../global/utils/determine-halved-time-slot'
import { useNavigate } from 'react-router-dom'
import PrimaryCheckbox from '../../../global/components/checkbox/Checkbox'

interface OrderInformationProps {
  order: IOrder | null
  isStandbyQueueChecked: boolean
  standbyQueueHandler: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function OrderInformation(props: OrderInformationProps) {
  const navigate = useNavigate()

  const { order, isStandbyQueueChecked, standbyQueueHandler } = props
  const [customerName, setCustomerName] = useState('')
  const [timeSlotInformation, setTimeSlotInformation] = useState('')
  const [vehicleYearAndMake, setVehicleYearAndMake] = useState('')
  const [vehicleModelAndVariant, setVehicleModelAndVariant] = useState('')
  const [disableStandbyQueueOption, setDisableStandbyQueueOption] =
    useState(false)

  useEffect(() => {
    const orderVehicle = order?.orderVehicles[0]
    const vehicleYear = orderVehicle?.customerVehicle?.year
    const vehicleMake = orderVehicle?.customerVehicle?.brand?.name
    const vehicleModel = orderVehicle?.customerVehicle?.model
    const vehicleVersion = orderVehicle?.customerVehicle?.version

    const timeSlot = order?.timeSlot
    const timeSlotFromMoment = moment.utc(timeSlot?.fromTime)
    const timeSlotToMoment = moment.utc(timeSlot?.toTime)

    const routeInstruction = order?.routeInstructions[0]

    const { fromTimeMoment, toTimeMoment } = determineHalvedTimeSlot(
      timeSlot,
      routeInstruction,
    )

    const timeSlotInformationStr =
      fromTimeMoment && toTimeMoment
        ? `${fromTimeMoment.format('M/D/YY, [arriving] h:mma')} - ${toTimeMoment.format('h:mma')}`
        : `${timeSlotFromMoment.format('M/D/YY, [arriving] h:mma')} - ${timeSlotToMoment.format('h:mma')}`

    const shouldDisableStandbyQueueOption = [
      ...CANCELLED_STATUSES,
      order?.status === STATUSES.completed,
    ].includes(order?.status ?? '')

    setCustomerName(order?.customer?.fullName ?? '')
    setTimeSlotInformation(timeSlotInformationStr)
    setVehicleYearAndMake(`${vehicleYear} ${vehicleMake}`)
    setVehicleModelAndVariant(`${vehicleModel} ${vehicleVersion}`)
    setDisableStandbyQueueOption(shouldDisableStandbyQueueOption)
    setDisableStandbyQueueOption(shouldDisableStandbyQueueOption)
  }, [order])

  function onRescheduleClick() {
    const url = `/reschedule-appointment/${order?.objectId}`
    navigate(url)
  }

  return (
    <div className={styles.orderInformationContainer}>
      <div className={styles.customerName}>{customerName}</div>
      <div className={styles.installationAppointment}>
        Installation Appointment:
      </div>
      <div className={styles.orderInformation}>
        {timeSlotInformation}
        <br />
        {!ARRIVED_STATUSES.includes(order?.status ?? '') ? (
          <div
            className={styles.rescheduleLink}
            onClick={onRescheduleClick}
          >
            Reschedule
          </div>
        ) : (
          <></>
        )}
        {vehicleYearAndMake}
        <br />
        {vehicleModelAndVariant}
        <br />
        {order?.location?.standbyQueueActive && (
          <PrimaryCheckbox
            style={{ height: '30px', width: '30px', marginRight: '5px' }}
            checked={isStandbyQueueChecked}
            onChange={standbyQueueHandler}
            label='Notify me about earlier appointments'
            disabled={disableStandbyQueueOption}
          />
        )}
      </div>
    </div>
  )
}
