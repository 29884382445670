import {
  Address,
  AddressComponent,
} from '../../../../../../../../app/types/scheduling'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from './styles.module.css'

export default function AddressConfirm({
  inputtedAddress,
  suggestedAddressComponents,
  setSeeAddressConfirmModal,
  onConfirm,
}: {
  inputtedAddress: Address
  suggestedAddressComponents: AddressComponent[]
  setSeeAddressConfirmModal: Function
  onConfirm: Function
}) {
  const inputAddressDisplay = (
    <div>
      <div className={`font--bold`}>You typed:</div>
      <div>
        {inputtedAddress.address}, {inputtedAddress.city},{' '}
        {inputtedAddress.state} {inputtedAddress.zipCode}
      </div>
    </div>
  )

  const formattedComponentMap = suggestedAddressComponents.reduce(
    (acc, component) => {
      const text = component.componentName.text
      acc[component.componentType] = (
        component.replaced ? <b>{text}</b> : <>{text}</>
      ) as JSX.Element
      return acc
    },
    {} as { [key: string]: JSX.Element },
  )

  const suggestedAddressDisplay = (
    <div>
      <div className={'font--bold'}>We found:</div>
      <div>
        {formattedComponentMap['street_number']}{' '}
        {formattedComponentMap['route']}, {formattedComponentMap['locality']},{' '}
        {formattedComponentMap['administrative_area_level_1']}{' '}
        {formattedComponentMap['postal_code']}
      </div>
    </div>
  )

  return (
    <div>
      <div className='font--bold font--x-large'>Confirm Address</div>
      <div className={`${styles.prompt} warning-message`}>
        We found an address similar to yours
      </div>
      <div className={styles.confirmAddressContainer}>
        {inputAddressDisplay}
        {suggestedAddressDisplay}
      </div>
      <div className={`${styles.confirm}`}>
        Is the suggested address correct or would you like to go back try a
        different address?
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          buttonName='Go back'
          onClick={() => {
            setSeeAddressConfirmModal(false)
          }}
        />
        <PrimaryButton
          buttonName='Use suggested address'
          onClick={() => onConfirm()}
        />
      </div>
    </div>
  )
}
