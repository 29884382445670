export const getBraintreeToken = (orderId: string) => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/braintree/client-token/${orderId}`,
	}
}

export const createPaymentMethod = (orderId: string, nonce: string) => {
	return {
		method: "POST",
		body: nonce,
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/payment-method`,
	}
}

export const getFreeOrderReasons = () => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/free_order_reasons`,
	}
}
export const setFreeOrder = (orderObjectId: string, freeOrderReasonObjectId: string) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/free-order-reason/${freeOrderReasonObjectId}`,
	}
}

export const setShouldRequestPaymentAfterScheduling = (orderId: string, shouldRequestPaymentAfterScheduling: boolean) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/should-request-payment-after-scheduling/${shouldRequestPaymentAfterScheduling}`,
	}
}

export const payOrderInvoice = (orderId: string) => {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/pay-invoice`,
  }
}
