import React from "react"
import MainPageWrapper from "../../../global/components/MainPageWrapper"
import LocationsTable from "../table/LocationsTable"
import { LocationModal } from "../location-modal/components/container-modal/LocationModal"
import NewLocation from "./new-location/NewLocation"

function Locations() {
	const [open, setOpen] = React.useState({
		isOpen: false,
		locationId: ""
	})
	const handleOpen = (id: string) => {
		setOpen({ isOpen: true, locationId: id });
	};


	const handleClosed = () => {
		setOpen({ isOpen: false, locationId: "" });
	};

	return (
		<>
			<LocationModal locationId={open.locationId} isOpen={open.isOpen} handleClosed={handleClosed} />
			<LocationsTable setOpen={setOpen} />
		</>
	)
}

function LocationsMain() {
	return (
		<MainPageWrapper titleFields={<NewLocation />}>
			<Locations />
		</MainPageWrapper>
	)
}

export default LocationsMain
