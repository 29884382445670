import { MouseEventHandler, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import CircularProgress from '@mui/material/CircularProgress'
import PrimaryButton from '../../../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import CasesEdit from './CasesEdit'
import CasesCreate from './CasesCreate'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IEditCaseDataData } from '../../../CasesWoDetailTable'
import { fetchData } from '../../../../../../../../../global/utils/fetch'
import {
  createCase,
  createCaseNote,
  getNotesByCaseId,
  updateCase,
} from '../../api'
import { useParams } from 'react-router-dom'
import { ToastNotificationContext } from '../../../../../../../../../global/context/toast-context/ToastNotificationContext'
import { ICase } from '../../../../../../../../../../../app/entities/Case'
import { INote } from '../../../../../../../../../../../app/entities/Note'
import hasPermission from '../../../../../../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../../../../../global/constants/permissions'

interface ICaseModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  editCaseData: IEditCaseDataData
  triggerRefresh: Function
  isNewCase?: boolean
}
interface INotes {
  notes: any[]
  created: string[]
}
export interface ICaseFormInfoProps {
  orderId: string
  status: string
  assignedTo: string
  type: string
  damageCost: number | null
  summary: string
  note: INotes
  createdBy?: string
}
export interface ICaseFormProps {
  setCaseFormInfo: Function
  caseFormInfo: ICaseFormInfoProps
}
type GetNotesByCaseIdResponse = {
  items: {
    caseObjectId: string | undefined
    noteObjectId: string
    createdGenerated: string
    note: string
    case?: ICase | undefined
    objectId: string
    created: number
    updated: number
  }[]
  count: number
}
export function CasesModal(props: ICaseModalProps) {
  const { id } = useParams()
  const { user } = useContext(UserContext)
  const { user: amplifyUser } = useAuthenticator((context) => {
    return [context.user]
  })
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [isLoading, setIsLoading] = useState(false)
  const { editCaseData, isNewCase } = props
  const [caseFormInfo, setCaseFormInfo] = useState<ICaseFormInfoProps>({
    orderId: '',
    status: '',
    assignedTo: '',
    type: '',
    damageCost: null,
    summary: '',
    createdBy: '',
    note: {
      notes: [],
      created: [],
    },
  })

  const isEditDisabled =
    isLoading ||
    !hasPermission(user).allow(
      PERMISSIONS.CASES.CATEGORY,
      PERMISSIONS.CASES.ENTRIES.EDIT_CASES.NAME,
    )

  function handleClose(e: any) {
    setCaseFormInfo({
      orderId: '',
      status: '',
      assignedTo: '',
      type: '',
      damageCost: null,
      summary: '',
      createdBy: '',
      note: {
        notes: [],
        created: [],
      },
    })
    props.handleClosed(e)
  }

  const fetchNotesForCase = async () => {
    const notesResponse = await fetchData<GetNotesByCaseIdResponse>(
      getNotesByCaseId(editCaseData.caseObjectId),
    )
    setCaseFormInfo((prevState) => ({
      ...prevState,
      note: {
        ...prevState.note,
        notes: [...notesResponse.items],
      },
    }))
  }
  async function handleCreateNote(caseObjectId: string) {
    const createdPromise = caseFormInfo.note.created.map((elem: any) => {
      return fetchData<INote>(createCaseNote(elem.note, caseObjectId))
    })
    return Promise.all([createdPromise])
  }

  async function handleCreateCase(e: any) {
    try {
      setIsLoading(true)
      // need to attach the user email to the case so we know who created it
      caseFormInfo.createdBy =
        (amplifyUser as any)?.signInUserSession?.idToken?.payload?.email || ''

      const res = await fetchData<ICase>(createCase(caseFormInfo, id))
      if (caseFormInfo.note.created.length > 0) {
        await handleCreateNote(res.objectId)
      }
      handleClose(e)
      props.triggerRefresh()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully created case!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error creating case.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCaseEdit(e: any) {
    try {
      setIsLoading(true)
      await fetchData<ICase>(
        updateCase(caseFormInfo, editCaseData.caseObjectId),
      )
      if (caseFormInfo.note.created.length > 0) {
        await handleCreateNote(editCaseData.caseObjectId)
      }
      handleClose(e)
      props.triggerRefresh()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully updated case!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating case.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const disabledButtonLogic: boolean = Boolean(
    !editCaseData.caseObjectId &&
      (!caseFormInfo.status ||
        !caseFormInfo.type ||
        caseFormInfo.damageCost === null ||
        caseFormInfo.damageCost === undefined ||
        !caseFormInfo.summary),
  )
  useEffect(() => {
    if (editCaseData.caseObjectId) {
      fetchNotesForCase()
    }
  }, [editCaseData])

  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.casesModal}>
          <>
            <div className={styles.casesModalHeader}>
              <div className={`${styles.casesModalPageTitle} font--bold`}>
                {editCaseData.caseObjectId ? 'Edit Case' : 'Create Case'}
              </div>
              {isLoading && (
                <div className={styles.progressContainer}>
                  <CircularProgress />
                </div>
              )}
              {editCaseData.caseObjectId && !isLoading && (
                <CasesEdit
                  formProps={{ caseFormInfo, setCaseFormInfo }}
                  editCaseData={editCaseData}
                  isNewCase={isNewCase}
                />
              )}
              {!editCaseData.caseObjectId && !isLoading && (
                <CasesCreate formProps={{ caseFormInfo, setCaseFormInfo }} />
              )}
            </div>
          </>
          <div className={styles.casesModalFooterButtons}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClose}
              />
            </div>
            <div>
              {editCaseData.caseObjectId ? (
                <PrimaryButton
                  buttonName='Save Case'
                  disabled={isEditDisabled}
                  onClick={handleCaseEdit}
                />
              ) : (
                <PrimaryButton
                  buttonName='Create Case'
                  disabled={isEditDisabled || disabledButtonLogic}
                  onClick={handleCreateCase}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
