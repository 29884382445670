import { IOrderService } from '../../../../../../../app/entities/OrderService'
import { fetchData } from '../../../../../global/utils/fetch'
import { deleteOrderService } from '../../../../../work-orders/components/work-order-detail/components/tables/line-items/line-items-modal/api'
import {
  ISchedulerStateProps,
  ITiresProps,
} from '../container-modal/initialData'
import { ITireSizeError } from './TireSizeQuantity'
import { createOrderService, setOrderServices } from './api'
import { ICreateLineItemBody } from './useDetectChangeOrderService'
import {
  TIRE_SIZE_KEYS,
  TIRE_SIZE_LIMITS,
} from '../../../../../global/constants/tire-sizes'

export function checkTireFormFields(
  object: ITiresProps,
  setTireSizeError: Function,
): boolean {
  let passes = true
  for (const key in object) {
    const element = object[key]
    if (typeof element === 'object') {
      passes = checkTireFormFields(element, setTireSizeError)
    } else if (Object.keys(TIRE_SIZE_KEYS).includes(key) && element === '') {
      passes = false
    } else if (
      key === TIRE_SIZE_KEYS.width &&
      parseFloat(element) > TIRE_SIZE_LIMITS.tire_width_warning_min
    ) {
      //if greater than 295 show warning
      setTireSizeError((prevState: ITireSizeError) => ({
        ...prevState,
        width: true,
      }))
      //if greater than 325 then disable
      if (parseFloat(element) > TIRE_SIZE_LIMITS.tire_width_disable_min) {
        passes = false
      }
    } else if (
      key === TIRE_SIZE_KEYS.diameter &&
      parseFloat(element) > TIRE_SIZE_LIMITS.tire_diameter_min
    ) {
      setTireSizeError((prevState: ITireSizeError) => ({
        ...prevState,
        diameter: true,
      }))
      passes = false
    } else if (
      key === TIRE_SIZE_KEYS.aspect_ratio &&
      (parseFloat(element) < TIRE_SIZE_LIMITS.tire_aspect_ratio_lower_min ||
        (TIRE_SIZE_LIMITS.tire_aspect_ratio_lower_max < parseFloat(element) &&
          parseFloat(element) < TIRE_SIZE_LIMITS.tire_aspect_ratio_upper_min) ||
        TIRE_SIZE_LIMITS.tire_aspect_ratio_upper_max < parseFloat(element))
    ) {
      setTireSizeError((prevState: ITireSizeError) => ({
        ...prevState,
        aspectRatio: true,
      }))
      passes = false
    } else {
      setTireSizeError((prevState: ITireSizeError) => ({
        ...prevState,
        [key]: false,
      }))
    }
    if (!passes) {
      break
    }
  }
  return passes
}

export const handleCreateAndDeleteOrderService = async (
  createLineItemBody: ICreateLineItemBody,
  id: string,
  schedulerState: ISchedulerStateProps,
  setSchedulerState: Function,
) => {
  try {
    const orderServiceResponse = await fetchData<IOrderService>(
      createOrderService(createLineItemBody),
    )
    setSchedulerState((prevState: ISchedulerStateProps) => ({
      ...prevState,
      orderService: orderServiceResponse.objectId,
    }))
    await fetchData<string[]>(
      setOrderServices(
        id,
        schedulerState.orderVehicleId,
        orderServiceResponse.objectId,
      ),
    )
    await fetchData<void>(deleteOrderService(schedulerState.orderService))
  } catch (err) {
    throw new Error()
  }
}
