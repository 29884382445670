import { IPermission, Permission } from '../../../../../app/entities/Permission'
import { IRolePartial } from '../../../../../app/entities/Role'
import CheckboxWrapper from '../../../global/components/checkbox/CheckboxWrapper'
import styles from '../styles.module.css'

interface PermissionDetailsEntry {
  allowedActions: string[]
  description: string | null
}

interface Category {
  [name: string]: PermissionDetailsEntry
}

export interface PermissionsDetails {
  [category: string]: Category
}

function isSamePermission(
  permission: IPermission,
  category: string,
  permissionName: string,
  action: string,
) {
  return (
    permission.category === category &&
    permission.name === permissionName &&
    permission.action === action
  )
}

function doesRoleAlreadyHavePermission(
  assignedPermissions: IPermission[],
  category: string,
  permissionName: string,
  action: string,
) {
  return Boolean(
    assignedPermissions.some((assignedPermission) =>
      isSamePermission(assignedPermission, category, permissionName, action),
    ),
  )
}

// Handle formatting of category names as they are snake case and capitalized
const WORDS_TO_CAPITALIZE = ['CS']
function formatCategory(category: string) {
  return category
    .split('_')
    .map((word) =>
      WORDS_TO_CAPITALIZE.includes(word)
        ? word
        : word.charAt(0) + word.slice(1).toLowerCase(),
    )
    .join(' ')
}

function generatePermissionOptions(
  allPermissions: IPermission[],
  assignedPermissions: IPermission[],
  permissionDetails: PermissionsDetails,
  roleData: IRolePartial,
  setRoleData: Function,
  setPermissionOptions: Function,
) {
  const options = Object.entries(permissionDetails)
    .sort()
    .map(([category, permissions]) => {
      const permissionOptions = Object.entries(permissions)
        .sort()
        .map(([permissionName, { allowedActions, description }]) => {
          return (
            <div
              key={description}
              className={styles.permissionOption}
            >
              <div className={styles.permissionMetadata}>
                <h3 className={styles.zeroMargin}>{permissionName}</h3>
                <div className={styles.permissionDescription}>
                  {description}
                </div>
              </div>
              <div className={styles.checkBoxContainer}>
                {allowedActions
                  .sort()
                  .reverse()
                  .map((action) => (
                    <div className={styles.singleCheckBoxContainer}>
                      <div>
                        {action.substring(0, 1).toLocaleUpperCase()}
                        {action.substring(1)}
                      </div>
                      <CheckboxWrapper
                        isInitiallyChecked={doesRoleAlreadyHavePermission(
                          assignedPermissions,
                          category,
                          permissionName,
                          action,
                        )}
                        onChange={(e: any, checked: boolean) => {
                          const permissionToModify = allPermissions.find(
                            (permission) =>
                              isSamePermission(
                                permission,
                                category,
                                permissionName,
                                action,
                              ),
                          )

                          if (!permissionToModify) {
                            return
                          }

                          let updatedPermissions = assignedPermissions
                          if (checked) {
                            updatedPermissions?.push(
                              permissionToModify as Permission,
                            )

                            updatedPermissions = updatedPermissions?.filter(
                              (permission) =>
                                !(
                                  permission.category === category &&
                                  permission.name === permissionName &&
                                  permission.action !== action
                                ),
                            )
                          } else {
                            const indexOfPermissionToRemove =
                              updatedPermissions?.findIndex((permission) =>
                                isSamePermission(
                                  permission,
                                  category,
                                  permissionName,
                                  action,
                                ),
                              ) ?? -1

                            if (indexOfPermissionToRemove === -1) {
                              return
                            }
                            updatedPermissions?.splice(
                              indexOfPermissionToRemove,
                              1,
                            )
                          }

                          setRoleData({
                            ...roleData,
                            permissions: updatedPermissions,
                          })
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )
        })

      return (
        <div
          className={styles.categoryContainer}
          key={category}
        >
          <h1 className={styles.zeroMargin}>{formatCategory(category)}</h1>
          {permissionOptions}
        </div>
      )
    })

  setPermissionOptions(<div className={styles.optionsContainer}>{options}</div>)
}

export default generatePermissionOptions
