import { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { postDailyWarehouseReports } from '../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { isValidEmail } from '../../../../global/utils/formValidation'
import CircularProgress from '@mui/material/CircularProgress'
import WarehouseReportTypeDropDown from '../../../../global/components/warehouse-type-drop-down/WarehouseReportTypeDropDown'

export interface IDailyWarehouseReportsFormData {
  locationId: string
  recipientEmail: string
  warehouseType: string
}

interface IDailyWarehouseReportsProps {
  isOpen: boolean
  handleClosed: Function
}

export default function DailyWarehouseReportsModal(
  props: IDailyWarehouseReportsProps,
) {
  const { user } = useAuthenticator((context) => [context.user])
  const [formData, setFormData] = useState<IDailyWarehouseReportsFormData>({
    locationId: '',
    recipientEmail: '',
    warehouseType: '',
  })
  const [validEmail, setValidEmail] = useState<any>(null)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const handleClosed = () => {
    setSuccessMessage('')
    setFormData({
      locationId: '',
      warehouseType: '',
      recipientEmail: '',
    })
    props.handleClosed()
  }

  const handleEmailChange = (e: any) => {
    const { name, value } = e.target
    console.log({ name, value })
    setFormData((prevData) => {
      console.log(prevData)

      return {
        ...prevData,
        [name]: value,
      }
    })
    if (value) {
      setValidEmail(isValidEmail(value))
    } else {
      setValidEmail(null)
    }
  }

  const handleSendEmail = async () => {
    try {
      if (successMessage) {
        setSuccessMessage('')
      }
      setIsLoading(true)
      const data = await fetchData<{ success: string }>(
        postDailyWarehouseReports(formData),
      )
      setSuccessMessage(data.success)
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={handleClosed}
      >
        <Box className={styles.scheduleModal}>
          <div className={styles.reportTitle}>Daily Warehouse Report</div>
          <div className={styles.inputsContainer}>
            <LocationDropDown
              showInactive
              onLocationChange={(locationId: string) =>
                setFormData((prevData) => ({
                  ...prevData,
                  locationId: locationId,
                }))
              }
            />
            <WarehouseReportTypeDropDown
              showInactive
              onWarehouseReportTypeChange={(warehouseType: string) =>
                setFormData((prevData) => ({
                  ...prevData,
                  warehouseType: warehouseType,
                }))
              }
            />
            <TextField
              label='Email'
              name='recipientEmail'
              type='email'
              sx={{ width: '300px' }}
              value={formData.recipientEmail}
              error={validEmail === false}
              helperText={
                validEmail === false ? 'Please enter a valid email address' : ''
              }
              onChange={handleEmailChange}
            />
          </div>
          <div className={styles.successMessage}>
            {successMessage ? (
              successMessage
            ) : isLoading ? (
              <CircularProgress size={30} />
            ) : null}
          </div>
          <div>
            <PrimaryButton
              buttonName='Send Email'
              onClick={handleSendEmail}
              disabled={
                !(formData.locationId && validEmail && formData.warehouseType)
              }
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
