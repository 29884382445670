import { refreshToken } from '../../../utils/refreshToken'

interface RetrieveDataProps {
  endpoint: string
  entityName?: string
  pageSize?: number
  offSet?: number
  sortBy?: string
  sortOrder?: string
  status?: string
  selectedLocationId?: string
  locationId?: string
  orderObjectId?: string
  weekName?: string
  generalFilter?: string
  columnFilters?: object
  additionalData?: boolean
  dataTransformer?: Function
  getRole?: boolean
  getPermissions?: boolean
}

const abortControllers = new Map<string, AbortController>()

async function retrieveData(
  setTableData: Function,
  {
    endpoint,
    entityName,
    offSet,
    pageSize,
    sortBy,
    sortOrder,
    status,
    generalFilter,
    columnFilters,
    selectedLocationId,
    orderObjectId,
    weekName,
    locationId,
    additionalData,
    dataTransformer,
    getRole,
    getPermissions,
  }: RetrieveDataProps,
) {
  if (abortControllers.has(endpoint)) {
    abortControllers.get(endpoint)?.abort()
  }
  const abortController = new AbortController()
  abortControllers.set(endpoint, abortController)

  setTableData((prevState: object) => ({
    ...prevState,
    isLoading: true,
    data: [],
  }))
  const idToken = await refreshToken()

  let url = endpoint
  if (pageSize || offSet) {
    url += new URLSearchParams({
      pageSize: pageSize ? pageSize.toString() : '',
      offSet: offSet !== undefined && offSet >= 0 ? offSet.toString() : '',
    })
  }

  if (entityName) {
    url += `&entityName=${entityName}`
  }
  if (sortBy) {
    url += `&sortBy=${sortBy}`
  }
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`
  }
  if (status) {
    url += `&status=${status}`
  }
  if (generalFilter) {
    url += `&searchValue=${generalFilter}`
  }
  if (orderObjectId) {
    url += `&orderObjectId=${orderObjectId}`
  }
  if (selectedLocationId) {
    url += `&selectedLocationId=${selectedLocationId}`
  }
  if (locationId) {
    url += `&locationId=${locationId}`
  }
  if (weekName) {
    url += `&weekName=${weekName}`
  }
  if (columnFilters) {
    url += `&columnFilters=${JSON.stringify(columnFilters)}`
  }
  if (getRole) {
    url += `&getRole=${getRole}`
  }
  if (getPermissions) {
    url += `&getPermissions=${getPermissions}`
  }

  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: idToken ? `Bearer ${idToken}` : '',
    },
    signal: abortController.signal, // Attach the signal to the fetch request
  })
    .then((res) => res.json())
    .then((data) => {
      let transformedData = dataTransformer ? dataTransformer(data) : data.items
      let count = data.count
      if (additionalData) {
        //This is specifically for the invoice table in WO Details
        setTableData((prevState: object) => ({
          ...prevState,
          isLoading: false,
          data: transformedData,
          allInvoices: data['All Invoices'],
        }))
      } else {
        setTableData((prevState: object) => ({
          ...prevState,
          isLoading: false,
          data: transformedData,
          count: count,
        }))
      }
    })
    .catch((err) => {
      console.error(err)
      setTableData((prevState: object) => ({
        ...prevState,
        isLoading: false,
        data: [],
      }))
    })
}

export default retrieveData
