import styles from './styles.module.css'
import { useEffect } from 'react'
import TechnicianCreate from './TechnicianCreate'
import CircularProgress from '@mui/material/CircularProgress'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { getPicturesByIds, getTechById } from '../../api'
import {
  ITechnicianFormInfoProps,
  ITechnicianUniqueDaysProps,
  ITechnicianWorkingDaysProps,
} from './TechnicianModal'
import { DaySetting } from '../../../../../../../app/entities/DaySetting'
import { ROUTING_ALGORITHMS } from '../../../../../global/constants/scheduling'
interface ITechEditProps {
  formInfo: ITechFormProps
  weekCalendars: IWeekCalendarsProps
  daySettings: IDaySettingsProps
  techId: string
}
export interface IDaySettingsProps {
  daySettingsFormInfo: ITechnicianUniqueDaysProps
  setDaySettingsFormInfo: Function
}
export interface IWeekCalendarsProps {
  weekCalendarsFormInfo: ITechnicianWorkingDaysProps
  setWeekCalendarsFormInfo: Function
}
export interface ITechFormProps {
  setTechnicianFormInfo: Function
  technicianFormInfo: ITechnicianFormInfoProps
  refetchAvatar?: Function
}
export interface ITechAvatarProps {
  setTechnicianAvatarInfo: Function
  technicianAvatarInfo: ITechnicianFormInfoProps
}

export default function TechnicianEdit(props: ITechEditProps) {
  const { formInfo, weekCalendars, daySettings, techId } = props
  const { setTechnicianFormInfo, technicianFormInfo } = formInfo
  const { setWeekCalendarsFormInfo, weekCalendarsFormInfo } = weekCalendars
  const { setDaySettingsFormInfo, daySettingsFormInfo } = daySettings

  const { data, isLoading, refetch } = useFetch<any>(getTechById(techId))
  const {
    data: avatarData,
    isLoading: isAvatarLoading,
    refetch: refetchAvatar,
  } = useFetch<any>(getPicturesByIds([techId]))

  useEffect(() => {
    let tmpTechFormInfo = technicianFormInfo
    if (data) {
      const isDefaultLunchDuration = !data.lunchDuration

      const isDefaultWorkDay = !data.workDayFrom && !data.workDayTo
      tmpTechFormInfo = {
        ...tmpTechFormInfo,
        name: data.name,
        email: data.email,
        bio: data.bio,
        employeeId: data.employeeId,
        samsaraId: data.samsaraId,
        inactive: data.inactive,
        lunchDuration: data.lunchDuration,
        workDayFrom: data.workDayFrom,
        workDayTo: data.workDayTo,
        lunchFrom: data.lunchFrom,
        lunchTo: data.lunchTo,
        technicianId: data.objectId,
        locationId: data.location.objectId,
        locationRoutingAlgorithm: data.location.routingAlgorithm,
        useDefaultLunch: isDefaultLunchDuration,
        useDefaultWorkDay: isDefaultWorkDay,
      }

      setWeekCalendarsFormInfo({
        ...weekCalendarsFormInfo,
        weekCalendars: data.weekCalendars,
      })
      const orderedDaySettings = (data.daySettings || []).sort(
        (a: DaySetting, b: DaySetting) => {
          return new Date(b.dayDate).getTime() - new Date(a.dayDate).getTime()
        },
      )

      setDaySettingsFormInfo({
        ...daySettingsFormInfo,
        daySettings: orderedDaySettings,
      })
    }

    if (avatarData && avatarData.length) {
      tmpTechFormInfo = {
        ...tmpTechFormInfo,
        techAvatar: avatarData[0].profilePictureURL
          ? avatarData[0].profilePictureURL
          : null,
      }
    }

    setTechnicianFormInfo(tmpTechFormInfo)
  }, [data, avatarData])

  if (isLoading || isAvatarLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <TechnicianCreate
      formInfo={formInfo}
      refetchAvatar={refetchAvatar}
      weekCalendars={weekCalendars}
      daySettings={daySettings}
      reloadModal={refetch}
      techId={techId}
    />
  )
}
