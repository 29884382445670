import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import WODetailsSubModal from '../../sub-modals/WODetailsSubModal'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getDiscountsAttachedToOrderById } from '../../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Discount,
  IDiscount,
} from '../../../../../../../../app/entities/Discount'
import { WoDetailContext } from '../../../../context/context'
import { STATUSES } from '../../../../../../global/constants/order-status'
import {
  DISCOUNT_TYPES,
  MEASURES,
} from '../../../../../../global/constants/discount'
import { SUB_MODAL_NAMES } from '../../sub-modals/constants'

interface IDiscountTableProps {
  isReadOnly: boolean
}

interface IDiscountTableData {
  data: IDiscount[]
  isLoading: boolean
}

export default function DiscountsTable({ isReadOnly }: IDiscountTableProps) {
  const { id } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const [tableState, setTableState] = useState<IDiscountTableData>({
    data: [],
    isLoading: false,
  })

  const { data: order } = useContext(WoDetailContext)
  const hasFreeOrderReason = !!order?.freeOrderReason?.objectId
  const isFullyDiscounted = order?.invoices.some(
    (invoice) => invoice.status === 'FREE',
  )

  const [refreshIndex, setRefreshIndex] = useState(0)
  const [selectedDiscount, setSelectedDiscount] = useState<
    undefined | IDiscount
  >(undefined)
  const [isManageDiscountDisabled, setIsManageDiscountDisabled] =
    useState<boolean>(false)
  const [isEditDiscountDisabled, setIsEditDiscountDisabled] =
    useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalName, setModalName] = useState<string>('')

  const isAddDiscountDisabled = isReadOnly || isManageDiscountDisabled

  function handleOpen(e: any) {
    setModalName(e.target.innerText)
    setModalOpen(true)
  }
  function handleClosed() {
    setSelectedDiscount(undefined)
    setModalOpen(false)
    setModalName('')
  }

  function handleRowClick(row: any) {
    const orderIsCompleted = [
      STATUSES.completed,
      STATUSES.cancelled,
      STATUSES.cancelled_onsite,
    ].includes(order.status as string)

    if (orderIsCompleted) {
      return
    }

    const discount = row.original as IDiscount

    setSelectedDiscount(discount)

    const discountIsPercentageType = discount.measure === MEASURES.PERCENTAGE

    if (discountIsPercentageType) {
      setModalName(SUB_MODAL_NAMES.DELETE_DISCOUNT)
    } else {
      setModalName(SUB_MODAL_NAMES.EDIT_DISCOUNT)
    }
    setModalOpen(true)
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState: any) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<IDiscount[]>(
        getDiscountsAttachedToOrderById(id),
      )
      setTableState((prevState) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    if (isOpen) {
      fetchTableData()
    }
  }, [isOpen, refreshIndex, id])

  useEffect(() => {
    const isQuoteOrder = order?.status === STATUSES.quote
    const isCompletedOrder = order?.status === STATUSES.completed
    setIsManageDiscountDisabled(
      isReadOnly ||
        hasFreeOrderReason ||
        isFullyDiscounted ||
        isQuoteOrder ||
        isCompletedOrder,
    )
    setIsEditDiscountDisabled(isQuoteOrder || isCompletedOrder)
  }, [order.status])

  const columns: Column<Discount | any>[] = [
    {
      Header: 'Discount Type',
      accessor: 'type',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
    {
      Header: 'Measure',
      accessor: 'measure',
    },
    {
      Header: 'Reason',
      accessor: 'reason',
    },
  ]

  const sortedDiscounts = tableState.data.sort((a, b) => b.created - a.created)

  return (
    <div style={{ width: '100%' }}>
      <WODetailsSubModal
        isOpen={modalOpen}
        handleClosed={handleClosed}
        actionName={modalName}
        triggerRerender={() => setRefreshIndex(refreshIndex + 1)}
        selectedDiscountForEdit={selectedDiscount}
      />
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Discounts</TableTitle>
          {
            <button
              onClick={(e) => handleOpen(e)}
              style={{
                all: 'unset',
                cursor: isManageDiscountDisabled ? 'not-allowed' : 'pointer',
                color: isManageDiscountDisabled ? '#999' : '#d70000',
              }}
              disabled={isAddDiscountDisabled}
            >
              Add Discount
            </button>
          }
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={sortedDiscounts as any}
        isLoading={tableState.isLoading}
        onRowClick={handleRowClick}
        disableAllRowClicks={isEditDiscountDisabled}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
