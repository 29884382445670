import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { getOrderHistoryById } from '../../../../api'
import { fetchData } from '../../../../../../global/utils/fetch'
import {
  IOrderHistory,
  OrderHistory,
} from '../../../../../../../../app/entities/OrderHistory'
import { STATUSES } from '../../../../../../global/constants/order-status'
interface IWorkOrderHistoryTableStateProps {
  data: IOrderHistory[]
  isLoading: boolean
}
export default function WorkOrderHistoryEntriesTable() {
  const { id } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const [tableState, setTableState] =
    useState<IWorkOrderHistoryTableStateProps>({
      data: [],
      isLoading: false,
    })
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<{
        items: IOrderHistory[]
        count: number
      }>(getOrderHistoryById(id))

      // Sort the histories newest -> oldest and rename the subsequent 'scheduled's -> 'rescheduled'
      // The logic is reversed though because `data.items.findLastIndex()` is not a function
      data.items.sort((a, b) => a.created - b.created)
      const lastScheduledHistoryIdx = data.items.findIndex(
        (item) => item.status === STATUSES.scheduled,
      )
      data.items.forEach((item, idx) => {
        if (
          item.status === STATUSES.scheduled &&
          idx !== lastScheduledHistoryIdx
        ) {
          item.status = STATUSES.rescheduled
        }
      })
      data.items = data.items.reverse()

      setTableState((prevState) => ({
        ...prevState,
        data: data.items,
        isLoading: false,
      }))
    }

    if (isOpen) {
      fetchTableData()
    }
  }, [isOpen, id, user])

  const columns: Column<OrderHistory>[] = [
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: ({ value }) => {
        return (
          value
            ? `${new Date(value).toLocaleTimeString()} ${new Date(value).toLocaleDateString()}`
            : 'N/A'
        ) as any
      },
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      Cell: ({ value }) => {
        return (
          value
            ? `${new Date(value).toLocaleTimeString()} ${new Date(value).toLocaleDateString()}`
            : 'N/A'
        ) as any
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Work Order History Entries </TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data as any}
        isLoading={tableState.isLoading}
        onRowClick={console.log}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
