import { ChangeEventHandler, useEffect, useState } from 'react'
import { IRole } from '../../../../app/entities/Role'
import { fetchData } from '../../global/utils/fetch'
import { getRoles } from '../../global/utils/roles/api'
import { MenuItem, TextField } from '@mui/material'

interface IRoleDropDownProps {
  initialValue: string
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  label?: string
}

export const ROLE_DROPDOWN_ID = 'roleName'

function RoleDropDown({ initialValue, onChange, label }: IRoleDropDownProps) {
  const [rolesArray, setRolesArray] = useState<IRole[]>([])

  async function fetchRoleInformation() {
    const res = await fetchData<any>(getRoles(false))
    const { count, items: roles } = res
    setRolesArray(roles)
  }

  useEffect(() => {
    fetchRoleInformation()
  }, [])

  const menuItems = rolesArray.map(({ name }, idx) => {
    return (
      <MenuItem
        key={idx}
        value={name}
      >
        {name}
      </MenuItem>
    )
  })
  return (
    <TextField
      label={label ?? 'Role'}
      select
      name={ROLE_DROPDOWN_ID}
      fullWidth
      value={initialValue}
      onChange={onChange}
    >
      {menuItems}
    </TextField>
  )
}

export default RoleDropDown
