import React, { createContext, useState } from 'react'
import { IAlertStatusProps } from '../../components/toast-alert/ToastNotification'

interface IToastContextProps {
  setToastStatus: Function
  toastStatus: IAlertStatusProps
  handleClose: Function
}
interface IProviderProps {
  children: React.ReactNode
}
export const ToastNotificationContext = createContext<IToastContextProps>(
  {} as IToastContextProps,
)

export const ToastNotificationContextProvider = ({
  children,
}: IProviderProps) => {
  const [toastStatus, setToastStatus] = useState<IAlertStatusProps>({
    message: '',
    severity: 'success',
    isOpen: false,
  })
  function handleClose() {
    setToastStatus({
      message: '',
      severity: toastStatus.severity,
      isOpen: false,
    })
  }

  return (
    <ToastNotificationContext.Provider
      value={{
        toastStatus,
        setToastStatus,
        handleClose,
      }}
    >
      {children}
    </ToastNotificationContext.Provider>
  )
}
