import styles from '../styles.module.css'
import logo from '../../../assets/images/logo-285x65.png'
import { useParams } from 'react-router-dom'
import useFetch from '../../../global/hooks/useFetchHook'
import CircularProgress from '@mui/material/CircularProgress'
import { getOrderData } from '../../api'
import { useEffect, useState } from 'react'
import { IOrder } from '../../../../../app/entities/Order'
import { ARRIVED_STATUSES } from '../../../global/constants/order-status'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../global/components/buttons/secondary-button/SecondaryButton'
import ConfirmCancel from '../submodals/confirm-cancel-order/ConfirmCancel'
import { Box, Modal } from '@mui/material'
import ToastNotification, { IAlertStatusProps } from '../../../global/components/toast-alert/ToastNotification'
import ContactUs from '../submodals/contact-us/ContactUs'
import { ACCESSIBILITY_COMPLIANCE_LINK, PRIVACY_COMPLIANCE_LINK, TERMS_OF_USE_COMPLIANCE_LINK } from '../../../global/constants/compliance-links'
import OrderInformation from '../order-information/OrderInformation'
import ServiceSummary from '../service-summary/ServiceSummary'
import TrackingBullets from '../tracking-bullets/TrackingBullets'
import TechnicianDisplay from '../technician-display/TechnicianDisplay'
import ConfirmToggleStandby from '../submodals/confirm-toggle-standby/ConfirmToggleStandby'
import TireRackOrderEntry from '../tire-rack-order-entry/TireRackOrderEntry'
import EditTireRackOrder from '../submodals/edit-tire-rack-order/EditTireRackOrder'

const MODAL_NAMES = {
  CONTACT_US: 'Contact Us',
  CONFIRM_CANCEL: 'Confirm Cancel',
  CONFIRM_TOGGLE_STANDBY: 'Confirm Toggle Standby',
  EDIT_TIRE_RACK_ORDER: 'Edit Tire Rack Order'
}

export default function OrderTracker() {
  const { id: orderObjectId } = useParams()
  const { data: order, isLoading: isOrderLoading, refetch: refetchOrder } = useFetch<IOrder>(getOrderData(orderObjectId ?? ''))

  const [alertStatus, setAlertStatus] = useState<IAlertStatusProps>({
    isOpen: false,
    message: '',
    severity: 'info',
  })
  const [modalName, setModalName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [isStandbyQueueChecked, setIsStandbyQueueChecked] = useState(false)

  useEffect(() => {
    setIsStandbyQueueChecked(Boolean(order?.timeOptedIntoStandbyQueue))
  }, [order])

  function handleOpenConfirmCancelHandler() {
    setModalName(MODAL_NAMES.CONFIRM_CANCEL)
    setModalOpen(true)
  }

  function handleOpenContactUsHandler() {
    setModalName(MODAL_NAMES.CONTACT_US)
    setModalOpen(true)
  }

  function handleOpenConfirmToggleStandbyHandler() {
    setIsStandbyQueueChecked(!isStandbyQueueChecked)

    setModalName(MODAL_NAMES.CONFIRM_TOGGLE_STANDBY)
    setModalOpen(true)
  }

  function handleOpenEditTireRackOrder() {
    setModalName(MODAL_NAMES.EDIT_TIRE_RACK_ORDER)
    setModalOpen(true)
  }

  function handleClose() {
    setModalOpen(false)
    setModalName('')

    if (modalName !== MODAL_NAMES.CONTACT_US) {
      refetchOrder()
    }
  }

  function toggleAlert(open: boolean) {
    setAlertStatus({ ...alertStatus, isOpen: open })
  }

  function whichModalContent(): JSX.Element {
    if (modalName === MODAL_NAMES.CONFIRM_CANCEL) {
      return (
        <ConfirmCancel
          alertStatus={alertStatus}
          handleClose={handleClose}
          isOpen={modalOpen}
          orderObjectId={orderObjectId ?? ''}
          setAlertStatus={setAlertStatus}
        />
      )
    } else if (modalName === MODAL_NAMES.CONTACT_US) {
      return (
        <ContactUs
          handleClose={handleClose}
          isOpen={modalOpen}
        />
      )
    } else if (modalName === MODAL_NAMES.CONFIRM_TOGGLE_STANDBY) {
      return (
        <ConfirmToggleStandby
          alertStatus={alertStatus}
          handleClose={handleClose}
          isOpen={modalOpen}
          orderObjectId={orderObjectId ?? ''}
          isStandbyQueueChecked={isStandbyQueueChecked}
          setAlertStatus={setAlertStatus}
        />
      )
    } else if (modalName === MODAL_NAMES.EDIT_TIRE_RACK_ORDER) {
      return (
        <EditTireRackOrder
          alertStatus={alertStatus}
          handleClose={handleClose}
          isOpen={modalOpen}
          orderObjectId={orderObjectId ?? ''}
          setAlertStatus={setAlertStatus}
        />
      )
    } else {
      return <div/>
    }
  }

  return (
    <div className={styles.orderTrackerWrapper}>
      <ToastNotification
        severity={alertStatus.severity}
        open={alertStatus.isOpen}
        message={alertStatus.message}
        handleClose={() => toggleAlert(false)}
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}>
        <Box className={styles.subModal}>
          {whichModalContent()}
        </Box>
      </Modal>
      <div className={styles.contentContainer}>
        {isOrderLoading
          ? (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            )
          : (
            <>
              <div className={styles.logoContainer}>
                <img
                  src={logo}
                  alt="logo"
                  className={styles.logo}
                />
              </div>
              <div className={styles.orderTrackerContentContainer}>
                <div className={styles.headerContainer}>
                  <div className={styles.header}>
                    Order Status
                  </div>
                </div>
                <div className={styles.orderTrackerContent}>
                  <div className={styles.informationContainer}>
                    <OrderInformation
                      isStandbyQueueChecked={isStandbyQueueChecked}
                      order={order}
                      standbyQueueHandler={handleOpenConfirmToggleStandbyHandler}
                    />
                    <TireRackOrderEntry
                      order={order}
                      handleOpenEditTireRackOrder={handleOpenEditTireRackOrder}
                    />
                    <TechnicianDisplay
                      order={order}/>
                    <TrackingBullets
                      order={order}/>
                    <ServiceSummary
                      order={order}/>
                  </div>
                </div>
              </div>
              <footer className={styles.footer}>
                <div className={styles.footerButtons}>
                  <SecondaryButton
                    buttonName="Cancel my Appointment"
                    onClick={handleOpenConfirmCancelHandler}
                    disabled={ARRIVED_STATUSES.includes(order?.status ?? '')}
                  />
                  <PrimaryButton
                    buttonName="Contact Us"
                    onClick={handleOpenContactUsHandler}
                  />
                </div>
                <div className={styles.footerLinks}>
                  <a href={TERMS_OF_USE_COMPLIANCE_LINK}>Terms of Use</a>
                  <a href={PRIVACY_COMPLIANCE_LINK}>Privacy</a>
                  <a href={ACCESSIBILITY_COMPLIANCE_LINK}>Accessibility</a>
                </div>
              </footer>
            </>
          )
        }
      </div>
    </div>
  )
}
