import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { RESCHEDULE_REASONS } from '../../../../../../../global/constants/orders'
import { useParams } from 'react-router-dom'
import ReasonDropDown from '../../../../../../../global/components/reason-drop-down/ReasonDropDown'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { getRescheduledReason } from './api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { WoDetailContext } from '../../../../../context/context'
import PrimaryCheckbox from '../../../../../../../global/components/checkbox/Checkbox'

interface IProps {
  handleCancel: Function
  handleConfirm: Function
  isLoading: boolean
  isOpen?: boolean
}
interface IScheduledToPendingFormDataProps {
  orderObjectId: string
  reason: string
}
export default function AddRescheduledReason(props: IProps) {
  const { isOpen, isLoading, handleCancel, handleConfirm } = props
  const { user } = useAuthenticator((context) => [context.user])
  const { id } = useParams()
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { data: order } = useContext(WoDetailContext)

  const [isChecked, setIsChecked] = useState(false)
  const [formData, setFormData] = useState<IScheduledToPendingFormDataProps>({
    orderObjectId: id ?? '',
    reason: '',
  })

  const handleDropDown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      reason: e.target.value,
    }))
  }

  useEffect(() => {
    async function setTableData() {
      if (!id) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: 'There is no order selected. Please refresh the page!',
          severity: 'error',
        })
      } else {
        const rescheduledReason = await fetchData<string>(
          getRescheduledReason(id),
        )
        setFormData((prevState) => ({
          ...prevState,
          reason: rescheduledReason,
        }))
      }
    }

    setTableData()
    setIsChecked(false)
  }, [isOpen, id, user, setToastStatus, toastStatus])

  const content = (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>
        <ReasonDropDown
          handleDropDown={handleDropDown}
          reasons={RESCHEDULE_REASONS}
          reason={formData.reason}
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <PrimaryCheckbox
          onChange={() => setIsChecked(!isChecked)}
          disabled={!formData.reason}
          checked={isChecked}
          label='Yes, I want to reschedule this order now.'
        />
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => handleCancel()}
        />
        <PrimaryButton
          buttonName='Confirm'
          disabled={!isChecked || isLoading}
          onClick={() => handleConfirm(formData.reason)}
        />
      </div>
    </div>
  )

  if (isOpen !== undefined) {
    return (
      <div>
        <Modal open={isOpen}>
          <Box className={styles.scheduleModal}>
            <div className={`${styles.scheduleModalPageTitle} font--bold`}>
              Add Reschedule Reason
            </div>
            <div className={styles.statusWarning + ' warning-message'}>
              This order will be bumped into the "pending rescheduling" status
              as soon as you click "Confirm". Then, on the next screen, once a
              new time slot is chosen, the order with be "scheduled" again.
            </div>
            {content}
          </Box>
        </Modal>
      </div>
    )
  } else {
    return content
  }
}
