const ADJUSTMENT_TYPE = {
  BALANCE     : 'balance',
  DIFFERENCE  : 'difference',
  DEFAULT     : 'default',
}

// There are other decline reasons besides these, but they all send a general message
const DECLINE_REASONS = {
  INSUFFICIENT_FUNDS              : 'Insufficient Funds',
  PROCESSOR_DECLINED              : 'Processor Declined',
  PROCESSOR_DECLINED_LOST_CARD    : 'Processor Declined - Possible Lost Card',
  PROCESSOR_DECLINED_STOLEN_CARD  : 'Processor Declined - Possible Stolen Card',
  PROCESSOR_DECLINED_FRAUD        : 'Processor Declined - Fraud Suspected',
  FRAUD_SUSPECTED                 : 'Fraud Suspected',
  PROCESSOR_NETWORK_UNAVAILABLE   : 'Processor Network Unavailable - Try Again',
}

/*
 * This is a mapping of Braintree error codes to error messages from this list:
 * https://developer.paypal.com/braintree/docs/reference/general/validation-errors/all/node
 *
 * We can add to this list as we encounter more errors.
 *
 * The codes should also be mapped to decline reasons in the app/constants/braintree.ts constant files
 * as well.
 */
const ERROR_ID_TO_DECLINE_REASON_MAP = {
  91521: 'Refund amount too large',
  91512: 'Transaction already fully refunded'
}

enum STATUSES {
  FREE                                = 'FREE',
  OPEN                                = 'OPEN',
  PAID                                = 'PAID', // deprecated for settled
  PAID_MANUALLY                       = 'PAID_MANUALLY', // deprecated
  PAYMENT_METHOD_VERIFICATION_FAILED  = 'PAYMENT_METHOD_VERIFICATION_FAILED',
  PAYMENT_METHOD_VERIFIED             = 'PAYMENT_METHOD_VERIFIED',
  PAYMENT_METHOD_REQUESTED            = 'PAYMENT_METHOD_REQUESTED',
  REFUND_CANCELLED                    = 'REFUND_CANCELLED',
  REFUND_REQUESTED                    = 'REFUND_REQUESTED',
  TRANSACTION_AUTHORIZED              = 'TRANSACTION_AUTHORIZED',
  TRANSACTION_AUTHORIZATION_FAILED    = 'TRANSACTION_AUTHORIZED_FAILED',
  

  // dherthoge= as of 2024-01-14 the below statuses have 1-1 mappings to Braintree statuses
  AUTHORIZED                          = 'AUTHORIZED',
  AUTHORIZATION_EXPIRED               = 'AUTHORIZATION_EXPIRED',
  FAILED                              = 'FAILED',
  GATEWAY_REJECTED                    = 'GATEWAY_REJECTED',
  PROCESSOR_DECLINED                  = 'PROCESSOR_DECLINED',
  SETTLED                             = 'SETTLED',
  SETTLING                            = 'SETTLING',
  SETTLEMENT_CONFIRMED                = 'SETTLEMENT_CONFIRMED',
  SETTLEMENT_DECLINED                 = 'SETTLEMENT_DECLINED',
  SETTLEMENT_PENDING                  = 'SETTLEMENT_PENDING',
  SUBMITTED_FOR_SETTLEMENT            = 'SUBMITTED_FOR_SETTLEMENT',
  VOIDED                              = 'VOIDED',

  // this is a special status for the total line item, it is not a Braintree status
  TOTAL                               = 'TOTAL'
}

const STATUSES_ARRAY = Object.values(STATUSES)

/*
 * There could be other payment sources, but these are the only ones that could
 * trigger special actions
 */
const SUBMIT_FOR_SETTLEMENT_SOURCES = {
  TECHNICIAN: 'technician'
}

const TYPES = {
  QUOTE         : 'quote',
  COMPLETED     : 'completed',
  CORRECTION    : 'correction',
  ACCOMMODATION : 'accommodation',
}

export {
  ADJUSTMENT_TYPE,
  DECLINE_REASONS,
  ERROR_ID_TO_DECLINE_REASON_MAP,
  STATUSES_ARRAY,
  STATUSES,
  SUBMIT_FOR_SETTLEMENT_SOURCES,
  TYPES,
}