import { useAuthenticator } from '@aws-amplify/ui-react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { useContext, useEffect, useState } from 'react'
import {
  IInvoice,
  Invoice,
} from '../../../../../../../../../app/entities/Invoice'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import LineItemAllocator from '../../../../../../../global/components/line-item-allocator/LineItemAllocator'
import { ADJUSTMENT_TYPES } from '../../../../../../../global/constants/adjustment'
import getMostRecentServiceInvoice from '../../../../../../../global/utils/invoice/get/most-recent-service-invoice'
import isTransactionCharged from '../../../../../../../global/utils/invoice/status/is-transaction-charged'
import isTransactionSettled from '../../../../../../../global/utils/invoice/status/is-transaction-settled'
import roundTo from '../../../../../../../global/utils/roundTo'
import { WoDetailContext } from '../../../../../context/context'
import AdjustInvoiceTable from './adjust-invoice-table/AdjustInvoiceTable'
import AdjustTipTable from './adjust-invoice-table/AdjustTipTable'
import AdjustInvoiceConfirmationModal from './confirmation-modal/ConfirmationModal'
import styles from './styles.module.css'
import { formatRefundAmount } from './helper-functions/helpers'
import DecimalWrapper from '../../../../../../../global/utils/decimal-wrapper/DecimalWrapper'
import { LineItem } from '../../../../../../../../../app/types/line-item'
import isTransactionDeclined from '../../../../../../../global/utils/invoice/status/is-transaction-declined'

interface IAdjustInvoiceProps {
  isOpen: boolean
  handleClosed: Function
  invoice: any
  associatedAdjustmentInvoices: IInvoice[]
}
export interface IRecalculatedValues {
  totalTax: number | null
  totalDiscount: number | null
  estimatedTotal: number | null
  totalTaxable: number | null
}
export interface IInvoiceStateProps {
  serviceAmount: number
  totalTaxAmount: number
  totalAmountCharged: number
  amountCollected: number
}

export default function EditInvoiceModal(props: IAdjustInvoiceProps) {
  const { data: order } = useContext(WoDetailContext)
  const { isOpen, handleClosed, invoice, associatedAdjustmentInvoices } = props
  const { user } = useAuthenticator((context) => [context.user])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const adjustMethod = invoice.tip
    ? ADJUSTMENT_TYPES.TIP
    : ADJUSTMENT_TYPES.SERVICE

  const [refundAmount, setRefundAmount] = useState<number>(0)

  const [invoiceState, setInvoiceState] = useState<IInvoiceStateProps>({
    serviceAmount: 0,
    totalTaxAmount: 0,
    totalAmountCharged: 0,
    amountCollected: 0,
  })

  const [adjustmentInvoice, setAdjustmentInvoice] = useState<Partial<IInvoice>>(
    {
      type: 'accommodation',
      note: '',
      tip: false,
      transactionIdToRefund: invoice.transactionId ?? undefined,
      paymentMethodToken: invoice.paymentMethodToken ?? undefined,
      invoiceId: order.workOrderId + '-' + order.invoices.length,
    },
  )

  const [allocations, setAllocations] = useState<Record<string, number>>({})

  function confirmationHandleClosed() {
    setModalOpen(false)
  }

  function recalculateAdjustmentInvoiceHandler() {
    setIsLoading(true)

    const updatedAdjustmentInvoice: Partial<IInvoice> = {
      ...adjustmentInvoice,
      tip: adjustMethod === ADJUSTMENT_TYPES.TIP,
      totalTax: 0,
      totalDiscount: 0,
      totalTaxable: 0,
      totalNonTaxable: 0,
      allocations,
      invoiced: new Date(),
    }

    if (adjustMethod === ADJUSTMENT_TYPES.TIP) {
      updatedAdjustmentInvoice.totalNonTaxable = -Number(refundAmount)
    } else if (adjustMethod === ADJUSTMENT_TYPES.SERVICE) {
      Object.keys(allocations).forEach((orderServiceObjectId: string) => {
        const lineItem = invoice.lineItems.find(
          (lineItem: LineItem) => lineItem.id === orderServiceObjectId,
        )

        if (!lineItem) {
          return
        }

        const {
          combined_tax_rate,
          quantity,
          unit_price,
          taxable_amount,
          tax_collectable,
          discount,
        } = lineItem

        const refundAmount = Number(allocations[orderServiceObjectId])
        const taxRate = Number(combined_tax_rate)
        const taxCollectable = Number(tax_collectable)
        const taxableAmount = Number(taxable_amount)

        // "netPrice" is the total amount of the line item before tax and after discount
        const netPrice =
          Number(unit_price) * Number(quantity) -
          (discount ? Number(discount) : 0)
        const lineItemTotal = netPrice + taxCollectable
        const percentRefunded = refundAmount / lineItemTotal

        const taxToRemoveForLineItem = taxRate * netPrice * percentRefunded

        updatedAdjustmentInvoice.totalTax! -= taxToRemoveForLineItem

        const taxableAmountToRemoveForLineItem = taxableAmount * percentRefunded

        updatedAdjustmentInvoice.totalTaxable! -=
          taxableAmountToRemoveForLineItem

        const nonTaxableAmountToRemoveForLineItem =
          (netPrice - taxableAmount) * percentRefunded

        updatedAdjustmentInvoice.totalNonTaxable! -=
          nonTaxableAmountToRemoveForLineItem

        const discountToRemoveForLineItem =
          taxableAmountToRemoveForLineItem + nonTaxableAmountToRemoveForLineItem

        updatedAdjustmentInvoice.totalDiscount! += discountToRemoveForLineItem
      })
    }
    updatedAdjustmentInvoice.totalTax = roundTo(
      updatedAdjustmentInvoice.totalTax ?? 0,
    )
    updatedAdjustmentInvoice.totalTaxable = roundTo(
      updatedAdjustmentInvoice.totalTaxable ?? 0,
    )
    updatedAdjustmentInvoice.totalNonTaxable = roundTo(
      updatedAdjustmentInvoice.totalNonTaxable ?? 0,
    )
    updatedAdjustmentInvoice.totalDiscount = roundTo(
      updatedAdjustmentInvoice.totalDiscount ?? 0,
    )

    // The rounding for the tex refund can be off in the negative direction
    // which causes us to refund $0.01 too much. If so, we need to cap the
    // amount of tax we can refund
    const isRefundingTooMuchTax =
      -1 * updatedAdjustmentInvoice.totalTax > invoiceState.totalTaxAmount
    if (isRefundingTooMuchTax) {
      updatedAdjustmentInvoice.totalTax = -1 * invoiceState.totalTaxAmount
    }

    setAdjustmentInvoice(updatedAdjustmentInvoice)

    setIsLoading(false)
  }

  useEffect(recalculateAdjustmentInvoiceHandler, [allocations, refundAmount])

  function calculateAmountBasedOnPredicate(
    invoice: any,
    amount: any,
    predicate: (invoice: any) => boolean,
  ) {
    return predicate(invoice) ? amount : 0
  }

  function getRefundableTipAmount() {
    const originalTipAmountCharged = invoice.totalTipCharged ?? 0
    const amountAlreadyRefunded = associatedAdjustmentInvoices
      .filter((refundInvoice: IInvoice) => {
        return !isTransactionDeclined(refundInvoice as Invoice)
      })
      .reduce((acc: number, refundInvoice: IInvoice) => {
        const refundAmount = refundInvoice.totalAmountCharged ?? 0

        return DecimalWrapper.add(2, acc, refundAmount)
      }, 0)

    return DecimalWrapper.add(
      2,
      originalTipAmountCharged,
      amountAlreadyRefunded,
    )
  }

  useEffect(() => {
    let adjustInvoiceState = {
      serviceAmount: 0,
      totalTaxAmount: 0,
      totalAmountCharged: 0,
      amountCollected: 0,
    } as any
    /*
     * The calculateAmountBasedOnPredicate function takes 2 predicates at the time of building out fron-447.
     *
     * serviceAmount, totalTaxAmount, and totalAmountCharged should only be the sum of the corresponding
     * invoice field if the invoice is not in a declined state (i.e. it is 'charged').
     *
     * amountCollected should only be the sum of the corresponding invoice field if the invoice is in a
     * settled state (i.e. it is "SETTLED")
     */

    const isServiceAdjustment = adjustMethod === ADJUSTMENT_TYPES.SERVICE
    const isTipAdjustment = adjustMethod === ADJUSTMENT_TYPES.TIP
    if (isServiceAdjustment) {
      const mostRecentServiceInvoice = getMostRecentServiceInvoice(
        order.invoices,
      )
      if (mostRecentServiceInvoice) {
        const {
          serviceAmount,
          totalTax,
          totalAmountCharged,
          totalAmountCollected,
        } = mostRecentServiceInvoice

        adjustInvoiceState.serviceAmount = calculateAmountBasedOnPredicate(
          invoice,
          serviceAmount,
          isTransactionCharged,
        )
        adjustInvoiceState.totalTaxAmount = calculateAmountBasedOnPredicate(
          invoice,
          totalTax,
          isTransactionCharged,
        )
        adjustInvoiceState.totalAmountCharged = calculateAmountBasedOnPredicate(
          invoice,
          totalAmountCharged,
          isTransactionCharged,
        )
        adjustInvoiceState.amountCollected += calculateAmountBasedOnPredicate(
          invoice,
          totalAmountCollected,
          isTransactionSettled,
        )
      }

      // Get any refunds that have been applied to the order before this one
      const refunds = order.invoices.filter((orderInvoice: any) =>
        Boolean(
          associatedAdjustmentInvoices.find(
            (associatedInvoice: any) =>
              associatedInvoice.objectId === orderInvoice.objectId,
          ),
        ),
      )
      refunds.forEach((invoice: any) => {
        const {
          serviceAmount,
          totalTax,
          totalAmountCharged,
          totalAmountCollected,
        } = invoice

        adjustInvoiceState.serviceAmount += calculateAmountBasedOnPredicate(
          invoice,
          serviceAmount,
          isTransactionCharged,
        )
        adjustInvoiceState.totalTaxAmount += calculateAmountBasedOnPredicate(
          invoice,
          totalTax,
          isTransactionCharged,
        )
        adjustInvoiceState.totalAmountCharged +=
          calculateAmountBasedOnPredicate(
            invoice,
            totalAmountCharged,
            isTransactionCharged,
          )
        adjustInvoiceState.amountCollected += calculateAmountBasedOnPredicate(
          invoice,
          totalAmountCollected,
          isTransactionSettled,
        )
      })

      setInvoiceState(adjustInvoiceState)
    } else if (isTipAdjustment) {
      /*
       * - serviceAmount and totalAmountCharged are the totalTipCharged (totalAmountCharged
       *    in the Invoice table) of the tip invoice
       * - amountCollected is the totalTipCollected (totalTipCollected in the Invoice table)
       *    of the tip invoice
       */

      const tipInvoice = order.invoices.find(
        (orderInvoice: any) => orderInvoice.objectId === invoice.objectId,
      )
      if (tipInvoice) {
        const { serviceAmount, totalAmountCharged, totalTipCollected } =
          tipInvoice

        adjustInvoiceState.serviceAmount = calculateAmountBasedOnPredicate(
          tipInvoice,
          serviceAmount,
          isTransactionCharged,
        )
        adjustInvoiceState.totalAmountCharged = calculateAmountBasedOnPredicate(
          tipInvoice,
          totalAmountCharged,
          isTransactionCharged,
        )
        adjustInvoiceState.amountCollected += calculateAmountBasedOnPredicate(
          tipInvoice,
          totalTipCollected,
          isTransactionSettled,
        )
      }
      // Get any refunds that have been applied to the order before this one
      const refunds = order.invoices.filter((orderInvoice: any) =>
        Boolean(
          associatedAdjustmentInvoices.find(
            (associatedInvoice: any) =>
              associatedInvoice.objectId === orderInvoice.objectId,
          ),
        ),
      )
      refunds.forEach((invoice: any) => {
        const { serviceAmount, totalAmountCharged, totalTipCollected } = invoice

        adjustInvoiceState.serviceAmount += calculateAmountBasedOnPredicate(
          invoice,
          serviceAmount,
          isTransactionCharged,
        )
        adjustInvoiceState.totalAmountCharged +=
          calculateAmountBasedOnPredicate(
            invoice,
            totalAmountCharged,
            isTransactionCharged,
          )
        adjustInvoiceState.amountCollected += calculateAmountBasedOnPredicate(
          invoice,
          totalTipCollected,
          isTransactionSettled,
        )
      })

      setInvoiceState(adjustInvoiceState)
    }
  }, [adjustMethod, order.invoices, user])

  function handleAllocationsChanges(allocations: Record<string, number>) {
    const total = Object.values(allocations).reduce((acc, value) => {
      if (!isNaN(Number(value))) {
        return DecimalWrapper.add(2, Number(acc), Number(value))
      }

      return acc
    }, 0)

    setRefundAmount(total)
    setAllocations(allocations)
  }

  const radioButtonStyles = {
    color: '#D70000',
    '&.Mui-checked': {
      color: '#D70000',
    },
  }

  // Update the refund amount if it's greater than the max refund
  // amount when the user switches between service and tip
  useEffect(() => {
    const isServiceAdjustment = adjustMethod === ADJUSTMENT_TYPES.SERVICE
    const isTipAdjustment = adjustMethod === ADJUSTMENT_TYPES.TIP
    if (isServiceAdjustment) {
      const maxRefundAmount = invoiceState.totalAmountCharged
      let cleanedValue = parseFloat(
        formatRefundAmount(String(refundAmount), maxRefundAmount),
      )
      setRefundAmount(cleanedValue)
    } else if (isTipAdjustment) {
      const maxTipRefundAmount = invoiceState.serviceAmount
      let cleanedValue = parseFloat(
        formatRefundAmount(String(refundAmount), maxTipRefundAmount),
      )
      setRefundAmount(cleanedValue)
    }
  }, [invoiceState, adjustMethod])

  return (
    <div>
      <Modal open={isOpen}>
        <Box className={styles.adjustInvoiceModal}>
          <AdjustInvoiceConfirmationModal
            isOpen={modalOpen}
            handleClosed={confirmationHandleClosed}
            refundAmount={refundAmount}
            allocations={allocations}
            adjustmentInvoice={adjustmentInvoice}
          />
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            Adjust Invoice
          </div>
          <div className={styles.contentHolder}>
            {adjustMethod === ADJUSTMENT_TYPES.SERVICE && (
              <LineItemAllocator
                orderServices={order.orderServices}
                discounts={order.discounts}
                lineItems={invoice.lineItems}
                invoices={order.invoices}
                onAllocationsChange={(allocations) =>
                  handleAllocationsChanges(allocations)
                }
              />
            )}
            <div className={styles.inputsContainer}>
              <div className={styles.flexIt}>
                <div>
                  <TextField
                    label='- $ Refund Amount'
                    name='amount'
                    type='number'
                    inputProps={{
                      pattern: '[0-9]*',
                    }}
                    value={refundAmount}
                    onChange={(e) => {
                      const numberValue = parseFloat(e.target.value)
                      const shouldCapToZero =
                        isNaN(numberValue) || numberValue < 0

                      // Max refund amounts for non-tip invoices are
                      // handled care of in: LineItemAllocator.tsx
                      const isTipRefund = adjustMethod === ADJUSTMENT_TYPES.TIP
                      if (isTipRefund) {
                        const refundableAmount = getRefundableTipAmount()
                        if (numberValue > refundableAmount) {
                          setRefundAmount(refundableAmount)
                          return
                        }
                      }

                      if (shouldCapToZero) {
                        setRefundAmount(0)
                      } else {
                        setRefundAmount(numberValue)
                      }
                    }}
                    sx={{ width: 150 }}
                    disabled={adjustMethod === ADJUSTMENT_TYPES.SERVICE}
                  />
                </div>
                <div className={styles.table}>
                  {isLoading ? (
                    <div className={styles.progressContainer}>
                      <CircularProgress />
                    </div>
                  ) : adjustMethod === ADJUSTMENT_TYPES.SERVICE ? (
                    <AdjustInvoiceTable
                      amount={refundAmount}
                      adjustmentInvoice={adjustmentInvoice}
                      invoiceState={invoiceState}
                    />
                  ) : (
                    adjustMethod === ADJUSTMENT_TYPES.TIP && (
                      <AdjustTipTable
                        amount={refundAmount}
                        invoiceState={invoiceState}
                      />
                    )
                  )}
                </div>
                <div>
                  <TextField
                    label='Note'
                    name='note'
                    value={adjustmentInvoice.note}
                    onChange={(e) =>
                      setAdjustmentInvoice({
                        ...adjustmentInvoice,
                        note: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </div>
              </div>
              <div className={styles.flexIt}>
                <RadioGroup
                  name='adjustType'
                  value={adjustmentInvoice.type}
                  onChange={(e) =>
                    setAdjustmentInvoice({
                      ...adjustmentInvoice,
                      type: e.target.value,
                    })
                  }
                >
                  <div className={styles.headerTwo}>Type</div>
                  <div className={styles.adjustmentTypeRadioButtonContainer}>
                    <Radio
                      value='accommodation'
                      sx={radioButtonStyles}
                    />
                    Accommodation
                  </div>
                  <div className={styles.adjustmentTypeRadioButtonContainer}>
                    <Radio
                      value='correction'
                      sx={radioButtonStyles}
                    />
                    Correction
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => handleClosed()}
            />
            <PrimaryButton
              buttonName='Submit'
              disabled={['', '0', '0.0', '0.00'].includes(String(refundAmount))}
              onClick={() => setModalOpen(true)}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
