import { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from '../styles.module.css'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PromoCodeForm from '../form/PromoCodeForm'
import {
  createPromo,
  deletePromoCode,
  setPromoCodeToLocationRelation,
} from '../../../api'
import { fetchData } from '../../../../global/utils/fetch'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IPromoCode } from '../../../../../../app/entities/PromoCode'
import { ILocation } from '../../../../../../app/entities/Location'
import DeleteButton from '../../../../global/components/buttons/delete-button/DeleteButton'
interface IPromoCodeModalProps {
  isOpen: boolean
  handleClosed: Function
  promoId?: string
  refresh: Function
}
export interface IPromoCodeFormDataProps {
  code: string
  startDate: string
  endDate: string
  measure: string
  locationId: string
  type: string
  value: number | null
}
export default function PromoCodeModal(props: IPromoCodeModalProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { handleClosed, isOpen, promoId } = props
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [promoCodeFormData, setPromoCodeFormData] =
    useState<IPromoCodeFormDataProps>({
      code: '',
      startDate: '',
      endDate: '',
      measure: '%',
      locationId: '',
      type: 'PromoCodeDiscount',
      value: null,
    })
  async function handleCreatePromo() {
    try {
      const promoCodeBody = {
        endDate: Date.parse(promoCodeFormData.endDate),
        startDate: Date.parse(promoCodeFormData.startDate),
        value: promoCodeFormData.code,
        discountPercent: promoCodeFormData.value,
        applicationCount: 0,
      }
      setIsLoading(true)
      //create promoCode & discount

      const createPromoResponse: IPromoCode = await fetchData<IPromoCode>(
        createPromo(promoCodeBody, promoCodeFormData.locationId),
      )

      //set relation to Location
      await fetchData<ILocation>(
        setPromoCodeToLocationRelation(
          promoCodeFormData.locationId,
          createPromoResponse.objectId,
        ),
      )

      setIsLoading(false)
      props.refresh((prevState: number) => prevState + 1)
      handleClose()
    } catch (error: any) {
      console.log(error.message)
      setError(error.message)
      setIsLoading(false)
    }
  }
  function handleClose() {
    setPromoCodeFormData({
      code: '',
      startDate: '',
      endDate: '',
      measure: '%',
      locationId: '',
      type: 'PromoCodeDiscount',
      value: null,
    })
    setError('')
    handleClosed()
  }

  async function handleDeletePromo() {
    try {
      setIsLoading(true)
      await fetchData<void>(deletePromoCode(promoId))
      setIsLoading(false)
      props.refresh((prevState: any) => prevState + 1)
      handleClose()
    } catch (error: any) {
      console.log(error.message)
      setError(error.message)
      setIsLoading(false)
    }
  }
  const disabledButtonLogic =
    promoCodeFormData.code === '' ||
    promoCodeFormData.startDate === '' ||
    promoCodeFormData.endDate === '' ||
    promoCodeFormData.locationId === '' ||
    promoCodeFormData.value === null

  return (
    <div>
      <Modal open={isOpen}>
        <Box className={styles.promoCodeModal}>
          <div className={`${styles.promoModalPageTitle} font--bold`}>
            {!promoId ? 'New Promo Code' : ''}
          </div>
          {!promoId ? (
            <PromoCodeForm
              promoCodeFormData={promoCodeFormData}
              setPromoCodeFormData={setPromoCodeFormData}
            />
          ) : (
            <div>
              <div className={`${styles.promoModalPageTitle} font--bold`}>
                Are you sure you want to delete this promo code?
              </div>
            </div>
          )}
          {isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {error !== '' && <div className={styles.errorMessage}>{error}</div>}

          {!promoId ? (
            <div className={styles.buttonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClose}
              />
              <PrimaryButton
                buttonName='Create'
                disabled={disabledButtonLogic || isLoading}
                onClick={() => handleCreatePromo()}
              />
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClose}
              />
              <DeleteButton
                buttonName='Delete'
                onClick={() => handleDeletePromo()}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  )
}
