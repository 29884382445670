export function createLocationServicesForLocationByService(body: {
	locationObjectId: string
	serviceObjectId: string
	price: number
	created: number
}) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/location_service/for-location-by-service`,
		body,
	}
}

export function createLocationRuleForLocationByRule(body: {
	locationObjectId: string
	ruleName: string
	value: number
	created: number
}) {
	return {
		method: 'POST',
		url: `${process.env.REACT_APP_ORIGIN}/location_rule/for-location-by-rule`,
		body,
	}
}