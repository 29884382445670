import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { assignTrOrderNumber } from './api'
import { WoDetailContext } from '../../../../../context/context'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

interface IAssignTrNumberProps {
  handleClose: Function
  trOrderNumber: string
}
export default function AssignTrNumber(props: IAssignTrNumberProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()

  const handleAssignTrOrderNumber = async () => {
    try {
      setIsLoading(true)
      await fetchData<IOrder>(assignTrOrderNumber(id, props.trOrderNumber))
      await queryOrderData(id ?? '')
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'TR Order number assigned to this work order!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message:
          'There has been an  error assigning TRO number to this work order.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>
          Yes I want to assign tire rack order number to this work order
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Assign'
          disabled={!isChecked || isLoading}
          onClick={handleAssignTrOrderNumber}
        />
      </div>
    </div>
  )
}
