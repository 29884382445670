import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import { useParams } from 'react-router-dom'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import { getTechAssignedToWorkOrder } from '../../../../api'
import { fetchData } from '../../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ITechnician } from '../../../../../../../../app/entities/Technician'
import { WoDetailContext } from '../../../../context/context'

export default function TechniciansTable() {
  const { id } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const { isLoading, data: order } = useContext(WoDetailContext)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
  })

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<ITechnician | undefined>(
        getTechAssignedToWorkOrder(order.objectId),
      )
      setTableState((prevState: any) => ({
        ...prevState,
        data: [data],
        isLoading: false,
      }))
    }
    if (isOpen) {
      fetchTableData()
    }
  }, [isOpen, id, user])
  const columns: Column<ITechnician>[] = [
    {
      Header: 'Employee ID',
      accessor: 'employeeId',
    },
    {
      Header: 'Samsara ID',
      accessor: 'samsaraId',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Bio',
      accessor: 'bio',
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Technicians</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data}
        isLoading={tableState.isLoading}
        onRowClick={console.log}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
