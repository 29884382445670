import { useEffect, useState } from 'react'
import GoogleAddressSearchBar from '../../../availability-by-address/components/auto-complete-search-bar/GoogleAddressSearchBar'
import { Address } from '../../../../../../app/types/scheduling'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import styles from './styles.module.css'
import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { IFormDataProps } from '../../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'
import { fetchData } from '../../../../global/utils/fetch'
import { changeAddress } from '../../../../work-orders/components/work-order-detail/components/sub-modals/modal-content/change-address/api'
interface IUpdateAddressComponentProps {
  schedulerState: ISchedulerStateProps
  fetchOrderData: any
  setNextDisabled: Function
}
function generateAddressString(customerInformation: IFormDataProps) {
  const { address, state, city } = customerInformation

  return `${address}, ${city}, ${state}, USA`
}
export default function UpdateAddress(props: IUpdateAddressComponentProps) {
  const { schedulerState, fetchOrderData, setNextDisabled } = props
  const { customerInformation } = schedulerState
  const isServicedInitialValue =
    customerInformation.address.length > 0 ? true : undefined
  const [addressInput, setAddressInput] = useState<string>(
    generateAddressString(customerInformation),
  )
  const [formattedAddress, setFormattedAddress] = useState<Address>({
    address: customerInformation.address,
    state: customerInformation.state,
    zipCode: customerInformation.zipCode,
    city: customerInformation.state,
  })
  const [isZipCodeServiced, setIsZipCodeServiced] = useState<any>(
    isServicedInitialValue,
  )
  const [isUpdateAddressLoading, setIsUpdateAddressLoading] = useState(false)

  const buttonNameLogic = isZipCodeServiced
    ? 'Update Address'
    : 'Address Not Serviced'
  const disabledButtonLogic = !isZipCodeServiced || isUpdateAddressLoading

  useEffect(() => {
    setNextDisabled(disabledButtonLogic)
  }, [disabledButtonLogic])

  const updateAddressHandler = async () => {
    try {
      setIsUpdateAddressLoading(true)

      await fetchData(
        changeAddress({
          address: formattedAddress.address,
          aptOrSuite: '',
          city: formattedAddress.city,
          orderObjectId: schedulerState.orderId,
          state: formattedAddress.state,
          zipCode: formattedAddress.zipCode,
        }),
      )
      //refetch order data
      await fetchOrderData()
    } catch (err) {
      console.error(err)
    } finally {
      setIsUpdateAddressLoading(false)
    }
  }
  return (
    <div className={styles.addressContainer}>
      <GoogleAddressSearchBar
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        setIsZipCodeServiced={setIsZipCodeServiced}
        setFormattedAddress={setFormattedAddress}
      />
      <div>
        <PrimaryButton
          buttonName={buttonNameLogic}
          onClick={updateAddressHandler}
          disabled={disabledButtonLogic}
        />
      </div>
    </div>
  )
}
