import MainPageWrapper from '../../../global/components/MainPageWrapper'
import CasesTable from '../table/CasesTable'

function Cases() {
  return <CasesTable />
}

function CasesMain() {
  return (
    <MainPageWrapper>
      <Cases />
    </MainPageWrapper>
  )
}

export default CasesMain
