import React, { createContext, useState } from 'react'
import { getOrderDataById } from '../api'
import { fetchData } from '../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { initialOrderValues } from './utils'
import { IOrder } from '../../../../../app/entities/Order'
import { IOrderService } from '../../../../../app/entities/OrderService'
import { IOrderVehicle } from '../../../../../app/entities/OrderVehicle'
import { ITireRackOrder } from '../../../../../app/entities/TireRackOrder'
interface IContextProps {
  queryOrderData: (id: string | undefined) => Promise<IGetOrderByIdResponse>
  clearData: () => void
  data: IGetOrderByIdResponse
  isLoading: boolean
}

interface IProviderProps {
  children: React.ReactNode
}

//Created this interface because endpoint added some additional legacy fields, so the return no longer matches IOrder entity
export interface IGetOrderByIdResponse extends IOrder {
  services: IOrderService[]
  tireRackOrder: ITireRackOrder | null
  cancelled_onsite_flag: number
  scheduleLeadTime: number
  vehicles: IOrderVehicle[]
}
export const WoDetailContext = createContext<IContextProps>({} as IContextProps)

export const WoDetailContextProvider = ({ children }: IProviderProps) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [data, setData] = useState<IGetOrderByIdResponse>(initialOrderValues)
  const [isLoading, setIsLoading] = useState(false)

  const queryOrderData = async (id: string | undefined) => {
    setIsLoading(true)
    try {
      const orderResults = await fetchData<IGetOrderByIdResponse>(
        getOrderDataById(id),
      )
      setData(orderResults)
      return orderResults
    } catch (error) {
      console.error('Failed to fetch order data.', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }
  const clearData = () => {
    setData(initialOrderValues)
  }
  return (
    <WoDetailContext.Provider
      value={{
        queryOrderData,
        clearData,
        data,
        isLoading,
      }}
    >
      {children}
    </WoDetailContext.Provider>
  )
}
