import { ChangeEvent, useState } from "react"
import styles from "./styles.module.css"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"

interface LocationDropdownProps {
	onWeekTypeChange?: Function
}

export default function WeekTypeDropDown({ onWeekTypeChange }: LocationDropdownProps) {
	const [value, setValue] = useState("odd")

	const onWeekTypeChangeWrapper = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const {
			target: { value: newValue },
		} = event
		setValue(newValue)
		onWeekTypeChange && onWeekTypeChange(newValue)
	}

	return (
		<div>
			<TextField
				className={styles.dropDownSelect}
				label="Week Type"
				select
				onChange={onWeekTypeChangeWrapper}
				value={value}
				SelectProps={{
					MenuProps: {
						PaperProps: {
							style: {
								maxHeight: 500,
								width: 150,
								fontFamily: "",
							},
						},
					},
				}}>
				<MenuItem
					className={styles.dropDownOption}
					value="odd"
					key={1}>
					odd
				</MenuItem>
				<MenuItem
					className={styles.dropDownOption}
					value="even"
					key={2}>
					even
				</MenuItem>
			</TextField>
		</div>
	)
}
