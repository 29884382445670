import { IContactProps } from "../CustomerInformation"
import styles from "./styles.module.css"
import AdditionalInformationSelect from "./vehicle-info-selects/AdditionalInformationSelect"
import MakeInformationSelect from "./vehicle-info-selects/MakeInformationSelect"
import ModelInformationSelect from "./vehicle-info-selects/ModelInformationSelect"
import YearInformationSelect from "./vehicle-info-selects/YearInformationSelect"
import { IFormDataProps } from "../CustomerInformation"

export interface IVehicleInformationSelectProps {
	formData: IFormDataProps
	label: string
	onChange: Function
}

export default function VehicleInformationForm(props: IContactProps) {
	const { formData, setFormData } = props

	return (
		<div className={styles.contactFormContainer}>
			<div className={`${styles.contactTitle} font--bold`}>Vehicle Information</div>
			<MakeInformationSelect
				label="Make"
				formData={formData}
				onChange={setFormData}
			/>
			{formData.make && (
				<YearInformationSelect
					label="Year"
					formData={formData}
					onChange={setFormData}
				/>
			)}
			{formData.year && (
				<ModelInformationSelect
					label="Model"
					formData={formData}
					onChange={setFormData}
				/>
			)}
			{formData.model && (
				<AdditionalInformationSelect
					label="Additional Information"
					formData={formData}
					onChange={setFormData}
				/>
			)}
		</div>
	)
}
