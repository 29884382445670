import React, { useEffect, useState, ChangeEvent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { getTechsByLocationId } from '../../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchData } from '../../../../../global/utils/fetch'
import { ITechnician } from '../../../../../../../app/entities/Technician'

interface ITechDropDownProps {
  location: string
  onTechChange: Function
}
export default function TechDropDown(props: ITechDropDownProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { location, onTechChange } = props
  const [value, setValue] = useState('')
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([])
  const onTechChangeWrapper = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      target: { value: newValue },
    } = event
    setValue(newValue)
    onTechChange && onTechChange(newValue)
  }
  const fetchAndSetTechs = async () => {
    try {
      const res = await fetchData<ITechnician[]>(getTechsByLocationId(location))
      const items = res.map((tech: any, index: number) => {
        return (
          <MenuItem
            key={index}
            value={tech.objectId}
          >
            {tech.name}
          </MenuItem>
        )
      })
      setMenuItems(items)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (location) {
      fetchAndSetTechs()
    }
  }, [location])

  return (
    <TextField
      select
      value={value}
      onChange={(e) => onTechChangeWrapper(e)}
      sx={{ width: 200 }}
      label='Technician'
      name='technicianId'
    >
      {menuItems.length ? (
        menuItems
      ) : (
        <MenuItem disabled>No technicians available</MenuItem>
      )}
    </TextField>
  )
}
