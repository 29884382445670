import moment, { Moment } from 'moment'
import { ITimeSlot } from '../../../../app/entities/TimeSlot'
import { IRouteInstruction } from '../../../../app/entities/RouteInstruction'

const ONE_AND_A_HALF_HOURS = 1.5 // hours

// This function is replicated in app/eworkflows/order/time-slot/determine-halved-time-slot.ts
export default function determineHalvedTimeSlot(timeSlot?: ITimeSlot, routeInstruction?: IRouteInstruction): {
  fromTimeMoment: Moment | undefined,
  toTimeMoment: Moment | undefined
} {
  let halvedTimeSlot: any = {
    fromTimeMoment: undefined,
    toTimeMoment: undefined
  }
  if (!timeSlot || !routeInstruction) {
    return halvedTimeSlot
  }

  const { fromTime, toTime } = timeSlot
  const { startTime } = routeInstruction

  const fromTimeMinusStartTime = moment.utc(startTime).diff(fromTime)
  const startTimeMinusToTime = moment.utc(toTime).diff(startTime)

  if (fromTimeMinusStartTime < startTimeMinusToTime) {
    halvedTimeSlot = {
      fromTimeMoment: moment.utc(fromTime),
      toTimeMoment: moment.utc(fromTime).add(ONE_AND_A_HALF_HOURS, 'hours')
    }
  } else {
    halvedTimeSlot = {
      fromTimeMoment: moment.utc(toTime).subtract(ONE_AND_A_HALF_HOURS, 'hours'),
      toTimeMoment: moment.utc(toTime)
    }
  }

  return halvedTimeSlot
}