import { ITrmiWorkOrder } from '../../../../../../../../../bi/entities/TrmiWorkOrder'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateTrmiWorkOrderColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Work Order Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ workOrderId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { workOrderId } = data
        return generateTextField(
          'workOrderId',
          workOrderId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'TR Order Ids',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ trOrderIds: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { trOrderIds } = data
        return generateTextField(
          'trOrderIds',
          trOrderIds?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Scheduled Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ scheduledDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { scheduledDate } = data
        return generateTextField(
          'scheduledDate',
          scheduledDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Actual Service Start',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ actualServiceStart: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { actualServiceStart } = data
        return generateTextField(
          'actualServiceStart',
          actualServiceStart?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Actual Service End',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ actualServiceEnd: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { actualServiceEnd } = data
        return generateTextField(
          'actualServiceEnd',
          actualServiceEnd?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Status',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ status: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { status } = data
        return generateTextField(
          'status',
          status,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Free Order Reason',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ freeOrderReason: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { freeOrderReason } = data
        return generateTextField(
          'freeOrderReason',
          freeOrderReason?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Cancelled Reason',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ cancelledReason: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { cancelledReason } = data
        return generateTextField(
          'cancelledReason',
          cancelledReason?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Customer Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ customerId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { customerId } = data
        return generateTextField(
          'customerId',
          customerId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Installation Address',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationAddress: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { installationAddress } = data
        return generateTextField(
          'installationAddress',
          installationAddress?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Installation City',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationCity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { installationCity } = data
        return generateTextField(
          'installationCity',
          installationCity?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Installation State',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationState: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { installationState } = data
        return generateTextField(
          'installationState',
          installationState?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Installation Zip',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ installationZip: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { installationZip } = data
        return generateTextField(
          'installationZip',
          installationZip?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Technician Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ technicianId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { technicianId } = data
        return generateTextField(
          'technicianId',
          technicianId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Technician Id 2',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ technicianId2: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { technicianId2 } = data
        return generateTextField(
          'technicianId2',
          technicianId2?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Treadware Location Object Id',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ treadwareLocationObjectId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { treadwareLocationObjectId } = data
        return generateTextField(
          'treadwareLocationObjectId',
          treadwareLocationObjectId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Treadware Location City',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ treadwareLocationCity: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { treadwareLocationCity } = data
        return generateTextField(
          'treadwareLocationCity',
          treadwareLocationCity?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Treadware Location State',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ treadwareLocationState: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { treadwareLocationState } = data
        return generateTextField(
          'treadwareLocationState',
          treadwareLocationState?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Treadware Location Name',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ treadwareLocationName: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { treadwareLocationName } = data
        return generateTextField(
          'treadwareLocationName',
          treadwareLocationName?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Van Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vanId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { vanId } = data
        return generateTextField(
          'vanId',
          vanId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Goods Price',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ goodsPrice: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { goodsPrice } = data
        return generateTextField(
          'goodsPrice',
          goodsPrice?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Service Price',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ servicePrice: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { servicePrice } = data
        return generateTextField(
          'servicePrice',
          servicePrice?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Discount',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ discount: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { discount } = data
        return generateTextField(
          'discount',
          discount?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Non Taxable',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ nonTaxable: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { nonTaxable } = data
        return generateTextField(
          'nonTaxable',
          nonTaxable?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Taxable',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ taxable: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { taxable } = data
        return generateTextField(
          'taxable',
          taxable?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Tax',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tax: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { tax } = data
        return generateTextField(
          'tax',
          tax?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Total Amount Charged',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ totalAmountCharged: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { totalAmountCharged } = data
        return generateTextField(
          'totalAmountCharged',
          totalAmountCharged?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Total Amount Collected',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ totalAmountCollected: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { totalAmountCollected } = data
        return generateTextField(
          'totalAmountCollected',
          totalAmountCollected?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Tip Charged',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tipCharged: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { tipCharged } = data
        return generateTextField(
          'tipCharged',
          tipCharged?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Tip Collected',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ tipCollected: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiWorkOrder, rowIndex: number) => {
        const { tipCollected } = data
        return generateTextField(
          'tipCollected',
          tipCollected?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateTrmiWorkOrderColumns
