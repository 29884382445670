import Checkbox from '@mui/material/Checkbox'
import { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { resendInvoice } from './api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import CircularProgress from '@mui/material/CircularProgress'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'

interface IResendInvoiceProps {
  handleClose: Function
}
export default function ResendInvoice(props: IResendInvoiceProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const orderId = id ?? ''

  const handleResendInvoice = async () => {
    try {
      setIsLoading(true)
      await fetchData(resendInvoice(orderId))
      props.handleClose()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Invoice has been resent!',
        severity: 'success',
      })
    } catch (err: any) {
      console.error(err.message)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message:
          'Could not resend the invoice; some information related to the order like the invoice or customer might be missing.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to resend the invoice associated with this order</div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Resend Invoice'
          disabled={!isChecked || isLoading}
          onClick={handleResendInvoice}
        />
        {isLoading ? <CircularProgress size={20} /> : null}
      </div>
    </div>
  )
}
