import { Row } from 'react-table'
import { ILocationServiceOrRule } from '../../../../../app/types/location-service-or-rule'
import isEntityALocationService from './is-entity-a-location-service'
import { ILocationRule } from '../../../../../app/entities/LocationRule'

export const consolidateLocationServicesOrRules = (
  locationServicesOrRules: ILocationServiceOrRule[]
) => {
  const consolidated = locationServicesOrRules.reduce(
    (acc: ILocationServiceOrRule[], current: ILocationServiceOrRule) => {
      const isAService = isEntityALocationService(current)

      const existing = acc.find((item) => {
        const withinAMinute = Math.abs(item.created - current.created) < 60000

        const hasSameAmount = isAService
          ? item.price === current.price
          : (item as ILocationRule).value === (current as ILocationRule).value

        return withinAMinute && hasSameAmount
      })

      if (!existing) {
        acc.push(current)
      }

      return acc
    }, [] as ILocationServiceOrRule[]) as unknown as Row[]

  return consolidated
}
