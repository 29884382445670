import React, { useContext } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import HolidayModal from '../../holiday-modal/container/HolidayModal'
import hasPermission from '../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../global/constants/permissions'

export default function NewHoliday(props: any) {
  const { user } = useContext(UserContext)
  const { setRefreshCounter } = props.refresh
  const [open, setOpen] = React.useState(false)

  function handleOpen() {
    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
  }

  const isCreateDisabled = !hasPermission(user).allow(
    PERMISSIONS.HOLIDAYS.CATEGORY,
    PERMISSIONS.HOLIDAYS.ENTRIES.CREATE_BUTTON.NAME,
  )

  return (
    <div>
      <PrimaryButton
        buttonName='Create'
        onClick={handleOpen}
        disabled={isCreateDisabled}
      />
      {open && (
        <HolidayModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={setRefreshCounter}
        />
      )}
    </div>
  )
}
