import Checkbox from '@mui/material/Checkbox'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { useState, useContext } from 'react'
import { completeWorkOrder } from './api'
import { useParams } from 'react-router-dom'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { WoDetailContext } from '../../../../../context/context'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { STATUSES, TYPES } from '../../../../../../../global/constants/invoice'
import moment from 'moment'
interface ICompleteWorkOrderModal {
  handleClose: Function
}

const ERROR_COMPLETING_ORDER = 'Error trying to complete order!'
const ORDER_COMPLETED = 'Order completed!'

export default function CompleteWorkOrder(props: ICompleteWorkOrderModal) {
  const { id } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const { queryOrderData } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function didPaymentDecline(order: IOrder) {
    const { invoices } = order
    if (!invoices) return false

    const hasPaidNonTipInvoice = invoices.some((invoice) => {
      return (
        !invoice.tip &&
        invoice.type === TYPES.COMPLETED &&
        (invoice.status === STATUSES.SUBMITTED_FOR_SETTLEMENT ||
          invoice.status === STATUSES.SETTLED)
      ) // the invoice could settle immediately if we are using a sandbox account
    })

    const hasPaidFreeInvoice = invoices.some((invoice) => {
      return invoice.status === STATUSES.FREE
    })

    return hasPaidNonTipInvoice || hasPaidFreeInvoice
  }

  function findDeclineReason(order: IOrder) {
    const { invoices } = order
    if (!invoices) return false

    // sorted by invoiced descending
    const mostRecentFailedInvoice = invoices
      .sort((a, b) => {
        return (
          moment.utc(b.invoiceId).toDate().getTime() -
          moment.utc(a.invoiceId).toDate().getTime()
        )
      })
      .find((invoice) => {
        return (
          !invoice.tip &&
          invoice.type === TYPES.COMPLETED &&
          invoice.status === STATUSES.SETTLEMENT_DECLINED
        )
      })

    return mostRecentFailedInvoice?.declineReason
  }

  const handleCompleteOrder = async () => {
    const orderId = id ?? ''
    try {
      setIsLoading(true)
      const responseOrder: IOrder = await fetchData(completeWorkOrder(orderId))

      const isPaymentDeclined = !didPaymentDecline(responseOrder)
      const declineReason = findDeclineReason(responseOrder)
      if (isPaymentDeclined) {
        alert(`Payment Declined: ${declineReason}`)
      }

      await queryOrderData(orderId)
      if (isPaymentDeclined) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: `Order completed but the payment declined: ${declineReason}.`,
          severity: 'error',
        })
      } else {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: ORDER_COMPLETED,
          severity: 'success',
        })
      }
    } catch (err) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: ERROR_COMPLETING_ORDER,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to complete this work order and process payment</div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Complete'
          disabled={!isChecked || isLoading}
          onClick={handleCompleteOrder}
        />
      </div>
    </div>
  )
}
