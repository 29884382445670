import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
interface ICustomerInformationComponentProps {
  schedulerState: ISchedulerStateProps
}
function anonymizeEmail(email: string) {
  const atIndex = email.indexOf('@')
  const username = email.substring(0, atIndex)
  let anonymizedEmail: string = username.charAt(0)
  for (let i = 0; i < username.length - 1; i++) {
    anonymizedEmail += '*'
  }
  anonymizedEmail += email.charAt(username.length - 1)
  return anonymizedEmail + email.substring(atIndex, email.length)
}

export default function CustomerInfo(
  props: ICustomerInformationComponentProps,
) {
  const { schedulerState } = props
  const { customerInformation } = schedulerState
  const { firstName, lastName, make, model, year, email, phone } =
    customerInformation
  const lastInitial = lastName.charAt(0)
  const anonymizedPhone = `${phone.charAt(0)}**-***-***${phone.charAt(phone.length - 1)}`

  return (
    <div>
      <div style={{ fontWeight: 600 }}>Appointment Info:</div>
      <div>{`${firstName} ${lastInitial}`}</div>
      <div>{`${year} ${make} ${model}`}</div>
      <div>{anonymizedPhone}</div>
      <div>{anonymizeEmail(email)}</div>
    </div>
  )
}
