import styles from "./styles.module.css"
import TextField from "@mui/material/TextField"
import { useState, ChangeEvent } from "react"
import { IZoneDefProps, IZoneDefinitionDataProps } from "../container-modal/LocationModal"

interface IZoneDefRowProps {
	cancel: React.MouseEventHandler
	zoneDefProps: IZoneDefProps
}

export default function CreateZoneDefRow(props: IZoneDefRowProps) {
	const [newZoneDef, setNewZoneDef] = useState({
		type: "",
		fromDistance: 0,
		toDistance: 0,
		width: 0,
		maxRoutes: 0,
	})
	const { setZoneDefinitionsData } = props.zoneDefProps
	console.log(newZoneDef)
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.name === "type") {
			setNewZoneDef({ ...newZoneDef, [e.target.name]: e.target.value })
		} else {
			setNewZoneDef({ ...newZoneDef, [e.target.name]: Number(e.target.value) })
		}
	}
	const addNewZoneDefinition = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		const { type, fromDistance, toDistance, width } = newZoneDef
		if (!type || !fromDistance || !toDistance || !width) {
			alert("Missing fields required to add new zone definition")
		} else {
			setZoneDefinitionsData((prevState: IZoneDefinitionDataProps) => {
				const createdZoneDefs = [...prevState.created, newZoneDef]
				const newZoneDefs = [...prevState.zoneDefinitions, newZoneDef]
				return { ...prevState, zoneDefinitions: newZoneDefs, created: createdZoneDefs }
			})
			props.cancel(e)
		}
	}
	return (
		<tr className={styles.bodyRow}>
			<td className={styles.bodyRowCell}>
				<TextField
					label="Type"
					name="type"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="From"
					name="fromDistance"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="To"
					name="toDistance"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="Width"
					name="width"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="Max Routes"
					name="maxRoutes"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<div className={styles.buttonCell}>
					<a
						onClick={props.cancel}
						className={styles.buttonText}>
						Cancel
					</a>
					<div className={styles.line}></div>
					<a
						className={styles.buttonText}
						onClick={addNewZoneDefinition}>
						Add
					</a>
				</div>
			</td>
		</tr>
	)
}
