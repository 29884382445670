const SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_HEIGHT = 2048
const SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_WIDTH = 2048
const SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_BYTES = 3 // 3MB
const SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_MB = 3 * 1024 * 1024 // 3MB

const SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png'
}

const SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES_ARRAY = Object.values(SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES)

export {
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_HEIGHT,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_IMAGE_WIDTH,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_BYTES,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_MAX_SIZE_MB,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES,
  SMALL_PART_SUPPLIES_QR_CODE_INPUT_TYPES_ARRAY
}