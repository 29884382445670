import styles from "./styles.module.css"
import TextField from "@mui/material/TextField"
import { ITabulationAreasDataProps, ITabulationAreasProps } from "../container-modal/LocationModal"
import { useState, ChangeEvent } from "react"

interface IZipCodeRowProps {
	cancel: React.MouseEventHandler
	tabulationAreasProps: ITabulationAreasProps
}

export default function CreateZipCodeRow(props: IZipCodeRowProps) {
	const { tabulationAreasData, setTabulationAreasData } = props.tabulationAreasProps
	const [newTabArea, setNewTabArea] = useState({
		zipCode: "",
		stateAbbr: "",
		status: "",
	})
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setNewTabArea({ ...newTabArea, [e.target.name]: e.target.value })
	}
	const addNewTabulationArea = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		const { zipCode, stateAbbr, status } = newTabArea
		if (!zipCode || !stateAbbr || !status) {
			alert("Missing fields required to add new zip code")
		} else {
			setTabulationAreasData((prevState: ITabulationAreasDataProps) => {
				const createdTabArea = [...prevState.created, newTabArea]
				const newTabAreas = [...prevState.tabulationAreas, newTabArea]
				return { ...prevState, tabulationAreas: newTabAreas, created: createdTabArea }
			})
			props.cancel(e)
		}
	}
	return (
		<tr className={styles.bodyRow}>
			<td className={styles.bodyRowCell}>
				<TextField
					label="Zip Code"
					name="zipCode"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="State Abbr"
					name="stateAbbr"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<TextField
					label="Status"
					name="status"
					size="small"
					onChange={handleChange}
				/>
			</td>
			<td className={styles.bodyRowCell}>
				<div className={styles.buttonCell}>
					<a
						onClick={props.cancel}
						className={styles.buttonText}>
						Cancel
					</a>
					<div className={styles.line}></div>
					<a
						className={styles.buttonText}
						onClick={addNewTabulationArea}>
						Add
					</a>
				</div>
			</td>
		</tr>
	)
}
