import { ILocationPartial } from '../../../../../../../../bi/entities/Location'
import tableStyles from '../../../../../../global/components/table/styles.module.css'
import buttonStyles from '../../styles.module.css'
import TextField from '@mui/material/TextField'
import { useState, ChangeEvent } from 'react'

interface ILocationRowProps {
  handleCancel: Function
  handleSaveNewRow: Function
}

const COLUMN_NAMES = [
  'locationMoniker',
  'startDate',
  'endDate',
  'tireRackCid1',
  'tireRackCid2',
  'tireRackCostCenterId',
  'tireRackParentCostCenterId',
  'type',
  'brand',
  'address',
  'city',
  'state',
  'zipCode',
  'phoneNumber',
] as (
  | 'locationMoniker'
  | 'startDate'
  | 'endDate'
  | 'tireRackCid1'
  | 'tireRackCid2'
  | 'tireRackCostCenterId'
  | 'tireRackParentCostCenterId'
  | 'type'
  | 'brand'
  | 'address'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'phoneNumber'
)[]

export default function CreateLocationRow(props: ILocationRowProps) {
  const [newLocation, setNewLocation] = useState<ILocationPartial>({
    locationMoniker: undefined,
    startDate: undefined,
    endDate: undefined,
    tireRackCid1: undefined,
    tireRackCid2: undefined,
    tireRackCostCenterId: undefined,
    tireRackParentCostCenterId: undefined,
    type: undefined,
    brand: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zipCode: undefined,
    phoneNumber: undefined,
  })

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setNewLocation({ ...newLocation, [e.target.name]: e.target.value })
  }

  function addNewLocation(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    props.handleSaveNewRow(newLocation)
  }
  const rows = COLUMN_NAMES.map((columnName) => {
    return (
      <td className={tableStyles.bodyRowCell}>
        <TextField
          size='small'
          name={`${columnName}`}
          value={newLocation[`${columnName}`]}
          sx={{ minWidth: 100 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </td>
    )
  })

  return (
    <tr className={tableStyles.bodyRow}>
      {rows}
      <td className={tableStyles.bodyRowCell}>
        <div className={buttonStyles.buttonCell}>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={addNewLocation}
            >
              Add
            </a>
          </div>
          <div className={buttonStyles.line}></div>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={() => props.handleCancel()}
            >
              Cancel
            </a>
          </div>
        </div>
      </td>
    </tr>
  )
}
