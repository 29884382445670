import { useMemo } from 'react'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import Table from '../../../../../global/components/table/Table'
import { Column } from 'react-table'
import styles from './styles.module.css'
import PrimaryCheckbox from '../../../../../global/components/checkbox/Checkbox'
import { IWeekCalendarsProps } from '../container-modal/TechnicianEdit'
import { IWeekCalendar } from '../../../../../../../app/entities/WeekCalendar'

interface IWorkingDaysTableProps {
  weekCalendars: IWeekCalendarsProps
}

export default function WorkingDaysTable(props: IWorkingDaysTableProps) {
  const { weekCalendars } = props
  const { weekCalendarsFormInfo, setWeekCalendarsFormInfo } = weekCalendars

  const handleWeekCalendarChange = (newValue: boolean, weekDay: string, rowIndex: number) => {
    setWeekCalendarsFormInfo((prevState: any) => {
      const newWeekDaySettings = prevState.weekCalendars.map((weekSettingsObj: any, index: number) => {
        if (index === rowIndex) {
          return { ...weekSettingsObj, [weekDay]: newValue }
        }
        return weekSettingsObj
      })
      return { ...prevState, weekCalendars: newWeekDaySettings }
    })
  }

  const columns: Column<IWeekCalendar>[] = useMemo(
    () => [
      {
        Header: 'Week',
        accessor: 'weekName',
      },
      {
        Header: 'Sun',
        accessor: (data: any, rowIndex: number) => {
          const { sunday } = data
          return (
            <PrimaryCheckbox
              checked={sunday}
              name="sunday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Mon',
        accessor: (data: any, rowIndex: number) => {
          const { monday } = data
          return (
            <PrimaryCheckbox
              checked={monday}
              name="monday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Tue',
        accessor: (data: any, rowIndex: number) => {
          const { tuesday } = data
          return (
            <PrimaryCheckbox
              checked={tuesday}
              name="tuesday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Wed',
        accessor: (data: any, rowIndex: number) => {
          const { wednesday } = data
          return (
            <PrimaryCheckbox
              checked={wednesday}
              name="wednesday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Thu',
        accessor: (data: any, rowIndex: number) => {
          const { thursday } = data
          return (
            <PrimaryCheckbox
              checked={thursday}
              name="thursday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Fri',
        accessor: (data: any, rowIndex: number) => {
          const { friday } = data
          return (
            <PrimaryCheckbox
              checked={friday}
              name="friday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
      {
        Header: 'Sat',
        accessor: (data: any, rowIndex: number) => {
          const { saturday } = data
          return (
            <PrimaryCheckbox
              checked={saturday}
              name="saturday"
              onChange={(e) => handleWeekCalendarChange(e.target.checked, e.target.name, rowIndex)}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <div style={{ width: '100%' }}>
      <TableTopper>
        <div className={styles.titleButtonContainer}>
          <TableTitle>Working Days</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={weekCalendarsFormInfo.weekCalendars as any}
        isDisplayMode={true}
        isLoading={false}
      />
    </div>
  )
}
