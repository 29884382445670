import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import React, { useContext, ChangeEvent, useState, MouseEvent } from 'react'
import styles from './styles.module.css'
import { ReactComponent as AddNoteSvg } from '../../../../../../../../../assets/images/add-icon.svg'
import { ICaseFormInfoProps, ICaseFormProps } from './CasesModal'
import { WoDetailContext } from '../../../../../../../context/context'
import useFetch from '../../../../../../../../../global/hooks/useFetchHook'
import { getUsers } from '../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { UserContext } from '../../../../../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../../../../../global/constants/permissions'

interface ICaseCreateProps {
  formProps: ICaseFormProps
  isNewCase?: boolean
}
export default function CasesCreate(props: ICaseCreateProps) {
  const { user } = useContext(UserContext)
  const { data } = useContext(WoDetailContext)
  const { data: userData, isLoading } = useFetch(getUsers())
  const { formProps } = props
  const { caseFormInfo, setCaseFormInfo } = formProps
  const uniformWidth = {
    width: 300,
  }
  const [noteInput, setNoteInput] = useState({
    note: '',
  })

  const isReadOnly = !hasPermission(user).allow(
    PERMISSIONS.CASES.CATEGORY,
    PERMISSIONS.CASES.ENTRIES.EDIT_CASES.NAME,
  )

  function handleChangeForm(e: ChangeEvent<HTMLInputElement>) {
    setCaseFormInfo((prevState: ICaseFormInfoProps) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const renderNotes = caseFormInfo.note.notes.map((note) => {
    return <div className={styles.note}>{note.note}</div>
  })
  function handleAddNote(e: MouseEvent) {
    setCaseFormInfo((prevState: any) => ({
      ...prevState,
      note: {
        ...prevState.note,
        notes: [...prevState.note.notes, noteInput],
        created: [...prevState.note.created, noteInput],
      },
    }))
    setNoteInput({
      note: '',
    })
  }
  if (isLoading && !userData) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className={styles.inputsContainer}>
      <div style={{ display: 'flex', gap: 24 }}>
        <div className={styles.topInputsContainer}>
          <TextField
            label='Work Order ID'
            name='orderId'
            disabled
            value={caseFormInfo.orderId || data.workOrderId}
            InputLabelProps={{ shrink: true }}
            sx={uniformWidth}
          />
          {caseFormInfo?.createdBy && (
            <TextField
              label='Created by'
              name='createdBy'
              disabled
              value={caseFormInfo.createdBy || ''}
              InputLabelProps={{ shrink: true }}
              sx={uniformWidth}
            />
          )}
          <StatusDropDown
            defaultValue={caseFormInfo.status}
            handleChange={handleChangeForm}
            disabled={isReadOnly}
          />
        </div>
        <div className={styles.topInputsContainer}>
          <TextField
            label='Type'
            name='type'
            onChange={handleChangeForm}
            value={caseFormInfo.type || ''}
            sx={uniformWidth}
            disabled={isReadOnly}
          />
          <TextField
            label='Damage Cost'
            name='damageCost'
            type='number'
            onChange={handleChangeForm}
            value={caseFormInfo.damageCost || ''}
            sx={uniformWidth}
            disabled={isReadOnly}
          />
          <TextField
            label='Summary'
            name='summary'
            sx={uniformWidth}
            onChange={handleChangeForm}
            value={caseFormInfo.summary || ''}
            multiline
            rows={8}
            disabled={isReadOnly}
          />
        </div>
        <div className={styles.topInputsContainer}>
          <div className={styles.notesTitle}>Case Notes</div>
          <div className={styles.notesContainer}>
            <div className={styles.notesBox}>{renderNotes}</div>
            <div className={styles.inputForNotes}>
              <TextField
                sx={{ width: 280 }}
                label='Add Note'
                name='note'
                value={noteInput.note}
                onChange={(e) =>
                  setNoteInput({
                    note: e.target.value,
                  })
                }
                disabled={isReadOnly}
                InputProps={{
                  endAdornment: isReadOnly ? (
                    <></>
                  ) : (
                    <div
                      className={styles.addNoteButton}
                      onClick={handleAddNote}
                    >
                      <AddNoteSvg
                        width={20}
                        height={20}
                      />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const StatusDropDown = ({ defaultValue, handleChange, disabled }: any) => {
  const statuses = ['open', 'close', 'in progress', 'overdue']
  const menuItems = statuses.map((status, index) => {
    return (
      <MenuItem
        key={index}
        value={status}
      >
        {status}
      </MenuItem>
    )
  })

  return (
    <TextField
      sx={{ width: 300 }}
      label='Status'
      name='status'
      select
      value={defaultValue}
      onChange={handleChange}
      disabled={disabled}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 200,
            },
          },
        },
      }}
    >
      {menuItems}
    </TextField>
  )
}

const AssignedToDropDown = ({ userData, defaultValue, handleChange }: any) => {
  let menuItems = userData.map((user: any, index: number) => {
    return (
      <MenuItem
        key={index}
        value={user.objectId}
      >
        {user.name}
      </MenuItem>
    )
  })
  return (
    <TextField
      sx={{ width: 300 }}
      label='Assigned To'
      name='assignedTo'
      select
      disabled
      onChange={handleChange}
      value={defaultValue}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 200,
            },
          },
        },
      }}
    >
      {userData ? menuItems : <MenuItem key={999}>Loading...</MenuItem>}
    </TextField>
  )
}
