import { useContext, useState } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import FuturePercentBooked from '../components/future-percent-booked/FuturePercentBooked'
import WeeksDropdown from '../components/dropdowns/WeeksDropDown'
import { fetchData } from '../../global/utils/fetch'
import { getFuturePercentBookedData } from '../components/future-percent-booked/api'
import styles from './styles.module.css'
import { CircularProgress } from '@mui/material'
import hasPermission from '../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../global/constants/permissions'
import { UserContext } from '../../base/components/base-container/BaseContainer'

function DashboardsMain() {
  const { user } = useContext(UserContext)
  const [weeks, setWeeks] = useState<number>(2)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function downloadCsv() {
    try {
      setIsLoading(true)

      const csv = await fetchData<any>(getFuturePercentBookedData(true))
      const blob = new Blob([csv.csv], { type: 'text/csv;charset=utf-8;' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      const errorMessage = `Error downloading CSV: ${error}`
      alert(errorMessage)
      console.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  function renderParameters() {
    return (
      <></>
      // <WeeksDropdown
      //   setWeeks={setWeeks}
      //   weeks={weeks}
      // />
    )
  }

  return (
    <MainPageWrapper
      titleFields={
        <div style={{ marginRight: 'auto', marginLeft: '1rem' }}>
          {renderParameters()}
        </div>
      }
    >
      <div>
        {hasPermission(user).allow(
          PERMISSIONS.DASHBOARDS.CATEGORY,
          PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_FUTURE_PERCENT_BOOKED.NAME,
        ) && (
          <PrimaryButton
            buttonName='Future % Booked'
            onClick={() => {}}
          />
        )}
        <div className={styles.link}>
          <button
            className={styles.navLink}
            onClick={downloadCsv}
            disabled={
              !hasPermission(user).allow(
                PERMISSIONS.DASHBOARDS.CATEGORY,
                PERMISSIONS.DASHBOARDS.ENTRIES.DOWNLOAD_CSV.NAME,
              )
            }
          >
            Download CSV
          </button>
          {isLoading && (
            <CircularProgress
              className={styles.loadingCircle}
              size={10}
            />
          )}
        </div>
      </div>
      {hasPermission(user).allow(
        PERMISSIONS.DASHBOARDS.CATEGORY,
        PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_FUTURE_PERCENT_BOOKED.NAME,
      ) && <FuturePercentBooked />}
    </MainPageWrapper>
  )
}

export default DashboardsMain
