export function isValidEmail(email: string) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(email)
}
export function isTenDigitPhoneNumber(phoneNumber: string) {
	const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "")
	return cleanedPhoneNumber.length === 10
}
export function isFiveDigitZipCode(zipCode: string) {
	return zipCode.length === 5
}

export function getErrors(form: { [key: string]: any }, formOptions: {[key: string]: any} ) {
	return Object.keys(formOptions).reduce((errors, key) => {
		const value = form[key]

		const error = formOptions[key].validator?.(value)

		if (error) {
			errors.push(error)
		}

		return errors
	}, [] as string[])
}