export const initialDaySettingValues = {
  workDay: false,
  reason: '',
  startTime: '',
  endTime: '',
  dayDateFrom: '',
  dayDateTo: '',
  lunchFrom: '',
  lunchTo: '',
  day: 0,
  occursOnce: true,
  occursYearly: false,
  lunchDuration: NaN,
}
export const initialTechFormInfoValues = {
  name: '',
  email: '',
  bio: '',
  employeeId: '',
  techAvatar: '',
  samsaraId: null,
  inactive: false,
  workDayFrom: '',
  workDayTo: '',
  lunchFrom: '',
  lunchTo: '',
  lunchDuration: 0,
  technicianId: '',
  locationId: '',
  locationRoutingAlgorithm: '',
  defaultWorkDayFrom: '',
  defaultWorkDayTo: '',
  defaultLunchFrom: '',
  defaultLunchTo: '',
  defaultLunchDuration: 0,
  useDefaultLunch: true,
  useDefaultWorkDay: true,
}
export const initialWeekCalendarFormValues = {
  weekCalendars: [
    {
      friday: false,
      monday: false,
      objectId: '',
      saturday: false,
      sunday: false,
      thursday: false,
      tuesday: false,
      wednesday: false,
      weekName: 'even',
    },
    {
      friday: false,
      monday: false,
      objectId: '',
      saturday: false,
      sunday: false,
      thursday: false,
      tuesday: false,
      wednesday: false,
      weekName: 'odd',
    },
  ],
}
export const initialDaySettingFormValues = {
  daySettings: [],
}
export const fileToDataUri = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = (event: ProgressEvent<FileReader>) => {
    if (event.target)
      resolve(event.target.result)
    else
      alert('Could not convert the uploaded file to an image!')
  }
  reader.readAsDataURL(file)
})