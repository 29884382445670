import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import { ACTIONS, PERMISSIONS } from '../../../global/constants/permissions'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { IPermission } from '../../../../../app/entities/Permission'
import hasPermission from '../../../global/utils/user/has-permission'

const { CATEGORY: CASES_SETTINGS_CATEGORY, ENTRIES: CASES_SETTINGS_ENTRIES } =
  PERMISSIONS.CASES
const {
  CATEGORY: LOCATION_SETTINGS_CATEGORY,
  ENTRIES: LOCATION_SETTINGS_ENTRIES,
} = PERMISSIONS.LOCATIONS
const { CATEGORY: SCHEDULE_VIEW_CATEGORY, ENTRIES: SCHEDULE_VIEW_ENTRIES } =
  PERMISSIONS.SCHEDULE_VIEW
const { CATEGORY: SERVICES_CATEGORY, ENTRIES: SERVICES_ENTRIES } =
  PERMISSIONS.SERVICES
const { CATEGORY: TECHNICIANS_CATEGORY, ENTRIES: TECHNICIANS_ENTRIES } =
  PERMISSIONS.TECHNICIANS
const { CATEGORY: VANS_CATEGORY, ENTRIES: VANS_ENTRIES } = PERMISSIONS.VANS
const { CATEGORY: WORK_ORDER_VIEW_CATEGORY, ENTRIES: WORK_ORDER_VIEW_ENTRIES } =
  PERMISSIONS.WORK_ORDERS

const { ALLOW, EDIT, VIEW } = ACTIONS

const DENY = 'deny'

function generateValue(
  category: string,
  name: string,
  permissions: IPermission[],
) {
  const hasAllow = hasPermission(permissions).allow(category, name)
  if (hasAllow) return ALLOW

  const hasEdit = hasPermission(permissions).edit(category, name)
  if (hasEdit) return EDIT

  const hasView = hasPermission(permissions).view(category, name)
  if (hasView) return VIEW

  return DENY
}

function generateColumns(
  onColumnFilterUpdate: Function,
  retrieveTableData: Function,
) {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: LOCATION_SETTINGS_ENTRIES.WORKDAY_INFO.NAME,
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          LOCATION_SETTINGS_ENTRIES.WORKDAY_INFO.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: LOCATION_SETTINGS_ENTRIES.ZIP_CODES.NAME,
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          LOCATION_SETTINGS_ENTRIES.ZIP_CODES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: LOCATION_SETTINGS_ENTRIES.ROUTING.NAME,
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          LOCATION_SETTINGS_ENTRIES.ROUTING.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: SCHEDULE_VIEW_ENTRIES.CAN_SCHEDULE.NAME,
      accessor: (data: any) =>
        generateValue(
          SCHEDULE_VIEW_CATEGORY,
          SCHEDULE_VIEW_ENTRIES.CAN_SCHEDULE.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: WORK_ORDER_VIEW_ENTRIES.INDIVIDUAL_WORK_ORDER.NAME,
      accessor: (data: any) =>
        generateValue(
          WORK_ORDER_VIEW_CATEGORY,
          WORK_ORDER_VIEW_ENTRIES.INDIVIDUAL_WORK_ORDER.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: CASES_SETTINGS_ENTRIES.EDIT_CASES.NAME,
      accessor: (data: any) =>
        generateValue(
          CASES_SETTINGS_CATEGORY,
          CASES_SETTINGS_ENTRIES.EDIT_CASES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: TECHNICIANS_ENTRIES.EDIT_TECHNICIANS.NAME,
      accessor: (data: any) =>
        generateValue(
          TECHNICIANS_CATEGORY,
          TECHNICIANS_ENTRIES.EDIT_TECHNICIANS.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: VANS_ENTRIES.EDIT_VAN_DETAILS.NAME,
      accessor: (data: any) =>
        generateValue(
          VANS_CATEGORY,
          VANS_ENTRIES.EDIT_VAN_DETAILS.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: SERVICES_ENTRIES.MAKE_PRICE_CHANGE_BUTTON.NAME,
      accessor: (data: any) =>
        generateValue(
          SERVICES_CATEGORY,
          SERVICES_ENTRIES.MAKE_PRICE_CHANGE_BUTTON.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
  ]
}

export default generateColumns
