import { ROUTE_OPTIMIZATION_HOUR_UTC } from '../constants/scheduling'

function hasDayBeenOptimized(
  day: number = Date.now(),
): boolean {
  const whenOptimizationLastRanFor = new Date()
  whenOptimizationLastRanFor.setUTCHours(ROUTE_OPTIMIZATION_HOUR_UTC, 0, 0, 0)

  const hasRouteOptimizationHappenedToday = new Date().getUTCHours() >= ROUTE_OPTIMIZATION_HOUR_UTC
  if (hasRouteOptimizationHappenedToday) {
    whenOptimizationLastRanFor.setDate(whenOptimizationLastRanFor.getDate() + 1)
  }
  
  const dateToTest = new Date(day)

  // Check if the given day is before Route Optimization happens today
  return dateToTest < whenOptimizationLastRanFor
}

// function exists in the backend as well
export default hasDayBeenOptimized