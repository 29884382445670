import { IAtaAccountDefinition } from '../../../../../../../../../bi/entities/AtaAccountDefinition'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateAtaAccountDefinitionColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Account Number',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ accountNumber: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaAccountDefinition, rowIndex: number) => {
        const { accountNumber } = data
        return generateTextField(
          'accountNumber',
          accountNumber?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Account Description',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ accountDescription: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaAccountDefinition, rowIndex: number) => {
        const { accountDescription } = data
        return generateTextField(
          'accountDescription',
          accountDescription,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Parent Acct',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ parentAcct: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaAccountDefinition, rowIndex: number) => {
        const { parentAcct } = data
        return generateTextField(
          'parentAcct',
          parentAcct?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Account Label',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ accountLabel: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaAccountDefinition, rowIndex: number) => {
        const { accountLabel } = data
        return generateTextField(
          'accountLabel',
          accountLabel,
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Include In Cp',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ includeInCp: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IAtaAccountDefinition, rowIndex: number) => {
        const { includeInCp } = data
        return generateTextField(
          'includeInCp',
          includeInCp?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateAtaAccountDefinitionColumns
