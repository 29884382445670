import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useContext, useState } from 'react'
import styles from '../styles.module.css'
import { ToastNotificationContext } from '../../../../global/context/toast-context/ToastNotificationContext'
import { IRefreshCounterProps } from '../../../main/VansMain'
import EditVanContentContainer from '../form/EditVanContentContainer'
import EditVanModalHeader from '../header/EditVanModalHeader'
import { fetchData } from '../../../../global/utils/fetch'
import { updateVan } from '../../../api'
import { VanContext } from '../../../../global/context/van-context/VanContext'
import ConfirmVanChangesModal from '../../van-form-component/edit/ConfirmVanChangesModal'
import parseForValidationError from '../../../../global/utils/error/parse-for-validation-error'

interface IEditVanModalProps {
  isOpen: boolean
  handleClosed: Function
  refresh: IRefreshCounterProps
}

function EditVanModal({ isOpen, handleClosed, refresh }: IEditVanModalProps) {
  const { vanData } = useContext(VanContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { refreshCounter, setRefreshCounter } = refresh

  const [isLoading, setIsLoading] = useState(false)
  const [isFormDataValid, setIsFormDataValid] = useState(true)

  const [operatingStatusNote, setOperatingStatusNote] = useState<string>('')

  const [isConfirmVanChangesModalOpen, setIsConfirmVanChangesModalOpen] =
    useState<boolean>(false)

  const [inventoryStatus, setInventoryStatus] = useState<string>('')

  async function handleEditVan(e: any) {
    try {
      if (!vanData) {
        return alert('Error: No Van Data, please refresh page.')
      }

      const vanDataCopy = { ...vanData }
      if (inventoryStatus) {
        vanDataCopy.inventoryStatus = inventoryStatus
      }

      setIsLoading(true)
      await fetchData(updateVan(vanDataCopy, operatingStatusNote))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully updated van!',
        severity: 'success',
      })
      setRefreshCounter(() => refreshCounter + 1)
      setIsConfirmVanChangesModalOpen(false)

      handleClosed()
    } catch (error: any) {
      console.error('Error updating van:', error)

      const match = parseForValidationError(error.message)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Error updating Van${match ? `: ${match}` : ''}`,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleSaveClicked(e: any): Promise<void> {
    setIsConfirmVanChangesModalOpen(true)

    return Promise.resolve()
  }

  function handleConfirmVanChangesModalClosed() {
    setIsConfirmVanChangesModalOpen(false)
  }

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        onClose={() => handleClosed()}
      >
        <Box className={styles.editVanModal}>
          <div className={styles.formContainer}>
            <ConfirmVanChangesModal
              isOpen={isConfirmVanChangesModalOpen}
              handleClosed={handleConfirmVanChangesModalClosed}
              handleSaveClicked={handleEditVan}
              inventoryStatus={inventoryStatus}
              setInventoryStatus={setInventoryStatus}
            />
            <EditVanModalHeader
              handleClosed={handleClosed}
              handleSaveClicked={handleSaveClicked}
              isSaveDisabled={!isFormDataValid || isLoading}
            />
            {Boolean(vanData?.objectId) && (
              <EditVanContentContainer
                setIsFormDataValid={setIsFormDataValid}
                setOperatingStatusNote={setOperatingStatusNote}
              />
            )}
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default EditVanModal
