import { IInvoice } from '../../../../../../app/entities/Invoice'
import { STATUSES } from '../../../constants/invoice'

// This function should be mirrored in app/eworkflows/invoice/status/is-transaction-settled.ts
function isTransactionSettled(invoice: IInvoice) {
  if (!invoice.transactionId) {
    return false
  }

  // STATUSES.PAID is included as it was the settled status before being updated in January 2024
  return [STATUSES.PAID, STATUSES.SETTLED].includes(invoice.status as STATUSES)
}

export default isTransactionSettled