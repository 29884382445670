import React from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import PromoCodeModal from '../../promo-modal/container/PromoCodeModal'
import { IRefreshProps } from '../PromoCodesMain'
import { ILocationFilters } from '../../../../work-orders/components/main/WorkOrdersMain'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import styles from './styles.module.css'
interface IPromoCodeHeaderProps {
  refresh: IRefreshProps
  locationFilters: ILocationFilters
  disabled: boolean
}
export default function PromoCodePageHeader(props: IPromoCodeHeaderProps) {
  const { refreshCounter, setRefreshCounter } = props.refresh
  const { locationFilter, setLocationFilter } = props.locationFilters
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClosed = () => {
    setOpen(false)
  }

  return (
    <>
      <div className={styles.promoHeaderContainer}>
        <LocationDropDown
          onLocationChange={(val: string) => {
            setLocationFilter(val)
          }}
        />
        <PrimaryButton
          buttonName='Create'
          onClick={handleOpen}
          disabled={props.disabled}
        />
      </div>
      {open && (
        <PromoCodeModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={setRefreshCounter}
        />
      )}
    </>
  )
}
