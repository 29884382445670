import styles from './styles.module.css'
import { Select, SelectListObject } from '../../../../../../global/components/select/Select'
import { diameter, width, aspectRatio } from './static-values'

interface ICustomTireSizeProps {
  setSelectedItem: Function
  selectedItem: any
}
function mapArrayToSelect(arr: Array<string>) {
  let mapped: SelectListObject[] = []

  arr.forEach((item: string) => {
    const obj = {
      name: item,
      objectId: item,
    }

    mapped.push(obj)
  })

  return mapped
}

export default function CustomTireSize(props: ICustomTireSizeProps) {
  const { selectedItem, setSelectedItem } = props

  function onChange(e: any) {
    setSelectedItem((prevItem: any) => {
      const newItem = { ...prevItem }
      switch (e.target.name) {
        case 'frontTireWidth':
          newItem.frontTire.width = e.target.value
          break
        case 'frontTireAspectRatio':
          newItem.frontTire.aspectRatio = e.target.value
          break
        case 'frontTireDiameter':
          newItem.frontTire.diameter = e.target.value
          break
        case 'rearTireWidth':
          newItem.rearTire.width = e.target.value
          break
        case 'rearTireAspectRatio':
          newItem.rearTire.aspectRatio = e.target.value
          break
        case 'rearTireDiameter':
          newItem.rearTire.diameter = e.target.value
          break
        default:
          break
      }
      return newItem
    })
  }

  return (
    <div className={styles.customTireSizeContainer}>
      <div className={styles.specifyCustomSizeTitle}>Specify Custom Size</div>
      <div className={styles.frontRearContainer}>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdownContainerLabel}>Front</div>
          <div className={styles.selectContainer}>
            <Select
              onChange={onChange}
              name="frontTireWidth"
              width="141px"
              label="Width"
              data={mapArrayToSelect(width)}
              defaultValue={selectedItem.frontTire.width}
              size="small"
            />
            <Select
              onChange={onChange}
              name="frontTireAspectRatio"
              width="141px"
              label="Aspect Ratio"
              data={mapArrayToSelect(aspectRatio)}
              defaultValue={selectedItem.frontTire.aspectRatio}
              size="small"
            />
            <Select
              onChange={onChange}
              name="frontTireDiameter"
              width="141px"
              label="Diameter"
              data={mapArrayToSelect(diameter)}
              defaultValue={selectedItem.frontTire.diameter}
              size="small"
            />
          </div>
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdownContainerLabel}>Rear</div>
          <div className={styles.selectContainer}>
            <Select
              onChange={onChange}
              name="rearTireWidth"
              width="141px"
              label="Width"
              data={mapArrayToSelect(width)}
              defaultValue={selectedItem.rearTire.width}
              size="small"
            />
            <Select
              onChange={onChange}
              name="rearTireAspectRatio"
              width="141px"
              label="Aspect Ratio"
              data={mapArrayToSelect(aspectRatio)}
              defaultValue={selectedItem.rearTire.aspectRatio}
              size="small"
            />
            <Select
              onChange={onChange}
              name="rearTireDiameter"
              width="141px"
              label="Diameter"
              data={mapArrayToSelect(diameter)}
              defaultValue={selectedItem.rearTire.diameter}
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
