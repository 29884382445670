import { IDtcStorePartial } from '../../../../../../../../bi/entities/DtcStore'
import tableStyles from '../../../../../../global/components/table/styles.module.css'
import buttonStyles from '../../styles.module.css'
import TextField from '@mui/material/TextField'
import { useState, ChangeEvent } from 'react'

interface IDtcStoreRowProps {
  handleCancel: Function
  handleSaveNewRow: Function
}

const COLUMN_NAMES = [
  'storeId',
  'sapId',
  'address',
  'city',
  'state',
  'region',
  'zip',
  'storeOpenDate',
  'storeCloseDate',
  'brand',
  'trCust1',
  'trCust2',
] as (
  | 'storeId'
  | 'sapId'
  | 'address'
  | 'city'
  | 'state'
  | 'region'
  | 'zip'
  | 'storeOpenDate'
  | 'storeCloseDate'
  | 'brand'
  | 'trCust1'
  | 'trCust2'
)[]

export default function CreateDtcStoreRow(props: IDtcStoreRowProps) {
  const [newDtcStore, setNewDtcStore] = useState<IDtcStorePartial>({
    storeId: undefined,
    sapId: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    region: undefined,
    zip: undefined,
    storeOpenDate: undefined,
    storeCloseDate: undefined,
    brand: undefined,
    trCust1: undefined,
    trCust2: undefined,
  })

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setNewDtcStore({ ...newDtcStore, [e.target.name]: e.target.value })
  }

  function addNewDtcStore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    props.handleSaveNewRow(newDtcStore)
  }

  const rows = COLUMN_NAMES.map((columnName) => {
    return (
      <td className={tableStyles.bodyRowCell}>
        <TextField
          size='small'
          name={`${columnName}`}
          value={newDtcStore[`${columnName}`]}
          sx={{ minWidth: 100 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </td>
    )
  })

  return (
    <tr className={tableStyles.bodyRow}>
      {rows}
      <td className={tableStyles.bodyRowCell}>
        <div className={buttonStyles.buttonCell}>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={addNewDtcStore}
            >
              Add
            </a>
          </div>
          <div className={buttonStyles.line}></div>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={() => props.handleCancel()}
            >
              Cancel
            </a>
          </div>
        </div>
      </td>
    </tr>
  )
}
